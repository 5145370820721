import baseApiSlice from 'api/baseApiSlice';

const PROFILE_API_URL = '/api/ti/profile';

const profileApiWithTags = baseApiSlice.enhanceEndpoints({
  addTagTypes: ['profile', 'meetingLogs'],
});

const profileApiSlice = profileApiWithTags.injectEndpoints({
  endpoints: builder => ({
    getProfileCourseTranscript: builder.query({
      query: ({ username, courseId }) => ({
        url: `${PROFILE_API_URL}/${username}/course/${courseId}/transcript/`,
      }),
    }),

    getProfileDetail: builder.query({
      query: ({ username }) => ({
        url: `${PROFILE_API_URL}/${username}/about/`,
      }),
      providesTags: (result, error, { username }) => [
        { type: 'profile', id: username },
      ],
    }),

    updateProfile: builder.mutation({
      query: ({ username, data }) => ({
        url: `${PROFILE_API_URL}/${username}/about/`,
        method: 'PATCH',
        data,
      }),
      invalidatesTags: (result, error, { username }) => [
        { type: 'profile', id: username },
      ],
    }),

    getSpeechTranscript: builder.query<any, any>({
      query: username => ({
        url: `/api/speech_craft/transcript/?page_size=100`,
        params: {
          ...(username && {
            username,
          }),
        },
      }),
      keepUnusedDataFor: 0,
    }),

    getMeetingRoles: builder.query({
      query: () => ({
        url: `${PROFILE_API_URL}/meeting/roles/`,
      }),
    }),

    getMeetingLogs: builder.query({
      query: (username = '') => ({
        url: `${PROFILE_API_URL}/meeting/logs/${
          username ? username + '/' : ''
        }`,
      }),
      providesTags: (result, error, args) => [
        { type: 'meetingLogs', id: 'LIST' },
      ],
    }),

    createMeetingLog: builder.mutation({
      query: data => ({
        url: `${PROFILE_API_URL}/meeting/logs/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: (result, error, args) => [
        { type: 'meetingLogs', id: 'LIST' },
      ],
    }),

    deleteMeetingLog: builder.mutation({
      query: meetingLogId => ({
        url: `${PROFILE_API_URL}/meeting/logs/${meetingLogId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, args) => [
        { type: 'meetingLogs', id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useGetProfileCourseTranscriptQuery,
  useGetProfileDetailQuery,
  useUpdateProfileMutation,
  useGetSpeechTranscriptQuery,
  useGetMeetingRolesQuery,
  useGetMeetingLogsQuery,
  useCreateMeetingLogMutation,
  useDeleteMeetingLogMutation,
} = profileApiSlice;

export default profileApiSlice;
