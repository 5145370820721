import baseApiSlice from 'api/baseApiSlice';
import { TOASTMASTER_API_URL } from 'config';

const SPEECH_HISTORY_API_URL = `${TOASTMASTER_API_URL}/badges/speech_logs/`;

enum ITags {
  LEARNING_BADGES = 'Learning badges',
  FEEDBACK_BADGES = 'Feedback badges',
  SPEECH_BADGES = 'Speech badges',
  SPEECH_HISTOORY = 'speech history',
  EVALUATION_FORMS = 'evaluation forms',
  COURSE_CERTIFICATES = 'course certificates',
}

const badgesApiWithTags = baseApiSlice.enhanceEndpoints({
  addTagTypes: [
    ITags.LEARNING_BADGES,
    ITags.FEEDBACK_BADGES,
    ITags.SPEECH_HISTOORY,
    ITags.EVALUATION_FORMS,
    ITags.SPEECH_BADGES,
    ITags.COURSE_CERTIFICATES,
  ],
});

const badgesApiSlice = badgesApiWithTags.injectEndpoints({
  endpoints: builder => ({
    getLearnigBadges: builder.query<any, void>({
      query: (username: any) => ({
        url: `${TOASTMASTER_API_URL}/badges/learner_badges/?badge_parent_type=learner_badge&filter_user=single`,
        params: {
          ...(username && {
            username,
          }),
        },
      }),
      providesTags: (result, error, username) => [
        { type: ITags.LEARNING_BADGES, username },
      ],
    }),

    getFeedbacksBadges: builder.query<any, void>({
      query: (username: any) => ({
        url: `${TOASTMASTER_API_URL}/badges/feedback_badges/?badge_parent_type=learner_badge&filter_user=single`,
        params: {
          ...(username && {
            username,
          }),
        },
      }),
      providesTags: (result, error, username) => [
        { type: ITags.FEEDBACK_BADGES, username },
      ],
    }),

    getSpeechCraftBadges: builder.query<any, void>({
      query: (username: any) => ({
        url: `${TOASTMASTER_API_URL}/badges/speech_badges/`,
        params: {
          ...(username && {
            username,
          }),
        },
      }),
      providesTags: (result, error, username) => [
        { type: ITags.SPEECH_BADGES, username },
      ],
    }),

    getSpeechHistory: builder.query<any, any>({
      query: ({ username, club }: any) => ({
        url: SPEECH_HISTORY_API_URL,
        params: {
          ...(username && {
            username,
          }),
          ...(club && {
            club: club,
          }),
        },
      }),
      providesTags: (result, error, username) => [
        { type: ITags.SPEECH_HISTOORY, username },
      ],
    }),

    getEvaluationForms: builder.query<any, void>({
      query: () => ({
        url: `${TOASTMASTER_API_URL}/evaluation_forms/form/list_basic/`,
      }),
      providesTags: [ITags.EVALUATION_FORMS],
    }),

    updateSpeechHistory: builder.mutation({
      query: ({ id, ...payload }) => {
        return {
          url: `${SPEECH_HISTORY_API_URL}${id}/`,
          method: 'PATCH',
          data: { ...payload },
        };
      },
      invalidatesTags: [ITags.SPEECH_HISTOORY],
    }),

    getCoursesCertificates: builder.query<any, string>({
      query: (username: string) => ({
        url: `${TOASTMASTER_API_URL}/ti/certificates/earned/`,
        params: {
          ...(username && {
            user: username,
          }),
        },
      }),
      providesTags: [ITags.COURSE_CERTIFICATES],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetLearnigBadgesQuery,
  useGetSpeechCraftBadgesQuery,
  useGetFeedbacksBadgesQuery,
  useGetSpeechHistoryQuery,
  useUpdateSpeechHistoryMutation,
  useGetEvaluationFormsQuery,
  useGetCoursesCertificatesQuery,
} = badgesApiSlice;

export default badgesApiSlice;
