import { Box, Button, Stack } from '@mui/material';
import { toast } from 'react-toastify';
// @ts-ignore
import { getAuthenticatedUser } from '@edx/frontend-platform/auth';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { FieldWrapper } from 'elements/Form/style';
import { StyledLabel as FormControlLabel } from 'elements/FormControlLabel/style';
import TextField from 'elements/Form/TextField';
import { useUpdateProfileMutation } from 'api/profileApi';

const EditField = ({ title, fieldTitle, initialValue, handleClose }: any) => {
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();
  const { username } = getAuthenticatedUser();

  const validationSchema = Yup.object().shape({
    [fieldTitle]: Yup.string().required('Field is required'),
  });

  const handleUpdateProfile = async (values: any) => {
    try {
      await updateProfile({
        username,
        data: values,
      }).unwrap();
      toast.success(
        `Your ${title.toLowerCase()} content has been updated successfully.`,
      );
      handleClose();
    } catch (error) {
      toast.error('Something went wrong, please try again later');
    }
  };

  const {
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
  } = useFormik({
    initialValues: {
      [fieldTitle]: initialValue || '',
    },
    onSubmit: values => handleUpdateProfile(values),
    validationSchema,
  });

  return (
    <Box>
      <FieldWrapper mb={4}>
        <FormControlLabel
          label={title}
          labelPlacement='top'
          control={
            <TextField
              multiline
              fullWidth
              className={values[fieldTitle] ? 'has-data' : ''}
              id={title}
              placeholder='Share your interests and goals with your fellow Speechcrafters.'
              value={values[fieldTitle]}
              onChange={(e: any) => {
                setFieldValue(fieldTitle, e.target.value);
                setFieldTouched(fieldTitle, true, false);
              }}
              helperText={touched[fieldTitle] && errors[fieldTitle]}
              error={!!touched[fieldTitle] && !!errors[fieldTitle]}
            />
          }
        />
      </FieldWrapper>

      <Stack
        direction='row'
        alignItems='center'
        justifyContent='flex-end'
        gap={1}
        mt={15}
      >
        <Button variant='outlined' onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant='contained'
          onClick={() => handleSubmit()}
          disabled={isLoading}
        >
          Update
        </Button>
      </Stack>
    </Box>
  );
};

export default EditField;
