import baseApiSlice from 'api/baseApiSlice';

const baseUrl = 'api';

const speechDashboardApiWithTags = baseApiSlice.enhanceEndpoints({
  addTagTypes: [],
});

const speechDashboardApiSlice = speechDashboardApiWithTags.injectEndpoints({
  endpoints: builder => ({
    getSpeechCompletion: builder.query<any, void>({
      query: () => ({
        url: `${baseUrl}/speech_craft/speech_completion/`,
      }),
      keepUnusedDataFor: 1,
    }),

    getSpeechcraftLearning: builder.mutation<any, any>({
      query: courseKey => ({
        url: `${baseUrl}/events/attendees/progress/?course_key=${courseKey}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetSpeechCompletionQuery,
  useGetSpeechcraftLearningMutation,
} = speechDashboardApiSlice;

export default speechDashboardApiSlice;
