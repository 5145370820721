import { useDispatch } from 'react-redux';
import { toggleConfirmation } from 'pages/Modals/ducks/slice';
import { useUpdateSpeechHistoryMutation } from 'api/achievementApi';
import { toast } from 'react-toastify';

export const ConfirmationHistoryHOC = (Component: any) => {
  const Content = (props: any) => {
    const dispatch = useDispatch();
    const [updateSpeechHistory] = useUpdateSpeechHistoryMutation();

    const handleSubmitForm = async (data: any) => {
      const { request, ...rest } = data;
      const payload = {
        ...rest,
        description: request,
      };

      try {
        await updateSpeechHistory(payload).unwrap();
        toast.success('Speech Log has been updated successfully!');
      } catch (error) {
        toast.error('Something went wrong!');
      } finally {
        dispatch(toggleConfirmation({} as any));
      }
    };

    return (
      <Component
        {...props}
        handleConfirmation={(data: any) => handleSubmitForm(data)}
      />
    );
  };
  return Content;
};
