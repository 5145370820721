import { Button, Stack, Typography } from '@mui/material';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { Chip } from '@mui/material';
import { ClubModal } from './../style';
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp';

import { DashboardRoutes as ROUTES } from 'routes';

const SelectClub = ({ clubs, username, handleClose }: any) => {
  return (
    <ClubModal>
      <Typography variant='h2' mb={6}>
        Select Club
      </Typography>
      <Stack direction='row' flexWrap='wrap'>
        {clubs.map(({ id, name }: any) => (
          <Button
            component={RouterLink}
            to={generatePath(ROUTES.PROFILE.path, {
              clubId: id,
              username,
              activeSection: 'about',
            })}
          >
            {name}
            <KeyboardArrowRightSharpIcon />
          </Button>
        ))}
      </Stack>

      <Stack alignItems='flex-end' mt={12}>
        <Button onClick={handleClose} variant='contained'>
          Close
        </Button>
      </Stack>
    </ClubModal>
  );
};

export default SelectClub;
