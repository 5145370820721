import { FC } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from 'elements/Form/Select';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import FormHelperText from '@mui/material/FormHelperText';

import { StyledFormControl, StyledFormLabel } from 'elements/DropdownFieldV2/style';

const DropdownFieldV2: FC<any> = ({
  label,
  value,
  handleChange,
  error,
  options = [],
  name,
  helperText,
  className,
  popUpMenuClassName,
  isLoading,
  disabled = false,
  labelId
}) => {
  return (
    <StyledFormControl error={!!error} disabled={disabled} fullWidth>
      {label && (
        <StyledFormLabel id='select-field-label'>{label}</StyledFormLabel>
      )}
      <Select
        labelId={labelId}
        name={name}
        value={value}
        className={className}
        onChange={handleChange}
        IconComponent={(props: any) => <ArrowDropDownRoundedIcon {...props} />}
        MenuProps={{ className : `secondary-options ${popUpMenuClassName}` }}
        fullWidth
        displayEmpty
      >
        {isLoading ? (
          <MenuItem value=''>
            <em>Loading</em>
          </MenuItem>
        ) : (
          options.map((option: any, index: any) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))
        )}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </StyledFormControl>
  );
};

export default DropdownFieldV2;
