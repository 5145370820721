import { Box, styled } from '@mui/material';
import { theme } from 'styles/theme';
import { DeviceUp } from 'styles/breakpoints';

export const Container = styled(Box)`
  max-width: 1140px;
  width: 100%;
  margin: 30px auto 0;

  & ul {
    margin-top: 0px;
  }
`;
