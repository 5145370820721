import baseApiSlice from 'api/baseApiSlice';

const SHARE_BADGE_API_URL = '/api/badges/shared_view';

const badgeSharingApiWithTags = baseApiSlice.enhanceEndpoints({
  addTagTypes: ['sharing'],
});

const badgeSharingApiSlice = badgeSharingApiWithTags.injectEndpoints({
  endpoints: builder => ({
    getSharedBadge: builder.query({
      query: slug => ({
        url: `${SHARE_BADGE_API_URL}/${slug}/`,
      }),
    }),
  }),
});

export const { useGetSharedBadgeQuery } = badgeSharingApiSlice;

export default badgeSharingApiSlice;
