import { Stack, Typography } from '@mui/material';

// @ts-ignore
import { getAuthenticatedUser } from '@edx/frontend-platform/auth';
import { ReactComponent as EditIcon } from 'assets/images/editIcon.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/deleteIcon.svg';

import { getUserName } from 'utils/utility';

import {
  FeedbackItemBox,
  FeedbackItemBlock,
  FeedbackItemStack,
  FeedbackItemAvatar,
  FeedbackReponseBox,
  FeedbackBadgeBox,
  BadgeAvatar,
  BadgeText,
  FeedbackText,
  FeedbackTextSection,
  FeedbackActionsHolder,
} from './style';

const FeedbackItem = ({
  feedback,
  handleEditFeedback = () => {},
  handleDeleteFeedback = () => {},
}: any) => {
  const user = getAuthenticatedUser();
  const canUpdateFeedback = user.userId === feedback.sender.id;

  const {
    badge,
    sender,
    receiver,
    feedback: feedbackText,
    feedbackRequest: request,
    isEdited,
  } = feedback;

  return (
    <FeedbackItemBox>
      {request ? (
        <FeedbackItemBlock>
          <FeedbackItemStack pr={4}>
            <FeedbackItemAvatar>
              {getUserName(receiver).slice(0, 1).toUpperCase()}
            </FeedbackItemAvatar>
            <div>
              <Typography className='username-title' variant='body1'>
                {getUserName(receiver)}
              </Typography>
              <Typography variant='body1'>
                {request.request}
              </Typography>
            </div>
          </FeedbackItemStack>
        </FeedbackItemBlock>
      ) : null}
      {feedbackText && (
        <FeedbackItemBlock className='full-width'>
          <FeedbackItemStack mt={request ? 2.5 : 0}>
            <FeedbackItemAvatar>
              {getUserName(sender).slice(0, 1).toUpperCase()}
            </FeedbackItemAvatar>

            <Stack gap={2}>
              <Typography
                className='username-title'
                variant='body1'
                fontWeight={500}
              >
                {getUserName(sender)}
              </Typography>
            </Stack>
          </FeedbackItemStack>

          <FeedbackReponseBox>
            {badge ? (
              <FeedbackBadgeBox>
                <BadgeAvatar src={badge.badgeMeta.image} alt={badge.badgeMeta.name} />
                <BadgeText variant='caption'>{badge.badgeMeta.name}</BadgeText>
              </FeedbackBadgeBox>
            ) : null}
            <FeedbackTextSection direction='row' spacing={8}>
              <FeedbackText variant='body1'>
                {feedbackText}
                {isEdited && ` (Edited)`}
              </FeedbackText>
              {canUpdateFeedback && (
                <FeedbackActionsHolder direction='row' spacing={1}>
                  <Typography
                    component='span'
                    onClick={() => handleDeleteFeedback(feedback)}
                  >
                    <DeleteIcon />
                  </Typography>
                  <Typography
                    component='span'
                    onClick={() => handleEditFeedback(feedback)}
                  >
                    <EditIcon />
                  </Typography>
                </FeedbackActionsHolder>
              )}
            </FeedbackTextSection>
          </FeedbackReponseBox>
        </FeedbackItemBlock>
      )}
    </FeedbackItemBox>
  );
};

export default FeedbackItem;
