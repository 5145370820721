import { FormControl, FormLabel, styled, Box } from '@mui/material';
import { theme } from 'styles/theme';

export const StyledFormLabel = styled(FormLabel)`
  display: flex;
  align-items: start;
  color: ${({ theme }) => theme.palette.text.primary};
  margin-top: 8px;
  font-weight: 700;
`;

export const StyledFormControl = styled(FormControl)`
  .secondary-dropdown {
    font-size: 0.875em;
    font-weight: 700;

    .MuiSvgIcon-root {
      font-size: 20px;
    }
  }
`;

export const CloseIconWrapper = styled(Box)`
  position: absolute;
  right: 30px;
  top: 16px;

  svg {
    font-size: 0.875em;
    cursor: pointer;
  }
`;
