import { Box, styled, ListItem } from '@mui/material';
import { DeviceUp } from 'styles/breakpoints';

export const ProfileTabsContainer = styled(Box)`
  div[role='tabpanel'] {
    a {
      color: ${({ theme }) => theme.palette.ternary.tertiary3};
      text-decoration: underline;

      &.MuiToggleButtonGroup-grouped {
        text-decoration: none;
        color: ${({ theme }) => theme.palette.ternary.greyShade};
      }
    }
  }
`;

export const Container = styled(Box)`
  max-width: 1110px;
  width: 100%;
  padding: 0 15px;
  margin: 0px auto;
`;

export const Header = styled(Box)`
  background: ${({ theme }) => theme.palette.ternary.light};
  padding-bottom: 30px;
`;

export const StyledListItem = styled(ListItem)`
  flex-wrap: wrap;

  @media ${DeviceUp.md} {
    flex-wrap: nowrap;
  }

  .flex-wrap {
    flex-wrap: wrap !important;
  }

  .club-name {
    &:not(:last-child) {
      &:after {
        content: " , ";
        display: inline-flex;
        vertical-align: top;
        margin: 0 2px;
      }
    }
  }

  .MuiBox-root {
    flex-wrap: wrap;

    @media ${DeviceUp.sm} {
      flex-wrap: nowrap;
    }
  }

  & .MuiAvatar-root {
    width: 120px;
    height: 120px;
    margin-right: 20px;
    border: 6px solid ${({ theme }) => theme.palette.ternary.main};
  }
`;

export const UserInfo = styled(Box)`
  display: flex;
  flex-wrap: nowrap !important; 
  align-items: center;
  min-width: 100%;
  margin: 5px 0;

  @media ${DeviceUp.sm} {
    margin-right: 33px;
    min-width: 180px;
  }

  &.email-box {
    @media ${DeviceUp.sm} {
      // min-width: 210px;
    }

    span {
      overflow: hidden;
      word-wrap: break-word;
    }

    a {
      color: ${({ theme }) => theme.palette.ternary.tertiary3};
      border-bottom: 1px solid ${({ theme }) => theme.palette.ternary.tertiary3};
    }
  }
  
  .MuiChip-root {
    margin: 3px;
  }

  &:last-child {
    margin-right: 0px;
  }

  svg {
    min-width: 25px;
  }
`;
