import baseApiSlice from 'api/baseApiSlice';

const BASE_API_URL = 'api/speech_craft';

enum ITags {
  INCOMPLETE_SPEECH = 'incomplete speech',
}

const speechValidationApiWithTags = baseApiSlice.enhanceEndpoints({
  addTagTypes: [ITags.INCOMPLETE_SPEECH],
});

const ApiSlice = speechValidationApiWithTags.injectEndpoints({
  endpoints: builder => ({
    getSpeeches: builder.query<any, void>({
      query: () => ({
        url: `${BASE_API_URL}/speech/`,
      }),
    }),

    getInCompleteSpeech: builder.query<any, void>({
      query: () => ({
        url: `${BASE_API_URL}/incomplete_speech/`,
      }),
      providesTags: [ITags.INCOMPLETE_SPEECH],
    }),

    getSpeechEventAttendees: builder.query<any, any>({
      query: speechId => ({
        url: `api/events/speech/${speechId}/attendees/`,
        providesTags: (result: any, error: any, { speechId }: any) => [
          { type: 'attendees', id: speechId },
        ],
      }),
      keepUnusedDataFor: 1,
    }),

    validateSpeech: builder.mutation<any, void>({
      query: data => ({
        url: `${BASE_API_URL}/validate_speech/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [ITags.INCOMPLETE_SPEECH],
    }),

    deleteIncompleteSpeech: builder.mutation<any, void>({
      query: id => ({
        url: `${BASE_API_URL}/delete_speech/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [ITags.INCOMPLETE_SPEECH],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSpeechesQuery,
  useGetInCompleteSpeechQuery,
  useValidateSpeechMutation,
  useGetSpeechEventAttendeesQuery,
  useDeleteIncompleteSpeechMutation,
} = ApiSlice;

export default ApiSlice;
