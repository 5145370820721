import baseApiSlice from 'api/baseApiSlice';

const REQUESTS_API_URL = '/api/requests/review_requests';

const reviewRequestsApiWithTags = baseApiSlice.enhanceEndpoints({
  addTagTypes: ['reviewRequest'],
});

const reviewRequestsApiSlice = reviewRequestsApiWithTags.injectEndpoints({
  endpoints: builder => ({
    getTranscript: builder.query({
      query: requestId => ({
        url: `${REQUESTS_API_URL}/${requestId}/transcript/`,
      }),
    }),

    reviewRequest: builder.mutation({
      query: ({ requestId, data }) => ({
        url: `${REQUESTS_API_URL}/${requestId}/`,
        method: 'PATCH',
        data,
      }),
    }),
  }),
});

export const { useGetTranscriptQuery, useReviewRequestMutation } =
  reviewRequestsApiSlice;

export default reviewRequestsApiSlice;
