import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import querystring from 'querystring';

import axiosClient from 'utils/client';
import { LMS_BASE_URL } from 'config';

const initialState = {
  data: {},
  isLoading: false,
  isError: false,
  isFilterUpdated: false,
};

const coursesAPI = {
  async fetchRecentActivity({ club, page = 1 }: any) {
    const params = {
      ...(club && {
        club,
      }),
      page,
      page_size: '10'
    };

    let qs = querystring.stringify(params);
    if (qs) {
      qs = `?${qs}`;
    }

    const result = await axiosClient({
      url: `${LMS_BASE_URL}/api/bcm/activity/${qs}`,
      method: 'GET',
    });
    return result;
  },
};

export const getRecentActivity = createAsyncThunk(
  'courses/getRecentActivity',
  async (props: any) => {
    const response = await coursesAPI.fetchRecentActivity(props);
    return response.data;
  },
);

const slice = createSlice({
  name: 'userRecentActivity',
  initialState: initialState,
  reducers: {
    updateFilters: (state: any) => {
      return {
        ...state,
        isFilterUpdated: true,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getRecentActivity.fulfilled, (state, action) => {
        const { resetInitialState } = action.meta.arg;

        if (resetInitialState) {
          return {
            data: action.payload,
            isLoading: false,
            isError: false,
            isFilterUpdated: false,
          };
        }

        return {
          data: {
            next: get(action, 'payload.next', null),
            count: get(action, 'payload.count', 0),
            results: [
              ...get(state, `data.results`, []),
              ...get(action, 'payload.results', []),
            ],
          },
          isLoading: false,
          isError: false,
          isFilterUpdated: false,
        };
      })
      .addCase(getRecentActivity.pending, state => {
        state.isLoading = true;
      })
      .addCase(getRecentActivity.rejected, state => {
        state.isError = true;
        state.isLoading = false;
        state.isFilterUpdated = false;
      });
  },
});

export const { updateFilters } = slice.actions;

export default slice.reducer;
