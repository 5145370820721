import { Box, styled, Stack } from '@mui/material';
import { themeConfig, theme } from 'styles/theme';
import { DeviceUp } from 'styles/breakpoints';

export const SearchFieldWrapper = styled('div')`
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  width: 352px;
  border: 2px solid ${({ theme }) => theme.palette.text.primary};
  border-radius: 3px;
  height: 40px;

  .MuiFormControl-root {
    min-width: 114px;
  }

  .search-dropdown {
    margin: 0;

    .MuiOutlinedInput-notchedOutline {
      border: none !important;
      outline: none !important;
      border-radius: none !important;
      top: 0;
    }

    .MuiSelect-select {
      padding: 7px;
    }
  }

  .search-holder {
    flex: 1;

    .MuiInputBase-root {
      margin: 0;
    }

    .MuiInputBase-input {
      height: 36px !important;
      padding-right: 30px !important;
      outline: none !important;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none !important;
      outline: none !important;
      border-radius: none !important;
      border-left: 1px solid ${({ theme }) => theme.palette.ternary.main} !important;
    }

    .MuiSvgIcon-root {
      position: absolute;
      right: 5px;
      top: 7px;
    }
  }
`;

export const SearchFieldHolder = styled(Box)`
  display: flex;
  justify-content: end;
  flex: 1;
`;

export const PreviewWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  min-height: 600px;

  @media ${DeviceUp.sm} {
    flex-wrap: no-wrap;
  }

  #pdf-controls {
    display: none;
  }
`;

export const MediaPreview = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-grow: 1;
  max-height: 600px;

  @media ${DeviceUp.sm} {
    flex: 6;
  }

  img,
  iframe {
    max-height: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    border: none;
  }

  video {
    height: auto;
    min-height: 300px;
  }
`;

export const DescriptionWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  margin: 20px 0 0 0;

  @media ${DeviceUp.sm} {
    flex: 3;
    margin: 0 0 0 25px;
  }
`;

export const Description = styled(Box)``;


export const ActionsHolder = styled(Stack)`
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;

  & button.icon-holder {
    background: ${({ theme }) => theme.palette.ternary.white};
    margin-left: 10px;
    border: 1px solid ${({ theme }) => theme.palette.ternary.tertiary12};
    border-radius: 4px;
    min-width: 40px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover,
    &:focus {
      background: ${({theme}) => theme.palette.ternary.light};
    }

    img {
      max-width: 18px;
    }
  }

  img {
    height: 30px;
    width: 30px;
    cursor: pointer;
  }
`;

export const ResourcesWrapper = styled(Box)`
  margin: 40px 0;

  .infinite-scroll-component  {
    padding: 0 3px;
  }
`;
