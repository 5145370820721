import { styled, Box } from '@mui/material';

export const ConfirmationBox = styled(Box)`
    text-align: center;
    max-width: 300px;
    margin: 0 auto;

    & .fs-20 {
        font-size: 20px;
    }
`;

export const ImageHolder = styled('div')`
    margin: 20px auto 30px;
    max-width: 160px;

    & img {
        object-fit: contain;
        width: 100%;
    }
`;

export const CTAButtons = styled(Box)`
    margin-top: 40px;
    align-items: center;
    justify-content: center;

    & .MuiButton-root {
        padding: 6px;
    }
`;
