import * as Yup from 'yup';

export const getValidationSchema = (
  isSpeechcraft: boolean,
  isFeedbackRequest = false,
) =>
  Yup.object({
    ...(!isSpeechcraft && {
      clubId: Yup.string().required('Field is required'),
    }),
    clubMembers: Yup.array()
      .min(1, 'At least 1 member is required')
      .required('Field is required'),
    ...(!isFeedbackRequest && {
      feedback: Yup.string().required('Field is required'),
    }),
    ...(isFeedbackRequest && {
      request: Yup.string().required('Field is required'),
    }),
  });
