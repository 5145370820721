import { createSelector } from 'reselect';

const stateSelector = (state: any) => state.courses;

export const getActiveCoursesSelector = createSelector(
  stateSelector,
  courses => courses.activeEnrolledCourses,
);

export const getSuggestedCoursesSelector = createSelector(
  stateSelector,
  courses => courses.suggestedCourses,
);

export const getCompletedCoursesSelector = createSelector(
  stateSelector,
  courses => courses.completedEnrolledCourses,
);

export const getResourcesSelector = createSelector(
  (state: any) => state.resources,
  resources => resources,
);

export const getEvaluationFormSelector = createSelector(
  (state: any) => state.evaluationFeedback,
  ({ evaluationForms }) => evaluationForms,
);

export const getReceivedEvaluationFormSelector = createSelector(
  (state: any) => state.evaluationFeedback,
  ({ receivedEvaluationForms }) => receivedEvaluationForms,
);

export const getResourceByIdSelector = createSelector(
  (state: any) => state.resources,
  (_: any, categoryId: any) => categoryId,
  (resources, categoryId) => ({
    data: resources.data.filter(
      (category: any) => category.id === Number(categoryId),
    ),
    isLoading: resources.isLoading,
    isError: resources.isError,
  }),
);
