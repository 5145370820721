import { useEffect } from 'react';

import { getFirstErrorKey } from 'utils/utility';

export const useValidations = (formik: any = {}): any => {
  const { isValid, submitCount, isSubmitting, errors } = formik;

  useEffect(() => {
    if (!isValid && submitCount !== 0 && isSubmitting) {
      const firstErrorKey = getFirstErrorKey(errors);
      if (global.window.document.getElementsByName(firstErrorKey).length) {
        global.window.document.getElementsByName(firstErrorKey)[0].focus();
      }
    }
  }, [submitCount, isValid, errors, isSubmitting]);
};
