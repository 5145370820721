import { FC, useEffect } from 'react';
import { Typography, Box, Grid } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import { useSelector, useDispatch } from 'react-redux';

import Loader from 'elements/Loader';
import Breadcrumb from 'components/Breadcrumb';
import { getPlatformName } from 'utils/utility';
import MemberCard from 'pages/MyCohort/MemberCard';
import {
  selectEventMembers,
  selectAllEventMembers,
  fetchEventsMembers,
  resetEventMembers,
} from 'pages/MyCohort/slice';

import { Container } from './style';

const links = [
  {
    label: 'Home',
    url: `${getPlatformName()}`,
  },
  {
    label: 'My Cohort',
  },
];

const MyCohort: FC<any> = () => {
  const dispatch = useDispatch();
  const { ref, inView } = useInView();

  const { isFetching, isLoading }: any = useSelector(selectEventMembers);
  const eventMembers: any = useSelector(selectAllEventMembers);

  useEffect(() => {
    dispatch(fetchEventsMembers({}));

    return () => {
      dispatch(resetEventMembers());
    };
  }, [dispatch]);

  useEffect(() => {
    if (inView) {
      dispatch(fetchEventsMembers({}));
    }
  }, [dispatch, inView]);

  return (
    <Container>
      <Breadcrumb links={links} />
      <Typography variant='h2' my={7.5}>
        My Cohort
      </Typography>
      <Box mt={4}>
        {eventMembers.length > 0 ? (
          <Grid container spacing={3}>
            {eventMembers?.map((member: any, index: any) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                ref={index + 1 === eventMembers.length ? ref : null}
              >
                <MemberCard member={member} />
              </Grid>
            ))}
          </Grid>
        ) : null}
        {isFetching ? (
          <Box py={4}>
            <Loader />
          </Box>
        ) : null}
      </Box>
      {!(isFetching || isLoading) && eventMembers.length <= 0 ? (
        <Typography variant='h1' textAlign='center' mt={20}>
          Members not found
        </Typography>
      ) : null}
    </Container>
  );
};

export default MyCohort;
