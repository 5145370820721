import baseApiSlice from 'api/baseApiSlice';
import { camelizeKeys } from 'humps';
import { NOTIFICATION_TYPES } from 'config';

const NOTIFICATION_API_URL = '/api/ti/notifications';
enum ITags {
  NOTIFICATIONS = 'notifications',
}

const notificationApiWithTags = baseApiSlice.enhanceEndpoints({
  addTagTypes: [ITags.NOTIFICATIONS],
});

const notificationApiSlice = notificationApiWithTags.injectEndpoints({
  endpoints: builder => ({
    getUnreadNotification: builder.query({
      query: _ => ({
        url: `${NOTIFICATION_API_URL}/unread_list/?page_size=10`,
      }),
      providesTags: [ITags.NOTIFICATIONS],
    }),

    updateUnreadNotification: builder.mutation({
      query: slug => {
        return {
          url: `${NOTIFICATION_API_URL}/read/${slug}/`,
          method: 'GET',
        };
      },
      invalidatesTags: [ITags.NOTIFICATIONS],
    }),

    getNotificationDetails: builder.query({
      query: slug => ({
        url: `${NOTIFICATION_API_URL}/detail/${slug}/`,
      }),
      keepUnusedDataFor: 0,
      transformResponse: response => {
        const data = camelizeKeys(response?.data);
        const { target, type } = data;

        if (type === NOTIFICATION_TYPES.feedbackRequested) {
          const { feedbackResponse } = target;
          return {
            id: feedbackResponse?.id ?? '', // feedack id
            receiver: target.sender,
            sender: target.receiver,
            badge: feedbackResponse?.badge ?? null,
            feedback: feedbackResponse?.feedback ?? null,
            isEdited: !!feedbackResponse?.isEdited,
            club: target?.club ?? {},
            event: target?.event ?? {},
            feedbackRequest: {
              request: target?.request,
              id: target?.id, //feedback request id
              club: target?.club?.id,
              event: target?.event,
              sender: target?.sender,
              receiver: target?.receiver,
              isPrivate: target?.isPrivate,
            },
          };
        }

        return { ...target, isDeleted: target === null };
      },
    }),
  }),
});

export const {
  useGetUnreadNotificationQuery,
  useUpdateUnreadNotificationMutation,
  useGetNotificationDetailsQuery,
  useLazyGetUnreadNotificationQuery,
  useLazyGetNotificationDetailsQuery
} = notificationApiSlice;

export default notificationApiSlice;
