import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import { LMS_BASE_URL } from 'config';
import axiosClient from 'utils/client';

const BASE_API_URL = `${LMS_BASE_URL}/api/events`;

const eventMembersAdapter = createEntityAdapter({
  selectId: (a: any) => a.user.id,
});

const initialState = eventMembersAdapter.getInitialState({
  isLoading: true,
  isFetching: false,
  isSuccess: false,
  isError: false,
  error: null as any,
  hasMore: true,
  page: 0,
});

export const fetchEventsMembers = createAsyncThunk(
  'eventMembers/list',
  async (_: any, thunkApi: any) => {
    const page = thunkApi.getState().eventMembers.page;
    const nextPage = page + 1;
    const res = await axiosClient.get(
      `${BASE_API_URL}/cohort/list/?page=${nextPage}&page_size=20`,
    );

    return res.data;
  },
  {
    condition: (_, thunkApi: any) => {
      const { isFetching, hasMore } = thunkApi.getState().eventMembers;
      if (isFetching || !hasMore) return false;
    },
  },
);

const eventMembersSlice = createSlice({
  name: 'eventMembers',
  initialState,

  reducers: {
    resetEventMembers: () => initialState,
  },

  extraReducers: builder => {
    builder
      .addCase(fetchEventsMembers.pending, (state, action) => {
        state.isFetching = true;
        state.isSuccess = false;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchEventsMembers.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.isLoading = false;

        const { results = [], next } = action.payload || {};

        if (results.length <= 0) {
          state.hasMore = false;
          return;
        }

        state.hasMore = !!next;
        state.page += 1;

        eventMembersAdapter.upsertMany(state, results);
      })
      .addCase(fetchEventsMembers.rejected, (state, action) => {
        state.isFetching = false;
        state.isSuccess = false;
        state.isError = true;
        state.error = action.payload;
      });
  },
});

export const { resetEventMembers } = eventMembersSlice.actions;

export default eventMembersSlice.reducer;

export const selectEventMembers = (state: any) => state.eventMembers;

export const { selectAll: selectAllEventMembers } =
  eventMembersAdapter.getSelectors(selectEventMembers);
