import { FunctionComponent, useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { isEmpty, get, map } from 'lodash';
import classNames from 'classnames';
import { camelizeKeys } from 'humps';

import Button from 'elements/Button';
import Loader from 'elements/Loader';

import { useGetPathProgressQuery } from 'api/bcmDashboardApi';

import { Container } from 'pages/BcmDashboard/components/style';
import { HOC } from 'elements/Modal';

const columns: GridColDef[] = [
  {
    field: 'level',
    headerName: 'Level',
    width: 125,
    sortable: false,
    renderHeader: () => <Typography variant='h4'>Level</Typography>,
  },
  {
    field: 'project',
    headerName: 'Project',
    width: 275,
    sortable: false,
    renderHeader: () => <Typography variant='h4'>Project</Typography>,
  },
  {
    field: 'speechTitle',
    width: 275,
    sortable: false,
    renderHeader: () => <Typography variant='h4'>Speech Title</Typography>,
    renderCell: (params: any) => {
      const { complete, speechTitle } = params.row;
      if (complete) {
        return speechTitle || '--';
      }

      return '';
    },
  },

  {
    field: 'completionDate',
    width: 250,
    sortable: false,
    renderHeader: () => <Typography variant='h4'>Completion Date</Typography>,
    renderCell: (params: any) => {
      const { complete, completionDate } = params.row;
      if (complete) {
        return completionDate
          ? dayjs(completionDate)?.format('MMMM DD, YYYY')
          : '--';
      }

      return '';
    },
  },
];

const PathProgressModal: FunctionComponent<any> = ({
  handleClose,
  courseId,
  username,
}) => {
  const {
    data = {},
    isFetching: isFetchingProgress,
    isSuccess,
    isError,
  } = useGetPathProgressQuery(
    { user: username, courseId },
    { skip: !username || !courseId },
  );

  const rows = useMemo(() => {
    let rows: any = [];
    const { blocks, speeches } = data;

    map(get(blocks, 'children', []), (value, index) => {
      const { displayName: level, children } = value || {};

      if (isEmpty(children)) {
        return;
      }

      map(children, (value, index) => {
        const { id, displayName, complete } = value || {};
        const speechId: any = Object.keys(camelizeKeys({ [id]: '' }))[0];
        const { speechTitle, speechDate } = speeches[speechId] || {};

        rows.push({
          id,
          level,
          project: displayName,
          speechTitle,
          completionDate: speechDate,
          complete,
        });
      });
    });

    return rows;
  }, [data]);

  const message = isError
    ? 'Oops! Something went wrong.'
    : isSuccess && isEmpty(rows)
    ? `No records found!`
    : '';

  const scrollCellIntoView = (cell: HTMLElement) => {
    const topOffset = 109;
    let cellRect = cell.getBoundingClientRect();
    const modal = document.querySelector('[role="dialog"]');

    if (!modal) {
      return;
    }

    // get cell bounding box relative to modal window
    const modalRect = modal.getBoundingClientRect();
    const relCellRect = {
      top: cellRect.top - modalRect.top,
      left: cellRect.left - modalRect.left,
      bottom: cellRect.top - modalRect.top + cellRect.height,
      right: cellRect.left - modalRect.left + cellRect.width,
    };

    const isInViewPort =
      relCellRect.top >= topOffset &&
      relCellRect.left >= 0 &&
      relCellRect.bottom <= modalRect.height &&
      relCellRect.right <= modalRect.width;

    if (!isInViewPort) {
      cell.scrollIntoView({ block: 'nearest' });

      // leave some space the bottom
      const scrollableDivs = document.getElementsByClassName(
        'MuiDialogContent-root',
      );
      if (!scrollableDivs.length) {
        return;
      }

      cellRect = cell.getBoundingClientRect();
      const relCellBottom = cellRect.top - modalRect.top + cellRect.height;

      if (Math.abs(relCellBottom - modal.clientHeight) <= 5) {
        const scrollableDiv = scrollableDivs[0];
        scrollableDiv.scrollTo({
          top: scrollableDiv.scrollTop + cellRect.height / 2,
        });
      }
    }
  };

  return (
    <>
      <Container>
        {isFetchingProgress ? (
          <Loader />
        ) : !isEmpty(rows) && !isError ? (
          <DataGrid
            autoHeight
            getRowHeight={() => 'auto'}
            hideFooter={true}
            rows={rows}
            columns={columns}
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnMenu
            getRowClassName={(params: any) =>
              classNames({
                'disable-selected-row': !params.row.complete,
              })
            }
            slotProps={{
              cell: {
                onFocus: (event: any) =>
                  scrollCellIntoView(event.currentTarget),
              },
            }}
          />
        ) : (
          <>
            {message && (
              <Typography variant='h3' textAlign='center'>
                {message}
              </Typography>
            )}
          </>
        )}
      </Container>
      <Stack alignItems='center' justifyContent='center'>
        <Button variant='outlined' onClick={handleClose}>
          Close
        </Button>
      </Stack>
    </>
  );
};

export default HOC(PathProgressModal);
