import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  request: {},
  comment: '',
  denyReason: '',
  requestModalOpen: false,
  approvalModalOpen: false,
  denyModalOpen: false,
  confirmModalOpen: false,
  isApproving: false,
};

const requestReviewSlice = createSlice({
  name: 'requestReview',
  initialState: initialState,
  reducers: {
    resetRequestReview: () => initialState,

    notificationResetRequestReview: state => {
      return {
        ...initialState,
        request: state.request,
      };
    },

    setRequest: (state, action) => {
      state.request = action.payload;
    },

    setComment: (state, action) => {
      state.comment = action.payload;
    },

    setDenyReason: (state, action) => {
      state.denyReason = action.payload;
    },

    handleOpenRequestModal: (state, action) => {
      return {
        ...initialState,
        request: action.payload,
        requestModalOpen: true,
      };
    },

    handleOpenApprovalModal: state => {
      state.requestModalOpen = false;
      state.approvalModalOpen = true;
      state.isApproving = true;
    },

    handleOpenDenyModal: state => {
      state.requestModalOpen = false;
      state.denyModalOpen = true;
      state.isApproving = false;
    },

    handleOpenConfirmationModal: state => {
      state.approvalModalOpen = false;
      state.denyModalOpen = false;
      state.confirmModalOpen = true;
    },

    handleConfirmGoBack: state => {
      state.confirmModalOpen = false;
      if (state.isApproving) {
        state.approvalModalOpen = true;
      } else {
        state.denyModalOpen = true;
      }
    },

    handleApprovalGoback: state => {
      state.approvalModalOpen = false;
      state.requestModalOpen = true;
      state.comment = '';
    },

    handleDenyGoBack: state => {
      state.denyModalOpen = false;
      state.requestModalOpen = true;
      state.denyReason = '';
      state.comment = '';
    },
  },
});

export const {
  handleApprovalGoback,
  handleConfirmGoBack,
  handleDenyGoBack,
  handleOpenApprovalModal,
  handleOpenConfirmationModal,
  handleOpenDenyModal,
  handleOpenRequestModal,
  resetRequestReview,
  setComment,
  setDenyReason,
  setRequest,
  notificationResetRequestReview,
} = requestReviewSlice.actions;

export default requestReviewSlice.reducer;

export const selectReviewReqeust = (state: any) => state.requestReview;
