import { styled, FormControlLabel as MuiLabel, FormHelperText } from '@mui/material';
import { theme } from 'styles/theme';

export const StyledLabel = styled(MuiLabel)`
    display: flex;
    margin: 0;

    &.MuiFormControlLabel-labelPlacementTop {
        display: flex;
        align-items: start;

        span.MuiFormControlLabel-label {
            font-weight: 700;
            margin-bottom: 5px;
        }
    }

    & .MuiInputBase-root {
        height: 50px;

        &.MuiInputBase-multiline {
            height: auto;
        }

        .MuiInputAdornment-root {
            margin: 0 2px 0 -8px;
        }
    }

    & .primary-color-icon {
        svg {
            color: ${({ theme }) => theme.palette.ternary.greyShade}
        }
    }
`;

export const ErrorMsg = styled(FormHelperText)`
    color: ${({ theme }) => theme.palette.error.main};
    margin-left: 14px !important;
`;
