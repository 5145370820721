import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import { LMS_BASE_URL } from 'config';
import axiosClient from 'utils/client';

const SELF_ASSESSMENT_FORMS_API_URL = `${LMS_BASE_URL}/api/self_assessment/form/`;

const selfAssessmentsFormsAdapter = createEntityAdapter({
  sortComparer: (a: any, b: any) => a.title.localeCompare(b.title),
});

const initialState = selfAssessmentsFormsAdapter.getInitialState({
  isFetching: false,
  isSuccess: false,
  isError: false,
  error: null as any,
});

export const fetchSelfAssessmentForms = createAsyncThunk(
  'selfAssessmentForms/list',
  async ({ eventId, username }: any, thunkApi: any) => {
    const res = await axiosClient.get(
      `${SELF_ASSESSMENT_FORMS_API_URL}?event=${eventId}&username=${username}&page_size=50`,
    );
    return res.data;
  },
  {
    condition: (eventId, thunkApi: any) => {
      const { isFetching } = thunkApi.getState().selfAssessmentForms;
      if (isFetching || !eventId) return false;
    },
  },
);

const selfAssessmentFormsSlice = createSlice({
  name: 'selfAssessmentForms',
  initialState,

  reducers: {
    resetState: () => initialState,
  },

  extraReducers: builder => {
    builder
      .addCase(fetchSelfAssessmentForms.pending, (state, action) => {
        state.isFetching = true;
        state.isSuccess = false;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchSelfAssessmentForms.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;

        if (action.payload.length <= 0) {
          return;
        }

        selfAssessmentsFormsAdapter.upsertMany(state, action.payload);
      })
      .addCase(fetchSelfAssessmentForms.rejected, (state, action) => {
        state.isFetching = false;
        state.isSuccess = false;
        state.isError = true;
        state.error = action.payload;
      });
  },
});

export const { resetState } = selfAssessmentFormsSlice.actions;

export default selfAssessmentFormsSlice.reducer;

export const selectSelfAssessmentForms = (state: any) =>
  state.selfAssessmentForms;

export const { selectAll: selectAllSelfAssessmentForms } =
  selfAssessmentsFormsAdapter.getSelectors(selectSelfAssessmentForms);
