import React, { memo, FunctionComponent, useMemo } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { get, findIndex, map } from 'lodash';
import { TabLinksWrapper, TabPanelsWrapper } from 'pages/Dashboard/style';

type propsTypes = {
  tabs: { ariaControls: string; label: string; id: string; component?: any }[];
  defaultTab: string;
  baseRoute: string;
};

interface TabPanelProps {
  children?: React.ReactNode;
  value: any;
  id: any;
  index: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, id, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={id}
      aria-labelledby={id}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const StyledTabs: FunctionComponent<propsTypes> = ({
  tabs,
  defaultTab,
  baseRoute,
}) => {
  const routerParams = useParams<any>();
  const activeSection = get(routerParams, 'activeSection', defaultTab);

  const getActiveTabIndex = useMemo(() => {
    const index = findIndex(tabs, tab => activeSection === tab.id);
    return index || 0;
  }, [activeSection, tabs]);

  return (
    <>
      <TabLinksWrapper>
        <Box role="navigation" aria-label="content" sx={{ borderBottom: 2, borderColor: 'transparent' }}>
          <Tabs
            TabIndicatorProps={{
              children: <span className='MuiTabs-indicatorSpan' />,
            }}
            value={getActiveTabIndex}
            aria-label='profile tabs'
            selectionFollowsFocus
            scrollButtons
            variant='scrollable'
            allowScrollButtonsMobile
          >
            {map(tabs, (tab, index) => (
              <Tab
                key={index}
                aria-controls={tab.ariaControls}
                label={tab.label}
                component={Link}
                to={`${baseRoute}/${tab.id}`}
              />
            ))}
          </Tabs>
        </Box>
      </TabLinksWrapper>
      <TabPanelsWrapper>
        {map(tabs, (tab, index) => (
          <TabPanel id={tab.ariaControls} value={getActiveTabIndex} index={index} key={index}>
            {tab.component}
          </TabPanel>
        ))}
      </TabPanelsWrapper>
    </>
  );
};

export default memo(StyledTabs);
