import { Box, Button, Stack } from '@mui/material';
// @ts-ignore
import { getAuthenticatedUser } from '@edx/frontend-platform/auth';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { FieldWrapper } from 'elements/Form/style';
import { StyledLabel as FormControlLabel } from 'elements/FormControlLabel/style';
import TextField from 'elements/Form/TextField';
import { useUpdateProfileMutation } from 'api/profileApi';
import { toast } from 'react-toastify';
import { PhoneField } from 'elements/Form/PhoneField';
import { PhoneNumberUtil } from 'google-libphonenumber';

// Extend Yup's types to include the custom method
declare module 'yup' {
  interface StringSchema {
    phoneNumberValidation(message?: string, region?: string): StringSchema;
  }
}

const phoneUtil = PhoneNumberUtil.getInstance();

// Add the custom method to the string schema
Yup.addMethod(Yup.string, 'phoneNumberValidation', function(message, region = 'US') {
  return this.test('phoneNumberValidation', message, function(value) {
    const { path, createError } = this;
    if (value === undefined) {
      return true;
    }
    try {
      const number = phoneUtil.parseAndKeepRawInput(value, region);
      if (phoneUtil.isValidNumber(number)) {
        return true;
      }
    } catch (e) {
      // Parsing failed
    }
    return createError({ path, message: message || 'Invalid phone number' });
  });
});


const validationSchema = Yup.object().shape(
  {
    phoneNumber: Yup.string()
      .matches(
        /^\+?1?\d*$/,
        'Phone number can only contain numbers and a single + at start',
      )
      .when(['contactEmail', 'additionalInformation'], {
        is: (contactEmail: string, additionalInformation: string) => !contactEmail && !additionalInformation,
        then: Yup.string().required('One field must be completed to update'),
      })
      .phoneNumberValidation('Please enter a valid phone number'),
    contactEmail: Yup.string()
      .email('Please enter a valid email address')
      .when(['phoneNumber', 'additionalInformation'], {
        is: (phoneNumber: string, additionalInformation: string) => !phoneNumber && !additionalInformation,
        then: Yup.string().required('One field must be completed to update'),
      }),
      additionalInformation: Yup.string().when(['phoneNumber', 'contactEmail'], {
      is: (phoneNumber: string, contactEmail: string) =>
        !phoneNumber && !contactEmail,
      then: Yup.string().required('One field must be completed to update'),
    }),
  },
  [
    ['phoneNumber', 'contactEmail'],
    ['phoneNumber', 'additionalInformation'],
    ['contactEmail', 'additionalInformation'],
  ],
);

const SpeechcrafterContactEdit = ({
  handleClose,
  phoneNumber,
  contactEmail,
  additionalInformation,
}: any) => {
  const { username } = getAuthenticatedUser();
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();

  const handleUpdateProfile = async (values: any) => {
    try {
      updateProfile({ username, data: values }).unwrap();
      toast.success('Your contact informatiion has been updated successfully');
      handleClose();
    } catch (error) {
      toast.error('Something went wrong, please try again later');
    }
  };

  const {
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
  } = useFormik({
    initialValues: {
      phoneNumber: phoneNumber || '',
      contactEmail: contactEmail || '',
      additionalInformation: additionalInformation || '',
    },
    validationSchema: validationSchema,
    onSubmit: handleUpdateProfile,
  });

  return (
    <Box>
      <FieldWrapper mb={4}>
        <FormControlLabel
          label='Phone Number'
          labelPlacement='top'
          control={
            <PhoneField
              id='phoneNumber'
              className={values.phoneNumber ? 'has-data' : ''}
              value={values.phoneNumber}
              onChange={(value: any) => {
                setFieldValue('phoneNumber', value);
                setFieldTouched('phoneNumber', true, false);
              }}
              helperText={touched['phoneNumber'] && errors['phoneNumber']}
              error={!!touched['phoneNumber'] && !!errors['phoneNumber']} 
            />
          }
        />
      </FieldWrapper>

      <FieldWrapper mb={4}>
        <FormControlLabel
          label='Email'
          labelPlacement='top'
          control={
            <TextField
              fullWidth
              className={values.contactEmail ? 'has-data' : ''}
              id='contactEmail'
              value={values.contactEmail}
              onChange={(e: any) => {
                setFieldValue('contactEmail', e.target.value);
                setFieldTouched('contactEmail', true, false);
              }}
              helperText={touched['contactEmail'] && errors['contactEmail']}
              error={!!touched['contactEmail'] && !!errors['contactEmail']}
            />
          }
        />
      </FieldWrapper>

      <FieldWrapper mb={4}>
        <FormControlLabel
          label='Additional Information'
          labelPlacement='top'
          control={
            <TextField
              multiline
              fullWidth
              className={values.additionalInformation ? 'has-data' : ''}
              id='otherinfo'
              value={values.additionalInformation}
              onChange={(e: any) => {
                setFieldValue('additionalInformation', e.target.value);
                setFieldTouched('additionalInformation', true, false);
              }}
              helperText={touched['additionalInformation'] && errors['additionalInformation']}
              error={!!touched['additionalInformation'] && !!errors['additionalInformation']}
            />
          }
        />
      </FieldWrapper>

      <Stack
        direction='row'
        alignItems='center'
        justifyContent='flex-end'
        gap={1}
        mt={15}
      >
        <Button variant='outlined' onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant='contained'
          onClick={() => handleSubmit()}
          disabled={isLoading}
        >
          Update
        </Button>
      </Stack>
    </Box>
  );
};

export default SpeechcrafterContactEdit;
