import { Box, Button, Typography } from '@mui/material';

const denyReasons = [
  {
    title: 'Missing speech',
    description:
      'The member did not present one or more of the speeches required in the level or path. Note the missing speech(es) and which project(s) need to be completed in your comments.',
  },
  {
    title: 'Incomplete assignment',
    description:
      'The member did not complete an assignment, or a component of an assignment, associated with this level or path. Note details related to the incomplete assignment(s) and project(s) in your comments.',
  },
  {
    title: 'Additional details requested',
    description:
      'The member did not provide all documentation needed to confirm project and/or speech completion in this level or path. Provide specific details and documentation as needed in your comments.',
  },
];

const DenyDetails = ({ reason, comment, handleClose }: any) => {
  const reasonIndex = +reason - 1;
  const reasonTitle = denyReasons[reasonIndex].title;
  const reasonDescription = denyReasons[reasonIndex].description;

  return (
    <Box>
      <Typography variant='h3'>Reason</Typography>
      <Typography variant='body1'>
        <Typography variant='h4' component='span'>
          {reasonTitle}:{' '}
        </Typography>
        {reasonDescription}
      </Typography>

      <Typography variant='h3' mt={7}>
        Comments provided
      </Typography>
      <Typography variant='body1'>{comment}</Typography>

      <Box mt={10} textAlign='center'>
        <Button variant='outlined' onClick={handleClose}>
          Close
        </Button>
      </Box>
    </Box>
  );
};

export default DenyDetails;
