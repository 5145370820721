import { useAppContext } from 'contexts/AppContext';
import { ROLE } from 'config';
import { useIsSpeechCraftMFE } from 'hooks';

const useRoles = () => {
  const { roles: rolesContainers } = useAppContext();
  const isSpeechcraftMfe = useIsSpeechCraftMFE();
  let isCoordinator = false;
  let isSpeechcrafter = false;

  if (!isSpeechcraftMfe) {
    const isBcm = rolesContainers.some(({ roles }: any) => {
      return roles?.some(({ isBcm }: any) => isBcm);
    });
    return { isCoordinator, isSpeechcrafter, isBcm };
  }

  rolesContainers.forEach(({ roles }: any) => {
    roles.forEach(({ positionCode }: { positionCode: string }) => {
      switch (positionCode) {
        case ROLE.SPEECHCRAFT_COORDINATOR.code:
          isCoordinator = true;
          break;

        case ROLE.SPEECHCRAFTER.code:
          isSpeechcrafter = true;
          break;

        default:
          break;
      }
    });
  });

  return { isCoordinator, isSpeechcrafter };
};

export default useRoles;
