// https://stackoverflow.com/questions/65899623/scroll-to-first-invalid-field-with-formik-and-userefs-react

import { useEffect } from 'react';

function useScrollToError(formik: any) {
  const submitting = formik?.isSubmitting;

  useEffect(() => {
    const el = document.querySelector('.Mui-error, [data-error]');
    const fieldName = Object.keys(formik.errors)?.[0];
    (document.querySelector(`#${fieldName}`) as HTMLElement)?.focus();
    (el?.parentElement ?? el)?.scrollIntoView({ block: 'center' });
  }, [submitting]);

  return null;
}

export default useScrollToError;
