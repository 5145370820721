import {FC, memo, useState} from "react";
import {ConfirmationBox, ImageHolder, CTAButtons } from "components/UploadConfirmation/style";
import { Backdrop, CircularProgress, Typography } from "@mui/material";
import Button from "elements/Button";
import ConfirmIcon from "elements/Svgs/Confirm";

const UploadConfirmation: FC<any> = ({data, name, handleClose, handleActivation}) => {
  const [loading, setLoading] = useState(false)

  const handleConfirmation = () => {
    const updatedData = {...data, isActive: !data.isActive}
    setLoading(!loading);
    handleActivation(updatedData, name)
  };
  return (
    <ConfirmationBox>
      <ImageHolder>
        <ConfirmIcon />
      </ImageHolder>
      <Typography className="fs-20" variant="body1">
        The file has been successfully uploaded
      </Typography>
      <CTAButtons>
        <Button onClick={handleClose} variant="contained">Okay</Button>
      </CTAButtons>
    </ConfirmationBox>
  );
};

export default memo(UploadConfirmation);
