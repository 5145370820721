import { memo, FunctionComponent } from 'react';
import dummyImage from 'assets/images/dummyImage.png';

const Avatar: FunctionComponent<any> = ({img, altText}) => {
  return (
    <>
      {
        img ?
        <img src={img} 
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src=dummyImage;
            }} alt={altText} /> : 
        <img src={dummyImage} alt={altText} />
      }
    </>
  );
};


export default memo(Avatar);
