import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Stack, Typography, CircularProgress } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';

import { useGetClubMembersQuery, useGetClubsQuery } from '../feedbackApi';
import useProfilePage from 'pages/Profile/useProfilePage';
import { getUserName } from 'utils/utility';

import { HOC } from 'elements/Modal';
import Confirmation from 'components/Confirmation';
import RequestFeedback from './RequestFeedback';
import GiveFeedback from './GiveFeedback';
import SpeechcraftGiveFeedback from './SpeechcraftGiveFeedback';
import FeedbackItem from './FeedbackItem';
import {
  fetchFeedbacks,
  selectFeedbacks,
  selectAllFeedbacks,
  resetFeedbacks,
} from '../feedbackSlice';

import Button from 'elements/Button';
import { useDeleteFeedbackMutation } from 'pages/Dashboard/components/Feedback/feedbackApi';
import { useIsSpeechCraftMFE } from 'hooks';
import { useAppContext } from 'contexts/AppContext';
import { useGetEventAttendeesQuery } from 'api/speechCraftApi';

import { FeedbackWrapper, FeedbackButtonsBox } from './style';
// @ts-ignore
import { getAuthenticatedUser } from '@edx/frontend-platform/auth';
import EmptyFeedback from './EmptyFeedback';
import { useGetProfileDetailQuery } from 'api/profileApi';

const ConfirmationModal = HOC(Confirmation);

const MemberFeedback: FC<any> = ({ memberName }) => {
  const isSpeechCraftMFE = useIsSpeechCraftMFE();
  const { event } = useAppContext();
  const user = getAuthenticatedUser();

  const { data: userData, isLoading } = useGetProfileDetailQuery(user);

  const [club, setClub] = useState<any>({});
  const [feedbackToUpdate, setFeedbackToUpdate] = useState<any>({});

  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const handleOpenRequestModal = () => setRequestModalOpen(true);
  const handleCloseRequestModal = () => setRequestModalOpen(false);

  const [giveModalOpen, setGiveModalOpen] = useState(false);
  const handleOpenGiveModal = () => setGiveModalOpen(true);
  const handleCloseGiveModal = () => setGiveModalOpen(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleOpenDeleteModal = (feedback: any) => {
    setDeleteModalOpen(true);
    setFeedbackToUpdate(feedback);
  };

  const [isEditModlaOpen, setIsEditModlaOpen] = useState(false);

  const handleEditFeedback = (feedback: any) => {
    setFeedbackToUpdate(feedback);
    setIsEditModlaOpen(true);
    handleOpenGiveModal();
  };

  const resetEditModal = () => {
    setFeedbackToUpdate({});
    setIsEditModlaOpen(false);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
    setFeedbackToUpdate({});
  };

  const dispatch = useDispatch();

  const { isFetching } = useSelector(selectFeedbacks);
  const feedbacks: any = useSelector(selectAllFeedbacks);

  const { isMemberProfilePage, clubId, username } = useProfilePage();

  const { data: clubs } = useGetClubsQuery(
    {},
    { skip: !isMemberProfilePage || !clubId || isSpeechCraftMFE },
  );

  const { data: eventMembersData } = useGetEventAttendeesQuery(
    { attendeesType: 'all' },
    { skip: !isMemberProfilePage || !isSpeechCraftMFE },
  );

  const { data: clubMembers } = useGetClubMembersQuery(
    { clubId: club?.id },
    { skip: !isMemberProfilePage || !clubId || !club?.id || isSpeechCraftMFE },
  );
  const [deleteFeedback] = useDeleteFeedbackMutation();

  const handleDeleteConfirmation = useCallback(async () => {
    try {
      await deleteFeedback(feedbackToUpdate?.id).unwrap();
      dispatch(resetFeedbacks());
      dispatch(fetchFeedbacks({ username }));
      handleCloseDeleteModal();
      toast.success(`Your feedback has been successfully deleted!`);
    } catch {
      toast.error('Something went wrong');
    }
  }, [deleteFeedback, dispatch, feedbackToUpdate?.id, username]);

  useEffect(() => {
    const club =
      clubs?.find((club: any) => club.id.toString() === clubId) || {};
    setClub(club);
  }, [clubId, clubs]);

  const feedbackProps = useMemo(() => {
    if (!isMemberProfilePage) {
      return {};
    }

    if (isSpeechCraftMFE) {
      const { attendees } = eventMembersData || {};
      return {
        slectedEvent: event?.id,
        selectedMembers: !isEmpty(feedbackToUpdate)
          ? feedbackToUpdate?.receiver
            ? [feedbackToUpdate?.receiver]
            : []
          : attendees?.filter((member: any) => member.username === username) ||
            [],
      };
    }

    const { name: selectedClubName, id: selectedClub } = club;
    const selectedMembers = !isEmpty(feedbackToUpdate)
      ? feedbackToUpdate?.receiver
        ? [feedbackToUpdate?.receiver]
        : []
      : clubMembers?.filter((member: any) => member.username === username) ||
        [];

    return { selectedClubName, selectedClub, selectedMembers };
  }, [
    isMemberProfilePage,
    isSpeechCraftMFE,
    club,
    feedbackToUpdate,
    clubMembers,
    eventMembersData,
    event?.id,
    username,
  ]);

  useEffect(() => {
    dispatch(fetchFeedbacks({ username }));

    return () => {
      dispatch(resetFeedbacks());
    };
  }, [dispatch, username]);

  const { ref, inView } = useInView({});
  useEffect(() => {
    if (inView) {
      dispatch(fetchFeedbacks({ username }));
    }
  }, [dispatch, inView, username]);

  return (
    <FeedbackWrapper>
      {!isFetching && feedbacks.length <= 0 && 1 > 2 ? (
        <EmptyFeedback
          handleGiveFeedback={handleOpenGiveModal}
          handleRequestFeedback={handleOpenRequestModal}
        />
      ) : (
        <>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            flexWrap='wrap'
            gap={5}
          >
            <Typography variant='h2' fontWeight={500}>
              { isSpeechCraftMFE ? 'Messages for' : 'Feedback for' }
              <Typography
                variant='body1'
                sx={{ textTransform: 'capitalize' }}
                fontWeight={700}
                component='span'
              >
                {' '}
                {memberName ? memberName : getUserName(userData?.user, true)}{' '}
              </Typography>
            </Typography>

            <FeedbackButtonsBox direction='row' gap={1}>
              <Button variant='outlined' onClick={handleOpenGiveModal}>
                {isSpeechCraftMFE ? 'Send Message/Feedback' : 'Give feedback' }
              </Button>
              <Button variant='contained' onClick={handleOpenRequestModal}>
                Request feedback
              </Button>
            </FeedbackButtonsBox>
          </Stack>

          <Stack mt={5} gap={3}>
            {feedbacks.map((feedback: any, i: number, feedbackArray: any) => {
              return feedbackArray.length === i + 1 ? (
                <Box ref={ref} key={feedback.id}>
                  <FeedbackItem
                    feedback={feedback}
                    handleDeleteFeedback={handleOpenDeleteModal}
                    handleEditFeedback={handleEditFeedback}
                  />
                </Box>
              ) : (
                <FeedbackItem
                  key={feedback.id}
                  feedback={feedback}
                  handleDeleteFeedback={handleOpenDeleteModal}
                  handleEditFeedback={handleEditFeedback}
                />
              );
            })}
          </Stack>
        </>
      )}

      {isFetching ? (
        <Stack alignItems='center'>
          <CircularProgress />
        </Stack>
      ) : null}

      <RequestFeedback
        modalOpen={requestModalOpen}
        handleClose={handleCloseRequestModal}
        handleOpen={handleOpenRequestModal}
        {...feedbackProps}
      />

      {React.createElement(
        isSpeechCraftMFE ? SpeechcraftGiveFeedback : GiveFeedback,
        {
          modalOpen: giveModalOpen,
          handleClose: handleCloseGiveModal,
          handleOpen: handleOpenGiveModal,
          feedbackData: feedbackToUpdate,
          isEditModal: isEditModlaOpen,
          resetEditModal: resetEditModal,
          ...feedbackProps,
        },
      )}
      {deleteModalOpen && (
        <ConfirmationModal
          title='Confirmation'
          buttonText='Yes'
          handleClose={handleCloseDeleteModal}
          handleConfirmation={handleDeleteConfirmation}
        >
          {`Are you sure you want to delete the feedback for ${getUserName(
            feedbackToUpdate.receiver, isSpeechCraftMFE
          )}?`}
        </ConfirmationModal>
      )}
    </FeedbackWrapper>
  );
};

export default MemberFeedback;
