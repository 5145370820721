import React, { memo, FunctionComponent, useCallback, useState } from 'react';
import { truncate } from 'lodash';
import classNames from 'classnames';
import { Typography, Box } from '@mui/material';
import { useHistory } from 'react-router-dom';
import Chip from 'elements/Chip';
import Button from 'elements/Button';
import Card from 'elements/Card';
import { LEARNING_APP_BASE_URL, COURSE_STATUS } from 'config';
import Confirmation from 'components/Confirmation';
import { HOC } from 'elements/Modal';
import { CardActionsHolder } from 'pages/Dashboard/components/CourseCard/style';
import { DescriptionHolder } from 'elements/Card/style';
import useProfilePage from 'pages/Profile/useProfilePage';
import CourseTranscript from 'pages/Profile/About/CourseTranscript';
import SpeechTranscript from 'pages/Profile/Transcript/SpeechTranscript';
import { toast } from 'react-toastify';
import compose from 'compose-function';

type CourseCardType = {
  data: any;
  handleCardRegistration?: any;
  isLoading?: boolean;
  canViewCourseProgress?: boolean;
  showCourseProgressAlert?: boolean;
  showSpeechTranscript?: boolean;
  transcriptTitle?: string;
};

const ConfirmationModalHoc = HOC(Confirmation);
const CourseTranscriptModal = compose(HOC)(CourseTranscript);
const SpeechTranscriptModal = HOC(SpeechTranscript);

const getClassNames = (isActiveCourse: boolean, isSuggestedCourse: boolean) =>
  classNames({
    'bg-dark': isActiveCourse,
    'bg-light-yellow': isSuggestedCourse,
  });

const CourseCard: FunctionComponent<CourseCardType> = ({
  data = {},
  handleCardRegistration = () => {},
  isLoading = false,
  canViewCourseProgress = false,
  showCourseProgressAlert = false,
  showSpeechTranscript = false,
  transcriptTitle,
}) => {
  const { course = {}, status, resumeCourseUrl = null } = data;
  const {
    name,
    shortDescription,
    media: { image = {} } = {},
    id: courseId,
  } = course;

  const { username, isMemberProfilePage, isSelfProfilePage } = useProfilePage();

  const isActiveCourse =
    status === COURSE_STATUS.REGISTERED || status === COURSE_STATUS.IN_PROGRESS;
  const isSuggestedCourse = status === COURSE_STATUS.AVAILABLE;
  const isCompletedCourse = status === 'completed';
  const isProfilePage = isMemberProfilePage || isSelfProfilePage;
  const showCardCTA = isProfilePage
    ? canViewCourseProgress || showSpeechTranscript
    : true;

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [transcriptModalOpen, setTranscriptModalOpen] = useState(false);
  const [isSpeechTransciptModalOpen, setIsSpeechTransciptModalOpen] =
    useState(false);

  const handleOpentranscriptModal = () => {
    setTranscriptModalOpen(true);
  };

  const handleClosetranscriptModal = () => {
    setTranscriptModalOpen(false);
  };

  const hadleClickCard = useCallback(async () => {
    if (showSpeechTranscript) {
      setIsSpeechTransciptModalOpen(true);
      return;
    }

    if (isProfilePage) {
      handleOpentranscriptModal();
      return;
    }

    if (isSuggestedCourse) {
      setShowConfirmation(true);
    } else if (resumeCourseUrl) {
      window.location.assign(resumeCourseUrl);
    } else {
      window.location.assign(
        `${LEARNING_APP_BASE_URL}/course/${courseId}/home`,
      );
    }
  }, [
    courseId,
    isProfilePage,
    isSuggestedCourse,
    resumeCourseUrl,
    showSpeechTranscript,
  ]);

  const handleConfirmation = useCallback(async () => {
    await handleCardRegistration(courseId, 'enroll');
    setShowConfirmation(false);
  }, [courseId, handleCardRegistration]);

  const classes = getClassNames(isActiveCourse, isSuggestedCourse);

  return (
    <>
      <Card
        cardImage={image.large}
        altText={name}
        buttonText={isSuggestedCourse ? 'Register' : 'Open'}
        buttonAction={() => hadleClickCard()}
        handleClick={showCardCTA ? () => hadleClickCard() : showCourseProgressAlert ? () => toast.error('Access Denied: Only officers of this club can view transcripts.') :null}
      >
        <Box>
          <Chip label={status} />
        </Box>
        <Typography variant='h3' className='fw-700' mt={1} title={name}>
          {name}
        </Typography>
        {shortDescription && (
          <DescriptionHolder>
            <Typography>
              {truncate(shortDescription, {
                length: 90,
                separator: ' ',
              })}
            </Typography>
          </DescriptionHolder>
        )}
      </Card>
      {showConfirmation && (
        <ConfirmationModalHoc
          handleClose={() => setShowConfirmation(false)}
          data={courseId}
          handleConfirmation={handleConfirmation}
          buttonText='Register'
        >
          Are you sure you want to register in{' '}
          <span className='color-primary'>{name}</span> ?
        </ConfirmationModalHoc>
      )}
      {isSpeechTransciptModalOpen && (
        <SpeechTranscriptModal
          title={transcriptTitle}
          handleClose={() => {
            setIsSpeechTransciptModalOpen(false);
          }}
        />
      )}
      {transcriptModalOpen ? (
        <CourseTranscriptModal
          title={name}
          handleClose={handleClosetranscriptModal}
          courseId={courseId}
          username={username}
        />
      ) : null}
    </>
  );
};

export default memo(CourseCard);
