import baseApiSlice from 'api/baseApiSlice';
import { TOASTMASTER_API_URL } from 'config';

enum ITags {
  THEME='Theme'
}

const themeApiWithTags = baseApiSlice.enhanceEndpoints({ addTagTypes: [ITags.THEME] });

const themeApiSlice = themeApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getTheme: builder.query<any, void>({
      query: () => ({
        url: `${TOASTMASTER_API_URL}/theme/`,
      }),
      providesTags: [ITags.THEME],
    }),
  }),
  overrideExisting: false,
});

export const { useLazyGetThemeQuery } = themeApiSlice;

export default themeApiSlice;
