import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get, uniqBy } from 'lodash';
import { LMS_BASE_URL, REQUEST_TYPES } from 'config';
import querystring from 'querystring';

import axiosClient from 'utils/client';

const APPROVAL_REQUESTS_BASE_URL = `${LMS_BASE_URL}/api/requests/view`;
const EVALUATION_REQUESTS_BASE_URL = `${LMS_BASE_URL}/api/evaluation_forms/requests/`;

const initialState = {
  data: {},
  isLoading: false,
  isError: false,
};

const requestAPI = {
  async fetchRequests({ page, filter , type}: any) {
    const params = {
      ...(page && {
        page: page,
      }),
      ...(filter && {
        filter,
      }),
    };

    let qs = querystring.stringify(params);
    if (qs) {
      qs = `?${qs}`;
    }
    const result = await axiosClient({
      url: `${type === REQUEST_TYPES.APPROVAL ? APPROVAL_REQUESTS_BASE_URL : EVALUATION_REQUESTS_BASE_URL}${qs}`,
      method: 'GET',
    });

    return result;
  },
};

export const getAllRequests = createAsyncThunk(
  'requests/view',
  async (props: any, thunkAPI) => {
    const response = await requestAPI.fetchRequests(props);
    return response.data;
  },
);

const slice = createSlice({
  name: 'requests',
  initialState: initialState,
  reducers: {
    reserRequests: () => {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAllRequests.fulfilled, (state, action) => {
        const shouldResetState = get(action, 'meta.arg.resetData', false);

        state.data = {
          next: get(action, 'payload.next', null),
          previous: get(action, 'payload.previous', null),
          count: get(action, 'payload.count', 0),
          results: shouldResetState
            ? get(action, 'payload.results', [])
            : uniqBy(
                [
                  ...get(state, 'data.results', []),
                  ...get(action, 'payload.results', []),
                ],
                'id',
              ),
        };
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(getAllRequests.pending, state => {
        state.isLoading = true;
      })
      .addCase(getAllRequests.rejected, state => {
        state.isError = true;
        state.isLoading = false;
      });
  },
});

export const { reserRequests } = slice.actions;

export const { reducer } = slice;
