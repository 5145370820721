import React, { memo, FunctionComponent } from 'react';
import { Avatar, Typography } from '@mui/material';
import classNames from 'classnames';

import { StyledBadge, BadgeWrapper } from 'components/Badge/style';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';

const Badge: FunctionComponent<any> = ({
  className,
  badgeContent,
  badgeImage,
  altText,
  color = 'primary',
  isDisabled = false,
  canShareBadge = true,
  handleClick,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);

  const handleMouseOver = () => {
    !isDisabled && canShareBadge && setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <StyledBadge
      //components={'p'}
      color={color}
      badgeContent={isHovered ? <ShareRoundedIcon /> : badgeContent}
      invisible={isHovered ? false : badgeContent > 1 ? false : true}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      tabIndex={ isDisabled ? -1 : 0}
      role='button'
      className={classNames({ disabled: isDisabled })}
      aria-label={`Share ${altText} on social media button to activate presenter`}
    >
      <BadgeWrapper className={classNames({[className]: !!className})}>
        <Typography className='badge-text'>Share Badge</Typography>
        <Avatar
          className='badge-avatar'
          alt={altText}
          src={badgeImage}
          sx={{ width: '100%', height: '100%' }}
        />
      </BadgeWrapper>
    </StyledBadge>
  );
};

export default memo(Badge);
