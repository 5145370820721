import { Box, styled, Paper, Stack } from '@mui/material';

export const Container = styled(Stack)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
`;

export const CompletionProgressCard = styled(Paper)`
  min-height: 436px;
  padding: 30px 15px;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 6px rgba(0, 0, 41, 0.12);
  position: relative;

  .recharts-holder {
    width: 100%;
    height: 100%;
  }

  .recharts-wrapper *:focus {
    outline: none;
  }
  .recharts-wrapper .recharts-layer:focus-visible {
    outline: none;
  }

  .custom-tooltip {
    background: ${({ theme }) => theme.palette.ternary.white};
    border: 1px solid ${({ theme }) => theme.palette.secondary.main};
    border-radius: 3px;
    padding: 20px 15px;
    box-shadow: 0px 4px 16px rgba(0, 0, 41, 0.24);
    max-width: 260px;
    position: relative;
    margin-bottom: 30px;
    word-break: break-word;

    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0 10px;
      border-color: #fff transparent transparent transparent;
      bottom: -8px;
      right: 10px;
      display: none;
    }
  }

  .chart-legend {
    h4,
    h5 {
      position: relative;

      &::before {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: transparent;
        display: inline-block;
        margin-right: 5px;
      }

      &.incomplete-legend::before {
        background: ${({ theme }) => theme.palette.ternary.main};
      }
      &.complete-legend::before {
        background: ${({ theme }) => theme.palette.secondary.main};
      }

      .MuiButtonBase-root {
        color: inherit;
        font-size: inherit;
        text-decoration: none;
        line-height: normal;
        vertical-align: top;
      }
    }
  }
`;
