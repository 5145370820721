import {
  FunctionComponent,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import classNames from 'classnames';
import { FormHelperText, IconButton, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useFormikContext } from 'formik';
import { capitalize } from 'lodash';

import TextField from 'elements/Form/TextField';
import Button from 'elements/Button';
import { useGetSpeechEventAttendeesQuery } from 'api/speechValidationApi';
import { getUserName } from 'utils/utility';

import { ReactComponent as CommentIcon } from 'assets/images/comment-icon.svg';
import dummyImage from 'assets/images/dummyImage.png';
import FeedbackModal from './FeedbackModal';
import SearchIcon from '@mui/icons-material/Search';
import Loader from 'elements/Loader';
import CloseIcon from '@mui/icons-material/Close';

import {
  SpeechCraftContainer,
  StyledAvatar,
  StyledCell,
  SearchForm,
  CloseIconWrapper,
} from '../style';
import { isEmpty, size } from 'lodash';

const columns: GridColDef[] = [
  {
    field: 'speechCrafterName',
    headerName: 'Name',
    flex: 1,
    minWidth: 150,
    sortable: false,
    renderCell: (params: any) => {
      return (
        <StyledCell gap={4}>
          <StyledAvatar
            src={params.row.speechCrafterImage || dummyImage}
            alt='User'
          />
          <Typography variant='h4'>{params.row.speechCrafterName}</Typography>
        </StyledCell>
      );
    },
    renderHeader: () => <Typography variant='h5'>Name</Typography>,
  },
  {
    field: 'coordinator',
    headerName: 'Coordinator',
    flex: 1,
    minWidth: 150,
    sortable: false,
    renderHeader: () => <Typography variant='h5'>Coordinator</Typography>,
    renderCell: (params: any) => (
      <Typography variant='h4'>{params.row.coordinator}</Typography>
    ),
  },
  {
    field: ' ',
    flex: 1,
    minWidth: 170,
    sortable: false,
    renderCell: (params: any) => {
      return (
        <StyledCell gap={4}>
          <CommentIcon />
          <Typography className='secondary-color' variant='h4'>
            Individual feedback
          </Typography>
        </StyledCell>
      );
    },
  },
];

const SpeechCrafter: FunctionComponent<any> = () => {
  const { initialValues, values, setFieldValue, resetForm, errors } =
    useFormikContext<any>();

  const { data = {}, isLoading }: any = useGetSpeechEventAttendeesQuery(
    values.speech?.id,
    { skip: !values.speech?.id },
  );
  const { attendees = [] } = data;

  const [selectedRow, setSelectedRow] = useState<any>({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectionModel, setSelectionModel] = useState<any>([]);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [groupComment, setGroupComment] = useState('');
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');
  const [filteredRows, setFilteredRows] = useState<any>([]);

  const rows = useMemo(() => {
    const rows = attendees.map((user: any) => {
      const { attendees } = initialValues;
      const attendee = attendees.find(({ id }: any) => id === user.id) || {};

      return {
        id: user.id,
        coordinator: user.coordinator,
        speechCrafterName: getUserName(user),
        speechCrafterImage: user?.memberPhotoUrl,
        isValidated: user.isValidated,
        isSelected: !isEmpty(attendee),
        individualComment: attendee.individualComment || '',
        ...user,
      };
    });

    return rows;
  }, [attendees, initialValues]);

  const onRowsSelectionHandler = (selectedRowIds: any) => {
    const filteredRows = selectedRowIds.filter(
      (id: any) => !rows.find((row: any) => row.id === id)?.isValidated,
    );

    setSelectionModel(selectedRowIds);
    const selectedIds = new Set(filteredRows);
    const _selectedRows = rows
      .filter((row: any) => selectedIds.has(row.id))
      .map((filterRow: any) => {
        const updatedRow = selectedRows.find(
          (row: any) => row.id === filterRow.id,
        );

        return updatedRow || filterRow;
      });
    setSelectedRows(_selectedRows);
    setFieldValue(
      'attendees',
      _selectedRows.map((row: any) => ({
        email: row.email,
        firstName: row.firstName,
        id: row.id,
        individualComment: row.individualComment,
        lastName: row.lastName,
        name: row.name,
        profileImage: row?.memberPhotoUrl,
        username: row.username,
      })),
    );
  };

  const isIndividualComment = !isEmpty(selectedRow);

  const rowsCount = size(
    filteredRows?.filter(({ isValidated }: any) => !isValidated),
  );
  const selectedRowsCount = size(
    selectedRows?.filter(({ isValidated }: any) => !isValidated),
  );

  const isCommentAllCtaDisabled =
    !!!rowsCount || (rowsCount === 1 ? true : selectedRowsCount < 2);

  const handleAddComment = useCallback(
    (comment: any) => {
      if (isIndividualComment) {
        setSelectedRows((prevRows: any) =>
          prevRows.map((row: any) => {
            if (row.id === selectedRow.id) {
              return { ...row, individualComment: comment };
            }

            return row;
          }),
        );

        setFieldValue(
          'attendees',
          values.attendees.map((row: any) => {
            if (row.id === selectedRow.id) {
              return { ...row, individualComment: comment };
            }

            return row;
          }),
        );
      } else {
        setGroupComment(comment);
        setFieldValue('groupComment', comment);
      }

      setIsModalOpen(false);
      setSelectedRow({});
    },
    [isIndividualComment, selectedRow.id, setFieldValue, values],
  );

  useEffect(() => {
    if (filter) {
      setFilteredRows(() =>
        rows.filter((row: any) =>
          capitalize(row.speechCrafterName).includes(capitalize(filter)),
        ),
      );
    } else {
      setFilteredRows(rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(rows), filter]);

  useEffect(() => {
    const preSelectedRows = filteredRows.filter(
      (row: any) => row.isValidated || row.isSelected,
    );

    setSelectionModel(preSelectedRows.map((row: any) => row.id));
    setSelectedRows(preSelectedRows);
  }, [filteredRows]);

  useEffect(() => {
    setGroupComment(initialValues.groupComment);
  }, [initialValues]);

  useEffect(() => {
    let disabledRows: any = document.getElementsByClassName(
      'disable-selected-row',
    );
    Array.from(disabledRows).forEach(function (element: any) {
      element.setAttribute('aria-disabled', 'true');
    });
  }, [filteredRows]);

  const handleResetSearch = () => {
    setFilter('');
    setSearch('');
    resetForm();
  };

  const handleSearch = () => {
    if (!search || search === filter) {
      return;
    }

    setFilter(search);
  };

  return (
    <SpeechCraftContainer>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {' '}
          <Stack mb={10} direction='row' justifyContent='space-between'>
            <SearchForm>
              <TextField
                variant='standard'
                placeholder='Search'
                value={search}
                onChange={(event: any) => {
                  const { value } = event.target;
                  setSearch(value);
                }}
                onKeyPress={(event: any) => {
                  if (event.key === 'Enter') {
                    handleSearch();
                  }
                }}
              />
              {search && (
                <CloseIconWrapper onClick={() => handleResetSearch()}>
                  <CloseIcon />
                </CloseIconWrapper>
              )}
              <IconButton aria-label='Search' onClick={handleSearch}>
                <SearchIcon />
              </IconButton>
            </SearchForm>
            <Button
              variant={
                isModalOpen && !isIndividualComment ? 'contained' : 'outlined'
              }
              onClick={() => {
                setIsModalOpen(true);
              }}
              disabled={isCommentAllCtaDisabled}
            >
              Comment to all Speechcrafters
            </Button>
          </Stack>
          <DataGrid
            rowHeight={76}
            hideFooter={true}
            rows={filteredRows}
            columns={columns}
            checkboxSelection
            onCellClick={(data: { field: any; row: any }) => {
              const { field, row } = data;
              if (
                field === ' ' &&
                !!selectedRows.find(
                  (selectedRow: any) =>
                    selectedRow.id === row.id && !selectedRow.isValidated,
                )
              ) {
                setSelectedRow(row);
                setIsModalOpen(true);
              }
            }}
            onRowSelectionModelChange={onRowsSelectionHandler}
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnMenu
            isRowSelectable={(params: { row: { isValidated: any } }) =>
              !params.row.isValidated
            }
            rowSelectionModel={selectionModel}
            getRowClassName={(params: { row: { isValidated: any } }) =>
              classNames({ 'disable-selected-row': params.row.isValidated })
            }
            localeText={{ noRowsLabel: 'No record found!' }}
          />
          {errors.attendees && (
            <fieldset tabIndex={-1} name='attendees' className='error-holder'>
              <FormHelperText className='error-msg'>
                {errors.attendees}
              </FormHelperText>
            </fieldset>
          )}
        </>
      )}

      {isModalOpen && (
        <FeedbackModal
          title='Add Comment'
          handleClose={() => {
            setIsModalOpen(false);
            setSelectedRow({});
          }}
          isIndividualComment={isIndividualComment}
          comment={
            isIndividualComment
              ? selectedRows.find((row: any) => row.id === selectedRow.id)
                  ?.individualComment
              : groupComment
          }
          handleAddComment={handleAddComment}
        />
      )}
    </SpeechCraftContainer>
  );
};

export default SpeechCrafter;
