import { Typography } from '@mui/material';
import React, { memo, FunctionComponent } from 'react';
import { FooterWrapper } from './style';

const Footer: FunctionComponent<any> = () => {
  const currentYear = new Date().getFullYear();
  return (
    <FooterWrapper className="site-footer">
      <Typography variant='body2'>
        &copy; {currentYear} Toastmasters International.  All rights reserved.
      </Typography>
    </FooterWrapper>
  );
};

export default memo(Footer);
