import { useState } from 'react';
import { Avatar, Box, Button, Grid, Typography } from '@mui/material';

import SelectClub from './SelectClub';
import { HOC } from 'elements/Modal';
import { SearchItem, SearchItemButton } from '../style';
import { useHistory, generatePath } from 'react-router-dom';
import { DashboardRoutes as ROUTES } from 'routes';
import { useIsSpeechCraftMFE } from 'hooks';
import { getUserName } from 'utils/utility';

const SelectClubModal = HOC(SelectClub);

const Members = ({ members }: any) => {
  const [clubModalOpen, setClubModalOpen] = useState(false);
  const [currentMember, setCurrentMember] = useState<any>(null);
  const history = useHistory();
  const isSpeechCraftMFE = useIsSpeechCraftMFE();

  const handleCloseClubModal = () => {
    setClubModalOpen(false);
  };

  const handleViewProfile = (member: any) => {
    if (isSpeechCraftMFE) {
      history.push(
        generatePath(ROUTES.SPEECHCRAFTER_PROFILE.path, {
          username: member.username,
          activeSection: 'about',
        }),
      );

      return;
    }

    if (member.clubs.length === 1) {
      const { id } = member.clubs[0];
      const profileURl = generatePath(ROUTES.PROFILE.path, {
        clubId: id,
        username: member.username,
        activeSection: 'about',
      });
      history.push(profileURl);
    } else {
      setCurrentMember(member);
      setClubModalOpen(true);
    }
  };

  return (
    <Box>
      {members.map((member: any) => {
        const { username, email, memberPhotoUrl, profileImage, id } = member;
        return (
          <SearchItem key={id}>
            <Grid container alignItems='center'>
              <Grid className='flex-center' item sm={12} md={5}>
                <Avatar alt={username} src={memberPhotoUrl} imgProps={{'role' : 'img'}} />
                <Typography variant='h4'>{getUserName(member)}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={5}>
                <Typography>{email}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <SearchItemButton>
                  <Button
                    color='secondary'
                    variant='text'
                    onClick={() => handleViewProfile(member)}
                  >
                    View profile
                  </Button>
                </SearchItemButton>
              </Grid>
            </Grid>
          </SearchItem>
        );
      })}
      {clubModalOpen ? (
        <SelectClubModal
          handleClose={handleCloseClubModal}
          clubs={currentMember.clubs}
          username={currentMember.username}
        />
      ) : null}
    </Box>
  );
};

export default Members;
