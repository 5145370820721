import baseApiSlice from 'api/baseApiSlice';

const DOCUMENTS_API_URL = '/api/documents';

const documentsApiWithTags = baseApiSlice.enhanceEndpoints({
  addTagTypes: ['document'],
});

const badgeSharingApiSlice = documentsApiWithTags.injectEndpoints({
  endpoints: builder => ({
    getDocuments: builder.query({
      query: () => ({
        url: `${DOCUMENTS_API_URL}/`,
      }),
      providesTags: [{ type: 'document', id: 'LIST' }],
    }),

    createDocuments: builder.mutation({
      query: ({ data, signal }: any) => ({
        url: `${DOCUMENTS_API_URL}/`,
        method: 'POST',
        data,
        queryOptions: {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          signal,
        },
      }),
    }),

    deleteDocument: builder.mutation({
      query: id => ({
        url: `${DOCUMENTS_API_URL}/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'document', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetDocumentsQuery,
  useDeleteDocumentMutation,
  useCreateDocumentsMutation,
} = badgeSharingApiSlice;

export default badgeSharingApiSlice;
