import { memo, FunctionComponent } from 'react';
import { Box, BoxContent, CTAButtons, ImageHolder } from "components/DeleteConfirmation/style";
import { Typography } from '@mui/material';
import Button from 'elements/Button';
import ConfirmIcon from "elements/Svgs/Confirm";

const DeleteConfirmation: FunctionComponent<any> = ({card, handleClose, handleDelete, isLoading }) => {
  return (
    <>
      <Box>
        <BoxContent>
          <ImageHolder>
            <ConfirmIcon />
          </ImageHolder>
          <Typography className="fs-20" variant="body1">
            Are you sure you want to delete <span>{card.fileName}</span>?
          </Typography>
        </BoxContent>
        <CTAButtons>
          <Button onClick={handleClose} variant="outlined">Cancel</Button>
          <Button onClick={handleDelete} variant="contained" disabled={isLoading}>Delete</Button>
        </CTAButtons>
      </Box>
    </>
  );
};

export default memo(DeleteConfirmation);
