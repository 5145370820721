import { styled, Card as WrapperBox } from '@mui/material';

export const Box = styled(WrapperBox)`
  box-shadow: none;
  max-width: 320px;
  margin: 150px auto 50px;
  text-align: center;

  .btn {
    min-width: 150px;
  }
`;

export const BoxImage = styled('div')`
  margin-bottom: 30px;
`;

export const BoxText = styled('div')`

`;

