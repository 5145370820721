import { useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import Button from 'elements/Button';
import { FunctionComponent } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Container } from './style';
import Loader from 'elements/Loader';
import { useGetGlobalSearchItemsQuery } from 'api/globalSearchApi';
import { DashboardRoutes as ROUTES } from 'routes';
import Members from './Components/Members';
import Resources from './Components/Resources';
import { Box as NoRecordBox, BoxImage, BoxText } from 'elements/NoRecord/style';
import NoRecordIcon from 'elements/Svgs/NoRecord';

import { addSearchResources } from './ducks/resourceSlice';
import { addSearchMembers } from './ducks/memberSlice';

const Search: FunctionComponent<any> = () => {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const query = searchParams.get('query') as string;
  const dispatch = useDispatch();

  const { data, isFetching, isSuccess } = useGetGlobalSearchItemsQuery(query);

  useEffect(() => {
    if (!query) {
      history.push('/dashboard');
    }
  }, [query, history]);

  const handleMembersViewAll = () => {
    history.push(`${ROUTES.SEARCH_MEMBERS.path}?query=${query}`);
  };

  const handleResourcesViewAll = () => {
    history.push(`${ROUTES.SEARCH_RESOURCES.path}?query=${query}`);
  };

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    dispatch(addSearchResources(data?.resources));
    dispatch(addSearchMembers(data?.members));
  }, [data, dispatch, isSuccess]);

  return (
    <Container>
      {isSuccess ? (
        <Typography variant='h3' component="h1" mb={4}>
          Search Results {isFetching ? '' : `(${data.count})`}
        </Typography>
      ) : null}

      {isSuccess &&
      !isFetching &&
      !data.members.results.length &&
      !data.resources.results.length ? (
        <NoRecordBox>
          <BoxImage>
            <NoRecordIcon />
          </BoxImage>
          <BoxText>
            <Typography variant='h2'>Results not found</Typography>
          </BoxText>
        </NoRecordBox>
      ) : null}

      {isSuccess && !isFetching && data.members.results.length ? (
        <Box className='search-results'>
          <Typography variant='h3' mb={4}>
            User Profiles
          </Typography>
          <Members members={data.members.results} />

          {isSuccess && !isFetching && data.members.count > 5 ? (
            <div className='mt-30 text-center'>
              <Button className='mw120' variant='outlined' onClick={handleMembersViewAll}>
                Load More
              </Button>
            </div>
          ) : null}
        </Box>
      ) : null}

      {isSuccess && !isFetching && data.resources.results.length ? (
        <Box className='search-results'>
          <Typography variant='h3' mb={4}>
            Resources
          </Typography>
          <Resources resources={data.resources.results} />

          {isSuccess && !isFetching && data.resources.count > 5 ? (
            <div className='mt-30 text-center'>
              <Button className='mw120' variant='outlined' onClick={handleResourcesViewAll}>
                Load More
              </Button>
            </div>
          ) : null}
        </Box>
      ) : null}

      {isFetching ? (
        <div className='mt-30 text-center'>
          <Loader position='absolute' background='transparent' />
        </div>
      ) : null}
    </Container>
  );
};

export default Search;
