import { styled, Avatar, Stack, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

export const StickyDataGrid = styled(DataGrid)(({ theme }) => ({
  overflow: 'visible',
  border: 'none',

  '&.progress': {
    '[aria-colindex]:not([aria-colindex="1"]):not([aria-colindex="2"])': {
      '.MuiDataGrid-columnHeaderTitleContainer': {
        justifyContent: 'center',
        flex: 1,
      },
    },
  },

  '& .MuiDataGrid-columnHeaders': {
    position: 'sticky',
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.mobileStepper - 1,
    top: '60px',
  },
  '& .MuiDataGrid-virtualScroller': {
    marginTop: '0 !important',
  },
  '& .MuiDataGrid-main': {
    overflow: 'visible',
  },

  '&.disable-pagination': {
    '.MuiButtonBase-root': {
      pointerEvents: 'none',
    },
  },
}));

export const Container = styled(Paper)`
  width: 100%;
  padding: 16px 0px;
  border-radius: 4px;
  box-shadow: none;
  height: auto;

  fieldset.error-holder {
    margin: 0;
    border: 0;
  }

  .disable-selected-row {
    opacity: 0.5;
    pointer-events: none;
  }

  .MuiDataGrid-root {
    border: none; 

    .MuiDataGrid-withBorderColor {
      border-color: ${({ theme }) => theme.palette.ternary.main};
    }

    .unsorted-icon {
      display: flex;
      flex-direction: column;
      height: 24px;
      width: 24px;
      opacity: 0.5;

      & svg {
        &:first-child {
          margin-bottom: -17px;
          margin-top: -3px;
        }
      }
    }
    .MuiDataGrid-columnHeaders {
      &:hover {
        .MuiDataGrid-columnSeparator {
          visibility: hidden;
        }
      }
    }
    .MuiDataGrid-columnHeader {
      color: ${({ theme }) => theme.palette.ternary.greyShade};

      &.MuiDataGrid-columnHeader--sortable {
        .MuiDataGrid-iconButtonContainer {
          visibility: visible;
          width: auto;
        }
      }
    }
    .MuiDataGrid-row {
      &.Mui-selected,
      &.Mui-selected:hover,
      &:hover {
        background: ${({ theme }) => theme.palette.ternary.light};
      }
    }

    .MuiDataGrid-row {
      min-height: 77px !important;
    }

    .MuiButtonBase-root.MuiCheckbox-root {
      &.Mui-checked {
        color: ${({ theme }) => theme.palette.secondary.light};
      }
    }
    .MuiDataGrid-columnHeader,
    .MuiDataGrid-cell {
      &:focus-within {
        outline-color: transparent;
      }
      &:focus-visible {
        outline-color: ${({ theme }) => theme.palette.ternary.focusBlue};
        outline-offset: -1px;
      }
    }
  }
`;

export const StyledAvatar = styled(Avatar)`
  width: '36px';
  height: '36px';
`;

export const StyledCell = styled(Stack)`
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .secondary-color {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const SearchHolder = styled('div')`
  width: 260px;
  position: relative;

  .MuiIconButton-root {
    padding: 0;
    position: absolute;
    right: 5px;
    top: 8px;

    &.clear-btn {
      right: 35px;

      .MuiSvgIcon-root {
        width: 16px;
      }

      &.disable {
        pointer-events: none;
      }
    }

    .MuiSvgIcon-root {
      position: static;
      color: ${({ theme }) => theme.palette.ternary.greyShade};
    }
  }

  & .MuiFormControl-root {
    & .MuiInputBase-root {
      margin: 0;

      input {
        background: ${({ theme }) => theme.palette.primary.white};
        border-radius: 3px;
        padding-right: 55px;
      }
    }
  }
`;
