import Footer from "components/Footer";
import StaticHeader from "components/StaticHeader";
import { Container, CustomRow, ContentWrapper, ContentHolder } from "./style";

const NoPageFound = (Content: any) => (props: any) => {
  return(
    <>
      <StaticHeader />
      <Container id='main' tabIndex={-1}>
        <CustomRow>
          <ContentWrapper>
            <ContentHolder>
              <Content {...props} />
            </ContentHolder>
            <Footer />
          </ContentWrapper>
        </CustomRow>
      </Container>
    </>
  );
};

export default NoPageFound;
