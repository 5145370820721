import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const LegendTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: theme.palette.ternary.white,
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '3px',
    padding: '20px 15px',
    boxShadow: '0px 4px 16px rgba(0, 0, 41, 0.24)',
    width: '260px',
    marginBottom: '30px',
    wordBreak: 'break-word',
    color: theme.palette.text.primary,
    fontSize: 'inherit',
  },

  [`& .${tooltipClasses.arrow}::before`]: {
    color: theme.palette.ternary.white,
    border: `1px solid ${theme.palette.secondary.main}`,
  },
}));

export default LegendTooltip;
