import { useState } from 'react';
import classNames from 'classnames';

import { BadgeConatiner, BadgeCheckbox, BadgeFormLabel } from './style';

const BadgeSelectItem = ({
  badge,
  selectedBadgeId,
  handleBadgeChange,
}: any) => {
  const [isFocus, setIsFocus] = useState(false);
  const handleFocus = () => {
    setIsFocus(true);
  };
  const handleBlue = () => {
    setIsFocus(false);
  };

  const isSelected = Number(selectedBadgeId || -1) === badge.id;

  return (
    <BadgeConatiner
      className={classNames({
        selected: isSelected,
        focused: isFocus,
      })}
    >
      <BadgeFormLabel
        label={badge.badgeMeta.name}
        value={badge.id}
        checked={selectedBadgeId === badge.id}
        onChange={handleBadgeChange}
        labelPlacement='bottom'
        control={
          <BadgeCheckbox
            onFocus={handleFocus}
            onBlur={handleBlue}
            inputProps={{ 'aria-label': badge.badgeMeta.name }}
          />
        }
        // @ts-ignore
        badgeImage={badge.badgeMeta.image}
      />
    </BadgeConatiner>
  );
};

export default BadgeSelectItem;
