import React, { memo, FunctionComponent } from 'react';

const NoRecordIcon: FunctionComponent<any> = () => {
  return (
    <svg width="140" height="132" viewBox="0 0 140 132" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M140 61.1825C140 100.294 106.372 132 64.8905 132C23.4087 132 0 109.929 0 70.8175C0 31.7061 33.6277 0 75.1095 0C116.591 0 140 22.071 140 61.1825Z" fill="#F5F5F5"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M122.177 48.1403L114.34 40.3033C113.364 39.327 111.781 39.327 110.805 40.3033L104.583 46.5255L115.955 57.8981L122.177 51.6759C123.154 50.6996 123.154 49.1167 122.177 48.1403ZM114.187 59.6659L102.815 48.2933L90.6718 60.4363C90.5453 60.7757 90.2757 61.0453 89.9363 61.1718L61.8466 89.2614C61.4858 89.6222 61.2441 90.0848 61.1538 90.587L59.4367 100.141C59.1285 101.856 60.6248 103.352 62.3395 103.044L71.8936 101.327C72.3958 101.237 72.8584 100.995 73.2192 100.634L114.187 59.6659ZM101.228 46.3446L102.815 44.7578L109.037 38.5355C110.99 36.5829 114.155 36.5829 116.108 38.5355L123.945 46.3726C125.898 48.3252 125.898 51.491 123.945 53.4437L117.723 59.6659L115.955 61.4337L74.9869 102.402C74.2654 103.123 73.3402 103.607 72.3359 103.787L62.7817 105.504C59.3523 106.121 56.3598 103.128 56.9761 99.6989L58.6932 90.1448C58.8737 89.1404 59.3572 88.2153 60.0788 87.4937L73.8225 73.75H37C36.3096 73.75 35.75 73.1904 35.75 72.5C35.75 71.8097 36.3096 71.25 37 71.25H75.75C75.9193 71.25 76.0807 71.2836 76.2279 71.3446L86.3225 61.25H37C36.3096 61.25 35.75 60.6904 35.75 60C35.75 59.3097 36.3096 58.75 37 58.75H88.8225L98.8225 48.75H37C36.3096 48.75 35.75 48.1904 35.75 47.5C35.75 46.8097 36.3096 46.25 37 46.25H100.75C100.919 46.25 101.081 46.2837 101.228 46.3446Z" fill="#772432"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M91.3934 2.5H29.5C26.7386 2.5 24.5 4.73858 24.5 7.5V112.5C24.5 115.261 26.7386 117.5 29.5 117.5H105.75C108.511 117.5 110.75 115.261 110.75 112.5V75C110.75 74.3096 111.31 73.75 112 73.75C112.69 73.75 113.25 74.3096 113.25 75V112.5C113.25 116.642 109.892 120 105.75 120H29.5C25.3579 120 22 116.642 22 112.5V7.5C22 3.35786 25.3579 0 29.5 0H91.3934C93.3825 0 95.2902 0.790177 96.6967 2.1967L111.053 16.5533C112.46 17.9598 113.25 19.8675 113.25 21.8566V28.75C113.25 29.4404 112.69 30 112 30C111.31 30 110.75 29.4404 110.75 28.75V21.8566C110.75 20.5305 110.223 19.2587 109.286 18.3211L94.9289 3.96447C93.9913 3.02679 92.7195 2.5 91.3934 2.5ZM93.25 8.75C93.25 8.05964 92.6904 7.5 92 7.5C91.3096 7.5 90.75 8.05964 90.75 8.75V18.75C90.75 20.1307 91.8693 21.25 93.25 21.25H103.25C103.94 21.25 104.5 20.6904 104.5 20C104.5 19.3096 103.94 18.75 103.25 18.75H93.25V8.75Z" fill="black"/>
    </svg>
  );
};


export default memo(NoRecordIcon);
