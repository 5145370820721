import { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { ReactComponent as SpeechValidatiion } from 'assets/images/speechValidatiion.svg';

import { HOC } from 'elements/Modal';

const ResumeSpeechModal: FC<any> = ({ handleCreateSpeech, handleClose }) => {
  return (
    <Stack mt={5} alignItems='center' justifyContent='center' mx={25} gap={7.5}>
      <SpeechValidatiion />
      <Typography align='center'>
        Your last speech verification session is incomplete. Would you like to 
        resume your previous session or start a new one?
      </Typography>
      <Stack direction='row' justifyContent='center' gap={1.2} mt={2.5}>
        <Button variant='outlined' size='small' onClick={handleCreateSpeech}>
          Create new
        </Button>
        <Button variant='contained' size='small' onClick={handleClose}>
          Resume
        </Button>
      </Stack>
    </Stack>
  );
};

export default HOC(ResumeSpeechModal);
