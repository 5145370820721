import { styled, CardContent, Card } from '@mui/material';
import { DeviceDown } from 'styles/breakpoints';

export const Box = styled(Card)<any>`
  max-width: 261px;
  min-width: 100%;
  box-shadow: 0px 2px 6px rgba(0, 0, 41, 0.16);
  background: ${({ theme }) => theme.palette.ternary.white};
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 10px);
  position: relative;
  margin-bottom: 10px;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  @media ${DeviceDown.sm} {
    margin: 0 auto 10px;
    min-width: auto;
  }
  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 41, 0.24);
  }

  .MuiCardActionArea-root {
    font-size: inherit;

    &:hover {
      .MuiCardActionArea-focusHighlight {
        display: none;
      }
    }

    &:focus-visible {
      outline: 3px solid ${({ theme }) => theme.palette.ternary.focusBlue};
      outline-offset: -3px;
    }
  }

  h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-height: 25px;
    max-height: 50px;
  }

  & .flexwrap {
    flex-wrap: wrap;
  }

  &.club-card {
    min-width: 100%;

    .MuiTypography-h4 {
      text-transform: capitalize;
    }

    & .MuiCardContent-root {
      padding: 20px 10px;

      &.flex-column {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }
    }

    .club-member-profile-img {
      border: 6px solid ${({ theme }) => theme.palette.ternary.main};
    }
  }
`;

export const BoxContent = styled(CardContent)`
  padding: 20px;

  & h2 {
    margin-bottom: 15px;
  }

  & h3 {
    margin: 10px 0 0;

    &.mt-1 {
      margin-top: 5px;
    }
  }

  & p {
    margin-top: 15px;
  }
`;

export const BoxHeader = styled('div')`
  height: 155px;
  width: 100%;

  img {
    width: 100%;
    height: 155px;
    object-fit: cover;
    display: block;
  }
`;

export const ImageHolder = styled('div')`
  width: 100%;
  position: relative;
  margin-bottom: 15px;

  img {
    width: 100%;
    max-height: 205px;
    object-fit: cover;
    display: block;
  }
`;

export const BoxDetails = styled('div')`
  padding: 10px 30px 0;
`;

export const CardCTA = styled('div')`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 9;
`;

export const DescriptionHolder = styled('div')`
  margin-bottom: 0px;
`;

export const CardActionButtons = styled('div')`

`;
