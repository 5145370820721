import { Box, styled, Popover, Typography } from '@mui/material';
import { DeviceDown } from 'styles/breakpoints';

export const BellIconHolder = styled('span')`
  postion: relative;

  .mediumIcon {
    border-radius: 4px;

    &.active,
    &:hover {
      background: ${({ theme }) => theme.palette.ternary.light};
    }
  }
`;

export const IconBubble = styled('span')`
  width: 9px;
  height: 9px;
  background: ${({ theme }) => theme.palette.primary.main};
  border: 2px solid ${({ theme }) => theme.palette.ternary.light};
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  top: 12px;
  right: 9px;
`;

export const MenuDropdownHolder = styled(Popover)`
  .MuiPaper-root {
    overflow: visible;
    box-shadow: 0px 2px 8px rgba(0, 0, 41, 0.3);
    border-radius: 0px 0px 4px 4px;
    margin-top: 17px;

    &:before {
      content: ' ';
      display: block;
      position: absolute;
      top: 0px;
      right: 14px;
      width: 18px;
      height: 18px;
      background-color: white;
      transform: translateY(-50%) rotate(45deg);
      box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.1);
      z-index: 0;
    }
  }

  & .MuiList-root {
    max-width: 450px;
    width: 100%;

    &.notification-list {
      max-height: 450px;
      overflow: auto;
      padding-top: 0;
    }

    .MuiListItemButton-root {
      border-left: 4px solid transparent;
      border-bottom: 1px solid ${({ theme }) => theme.palette.ternary.main};
      background: ${({ theme }) => theme.palette.ternary.white};
      padding: 15px 20px;
      margin: 0;
      cursor: pointer;
      align-items: flex-start;
      &:last-child {
        border-bottom: none;
      }

      &:hover {
        border-left: 4px solid ${({ theme }) => theme.palette.primary.main};
      }
    }
  }
`;

export const HeaderList = styled(Box)`
  display: flex;
  flex: 1;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.palette.ternary.main};
  padding: 10px 20px;
`;

export const NotificationCount = styled(Typography)<any>`
  margin-left: 10px;
  font-weight: 700;
  font-size: 12px;
  padding: 5px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  min-width: 26px;
  min-height: 26px;
  display: inline-block;
  text-align: center;
`;

export const NotificationHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .notify-timestamp {
    font-size: 14px;
    color: ${({ theme }) => theme.palette.ternary.dark};
  }
`;

export const EmptyState = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;
