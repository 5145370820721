import { FunctionComponent, useState } from 'react';
import { useFormikContext } from 'formik';

import Button from 'elements/Button';
import Confirmation from 'components/Confirmation';
import { HOC } from 'elements/Modal';

const ConfirmModal = HOC(Confirmation);

const CancelButton: FunctionComponent<any> = ({ handleClose }) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const { dirty }: any = useFormikContext();

  return (
    <>
      <Button
        className='mw120'
        variant='outlined'
        onClick={() => {
          if (dirty) {
            setIsConfirmModalOpen(true);
            return;
          }

          handleClose();
        }}
      >
        Cancel
      </Button>
      {isConfirmModalOpen && (
        <ConfirmModal
          handleClose={() => setIsConfirmModalOpen(false)}
          title='Confirmation'
          buttonText='Continue'
          cancelText='Stay on page'
          showLoader={false}
          handleConfirmation={handleClose}
        >
          If you continue, you will lose all text entered on this page.
        </ConfirmModal>
      )}
    </>
  );
};

export default CancelButton;
