import { styled, Box, Menu, alpha } from '@mui/material';
import { theme } from 'styles/theme';

export const MenuWrapper = styled(Box)`
    &.active {
        & .MuiButtonBase-root.MuiButton-root {
            color: ${({ theme }) => theme.palette.ternary.greyShade};
            border-color: ${({ theme }) => theme.palette.secondary.light};

            svg {
                color: ${({ theme }) => theme.palette.ternary.greyShade};
            }

            &:focus-visible {
                outline-offset: 0;
            }
        }
    }

    & .MuiButtonBase-root.MuiButton-root {
        min-height: 50px;
        padding: 5px 10px;
        background-color: ${({ theme }) => theme.palette.ternary.white};
        border-color: ${({ theme }) => theme.palette.ternary.greyShade};
        color: ${({ theme }) => theme.palette.text.primary};

        &:hover {
            outline: 1px solid ${({ theme }) => theme.palette.ternary.greyShade};

            svg {
                color: ${({ theme }) => theme.palette.ternary.greyShade};
            }
        }

        &.active,
        &:active {
            color: ${({ theme }) => theme.palette.ternary.greyShade};
            border-color: ${({ theme }) => theme.palette.secondary.light};
            outline: 1px solid ${({ theme }) => theme.palette.secondary.light};

            svg {
                color: ${({ theme }) => theme.palette.ternary.greyShade};
            }
        }

        svg {
            transition: 0.2s all; 
        }

        &.active {
            svg {
                transform: scale(-1);
            }
        }

        &:focus-visible {
            outline: 1px solid ${({ theme }) => theme.palette.secondary.light};
            border-color: ${({ theme }) => theme.palette.secondary.light};
            outline-offset: 0;
        }

        &:disabled {
            border-color: ${({ theme }) => theme.palette.ternary.greyShade};
            color: ${({ theme }) => theme.palette.ternary.greyShade};
        }
    }
    &.rounded {
        .MuiButtonBase-root {
            &.btn {
                border-radius: 100%;
                padding: 0;
                width: 30px;
                height: 30px;
                min-height: inherit;
                min-width: inherit;
            }
        }
    }
`;

export const MenuDropdownHolder = styled(Menu)`

    & .MuiPaper-root {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        border-radius: 3px;
        margin-top: 1px;
        min-width: 120px;
    }

    & .MuiList-root {
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 3px;
        border: 1px solid ${({ theme }) => theme.palette.ternary.mediumGray};
        max-height: 210px;
        overflow-y: auto;

        .MuiMenuItem-root {
            position: relative;
            z-index: 2;

            .MuiCheckbox-root.Mui-checked {
                position: static;

                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0; 
                    left: 0;
                    right: 0;
                    z-index: -1;
                    background: ${alpha(theme.palette.ternary.tertiary1, 0.08)};
                }
            }
        }
    }
    .error {
        color: ${({ theme }) => theme.palette.error.main};
    }
    .secondary-btn {
        background: rgba(124, 46, 50, 0.1);
        color: ${({ theme }) => theme.palette.primary.main};
        box-shadow: none;
        text-transform: none;
        
        &:hover {
            background: rgba(124, 46, 50, 0.3);
        }
    }
`;

export const MenuDropdownTop = styled('div')`
    padding: 5px 10px;
`;

export const DispalyMenuItems = styled('div')`
    padding: 10px 10px;
    max-height: 160px;
    overflow: auto;

    li {
        border-top: 1px solid ${({ theme }) => theme.palette.ternary.mediumGray};
        padding: 10px;
        margin: 0;
    }
`;
