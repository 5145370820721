import { styled, Typography, Box, Paper, IconButton } from '@mui/material';

import { theme,  } from 'styles/theme';
import { DeviceDown, DeviceUp } from 'styles/breakpoints';

export const StyledPaper = styled(Paper)`
  box-shadow: none;
  border-radius: 4px;
  background: ${({ theme }) => theme.palette.ternary.light};

  & > .MuiTypography-h4 {
    margin-bottom: 20px;
  }
`;

export const ChipWrapper = styled('div')`
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .filter-chip {
    @media ${DeviceDown.sm} {
      flex-grow: 1;
    }
  }
`;

export const IconWrapper = styled('div')`
  min-width: 50px;
  width: 50px;
  height: 50px;
  background: ${({ theme }) => theme.palette.ternary.white};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0px;
`;

export const ContentRow = styled('div')`
  min-height: 70px;
  position: relative;
  padding-left: 65px;
  
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.ternary.main};
    padding-bottom: 20px;
    margin-bottom: 30px;
    
  }

  .MuiTypography-h4 {
    margin-bottom: 5px;
  }

  .MuiTypography-body1 {
    font-size: 14px;
  }

  .MuiSvgIcon-colorSecondary {
    &:hover {
      color: ${({ theme }) => theme.palette.secondary.dark};
    }
  }
`;

export const SectionHeading = styled(Typography)`
  margin-bottom: 16px;
  padding-left: 8px;
`;

export const FormButton = styled(IconButton)`
  min-width: unset;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  color: ${({ theme }) => theme.palette.primary.main};

  &:hover {
    background: ${({ theme }) => theme.palette.ternary.main};
    color: ${({ theme }) => theme.palette.primary.dark};
  }

  &:active {
    background: ${({ theme }) => theme.palette.ternary.main};
    color: ${({ theme }) => theme.palette.primary.light};
  }

`;

export const LearningBadgesWrapper = styled(Box)`
  display: flex;
  margin-bottom: 8px;
  margin-top: 8px;
  flex-direction: column;
`;

export const LearningBadgesRow = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 25px;

  @media ${DeviceDown.sm} {
    justify-content: space-between;
    gap: 10px;
  }
`;

export const FeedbackBadgesWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  @media ${DeviceDown.sm} {
    justify-content: space-between;
  }
`;

export const BadgeElement = styled('div')`
  width: 150px;
  margin: 8px auto;
  text-align: center;

  &:not(.hovered) {
    .MuiBadge-root {
      cursor: default !important;
    }
  }

  &.hovered {
    .MuiBadge-root {
      &:focus-visible,
      &:hover {
        .badge-avatar {
          display: none;
        }
        .badge-text {
          display: block;
        }
      }
    }
  }

  &:focus-visible {
    outline: 3px solide ${({ theme }) => theme.palette.ternary.focusBlue};
    outline-offset: 3px;
  }

  &.disabled,
  &.cursor-auto {
    cursor: auto;

    & .badge-title {
      cursor: auto;
    }
  }

  @media ${DeviceUp.sm} {
    margin: 8px 0;
  }

  &.disabled {
    opacity: 0.4;

    & img {
      filter: grayscale(1);
    }
  }

  & .badge-title {
    display: inline-flex;
    cursor: pointer;
  }

  & .MuiTypography-root {
    font-size: 16px;
    font-weight: 700;
    margin-top: 15px;
    text-align: center;
    word-wrap: break-word;
  }
`;


export const ClearAll = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
`;

export const BadgeSharingWrapper = styled(Box)`

  & .react-share__ShareButton {

    &:hover {
      filter: drop-shadow(0px 2px 8px rgba(0, 0, 41, 0.3));
    }
  }

  .social-link-holder {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 20px 0 0;

    .MuiFormControlLabel-root {
      width: 50%;
    }

    .MuiInputBase-root {
      .MuiInputBase-input{
        border-radius: 5px 0 0 5px;
      }
    }

    .MuiButtonBase-root {
      margin: 5px 0 0 -2px;
      border-radius: 0 5px 5px 0;
    }
  }

  .social-icon {
    width: 40px;
    height: 40px;
    padding: 11px;
    border-radius: 8px;
  }

  .twitterX-logo {
    background: rgb(0, 172, 237);

    svg path {
      fill: #fff;
    }
  }
`;
