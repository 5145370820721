import React, { useEffect, useState } from "react";
import compose from 'compose-function';
import {HOC} from "elements/Modal"
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import UploadConfirmation from "components/UploadConfirmation";

const SuccessModal = compose(HOC)(UploadConfirmation);

const Modals:React.FC<any> = (props) => {
  const [success, setSuccessModal] = useState(false)
  const modals = useSelector((state:any) => state.modals);
  const dispatch = useDispatch();

  useEffect(() => {
    if(success) {
      
    }
  }, [success])
  return (
    <>
      {success && (
        <SuccessModal handleClose={() => setSuccessModal(false)} />
      )}
    </>

  );
};

export default Modals;
