import React, { memo, FunctionComponent } from 'react';
import { TextField as StyledTextField } from 'elements/Form/style';

const TextField: FunctionComponent<any> = ({
  placeholder,
  disabled,
  error,
  color,
  helperText,
  fullWidth,
  onChange,
  value,
  multiline,
  className,
  onKeyDown,
  onFocus,
  onBlur,
  onKeyPress,
  name,
  ...props
}) => {
  return (
    <StyledTextField
      disabled={disabled}
      error={error}
      color={color}
      helperText={helperText}
      fullWidth={fullWidth}
      className={className}
      onChange={onChange}
      value={value}
      multiline={multiline}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyPress={onKeyPress}
      name={name}
      {...props}
    />
  );
};

TextField.defaultProps = {
  fullWidth: true,
  multiline: false,
  onChange: () => {},
  disabled: false,
};

export default memo(TextField);
