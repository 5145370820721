import { memo, FunctionComponent } from 'react';
import { Alert as MuiAlert } from '@mui/material';

const Alert: FunctionComponent<any> = ({ type="success", action, children }) => {
  return (
    <MuiAlert severity={type} action={action}>
      {children}
    </MuiAlert>
  );
};

export default memo(Alert);
