import baseApiSlice from 'api/baseApiSlice';
import { TOASTMASTER_API_URL } from 'config';

enum ITags {
  CATEGORIES = 'Categories',
  LANGUAGES = 'Languages',
  LANGUAGE_PREFERENCES = 'language preferences',
}

const categoriesApiWithTags = baseApiSlice.enhanceEndpoints({
  addTagTypes: [ITags.CATEGORIES, ITags.LANGUAGES, ITags.LANGUAGE_PREFERENCES],
});

const categoriesApiSlice = categoriesApiWithTags.injectEndpoints({
  endpoints: builder => ({
    getCategories: builder.query<any, void>({
      query: () => ({
        url: `${TOASTMASTER_API_URL}/asset_management/resources/filters/`,
      }),

      providesTags: [ITags.CATEGORIES],
    }),

    getLanguages: builder.query<any, void>({
      query: () => ({
        url: `${TOASTMASTER_API_URL}/asset_management/languages/`,
      }),
      providesTags: [ITags.LANGUAGES],
    }),

    shareAsset: builder.mutation({
      query: data => ({
        url: `${TOASTMASTER_API_URL}/asset_management/resource_sharing/`,
        method: 'POST',
        data,
      }),
    }),

    getAvailableMembers: builder.query({
      query: ({ clubId, assetId, eventId }) => ({
        url: `${TOASTMASTER_API_URL}/asset_management/resource_sharing/available_to_members/`,
        params: {
          ...(eventId && { event: eventId }),
          ...(clubId && { club: clubId }),
          asset: assetId,
        },
      }),
    }),

    getAsset: builder.query({
      query: assetUuid => ({
        url: `${TOASTMASTER_API_URL}/asset_management/resources/${assetUuid}/get/`,
      }),
    }),

    getLanguagePreferences: builder.mutation<any, any>({
      query: () => ({
        url: `${TOASTMASTER_API_URL}/ti/profile/language/`,
        method: 'GET',
      }),
    }),

    updateLanguagePreferences: builder.mutation({
      query: data => ({
        url: `${TOASTMASTER_API_URL}/ti/profile/language/`,
        method: 'PATCH',
        data,
      }),
      invalidatesTags: [],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCategoriesQuery,
  useGetLanguagesQuery,
  useShareAssetMutation,
  useGetAvailableMembersQuery,
  useGetAssetQuery,
  useGetLanguagePreferencesMutation,
  useUpdateLanguagePreferencesMutation,
} = categoriesApiSlice;

export default categoriesApiSlice;
