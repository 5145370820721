import { FC, useState, useEffect } from 'react';
import { Box, Button, RadioGroup, Stack, Typography } from '@mui/material';
import Slider from 'react-slick';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { useFormik } from 'formik';
import { size, trim } from 'lodash';

import Modal from 'elements/Modal';
import { getUserName } from 'utils/utility';
import { SliderWrapper } from './style';
import {
  useGiveFeedbackMutation,
  useGetFeedbackBadgesQuery,
  useUpdateFeedbackMutation,
} from '../feedbackApi';
import { resetFeedbacks, fetchFeedbacks } from '../feedbackSlice';

import TextField from 'elements/Form/TextField';
import CheckboxesAutoComplete from 'elements/AutoComplete/CheckboxesAutoComplete';
import { Radio } from 'elements/Radio/style';
import { StyledLabel as FormControlLabel } from 'elements/FormControlLabel/style';
import { FieldWrapper } from 'elements/Form/style';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import ConfirmIcon from 'elements/Svgs/Confirm';
import { ImageWrapper } from './style';
import BadgeSelectItem from './BadgeSelectItem';
import { useGetEventAttendeesQuery } from 'api/speechCraftApi';

// @ts-ignore
import { getAuthenticatedUser } from '@edx/frontend-platform/auth';
import useProfilePage from 'pages/Profile/useProfilePage';
import { useAppContext } from 'contexts/AppContext';
import { useValidations } from 'hooks';
import { getValidationSchema } from './validationSchema';

const INITIAL_VALUES = {
  clubMembers: [],
  feedback: '',
  badgeId: '',
  visibility: 'public',
};

const GiveFeedback: FC<any> = props => {
  const user = getAuthenticatedUser();
  const { username } = useProfilePage();
  const { event } = useAppContext();
  const dispatch = useDispatch();

  const {
    modalOpen,
    handleClose,
    handleOpen,
    selectedMembers = [],
    isRequestedFeedback = false,
    feedbackData = {},
    handleRequestedFeedback = () => {},
    refetchRequestFeedback = () => {},
    isEditModal = false,
    resetEditModal = () => {},
    slectedEvent = '',
    memberUsername = '',
  } = props;

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [initialValues, setInitialValues] = useState<any>(INITIAL_VALUES);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: getValidationSchema(true, false),
    onSubmit: () => {
      handleOpenConfirmation();
    },
  });

  const {
    setFieldValue,
    values: formikValues,
    setFieldTouched,
    touched,
    errors,
    resetForm,
  }: any = formik;

  const handleCloseConfirmation = () => {
    setConfirmationModalOpen(false);
    handleClose();
    resetState();
    resetEditModal();
  };

  const handleCloseFeedbackModal = () => {
    handleClose();
    resetState();
    resetEditModal();
  };

  const handleOpenConfirmation = () => {
    handleClose();
    setConfirmationModalOpen(true);
  };

  const handleFormSubmitted = () => {
    handleClose();
    setConfirmationModalOpen(false);
  };

  const handleGoBack = () => {
    setConfirmationModalOpen(false);
    handleOpen();
  };

  const resetState = () => {
    setInitialValues(INITIAL_VALUES);
    handleFormSubmitted();
    resetForm();
  };

  const handleBadgeChange = (e: any) => {
    if (e.target.checked) setFieldValue('badgeId', e.target.value);
    else setFieldValue('badgeId', e.target.value);
  };

  const [giveFeedback, { isLoading }] = useGiveFeedbackMutation();
  const [updateFeedback, { isLoading: isFeedbackUpdating }] =
    useUpdateFeedbackMutation();

  const {
    data: eventMembersData,
    isFetching: isFetchingMembers,
    isSuccess: isSuccessMembers,
  } = useGetEventAttendeesQuery({ attendeesType: 'all' }, { skip: !modalOpen });

  const { attendees } = eventMembersData || {};

  const { data: feedbackBadges, isSuccess: isSuccessBadges } =
    useGetFeedbackBadgesQuery({});

  const handleGiveFeedback = async () => {
    const { badgeId, clubMembers, feedback, visibility } = formikValues;
    const membersIds = clubMembers.map(({ id }: Record<string, string>) => id);

    const data = {
      event: event?.id,
      receiver: membersIds,
      isPrivate: visibility === 'private',
      sender: user.userId,
      feedback,
      badge: badgeId ?? null,
      ...(isRequestedFeedback && {
        feedbackRequest: feedbackData.id,
      }),
      ...(isEditModal && {
        id: feedbackData.id,
      }),
    };

    try {
      if (isEditModal) {
        await updateFeedback(data).unwrap();
      } else {
        await giveFeedback(data).unwrap();
      }

      (isRequestedFeedback || isEditModal) && refetchRequestFeedback();
      toast.success(
        `Your message has been successfully ${
          isEditModal ? 'updated' : 'sent'
        }`,
      );
      resetState();
      handleRequestedFeedback();
      resetEditModal();
      dispatch(resetFeedbacks());
      dispatch(fetchFeedbacks({ username: memberUsername || username }));
    } catch (error: any) {
      toast.error(error?.data || 'Something went wrong!');
    }
  };

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }: any) => (
    <button
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden='true'
      role='tab'
      aria-disabled={currentSlide === 0 ? true : false}
      type='button'
    >
      <KeyboardArrowLeft />
    </button>
  );

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }: any) => (
    <button
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden='true'
      role='tab'
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type='button'
    >
      <KeyboardArrowRight />
    </button>
  );

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleUpdateField = (event: any) => {
    const { name, value } = event.target;
    const isFeedbackField = name === 'feedback';
    const updatedValue = size(trim(value)) > 0 ? value : '';

    setFieldValue(name, isFeedbackField ? updatedValue : value);
    setFieldTouched(name, true, false);
  };

  useEffect(() => {
    if (!isEmpty(selectedMembers)) {
      setInitialValues((prev: any) => ({
        ...prev,
        clubMembers: selectedMembers?.map((user: any) => ({
          id: user?.id,
          name: getUserName(user),
          firstName: getUserName(user, true),
        })),
      }));
    }
  }, [selectedMembers]);

  useEffect(() => {
    const { feedback, badgeId } = formikValues;
    if (isEditModal) {
      setInitialValues((prev: any) => ({
        ...prev,
        ...(!feedback && { feedback: feedbackData?.feedback || '' }),
        ...(!badgeId && { badgeId: feedbackData?.badge?.id || '' }),
        clubMembers: selectedMembers.map((user: any) => ({
          id: user?.id,
          name: getUserName(user),
          firstName: getUserName(user, true),
        })),
      }));

      return;
    }

    if (isRequestedFeedback) {
      setInitialValues((prev: any) => ({
        ...prev,
        clubMembers: [
          {
            id: feedbackData.sender.id,
            name: getUserName(feedbackData?.sender),
            firstName: getUserName(feedbackData?.sender, true),
          },
        ],
        visibility: feedbackData?.isPrivate ? 'private' : 'public',
      }));
    }
  }, [
    isRequestedFeedback,
    JSON.stringify({ feedbackData, selectedMembers }),
    isEditModal,
    selectedMembers,
  ]);

  useValidations(formik);

  return (
    <>
      {modalOpen ? (
        <Modal
          title={`${isEditModal ? 'Edit' : 'Send'} Message/Feedback`}
          handleClose={() => {
            handleRequestedFeedback();
            handleCloseFeedbackModal();
            resetForm();
          }}
        >
          <Box mt={1.5}>
            <FieldWrapper mb={4}>
              <FormControlLabel
                label='Event'
                labelPlacement='top'
                control={
                  <TextField
                    fullWidth
                    className='has-data'
                    disabled={true}
                    id='selectClub'
                    value={event?.name}
                  />
                }
              />
            </FieldWrapper>

            {slectedEvent ? (
              <FieldWrapper mb={4}>
                <FormControlLabel
                  label='Recipient'
                  labelPlacement='top'
                  control={
                    <TextField
                      fullWidth
                      className='has-data'
                      disabled={true}
                      id='selectMember'
                      value={formikValues.clubMembers[0]?.name}
                    />
                  }
                />
              </FieldWrapper>
            ) : null}

            {!isRequestedFeedback && !slectedEvent && (
              <FieldWrapper mb={4}>
                <FormControlLabel
                  label='Recipient'
                  labelPlacement='top'
                  control={
                    <CheckboxesAutoComplete
                      multiple
                      id='select-club-members'
                      options={
                        isSuccessMembers
                          ? attendees?.map((user: any) => ({
                              id: user.id,
                              name: getUserName(user),
                              firstName: getUserName(user, true),
                            }))
                          : []
                      }
                      disableCloseOnSelect
                      loading={isFetchingMembers}
                      loadingText='Loading recipient(s)'
                      getOptionLabel={(option: any) => option.name}
                      defaultValue={formikValues.clubMembers}
                      selectedOptions={(members: any) => {
                        setFieldValue('clubMembers', members);
                        setFieldTouched('clubMembers', true, false);
                      }}
                      name='clubMembers'
                      helperText={
                        touched['clubMembers'] && errors['clubMembers']
                      }
                      error={
                        !!touched['clubMembers'] && !!errors['clubMembers']
                      }
                      placeholder='Select Speechcrafter(s)'
                    />
                  }
                />
              </FieldWrapper>
            )}

            {isRequestedFeedback && (
              <FieldWrapper mb={1}>
                <Typography variant='h4'>{feedbackData.request}</Typography>
              </FieldWrapper>
            )}

            <FieldWrapper mb={4}>
              <FormControlLabel
                label={isRequestedFeedback ? '' : 'Message'}
                labelPlacement='top'
                control={
                  <TextField
                    multiline
                    fullWidth
                    className={`${formikValues.feedback ? 'has-data' : ''}`}
                    id='feedback'
                    placeholder='For example: You used gestures effectively during your speech today!'
                    name='feedback'
                    value={formikValues.feedback}
                    onChange={handleUpdateField}
                    helperText={touched['feedback'] && errors['feedback']}
                    error={!!touched['feedback'] && !!errors['feedback']}
                  />
                }
              />
            </FieldWrapper>

            {isSuccessBadges ? (
              <SliderWrapper mt={7.5} px={3}>
                <Slider {...settings}>
                  {feedbackBadges.map((badge: any) => (
                    <BadgeSelectItem
                      badge={badge}
                      selectedBadgeId={formikValues.badgeId}
                      handleBadgeChange={handleBadgeChange}
                    />
                  ))}
                </Slider>
              </SliderWrapper>
            ) : null}
          </Box>
          <Box mt={10}>
            <Stack direction='column' ml={4}>
              {!isRequestedFeedback && !isEditModal && (
                <>
                  <Stack direction='column'>
                    <Typography variant='h4'>Visibility</Typography>
                    <RadioGroup
                      aria-labelledby='feedback-visibility-controlled-radio-buttons-group'
                      name='visibility'
                      value={formikValues.visibility}
                      onChange={e => handleUpdateField(e)}
                    >
                      <FormControlLabel
                        value='public'
                        control={<Radio size='small' />}
                        label='Visible to all members of my event'
                      />
                      <FormControlLabel
                        value='private'
                        control={<Radio size='small' />}
                        label='Visible to selected recipient(s) only'
                      />
                    </RadioGroup>
                  </Stack>
                  <br />
                </>
              )}
              <Button
                sx={{ alignSelf: 'flex-end' }}
                variant='contained'
                size='small'
                onClick={() => {
                  formik.handleSubmit();
                }}
              >
                {isEditModal ? 'Update' : 'Send message'}
              </Button>
            </Stack>
          </Box>
        </Modal>
      ) : null}

      {confirmationModalOpen ? (
        <Modal title='Confirmation' handleClose={handleCloseConfirmation}>
          <ImageWrapper>
            <ConfirmIcon />
          </ImageWrapper>
          <Typography textAlign='center' variant='h3' fontWeight={400}>
            Are you sure you want to
            {`${
              isEditModal
                ? ' update the message for '
                : ' send this message to '
            }`}
            {[
              formikValues.clubMembers
                .map((m: any) => getUserName(m, true))
                .slice(0, -1)
                .join(', '),
              formikValues.clubMembers
                .map((m: any) => getUserName(m, true))
                .slice(-1)[0],
            ].join(
              formikValues.clubMembers.length === 1
                ? ''
                : formikValues.clubMembers.length < 3
                ? ' and '
                : ', and ',
            )}
            ?
          </Typography>

          <Stack
            direction='row'
            alignItems='center'
            justifyContent='center'
            gap={2.5}
            mt={15}
          >
            <Button
              variant='outlined'
              onClick={handleGoBack}
              disabled={isLoading || isFeedbackUpdating}
            >
              Back
            </Button>
            <Button
              variant='contained'
              onClick={handleGiveFeedback}
              disabled={isLoading || isFeedbackUpdating}
            >
              Send
            </Button>
          </Stack>
        </Modal>
      ) : null}
    </>
  );
};

export default GiveFeedback;
