import { createSelector } from 'reselect';

const stateSelector = (state: any) => state.notifications;

export const getAllNotificationsSelector = createSelector(
  stateSelector,
  notifications => ({
    data: notifications.data,
    isLoading: notifications.isLoading,
  }),
);
