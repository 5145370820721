import React, { FC } from 'react';
import {
  AppBar,
  Toolbar,
  LogoWrapper,
  HeaderLeft,
  SkipNav,
} from 'components/StaticHeader/style';
// @ts-ignore
import { getConfig } from '@edx/frontend-platform';

import {
  REACT_APP_LOGO_URL,
  REACT_APP_PATHWAYS_LOGO_URL,
  REACT_APP_SPEECHCRAFT_LOGO_URL,
} from 'config';
import { useIsSpeechCraftMFE } from 'hooks';

const StaticHeader: FC<any> = ({ user }) => {
  const isSpeechCraftMFE = useIsSpeechCraftMFE();

  return (
    <AppBar
      sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
      enableColorOnDark={true}
      color='transparent'
    >
      <SkipNav href='#main'>Skip to main content</SkipNav>
      <Toolbar>
        <HeaderLeft>
          <LogoWrapper>
            <a href={`${getConfig().BASE_URL}`}>
              <img
                src={
                  isSpeechCraftMFE
                    ? REACT_APP_SPEECHCRAFT_LOGO_URL
                    : REACT_APP_PATHWAYS_LOGO_URL
                }
                onError={({ currentTarget }: any) => {
                  currentTarget.onerror = null;
                  currentTarget.src = REACT_APP_LOGO_URL;
                }}
                alt='Toastmasters International'
              />
            </a>
          </LogoWrapper>
        </HeaderLeft>
      </Toolbar>
    </AppBar>
  );
};

export default StaticHeader;
