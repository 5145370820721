import { useState, useMemo, useEffect } from 'react';
import { each, filter, first, toString, get, includes, keys } from 'lodash';
import { INITIAL_FILTERS_STATE } from 'config';

export const useResourcesFilter = (
  categories: any = [],
  initialFilters = {},
  setPageNubmer: Function,
) => {
  const [selectedFilteres, setSelectedFilteres] = useState<any>(
    INITIAL_FILTERS_STATE,
  );
  const [sortType, setSortType] = useState('');

  useEffect(() => {
    setSelectedFilteres((prevState: any) => ({
      ...prevState,
      ...initialFilters,
    }));
  }, [JSON.stringify(initialFilters)]);

  const resetSubCategories = (categoryId: any, categoryName: any) => {
    const removedCategory = filter(categories, item => item.id === categoryId);
    const allSubcategories = get(first(removedCategory), 'subCategory');
    const selectedSubCategory = get(selectedFilteres, 'subCategory');
    const subcategoryKeys = keys(selectedSubCategory);
    const updatedSubCategories: any = {};

    each(allSubcategories, (subcategory: any) => {
      if (includes(subcategoryKeys, toString(subcategory.id))) {
        updatedSubCategories[subcategory.id] = {
          value: false,
          name: subcategory.name,
        };
      }
    });

    setSelectedFilteres({
      ...selectedFilteres,
      subCategory: {
        ...selectedFilteres['subCategory'],
        ...updatedSubCategories,
      },
      category: {
        ...selectedFilteres['category'],
        [categoryId]: {
          value: false,
          name: categoryName,
        },
      },
    });
  };

  const handleUpdateFilters = (event: any) => {
    setPageNubmer('');
    const [filterType, name, id] = event.target.name.split('.');
    if (filterType === 'category' && !event.target.checked) {
      resetSubCategories(id, name);
      return;
    }

    setSelectedFilteres({
      ...selectedFilteres,
      [filterType]: {
        ...selectedFilteres[filterType],
        [id]: {
          value: event.target.checked,
          name,
        },
      },
    });
  };

  const handleRemoveFilters = (filterType: any, filterId: any) => {
    setPageNubmer('');
    if (filterType === 'category') {
      resetSubCategories(
        filterId,
        selectedFilteres[filterType][filterId]['name'],
      );
      return;
    }

    setSelectedFilteres({
      ...selectedFilteres,
      [filterType]: {
        ...selectedFilteres[filterType],
        [filterId]: {
          value: false,
          name: selectedFilteres[filterType][filterId]['name'],
        },
      },
    });
  };

  const resetFilters = () => {
    setPageNubmer('');
    setSortType('');
    setSelectedFilteres(INITIAL_FILTERS_STATE);
    return;
  };

  const handleUpdateSortType = (event: any) => {
    setPageNubmer('');
    const { value } = event.target;
    setSortType(value);
  };

  const isFilterApplied = useMemo(
    () =>
      Object.keys(selectedFilteres)?.some((filterType: any) => {
        return Object.keys(selectedFilteres[filterType])?.some(
          filterId => selectedFilteres[filterType][filterId]['value'],
        );
      }),
    [selectedFilteres],
  );

  const handleSelectCategaory = (categoryId: any, categoryName: any) => {
    const selectedCategory = filter(categories, item => item.id === categoryId);
    const allSubcategories = get(first(selectedCategory), 'subCategory');

    const filterSubCategory = get(selectedFilteres, 'subCategory');
    const subcategoryKeys = Object.keys(filterSubCategory || {}).filter(
      id => filterSubCategory[id].value,
    );

    const updatedSubCategories: any = {};

    each(allSubcategories, (subcategory: any) => {
      const isSelected = includes(subcategoryKeys, toString(subcategory.id));
      updatedSubCategories[subcategory.id] = {
        value: isSelected,
        name: subcategory.name,
      };
    });

    setSelectedFilteres({
      ...selectedFilteres,
      subCategory: {
        ...updatedSubCategories,
      },
      category: { [categoryId]: { value: true, name: categoryName } },
    });
    setPageNubmer('');
  };

  return {
    handleUpdateFilters,
    handleRemoveFilters,
    handleUpdateSortType,
    sortType,
    resetFilters,
    isFilterApplied,
    selectedFilteres,
    handleSelectCategaory,
  };
};
