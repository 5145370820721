import { styled, Radio as MuiRadio, } from '@mui/material';

export const Radio = styled(MuiRadio)`
color: ${({ theme }) => theme.palette.ternary.greyShade};

&.Mui-focusVisible {
  outline: 2px solid ${({ theme }) => theme.palette.ternary.focusBlue};
  outline-offset: -4px;
}
&.Mui-focusVisible,
&:hover {
  color: ${({ theme }) => theme.palette.ternary.greyShade};
  background: rgba(119, 36, 50, 0.04);
}
&.Mui-checked {
  color: ${({ theme }) => theme.palette.secondary.light};

  &:hover {
    color: ${({ theme }) => theme.palette.secondary.dark};
  }

  &:focus,
  &:active {
    color: ${({ theme }) => theme.palette.secondary.dark};
  }
}

&.Mui-disabled {
  opacity: 0.25;
  color: ${({ theme }) => theme.palette.ternary.dark};

  &.Mui-checked {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
}

`;

