import dayjs, { Dayjs } from 'dayjs';
import { Box } from '@mui/material';
import { useFormikContext } from 'formik';

import { FieldWrapper } from 'elements/Form/style';
import DatePicker from 'elements/DatePicker';
import { StyledLabel as FormControlLabel } from 'elements/FormControlLabel/style';

const GeneralInformation = () => {
  const { values, touched, errors, setFieldValue, setFieldTouched }: any =
    useFormikContext<any>();

  const { issueDate, event } = values;
  const { created: eventCreateDate } = event || {};

  const handleChangeDate = (newValue: Dayjs | null) => {
    setFieldValue(
      'issueDate',
      newValue ? dayjs(newValue).format('YYYY-MM-DD') : null,
    );
    setFieldTouched('issueDate', true, false);
  };

  return (
    <Box>
      <FieldWrapper>
        <FormControlLabel
          label='Issue Date*'
          labelPlacement='top'
          control={
            <DatePicker
              label='Issue Date'
              value={issueDate}
              handleChange={handleChangeDate}
              name='speechDate'
              placeholder='Add certificate issue date'
              className={`primary-color-icon ${issueDate ? 'has-data' : ''}`}
              helperText={touched['issueDate'] && errors['issueDate']}
              error={!!touched['issueDate'] && !!errors['issueDate']}
              minDate={new Date(eventCreateDate)}
              disableFuture
            />
          }
        />
      </FieldWrapper>
    </Box>
  );
};

export default GeneralInformation;
