import { memo, FC } from 'react';
import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';

const Loader: FC<any> = ({
  alignment,
  background,
  position,
  LoadingMessage,
  direction,
}) => {
  return (
    <Backdrop
      open={Boolean(true)}
      sx={{
        background,
        zIndex: theme => theme.zIndex.drawer + 1,
        position,
        justifyContent: alignment,
        flexDirection: direction,
      }}
    >
      <CircularProgress />
      {LoadingMessage && (
        <Box>
          <Typography variant='h3'> {LoadingMessage}</Typography>
        </Box>
      )}
    </Backdrop>
  );
};

Loader.defaultProps = {
  alignment: 'center',
  background: 'white',
  position: 'relative',
  LoadingMessage: '',
  direction: 'row',
};

export default memo(Loader);
