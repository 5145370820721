import { styled, Button as MuiButton } from '@mui/material';


export const Button = styled(MuiButton, {
  shouldForwardProp: prop => prop !== 'isFilterDropdown',
})<any>(({ isFilterDropdown }) => ({
  ...(isFilterDropdown && {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    color: `${({ theme }: any) => theme.palette.ternary.black}`,
    borderColor: `${({ theme }: any) => theme.palette.ternary.black}`,
  }),
}));
