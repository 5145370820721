import { styled, Autocomplete as MuiAutocomplete, FormControlLabel as MuiLabel, Popper as MuiPopper, alpha } from '@mui/material';
import { theme } from 'styles/theme';
import { DeviceUp } from 'styles/breakpoints';

export const StyledAutocomplete = styled(MuiAutocomplete)`
  width: 100%;

  .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
  }
  
  &.has-data {
    .MuiOutlinedInput-notchedOutline {
      // border: 2px solid ${({ theme }) => theme.palette.secondary.light};
    }
  }

  &.MuiAutocomplete-hasClearIcon {
    & .MuiAutocomplete-input {
      &::placeholder {
        visibility: hidden;
        opacity: 0 !important;
      }
    }
  }
  &.MuiAutocomplete-root {
    .MuiInputBase-root {
      padding-top: 3px;
      padding-bottom: 3px;
      height: auto;
      min-height: 50px;
      margin-top: -1px

      @media ${DeviceUp.md} {
        overflow: hidden;
        flex-wrap: nowrap;
      }

      &.Mui-focused {
        min-height: 50px;
        height: auto;
        flex-wrap: wrap;
      }

      & .MuiAutocomplete-input {
        &::placeholder {
          color: ${({ theme }) => theme.palette.text.primary};
          opacity: 1;
        }
      }

      & .MuiChip-root {
        & svg {
          width: 15px;
        }
      }

      & svg {
        color: ${({ theme }) => theme.palette.text.primary};
      }

      & .MuiChip-root {
        height: 26px;
      }
      & .MuiChip-filled {
        margin: 1px 3px;
        background: ${({ theme }) => theme.palette.ternary.light};
      }

      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border-width: 2px;
        }
      }

      &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-color: ${({ theme }) => theme.palette.secondary.light};
        }
      }
    }

    &.no-labelled {
      .MuiInputBase-root {
        height: 40px;

        &.Mui-focused {
          min-height: 40px;
          height: auto;
        }
      }
    }
  }
`;

export const StyledLabel = styled(MuiLabel)`
  display: flex;
  align-items: start;
  margin: 0;

  & .MuiTypography-root {
    font-weight: 700;
  }
`;

const popperCommonStyle = `
  & .MuiAutocomplete-listbox {
    max-height: 165px;

    & .MuiAutocomplete-option {
      min-height: 38px;
      height: auto;
      padding: 0 10px;
      position: relative;
      white-space: normal !important;

      & .MuiCheckbox-root {
        margin-left: -10px;
      }

      & .MuiFormControlLabel-root {
        align-items: center;
      }

      & .MuiTypography-root {
        margin: 0;
        font-weight: 400;
      }
    }
  }
`;
export const StyledPopper = styled(MuiPopper)`
  ${popperCommonStyle}

  .MuiAutocomplete-listbox {
    & .MuiAutocomplete-option {
      &:hover {
        background: ${({ theme }) => theme.palette.ternary.light};
      }
      &[aria-selected="true"].Mui-focused {
        background: ${({ theme }) => theme.palette.ternary.light};
      }
      &[aria-selected="true"] {
        background: ${({ theme }) => alpha(theme.palette.ternary.tertiary1, 0.1)};
      }
    }
  }
`;

export const StyledPopperV2 = styled(MuiPopper)`
  .MuiPaper-root {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }

  .MuiAutocomplete-option {
    white-space: normal;

    &[aria-selected="true"].Mui-focused,
    &[aria-selected="true"] {
      background: ${({ theme }) => theme.palette.ternary.light};
    }
  }
`;

export const StyledPopperOptions = styled(MuiPopper)`
  ${popperCommonStyle}

  .MuiAutocomplete-listbox {
    & .MuiAutocomplete-option {
      &:hover {
        background: ${({ theme }) => theme.palette.ternary.light};
      }
      &[aria-selected="true"].Mui-focused {
        background: ${({ theme }) => theme.palette.ternary.light};
      }
      &[aria-selected="true"] {
        background: ${({ theme }) => alpha(theme.palette.ternary.tertiary1, 0.1)};
      }
    }
  }

  .MuiAutocomplete-option {

    &[aria-selected="true"].Mui-focused,
    &[aria-selected="true"] {
      background: ${({ theme }) => theme.palette.ternary.light};
      color: ${({ theme }) => theme.palette.ternary.tertiary1};

      &::after {
        font-family: 'Material Icons';
        content: "check_icon";
        width: 20px;
        height: 18px;
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 3px;
        margin: auto;
      }
    }
  }
`;


export const GroupHeader = styled('div')`
  position: sticky;
  top: -8px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.2;
  color: ${({ theme }) => theme.palette.primary.main};
  background: ${({ theme }) => theme.palette.ternary.light};
  box-shadow: inset -1px 0px 0px ${({ theme }) => theme.palette.ternary.mediumGray}, inset 1px 0px 0px ${({ theme }) => theme.palette.ternary.mediumGray};
  z-index: 999;
  font-weight: 700;
`;

export const GroupItems = styled('ul')`
  padding: 0;
`;

export const OptionTitle = styled('h4')`
  font-weight: 700;
  margin: 0 0 3px;
  font-size: 1rem;
  line-height: 1.25;
`;
