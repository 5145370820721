import { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import {
  Avatar,
  AccordionDetails,
  Box,
  Divider,
  FormGroup,
  Typography,
  Stack,
} from '@mui/material';
import dayjs from 'dayjs';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { InfoSection } from 'pages/SpeechValidation/style';
import { AccordionWrapper, AccordionHeader } from 'components/Accordion/style';
import FormControlLabel from 'elements/FormControlLabel/FormControlLabel';
import dummyImage from 'assets/images/dummyImage.png';
import { useValidations } from 'hooks';
import { Checkbox } from 'elements/Checkbox/style';

const Confirmation = () => {
  const formik = useFormikContext<any>();
  useValidations(formik);
  const { values, errors, touched, setFieldValue, setFieldTouched, setValues } =
    formik;
  const {
    attendees,
    issueDate,
    coordinatorCheck1,
    coordinatorCheck2,
    coordinatorCheck3,
  } = values;

  const coordinators = useMemo(
    () => attendees.filter((item: any) => item.isCoordinator),
    [attendees],
  );

  const speechcrafters = useMemo(
    () => attendees.filter((item: any) => !item.isCoordinator),
    [attendees],
  );

  useEffect(() => {
    if (coordinators.length) {
      setValues({
        ...values,
        coordinatorCheck1: false,
        coordinatorCheck2: false,
        coordinatorCheck3: false,
      });
    } else {
      setValues({
        ...values,
        coordinatorCheck1: true,
        coordinatorCheck2: true,
        coordinatorCheck3: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFieldTouched('coordinatorCheck1', false, false);
    setFieldTouched('coordinatorCheck2', false, false);
    setFieldTouched('coordinatorCheck3', false, false);
  }, [setFieldTouched]);

  return (
    <Box>
      <InfoSection>
        <Typography variant='h3' mb={7.5} fontWeight={700}>
          General Information
        </Typography>

        <Typography variant='body1'>Date</Typography>
        <Typography variant='h4'>
          {dayjs(issueDate)?.format('MMMM DD, YYYY')}
        </Typography>
      </InfoSection>

      {coordinators.length ? (
        <InfoSection>
          <Typography variant='h3' mb={7.5} fontWeight={700}>
            Coordinators
          </Typography>

          <Box px={3} pb={5}>
            <Typography variant='h4'>Selected Coordinators</Typography>
            <Stack gap={4} mt={3}>
              {coordinators.map((user: any) => (
                <Stack direction='row' alignItems='center' gap={2.5}>
                  <Avatar
                    alt={user.userName}
                    src={user.userImage || dummyImage}
                    sx={{ width: '26px', height: '26px' }}
                    key={user.userId}
                  />
                  <Typography variant='h5'>{user.userName}</Typography>
                </Stack>
              ))}
            </Stack>
          </Box>
          <Divider />

          <Typography variant='h4' mt={5}>
            To officially close your Speechcraft course and make sure all
            Speechcrafters can get credit, it’s critical that you complete the
            steps below:
          </Typography>

          <Typography variant='h4' marginY={5} fontWeight={400}>
            By checking the items listed here, you verify that you have
            completed these Coordinator responsibilities. If any of the tasks
            below are not complete, please click “Cancel” and complete them
            before awarding the coordinator certificate.
          </Typography>

          <Typography variant='h4' marginY={5}>
            Note: You will not be able to award the Coordinator certificate
            until all these tasks are marked complete.
          </Typography>

          <Divider />
          <Box marginY={4}>
            <FormGroup sx={{ gap: 5 }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='coordinatorCheck1'
                      checked={coordinatorCheck1}
                      color='secondary'
                      onChange={e => {
                        setFieldTouched('coordinatorCheck1', false, false);
                        setFieldValue('coordinatorCheck1', e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography fontWeight='bold'>
                      Mark all speeches presented by your Speechcrafters as
                      “Complete.”*
                    </Typography>
                  }
                  error={
                    touched['coordinatorCheck1'] && errors.coordinatorCheck1
                  }
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='coordinatorCheck2'
                      checked={coordinatorCheck2}
                      color='secondary'
                      onChange={e => {
                        setFieldTouched('coordinatorCheck2', false, false);
                        setFieldValue('coordinatorCheck2', e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography fontWeight='bold'>
                      Mark the Speechcrafter Certificate "Complete" for everyone
                      no matter how many speeches they completed.*
                    </Typography>
                  }
                  error={
                    touched['coordinatorCheck2'] && errors.coordinatorCheck2
                  }
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='coordinatorCheck3'
                      checked={coordinatorCheck3}
                      color='secondary'
                      onChange={e => {
                        setFieldTouched('coordinatorCheck3', false, false);
                        setFieldValue('coordinatorCheck3', e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography fontWeight='bold'>
                      Complete the Speechcraft Coordinator survey.*
                    </Typography>
                  }
                  error={
                    touched['coordinatorCheck3'] && errors.coordinatorCheck3
                  }
                />
              </FormGroup>
            </FormGroup>
          </Box>
          <Divider />
        </InfoSection>
      ) : null}

      {speechcrafters.length ? (
        <InfoSection>
          <Typography variant='h3' mb={7.5} fontWeight={700}>
            Speechcrafters
          </Typography>

          <Typography variant='h4' fontWeight={700} mb={1}>
            {' '}
            Awarding Certificate to
          </Typography>
          <Typography variant='h4' fontWeight={700} mb={4}>
            {speechcrafters.length}
          </Typography>

          <AccordionWrapper className='theme-accordion' defaultExpanded={true}>
            <AccordionHeader
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel2bh-content`}
              id={`panel2bh-header`}
            >
              <Typography variant='h4'>Selected Speechcrafters</Typography>
            </AccordionHeader>
            <AccordionDetails>
              <Stack gap={4}>
                {speechcrafters.map((user: any) => (
                  <Stack
                    direction='row'
                    alignItems='center'
                    gap={2.5}
                    key={user.userId}
                  >
                    <Avatar
                      alt={user.userName}
                      src={user.userImage || dummyImage}
                      sx={{ width: '26px', height: '26px' }}
                    />
                    <Typography variant='h5'>{user.userName}</Typography>
                  </Stack>
                ))}
              </Stack>
            </AccordionDetails>
          </AccordionWrapper>
        </InfoSection>
      ) : null}

      {coordinators.length ?
        (<Typography variant='body2'>
          All fields marked with <span className='asteric'>*</span> are required
        </Typography>) : null
      }
    </Box>
  );
};

export default Confirmation;
