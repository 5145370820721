import React, { memo, FunctionComponent } from 'react';
import { SelectField as StyledSelectField } from 'elements/Form/style';

const Select: FunctionComponent<any> = ({
  IconComponent,
  children,
  error,
  color,
  name,
  value,
  onChange,
  fullWidth,
  displayEmpty,
  className,
  onFocus,
  onBlur,
  MenuProps,
  ...props
}) => {
  return (
    <StyledSelectField
      error={error}
      color={color}
      name={name}
      value={value}
      IconComponent={IconComponent}
      onChange={onChange}
      fullWidth={fullWidth}
      onFocus={onFocus}
      onBlur={onBlur}
      displayEmpty={displayEmpty}
      className={className}
      MenuProps={MenuProps}
      {...props}
    >
      {children}
    </StyledSelectField>
  );
};

export default memo(Select);
