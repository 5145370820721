import { FunctionComponent } from 'react';
import { Stack, Typography } from '@mui/material';

import DatePicker from 'elements/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';

import { FieldWrapper } from 'elements/Form/style';
import { StyledLabel as FormControlLabel } from 'elements/FormControlLabel/style';
import { useGetSpeechesQuery } from 'api/speechValidationApi';
import { useAppContext } from 'contexts/AppContext';

import AutoComplete from 'elements/AutoComplete';

const GeneralInformation: FunctionComponent<any> = () => {
  const { data = [], isLoading } = useGetSpeechesQuery();
  const { event } = useAppContext();
  const { created: eventCreateDate } = event || {};

  const { values, touched, errors, setFieldValue, setFieldTouched }: any =
    useFormikContext<any>();

  const { speech, speechDate } = values;

  const handleChangeDate = (newValue: Dayjs | null) => {
    setFieldValue(
      'speechDate',
      newValue ? dayjs(newValue).format('YYYY-MM-DD') : null,
    );
    setFieldTouched('speechDate', true, false);
  };

  const handleChangeSpeech = (value: any) => {
    setFieldValue('speech', value);
    setFieldTouched('speech', true, false);
  };

  const speechOptions = data.map((speech: any) => ({
    id: speech.id,
    name: `${speech.name}#${speech.description}`,
  }));

  return (
    <Stack>
      <Typography mb={3}>
        To give your Speechcrafters credit for their speeches, select a speech type from the drop down below. 
        Then, select the date that some (or all) of your Speechcrafters gave that speech.
      </Typography>

      <Typography>
        You will select which Speechcrafters gave the speech on the selected date on the next page.
      </Typography>

      <FieldWrapper mb={4} mt={4}>
        <FormControlLabel
          label='Select speech to validate*'
          labelPlacement='top'
          control={
            <AutoComplete
              id='select-club-members'
              options={speechOptions || []}
              loading={isLoading}
              loadingText='Loading Members'
              defaultValue={isEmpty(speech) ? null : speech}
              getOptionLabel={(option: any) => option.name}
              selectedOptions={handleChangeSpeech}
              placeholder='Select speech to validate..'
              name='speech'
              helperText={touched['speech'] && (errors['speech']?.name || errors['speech'])}
              error={!!touched['speech'] && (!!errors['speech']?.name || !!errors['speech'])}
              multiline
              isSpeechValition
            />
          }
        />
      </FieldWrapper>
      <FieldWrapper>
        <FormControlLabel
          label='Date*'
          labelPlacement='top'
          control={
            <DatePicker
              label='Date'
              value={speechDate}
              handleChange={handleChangeDate}
              name='speechDate'
              placeholder='Add date of speech completion'
              className={`primary-color-icon ${speechDate ? 'has-data' : ''}`}
              helperText={touched['speechDate'] && errors['speechDate']}
              error={!!touched['speechDate'] && !!errors['speechDate']}
              minDate={new Date(eventCreateDate)}
              disableFuture={true}
            />
          }
        />
      </FieldWrapper>
    </Stack>
  );
};

export default GeneralInformation;
