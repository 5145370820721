import { FunctionComponent } from 'react';
import { map, get, isEmpty } from 'lodash';
import { MenuList, Typography } from '@mui/material';

import NotificationItem from './NotificationItem';
import { EmptyState } from './style';

const NotificationList: FunctionComponent<any> = ({
  notifications,
  handleClose,
}) => {
  return (
    <MenuList className='notification-list'>
      {isEmpty(get(notifications, 'results', [])) ? (
        <EmptyState>
          <Typography>No Results Found</Typography>
        </EmptyState>
      ) : (
        map(get(notifications, 'results', []), notification => (
          <NotificationItem
            notification={notification}
            key={notification.slug}
            handleClose={handleClose}
          />
        ))
      )}
    </MenuList>
  );
};

export default NotificationList;
