import {
  styled,
  Select as MuiSelectField,
  FormControlLabel as MuiLabel,
  TextField as MuiTextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/system';

let CommonStyle = `
    & .MuiInputBase-input {
        box-sizing: border-box;
        height: 40px;
        line-height: 30px;
        padding: 5px 10px;

        &::placeholder {
          opacity: 1;
        }
    }
    & .MuiInputBase-inputMultiline {
        min-height: 100px !important;
        padding: 0;
    }
`;

export const TextField = styled(MuiTextField)`
  &.has-data {
    .MuiOutlinedInput-notchedOutline {
      // border: 2px solid ${({ theme }) => theme.palette.secondary.light};
    }
  }

  & .MuiInputBase-root {
    ${CommonStyle}
  }

  &.h-50 {
    height: 50px;
  }
`;

export const SelectField = styled(MuiSelectField)`
  &.has-data {
    .MuiOutlinedInput-notchedOutline {
      // border: 2px solid ${({ theme }) => theme.palette.secondary.light};
    }
  }

  &.MuiInputBase-root {
    min-width: 140px;
    ${CommonStyle}

    & svg {
      color: ${({ theme }) => theme.palette.ternary.greyShade};
    }
  }

  &.h-50 {
    height: 50px;
    line-height: 40px;
  }
`;

export const StyledSearchIcon = styled(SearchIcon)`
  &.MuiSvgIcon-root {
    cursor: pointer;
  }
`;

export const FieldWrapper = styled(Box)`
  &.disabled {
    opacity: 0.25;

    .MuiButtonBase-root {
      pointer-events: none;
      cursor: default;
    }
  }
  & .MuiTypography-h4 {
    margin-bottom: 5px;
  }
`;
