import { FunctionComponent } from 'react';
import { map } from 'lodash';
import { Box, Grid, Typography } from '@mui/material';

import { QUESTION_OPTIONS } from './constants';

const EvaluationCriteria: FunctionComponent<any> = ({ questions = {} }) => {
  return (
    <>
      <Typography variant='h4'>Evaluation Criteria</Typography>
      <Typography>
        This criteria lists the specific goals and expectations for the speech.
        Please review each level to help you complete the evaluation.
      </Typography>
      <Box className='criteria-options'>
        <ul>
          {QUESTION_OPTIONS.map(option => (
            <li>{option.label}</li>
          ))}
        </ul>
      </Box>

      <div className="column-text page-break">
        {map(questions, question => {
          return (
            <Box className='text-box'>
              <Typography variant='h4'>{question.title}</Typography>
              {Array(5)
                .fill(0)
                .map((_, index) => (
                  <Typography className='list-item'>
                    <span className='serial-number'>{5 - index} -</span>
                    {question[`criteriaOption${5 - index}`]}
                  </Typography>
                ))}
            </Box>
          );
        })}
      </div>
    </>
  );
};

export default EvaluationCriteria;
