import { Avatar, Grid, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import { Helmet } from 'react-helmet';

import Loader from 'elements/Loader';
import { useGetSharedBadgeQuery } from 'api/badgeSharingApi';
import { SharedContentWrapper, LevelImage, CourseTitle } from './style';

const SharedBadge = () => {
  const routerParams = useParams<any>();
  const slug = get(routerParams, 'slug');

  const {
    data: sharedBadgeInfo,
    isFetching,
    isError,
  } = useGetSharedBadgeQuery(slug, { skip: !slug });

  if (isFetching) return <Loader position='fixed' background='none' />;

  const {
    badgeName,
    earnerName,
    badgeImage,
    badgeDescription,
    badgeSocialDescription,
    earnedAt,
    earnerProfilePicture,
    memberPhotoUrl,
    pathBadge,
  } = sharedBadgeInfo;

  const earnedDate = new Date(earnedAt);

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>{badgeImage}</title>
        <meta name='description' content={badgeSocialDescription} />
        <meta name='twitter:image:src' content={badgeImage} />
        <meta name='twitter:site' content='@toastmasterInternational' />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content={badgeName} />
        <meta name='twitter:description' content={badgeSocialDescription} />
        <meta property='og:image' content={badgeImage} />
        <meta property='og:image:alt' content={badgeSocialDescription} />
        <meta property='og:site_name' content='Toastmaster International' />
        <meta property='og:type' content='object' />
        <meta property='og:title' content={badgeName} />
        <meta property='og:description' content={badgeSocialDescription} />
      </Helmet>
      <SharedContentWrapper>
        <CourseTitle>
          {pathBadge && (
            <>
              <img src={pathBadge.badgeImage} alt={pathBadge.badgeName} />
              <Typography variant='h2'>{pathBadge.badgeName}</Typography>
            </>
          )}
        </CourseTitle>
        <Grid container spacing={3.5} mt={6}>
          <Grid item xs={12} md={6}>
            <Stack alignItems='center'>
              <Avatar src={memberPhotoUrl} alt={earnerName} />

              <div className='earned-content'>
                <Typography className='color-light'>Earned by</Typography>
                <Typography mb={5} variant='h3' className='fw-600'>
                  {earnerName}
                </Typography>
                <Typography>{badgeDescription}</Typography>
              </div>
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            <Stack alignItems='center'>
              <LevelImage>
                <div className='img-holder'>
                  <img src={badgeImage} alt={badgeName} />
                </div>
              </LevelImage>
              <Typography variant='h2'>{badgeName}</Typography>
              <Typography variant='body2' className='color-light'>
                {earnedDate.toLocaleDateString(undefined, {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                })}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </SharedContentWrapper>
    </>
  );
};

export default SharedBadge;
