import { styled } from '@mui/material';
import Badge, { BadgeProps } from '@mui/material/Badge';
import { Box } from '@mui/material';
import badgeBg from '../../assets/images/badge-bg.svg';
import badgeBgHover from '../../assets/images/badge-bg-hover.svg';

export const StyledBadge = styled(Badge)(({ theme }) => {
  return {
    '&:hover:not(.disabled)': {
      'cursor': 'pointer',
    },
    '&:hover:not(.disabled) .learner-badge': {
      background: `url(${badgeBgHover}) no-repeat center top`,
      backgroundSize: '125px 150px !important',
    },
    '& .MuiTypography-root': {
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
    '& .MuiBadge-badge': {
      right: 24,
      top: "-7px",
      padding: 8,
      borderRadius: '50%',
      height: 30,
      width: 30,
      fontWeight: 600,
      backgroundColor: theme.palette.ternary.main,
      color: theme.palette.ternary.greyShade,

      '& svg': {
        width: 18
      },
    },
    '& .MuiBox-root': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
  };
});

export const BadgeWrapper = styled(Box)<BadgeProps>(({ theme }) => ({
  height: 150,
  width: 150,
  padding: 25,
  borderRadius: '100%',
  boxShadow: '0px 2px 8px rgba(0, 0, 41, 0.16)',

  '& .badge-text': {
    display: 'none'
  },

  '&.learner-badge': {
    boxShadow: 'none',
    background: `url(${badgeBg}) no-repeat center top`,
    backgroundSize: '136px 155px !important',
    padding: 35,

    "& img": {
      height: 'auto'
    },

    '+ .MuiBadge-badge': {
      "right": 40
    }
  },
}));
