import { FunctionComponent } from 'react';
import {
  Box,
  Typography,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItemButton,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import dummyImage from 'assets/images/dummyImage.png';
import { timeAgo } from 'utils/utility';
import {
  NOTIFICATION_TYPES,
  APPROVAL_REQUEST_STATUS,
  TOASTMASTERS_WEBSITE_URL,
} from 'config';
import { useUpdateUnreadNotificationMutation } from 'api/notificationApi';

import { useNotifications } from './useNotifications';
import { NotificationHeader } from './style';

const NotificationItem: FunctionComponent<any> = ({
  notification,
  handleClose,
}) => {
  const history = useHistory();

  const {
    notificationText,
    redirectRoute,
    notificationType,
    isSenderReceiver,
    displayName,
    status,
    shouldRedirectToToastmasterWebsite,
  } = useNotifications(notification);
  const [updateUnReadNotification] = useUpdateUnreadNotificationMutation();

  const handleClick = () => {
    updateUnReadNotification(notification.slug);
    handleClose();

    if (status === APPROVAL_REQUEST_STATUS.Reversed) {
      toast.info(
        `The request has become obsolete. The learner's progress may have been reversed. For further information, please reach out to our support team.`,
      );

      return;
    }

    if (shouldRedirectToToastmasterWebsite) {
      window.location.assign(`${TOASTMASTERS_WEBSITE_URL}start-pathways/select-your-preference`);

      return;
    }

    if (!redirectRoute) {
      return;
    }

    if (
      NOTIFICATION_TYPES.pathwaysMentorProgramEnrollment === notificationType ||
      NOTIFICATION_TYPES.clubCoachModuleEnrollment === notificationType ||
      NOTIFICATION_TYPES.courseInactivity === notificationType ||
      NOTIFICATION_TYPES.courseEnrollment === notificationType ||
      NOTIFICATION_TYPES.completionReview === notificationType ||
      (notificationType === NOTIFICATION_TYPES.completionReviewNotifyBCM &&
        isSenderReceiver) ||
      NOTIFICATION_TYPES.speechcrafterCertificate === notificationType
    ) {
      window.location.assign(redirectRoute);
      return;
    }

    history.push(redirectRoute);
  };

  return (
    <ListItemButton onClick={handleClick}>
      <ListItemAvatar>
        <Avatar
          aria-hidden="true"
          role="img"
          alt={displayName ? displayName : notification?.sender?.username}
          src={
            NOTIFICATION_TYPES.completionReviewNotifyBCM === notificationType
              ? dummyImage
              : notification?.sender?.memberPhotoUrl || notification?.sender?.profileImage?.small
          }
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <NotificationHeader>
              <Typography
                variant='h4'
                component='span'
                className='fw-700 text-capitalize'
              >
                {displayName}
              </Typography>
              <Typography
                variant='h4'
                component='span'
                className='fw-700 notify-timestamp'
                aria-hidden="true"
              >
                {timeAgo(notification.timestamp)}
              </Typography>
            </NotificationHeader>
            <Box sx={{ mr: 5 }}>
              <div dangerouslySetInnerHTML={{ __html: notificationText }}></div>
            </Box>
          </>
        }
      />
    </ListItemButton>
  );
};

export default NotificationItem;
