import { styled, Box as DropzoneWrapper } from '@mui/material';
import { theme } from 'styles/theme';

export const Box = styled(DropzoneWrapper)`
  width: 100%;

  .dropzone {
    text-align: center;
    width: 100%;

    & .file-upload {
      border: 1px dashed rgba(0, 0, 0, 0.23);
      border-radius: 3px;
    }

    & .file-upload-box {
      padding: 60px 8px;
    }

    & .flex-file-upload {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 100px 12px 8px;
      border: 1px solid rgba(0, 0, 0, 0.23);
      position: relative;

      .MuiButton-contained {
        position: absolute;
        top: 4px;
        bottom: 4px;
        right: 4px;
        margin: auto;
        height: 38px;
      }
    }

    &.files {
      & .file-upload {
        border: 1px solid ${({ theme }) => theme.palette.ternary.tertiary1};
        outline: 1px solid ${({ theme }) => theme.palette.ternary.tertiary1};
      }
    }

    &:hover {
      & .file-upload {
        border: 1px solid ${({ theme }) => theme.palette.ternary.greyShade};
        outline: 1px solid ${({ theme }) => theme.palette.ternary.greyShade};
      }
    }

    &:active {
      & .file-upload {
        border: 1px solid ${({ theme }) => theme.palette.ternary.tertiary1};
        outline: 1px solid ${({ theme }) => theme.palette.ternary.tertiary1};
      }
    }

    .MuiTypography-body1 {
      margin: 0;
      font-weight: 500;

      a {
        color: ${({ theme }) => theme.palette.primary.main};
        text-decoration: underline;
      }

      &.fw-700 {
        font-weight: 700;
      }
    }

    .MuiButton-contained {
      margin: 15px 0 5px;
      min-width: 90px;

      &:hover {
        background: ${({ theme }) => theme.palette.primary.dark};
        color: ${({ theme }) => theme.palette.primary.contrastText};
      }

      &:active {
        background: ${({ theme }) => theme.palette.primary.light};
        color: ${({ theme }) => theme.palette.primary.contrastText};
      }
    }

    .MuiTypography-body2 {
      color: ${({ theme }) => theme.palette.ternary.dark};
      font-size: 11px;
      font-weight: 600;
    }
  }
`;
