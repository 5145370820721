import React, { FC } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Typography from '@mui/material/Typography';
import Loader from 'elements/Loader';

import { AccordionWrapper, AccordionHeader } from 'components/Accordion/style';

const AccordionPanel: FC<any> = ({
  children,
  activeAccordion,
  accordianId,
  handleChangeAccordion,
  title,
  isLoading = false,
}) => {
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      handleChangeAccordion(accordianId, isExpanded ? panel : false);
    };

  return (
    <>
      <AccordionWrapper
        expanded={accordianId && activeAccordion === accordianId}
        onChange={accordianId && handleChange(accordianId)}
      >
        <AccordionHeader
          expandIcon={
            accordianId && activeAccordion === accordianId ? (
              <div className='icon-text'>
                <RemoveIcon /> Collapse
              </div>
            ) : (
              <div className='icon-text'>
                <AddIcon /> Expand
              </div>
            )
          }
          aria-controls={`content-${accordianId}`}
          id={accordianId}
        >
          <Typography variant='h3'>{title}</Typography>
        </AccordionHeader>
        <AccordionDetails>
          {isLoading ? <Loader position='relative' /> : children}
        </AccordionDetails>
      </AccordionWrapper>
    </>
  );
};

export default AccordionPanel;
