import { AccordionDetails, Box, Stack, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classNames from 'classnames';

import { AccordionWrapper, AccordionHeader } from 'components/Accordion/style';
import { ReactComponent as UnitCompleteIcon } from 'assets/images/checkedIcon.svg';
import { ReactComponent as UnitIcon } from 'assets/images/bookIcon.svg';
import { ReactComponent as Level1 } from 'assets/images/level1.svg';
import { ReactComponent as Level2 } from 'assets/images/level2.svg';
import { ReactComponent as Level3 } from 'assets/images/level3.svg';
import { ReactComponent as Level4 } from 'assets/images/level4.svg';
import { ReactComponent as Level5 } from 'assets/images/level5.svg';

const levelIcons = [Level1, Level2, Level3, Level4, Level5];

const Transcript = ({
  blocks,
  expanded,
  handleAccordianChange,
  levelNumber,
}: any) => {
  const hasLevelNumber = typeof levelNumber !== 'undefined';

  return (
    <Box>
      {blocks?.children?.map(
        (
          { blockId, displayName, complete = false, children = [] }: any,
          i: number,
        ) => {
          const isPathCompletionLevel = i > 4;
          const SectionIcon = hasLevelNumber
            ? levelIcons[levelNumber]
            : levelIcons[i < levelIcons.length ? i : levelIcons.length - 1];
          const panel = `panel${hasLevelNumber ? levelNumber + 1 : i + 1}`;

          return (
            <AccordionWrapper
              className='theme-accordion'
              key={blockId}
              expanded={expanded === panel}
              onChange={handleAccordianChange(panel)}
            >
              <AccordionHeader
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1bh-content'
                id={`panel${i + 1}bh-header`}
              >
                <Stack
                  direction='row'
                  gap={4}
                  alignItems='center'
                  className={classNames({
                    'hide-icon': isPathCompletionLevel && !complete,
                  })}
                >
                  {complete ? (
                    <Stack
                      alignItems='center'
                      justifyContent='center'
                      borderRadius={9999}
                      sx={{
                        width: 28,
                        height: 28,
                        backgroundColor: 'ternary.main',
                      }}
                    >
                      <UnitCompleteIcon />
                    </Stack>
                  ) : (
                    <SectionIcon />
                  )}
                  <Typography variant='h4'>{displayName}</Typography>
                </Stack>
              </AccordionHeader>
              <AccordionDetails>
                {children.map(
                  ({ blockId, displayName, complete = false }: any) => (
                    <Stack
                      direction='row'
                      gap={4}
                      alignItems='center'
                      mt={5}
                      key={blockId}
                    >
                      <Stack
                        alignItems='center'
                        justifyContent='center'
                        sx={{ width: 28, height: 28 }}
                        className={classNames({
                          'hide-icon': isPathCompletionLevel && !complete,
                        })}
                      >
                        {complete ? <UnitCompleteIcon /> : <UnitIcon />}
                      </Stack>
                      <Typography>{displayName}</Typography>
                    </Stack>
                  ),
                )}
              </AccordionDetails>
            </AccordionWrapper>
          );
        },
      )}
    </Box>
  );
};

export default Transcript;
