import { FC, memo, useState } from 'react';
import {
  ConfirmationBox,
  ImageHolder,
  CTAButtons,
} from 'components/Confirmation/style';
import { Backdrop, CircularProgress, Typography } from '@mui/material';
import Button from 'elements/Button';
import ConfirmIcon from 'elements/Svgs/Confirm';
import Loader from 'elements/Loader';

const Confirmation: FC<any> = ({
  data,
  handleClose,
  handleConfirmation,
  children,
  buttonText,
  cancelText = 'Cancel',
  showLoader = true,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <ConfirmationBox>
      {loading && showLoader && (
        <Loader position='absolute' background='white' />
      )}
      <ImageHolder>
        <ConfirmIcon />
      </ImageHolder>
      <Typography className='fs-20' variant='body1'>
        {children}
      </Typography>
      <CTAButtons>
        <Button onClick={handleClose} variant='outlined'>
          {cancelText}
        </Button>
        <Button
          onClick={() => {
            setLoading(!loading);
            handleConfirmation(data);
          }}
          variant='contained'
        >
          {buttonText ? buttonText : 'Confirm'}
        </Button>
      </CTAButtons>
    </ConfirmationBox>
  );
};

export default memo(Confirmation);
