import {
  styled,
  Box,
  FormControlLabel as MuiLabel,
  ToggleButtonGroup,
  Stack,
  Button,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { DeviceUp } from 'styles/breakpoints';

export const StyleToggleButtonGroup = styled(ToggleButtonGroup)`
    padding: 10px;
    background: ${({ theme }) => theme.palette.ternary.white};
    border: 1px solid ${({ theme }) => theme.palette.ternary.main};

    .MuiToggleButtonGroup-grouped {

        &:not(:first-of-type) {
            border: 1px solid transparent;
            margin-left: 5px;

            &:hover {
                border-color: ${({ theme }) => theme.palette.ternary.main};
            }
        }

        &.active {
            border-color: ${({ theme }) => theme.palette.ternary.dark};
            background: ${({ theme }) => theme.palette.ternary.dark};
            color: ${({ theme }) => theme.palette.ternary.white};
    
            &:hover {
                border-color: ${({ theme }) => theme.palette.ternary.dark};
                background: ${({ theme }) => theme.palette.ternary.dark};
            }
        }
    }
`;

export const StyledToggleButton = styled(Link)`
    text-transform: capitalize;
    font-weight: 700;
    font-size: 15px;
    padding: 10px 15px;
    color: ${({ theme }) => theme.palette.ternary.greyShade};
    border: 1px solid transparent;
    border-radius: 3px !important;

    &:hover {
        border-color: ${({ theme }) => theme.palette.ternary.main};
    }

    @media ${DeviceUp.sm} {
        font-size: 16px;
        padding: 9px 20px;
        min-width: 170px;
        text-align: center;
    }
`;

export const SortFieldWrapper = styled(Box)`
  & .MuiTypography-root {
    white-space: nowrap;
    margin-right: 5px;
  }

  & .MuiFormControl-root {
    height: 40px
  }
`;

export const FilterBox = styled(Box)`
  display: flex;
  margin-top: 40px;
`;

export const FilterWrapper = styled(Box)`
  margin-top: 20px;
  padding-top: 20px;

  .disabled-dropdown {
    opacity: 0.25;
    pointer-events: none;
    cursor: default;
  }

  & .MuiButtonBase-root.MuiButton-root:not(.MuiButton-text) {
    min-height: 40px !important;
  }
`;

export const DispalyMenuItems = styled('div')`
  padding: 0;
  margin: 0;
  max-height: 160px;
  overflow: auto;

  .item-holder {
    position: relative;
    padding-right: 205px;

    .custom-label {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
      margin: 0;
      pointer-events: none;

      .MuiSwitch-root {
        pointer-events: all;
      }

      .MuiTypography-root {
        margin: 0;
      }
    }

    li:hover {
      background-color: transparent;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.04);
    }
  }

  &.mt-0 {
    margin-top: 0;
  }

  li {
    padding: 4px 10px;
    margin: 0;
    display: flex;
    justify-content: space-between;
    min-height: 42px;
    white-space: pre-wrap;

    & .MuiFormControlLabel-root {
      margin-right: 0;
      color: ${({ theme }) => theme.palette.ternary.greyShade};
    }

    & .MuiTypography-root {
      margin: 0;
    }
  }
`;

export const StyledLabel = styled(MuiLabel)`
  display: flex;
  align-items: start;
  margin: 0;
  justify-content: center;
  align-items: center;

  & .MuiCheckbox-root {
    padding: 5px;
    margin-right: 4px;
  }
`;

export const GroupHeader = styled('div')`
  position: sticky;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.2;
  color: ${({ theme }) => theme.palette.primary.black};
  background: ${({ theme }) => theme.palette.ternary.light};
  box-shadow: inset -1px 0px 0px ${({ theme }) => theme.palette.ternary.mediumGray},
    inset 1px 0px 0px ${({ theme }) => theme.palette.ternary.mediumGray};
  z-index: 999;
  font-weight: 700;
`;

export const AppliedFilters = styled(Box)`
  margin-top: 15px;

  & .MuiChip-deleteIcon {
    width: 16px;
    color: ${({ theme }) => theme.palette.ternary.greyShade} !important;

    &:hover {
      color: ${({ theme }) => theme.palette.ternary.greyShade} !important;
    }
  }

  & .filtersWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    & .MuiChip-root {
      margin: 5px 8px 0 0;
    }

    & .MuiTypography-body2 {
      margin: 4px 0 0;
    }
  }
`;

export const ResetAllFilters = styled(Button)`
  cursor: pointer;
  text-decoration: underline;
  border: none;
  background: none;
  padding: 0;
  margin-top: 5px;
`;

export const CardActions = styled(Stack)`
  align-items: center;
  justify-content: space-between;

  &.card-cta-icons-wrapper {
    margin-left: 10px;

    .disabled{
      pointer-events: none;
    }
  }

  .card-cta-icons {
    display: flex;
    align-items: center;

    button {
      border: none;
      background: none;
      padding: 0;
      height: 32px;
      width: 32px;
      min-width: 32px;
      text-align: center;
      border-radius: 50%;
      background: ${({theme}) => theme.palette.ternary.white};
      border: 1px solid ${({theme}) => theme.palette.ternary.tertiary12};
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover,
      &:focus {
        background: ${({theme}) => theme.palette.ternary.light}
      }
    }

    svg,
    img {
      margin: auto;
      max-width: 18px;
    }
  }

  img {
    // height: 32px;
    // width: 32px;
    cursor: pointer;
  }
`;
