import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import { LMS_BASE_URL } from 'config';
import axiosClient from 'utils/client';

const CLUB_MEMBERS_API_URL = `${LMS_BASE_URL}/api/members/club_detail`;

const clubMembersAdapter = createEntityAdapter({
  selectId: (a: any) => a.user.id,
  sortComparer: (a: any, b: any) => (a.user.id > b.user.id ? 1 : -1),
});

const initialState = clubMembersAdapter.getInitialState({
  isFetching: false,
  isSuccess: false,
  isError: false,
  error: null as any,
  hasMore: true,
  page: 0,
});

export const fetchClubMembers = createAsyncThunk(
  'clubMembers/list',
  async (clubId: any, thunkApi: any) => {
    const page = thunkApi.getState().clubMembers.page;
    const nextPage = page + 1;
    const res = await axiosClient.get(
      `${CLUB_MEMBERS_API_URL}?selected_club=${clubId}&page=${nextPage}&page_size=30`,
    );
    return res.data;
  },
  {
    condition: (_, thunkApi: any) => {
      const { isFetching, hasMore } = thunkApi.getState().clubMembers;
      if (isFetching || !hasMore) return false;
    },
  },
);

const clubMembersSlice = createSlice({
  name: 'clubMembers',
  initialState,

  reducers: {
    resetClubMembers: () => initialState,
  },

  extraReducers: builder => {
    builder
      .addCase(fetchClubMembers.pending, (state, action) => {
        state.isFetching = true;
        state.isSuccess = false;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchClubMembers.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;

        if (action.payload.length <= 0) {
          state.hasMore = false;
          return;
        }

        const { members } = action.payload[0];
        const { next, results } = members;
        state.hasMore = !!next;
        state.page += 1;

        clubMembersAdapter.upsertMany(state, results);
      })
      .addCase(fetchClubMembers.rejected, (state, action) => {
        state.isFetching = false;
        state.isSuccess = false;
        state.isError = true;
        state.error = action.payload;
      });
  },
});

export const { resetClubMembers } = clubMembersSlice.actions;

export default clubMembersSlice.reducer;

export const selectClubMembers = (state: any) => state.clubMembers;

export const { selectAll: selectAllClubMembers } =
  clubMembersAdapter.getSelectors(selectClubMembers);
