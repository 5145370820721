import { FC, useState, useEffect } from 'react';
import { Box, Button, Stack } from '@mui/material';

import { HOC } from 'elements/Modal';

import TextField from 'elements/Form/TextField';

import { StyledLabel as FormControlLabel } from 'elements/FormControlLabel/style';
import { FieldWrapper } from 'elements/Form/style';

const AddComment: FC<any> = ({
  handleClose,
  isIndividualComment,
  comment,
  handleAddComment,
}) => {
  const [updatedComment, setUpdatedComment] = useState('');

  useEffect(() => {
    setUpdatedComment(comment);

    return () => setUpdatedComment('');
  }, [comment]);

  return (
    <Box mt={1.5}>
      <FieldWrapper mb={4}>
        <FormControlLabel
          label={`Leave a comment for ${
            isIndividualComment
              ? 'selected Speechcrafter'
              : 'all Speechcrafters'
          }`}
          labelPlacement='top'
          control={
            <TextField
              multiline
              fullWidth
              className={`${comment ? 'has-data' : ''}`}
              id='feedback'
              placeholder='Please type your comment here'
              value={updatedComment}
              onChange={(e: any) => {
                setUpdatedComment(e.target.value);
              }}
            />
          }
        />
      </FieldWrapper>
      <Stack direction='row' justifyContent='flex-end' gap={1.2} mt={15}>
        <Button variant='outlined' size='small' onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant='contained'
          size='small'
          onClick={() => {
            handleAddComment(updatedComment);
          }}
        >
          Add
        </Button>
      </Stack>
    </Box>
  );
};

export default HOC(AddComment);
