import { styled, Box, Typography } from '@mui/material';

export const LegendTypography = styled(Typography)`
  position: relative;

  &::before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: transparent;
    display: inline-block;
    margin-right: 10px;
  }

  &.incomplete-legend::before {
    background: ${({ theme }) => theme.palette.ternary.main};
  }
  &.complete-legend::before {
    background: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const TooltipWrapper = styled(Box)`
  z-index: 100;
  position: absolute;
  left: 0;
  top: 0;
  background: ${({ theme }) => theme.palette.ternary.white};
  border: 1px solid ${({ theme }) => theme.palette.secondary.main};
  border-radius: 3px;
  padding: 20px 15px;
  box-shadow: 0px 4px 16px rgba(0, 0, 41, 0.24);
  max-width: 260px;
  margin-bottom: 30px;
  word-break: break-word;
  cursor: auto;
`;

export const PieTooltipWrapper = styled(Box)`
  position: absolute;
  left: ${({ leftX }: any) => leftX + 'px'};
  top: ${({ topY }: any) => topY + 'px'};
  background: ${({ theme }) => theme.palette.ternary.white};
  border: 1px solid ${({ theme }) => theme.palette.secondary.main};
  border-radius: 3px;
  padding: 20px 15px;
  box-shadow: 0px 4px 16px rgba(0, 0, 41, 0.24);
  width: 260px;
  word-break: break-word;
  cursor: auto;

  & .tooltip-arrow {
    position: absolute;
    width: 14px;
    height: 28px;
    background-color: white;
    right: 10px;
    bottom: -6px;
    transform: rotate(45deg);
  }
`;
