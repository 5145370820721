import { useEffect, useMemo, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useDownloader from 'react-use-downloader';
import { toast } from 'react-toastify';

import { LMS_BASE_URL } from 'config';
import { FieldWrapper, TextField } from 'elements/Form/style';
import { StyledLabel as FormControlLabel } from 'elements/FormControlLabel/style';
import {
  StyledAutocomplete as Autocomplete,
  StyledLabel,
  StyledPopperOptions,
} from 'elements/AutoComplete/style';
import Loader from 'elements/Loader';
import AssessmentChart from 'pages/Dashboard/components/SelfAssessments/AssessmentResults/AssessmentChart';
import {
  useGetAssessmentFormsQuery,
  useGetAssessmentResultsQuery,
  useGetAverageAssessmentResultsQuery,
} from 'api/selfAssessmentApi';
import { useGetEventAttendeesQuery } from 'api/speechCraftApi';
import { useGetRolesQuery } from 'api/initializeAppApi';
import { getPlatformName, getUserName } from 'utils/utility';
import Breadcrumb from 'components/Breadcrumb';
import AssessmentResponse from './AssessmentResponse';

const links = [
  {
    label: 'Home',
    url: `${getPlatformName()}`,
  },
  {
    label: 'Dashboard',
  },
];

const allSpeechcrafters = {
  id: 0,
  firstName: 'All',
  lastName: 'Speechcrafters',
  name: 'All Speechcrafters',
  username: '',
};

const Assessments = () => {
  const [form, setForm] = useState<any>(null);

  const [speechcrafter, setSpeechcrafter] = useState<any>(null);
  const [speechcrafterInput, setSpeechcrafterInput] = useState<any>('');

  const { data: events = [] } = useGetRolesQuery();
  const eventId = events[0]?.id;

  const {
    data: assessmentForms,
    isFetching: isFetchingForms,
    isSuccess: isSuccessForms,
  } = useGetAssessmentFormsQuery({});

  useEffect(() => {
    if (isSuccessForms && assessmentForms?.length > 0) {
      setForm(assessmentForms?.[0]);
    }
  }, [isSuccessForms, assessmentForms]);

  const {
    data: event,
    isFetching: isFetchingEvent,
    isSuccess: isSuccessEvent,
  } = useGetEventAttendeesQuery({ attendeesType: 'all' });

  useEffect(() => {
    if (isSuccessEvent && event.attendees.length > 0) {
      setSpeechcrafter(allSpeechcrafters);
      setSpeechcrafterInput(getUserName(allSpeechcrafters));
    }
  }, [isSuccessEvent, event?.attendees]);

  const {
    data: userAssessmentResults,
    isFetching: isFetchingUserResults,
    isSuccess: isSuccessUserResults,
  } = useGetAssessmentResultsQuery(
    { eventId, formId: form?.id, user: speechcrafter?.username },
    { skip: !eventId || !form?.id || !speechcrafter?.username },
  );

  const {
    data: avgAssessmentResults,
    isFetching: isFetchingAvgResults,
    isSuccess: isSuccessAvgResults,
  } = useGetAverageAssessmentResultsQuery(
    { formId: form?.id },
    {
      skip:
        !eventId ||
        !form?.id ||
        speechcrafter?.username ||
        event?.attendees?.length <= 0,
    },
  );

  const isFetchingResults = isFetchingUserResults || isFetchingAvgResults;
  const isSuccessResults = isSuccessUserResults || isSuccessAvgResults;

  const assessmentResults = speechcrafter?.username
    ? userAssessmentResults
    : avgAssessmentResults?.questions;

  const assessmentResultsGrouped = useMemo(() => {
    const groupedAssessment = new Map<String, [object]>();

    assessmentResults?.forEach((question: any) => {
      const key = question.questionType;
      const unknownKey = 'undefined category';

      if (key) {
        if (groupedAssessment.has(key)) {
          groupedAssessment.get(key)!.push(question);
        } else {
          groupedAssessment.set(key, [question]);
        }
      } else {
        if (groupedAssessment.has(unknownKey)) {
          groupedAssessment.get(unknownKey)!.push(question);
        } else {
          groupedAssessment.set(unknownKey, [question]);
        }
      }
    });

    return groupedAssessment;
  }, [assessmentResults]);

  const assessmentResultsGroupedComp: any = [];
  assessmentResultsGrouped?.forEach((questions: any, key) => {
    assessmentResultsGroupedComp.push(
      // @ts-ignore
      <Box mb={16} key={key}>
        <Typography variant='h2' textTransform='capitalize'>
          {`${key} responses`}
        </Typography>
        <Grid container spacing={8} pt={5}>
          {questions.map((assessmentResult: any) => (
            <Grid item xs={12} lg={6} key={assessmentResult.id}>
              <AssessmentChart assessmentResult={assessmentResult} />
            </Grid>
          ))}
        </Grid>
      </Box>,
    );
  });

  const pieChartData = speechcrafter?.username
    ? {}
    : avgAssessmentResults?.assessmentStatus;

  const { download, isInProgress } = useDownloader({
    cache: 'no-cache',
    credentials: 'include',
  });

  const handleAssessmentDownload = async () => {
    const url = `${LMS_BASE_URL}/api/self_assessment/form/${form?.id}/event/${eventId}/download/`;
    try {
      await download(url, `${form?.title}.xlsx`);
    } catch (error) {
      toast.error('Download failed! Please try again later');
    }
  };

  const attendeesOptions =
    isSuccessEvent && event?.attendees?.length > 0
      ? [
          allSpeechcrafters,
          ...event?.attendees?.map((user: any) => ({
            ...user,
            name: getUserName(user),
          })),
        ]
      : [];

  return (
    <Box width='100%'>
      <Breadcrumb links={links} />

      <Typography>
        Use this dashboard to assess the progress of your Speechcrafters over
        time to provide them with additional support as appropriate.
        Additionally, see which Speechcrafters have not yet completed each
        assessment.
      </Typography>

      <Grid container spacing={2.5} mt={2.5}>
        <Grid item xs={12} md={6} lg={4}>
          <FieldWrapper>
            <FormControlLabel
              label='Speechcrafters'
              labelPlacement='top'
              control={
                <Autocomplete
                  id='attendees-combo-box'
                  value={speechcrafter}
                  onChange={(e: any, v: any) => {
                    setSpeechcrafter(v);
                  }}
                  className={`${speechcrafter?.id ? 'has-data' : ''}`}
                  options={attendeesOptions}
                  isOptionEqualToValue={(option: any, value: any) =>
                    !value ? value === '' : option.id === value.id
                  }
                  inputValue={speechcrafterInput}
                  onInputChange={(e: any, v: any) => {
                    setSpeechcrafterInput(v);
                  }}
                  getOptionLabel={(option: any) => {
                    if (!option) {
                      if (isFetchingEvent) {
                        return 'Loading...';
                      } else {
                        return 'No Options';
                      }
                    }
                    return getUserName(option);
                  }}
                  renderInput={(params: any) => (
                    <StyledLabel
                      label=''
                      value=''
                      labelPlacement='top'
                      control={
                        <TextField
                          {...params}
                          placeholder={
                            isFetchingEvent
                              ? 'Loading...'
                              : event?.attendees?.length
                              ? 'Select a speechcrafter'
                              : 'Not available'
                          }
                          name='attendees-combo-box'
                        />
                      }
                    />
                  )}
                  disableClearable
                  PopperComponent={({ style, ...props }) => (
                    <StyledPopperOptions
                      {...props}
                      style={{ ...style, zIndex: 1199 }}
                    />
                  )}
                  popupIcon={<KeyboardArrowDownIcon />}
                  disabled={
                    isFetchingForms ||
                    isFetchingEvent ||
                    isFetchingResults ||
                    !form?.id ||
                    event?.attendees?.length <= 0
                  }
                />
              }
            />
          </FieldWrapper>
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
          alignSelf='flex-end'
          textAlign={{ xs: 'right', lg: 'initial' }}
        >
          <Box pl={5} pb={3}>
            <Button
              startIcon={<DownloadIcon />}
              onClick={handleAssessmentDownload}
              disabled={
                isInProgress ||
                !eventId ||
                !form?.id ||
                event?.attendees?.length <= 0 ||
                isFetchingForms ||
                isFetchingEvent ||
                isFetchingResults
              }
            >
              Download
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Box mt={10}>
        {isFetchingForms || isFetchingEvent || isFetchingResults ? (
          <Loader />
        ) : isSuccessForms && assessmentForms?.length <= 0 ? (
          <Typography variant='h4' textAlign='center'>
            No assessment form found!
          </Typography>
        ) : isSuccessEvent && event?.attendees?.length <= 0 ? (
          <Typography variant='h4' textAlign='center'>
            No speechcrafters found!
          </Typography>
        ) : !isFetchingResults &&
          isSuccessResults &&
          assessmentResults?.length <= 0 ? (
          <Typography variant='h3' textAlign='center'>
            No records found!
          </Typography>
        ) : null}

        {!isFetchingResults &&
        isSuccessResults &&
        assessmentResults?.length &&
        !speechcrafter?.username ? (
          <Box mb={16}>
            <Typography variant='h2'>Assessment Responses</Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <AssessmentResponse
                  assessmentName='Before Self-Assessment'
                  completedUsers={pieChartData.beforeCompleted}
                  incompletedUsers={pieChartData.beforeIncompleted}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <AssessmentResponse
                  assessmentName='Mid Self-Assessment'
                  completedUsers={pieChartData.midCompleted}
                  incompletedUsers={pieChartData.midIncompleted}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <AssessmentResponse
                  assessmentName='After Self-Assessment'
                  completedUsers={pieChartData.afterCompleted}
                  incompletedUsers={pieChartData.afterIncompleted}
                />
              </Grid>
            </Grid>
          </Box>
        ) : null}

        {!isFetchingResults && isSuccessResults && assessmentResults?.length > 0
          ? assessmentResultsGroupedComp
          : null}
      </Box>
    </Box>
  );
};

export default Assessments;
