import React, {
  FunctionComponent,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { Stack, Typography, Box } from '@mui/material';
import useDownloader from 'react-use-downloader';

import axiosClient from 'utils/client';
import Loader from 'elements/Loader';
import Button from 'elements/Button';
import Chip from 'elements/Chip';
import { LMS_BASE_URL, ACCEPTED_VIDEO_FORMATS } from 'config';

import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';

import {
  PreviewWrapper,
  MediaPreview,
  DescriptionWrapper,
  ActionsHolder,
} from './style';
import { map } from 'lodash';
import FileView from './DocView';
import classNames from 'classnames';

const AssetPreview: FunctionComponent<any> = props => {
  const {
    handleClose,
    asset,
    disableFavorite,
    handleFavoriteAsset,
    handleShareAsset,
  } = props;
  const {
    asset: assetUrl,
    id,
    description,
    keywords,
    resourceId,
    fileName,
    categoryId,
    uuid,
    assetExtension,
    subCategory,
    language,
    resources
  } = asset;

  const { favorite } = resources;

  const [url, setUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [rerenderIframe, setRerenderIframe] = useState(1);
  const [error, setError] = useState('');

  const { download, isInProgress } = useDownloader({
    cache: 'no-cache',
  });

  const handlePreview = useCallback(() => {
    setIsLoading(true);
    axiosClient({
      url: `${LMS_BASE_URL}/api/asset_management/assets/${uuid}/asset_url/`,
      method: 'GET',
    })
      .then(resp => {
        setUrl(resp.data.fileUrl);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setError('Something went wrong!');
      });
  }, [uuid]);

  const handleDownloadAsset = () => {
    axiosClient({
      url: `${LMS_BASE_URL}/api/asset_management/assets/${uuid}/download/`,
      method: 'GET',
    }).then(resp => {
      download(resp.data.fileUrl, `${fileName}${assetExtension}`);
    });
  };

  React.useEffect(() => {
    handlePreview();
  }, [handlePreview]);

  const fileType = assetExtension?.split('.')?.pop();
  const isDisabled = !!disableFavorite[id];

  const viewByFiletype = useMemo(() => {
    if (isLoading) {
      return <Loader position='relative' />;
    }

    if (error) {
      <div>{error}</div>;
    }

    if (ACCEPTED_VIDEO_FORMATS.includes(fileType)) {
      return (
        <video height='100%' width='100%' controls controlsList='nodownload'>
          <source src={url} type={`video/${fileType}`} />
          <track default kind='captions' label={fileName} />
        </video>
      );
    }

    if (
      fileType === 'jpeg' ||
      fileType === 'jpg' ||
      fileType === 'png' ||
      fileType === 'svg'
    ) {
      return <img src={url} alt={fileName} />;
    }

    if (fileType === 'doc' || fileType === 'docx' || fileType === 'pdf') {
      return <FileView url={url} fileName={fileName} />;
    }

    return (
      <iframe src={`${url}`} title={fileName} height='100%' width='100%' />
    );
  }, [error, fileName, fileType, isLoading, url]);

  return (
    <>
      <PreviewWrapper>
        <MediaPreview>{viewByFiletype}</MediaPreview>
        <DescriptionWrapper>
          <Box>
            <Typography variant='h2'>{fileName}</Typography>
            <Typography variant='body1'>{description}</Typography>
          </Box>
          <ActionsHolder direction='row'>
            <Button
              disabled={isInProgress}
              fullWidth
              onClick={() => handleDownloadAsset()}
            >
              {isInProgress ? 'Downloading...' : 'Download'}
            </Button>
            <button
              aria-label={'Share'}
              className='icon-holder'
              onClick={() => handleShareAsset(asset)}
            >
              <ShareOutlinedIcon />
            </button>
            <button
              aria-label={favorite ? 'Favorite' : 'Unfavorite'}
              className={classNames('icon-holder', { active: favorite })}
              onClick={() =>
                !isDisabled &&
                handleFavoriteAsset(id, !favorite, resourceId, categoryId)
              }
            >
              {favorite ? (
                <FavoriteOutlinedIcon />
              ) : (
                <FavoriteBorderOutlinedIcon />
              )}
            </button>
          </ActionsHolder>
        </DescriptionWrapper>
      </PreviewWrapper>
      <Typography variant='h2'>Tags</Typography>
      <Stack
        className='flexwrap'
        direction='row'
        spacing={1}
        justifyContent='flex-start'
      >
        {map(subCategory, (label: any, index: number) => (
          <Chip label={label.name} key={index} className='filter-chip' />
        ))}
        {map(language, (label: any, index: number) => (
          <Chip label={label.name} key={index} className='filter-chip' />
        ))}
      </Stack>
    </>
  );
};

export default AssetPreview;
