import React, { FunctionComponent, useEffect } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';

import {
  Typography,
  Step,
  Box,
  StepButton,
  Stack,
  Divider,
} from '@mui/material';

import Button from 'elements/Button';

import CancelButton from './CancelButton';
import { Title, ActionHolder, StyledStepper, ContentWrapper } from './style';

const FormStepper: FunctionComponent<any> = ({
  children,
  isLoading = false,
  loadingMessage = '',
  setValues,
  onSubmit,
  isNextDisabled = false,
  enableReinitialize,
  initialValues,
  title,
  step,
  handleClose,
  ConfirmationButtonText = 'Validate',
  ...props
}) => {
  const steps = React.Children.toArray(children);
  const [activeStep, setActiveStep] = React.useState<any>(0);
  const currentChild: any = steps[activeStep];
  const [completed, setCompleted] = React.useState<any>([]);

  const validationSchema = currentChild.props.validationSchema
    ? Yup.object().shape(currentChild.props.validationSchema)
    : null;

  const isFirstStep = React.useMemo(() => {
    return activeStep === 0;
  }, [activeStep]);

  const isLastStep = React.useMemo(() => {
    return activeStep === steps.length - 1;
  }, [activeStep, steps]);

  const handleNext = () => {
    if (isLastStep) {
      return;
    }

    setActiveStep(activeStep + 1);
    setCompleted([...completed, activeStep]);
  };

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };

  const handleStep = (step: any) => () => {
    const nextStep = step;

    setActiveStep(step); //remove

    if ([nextStep].some(step => completed.includes(step))) {
      setActiveStep(step);
    }
  };

  const handleSubmit = async (values: any, helpers: any) => {
    await onSubmit(values, activeStep, helpers, handleNext);
  };

  function NextButton() {
    return (
      <Button className='mw120' onClick={() => {}} type='submit'>
        {isLastStep ? ConfirmationButtonText : 'Next'}
      </Button>
    );
  }

  useEffect(() => {
    setActiveStep(step);
  }, [step]);

  return (
    <Formik
      {...props}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      enableReinitialize={enableReinitialize}
    >
      <Form>
        <Title mb={10}>
          <Typography variant='h1'>{title}</Typography>
        </Title>
        {steps.length > 1 && (
          <StyledStepper
            activeStep={activeStep}
            alternativeLabel
            connector={null}
          >
            {steps.map((child: any, index: any) => (
              <Step key={`${child.props.label}-${index}`}>
                <Divider absolute />
                <StepButton onClick={handleStep(index)}>
                  <Typography variant='h4'>{child?.props.label}</Typography>
                </StepButton>
              </Step>
            ))}
          </StyledStepper>
        )}
        <ContentWrapper mt={22.5} isSecondStep={activeStep === 1}>
          {currentChild}
          <ActionHolder>
            <Box>
              {!isFirstStep && (
                <Button
                  className='mw120'
                  variant='outlined'
                  onClick={handlePrevious}
                >
                  Back
                </Button>
              )}
            </Box>

            <Stack direction='row' spacing={1}>
              <CancelButton handleClose={handleClose} />
              <NextButton />
            </Stack>
          </ActionHolder>
        </ContentWrapper>
      </Form>
    </Formik>
  );
};

export default FormStepper;
