import { useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';

import Loader from 'elements/Loader';
import Transcript from 'components/Transcript';
import { useGetProfileCourseTranscriptQuery } from 'api/profileApi';

const CourseTranscript = ({ courseId, username, ...props }: any) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleAccordianChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const {
    data: transcript,
    isFetching,
    isSuccess,
    isError,
  } = useGetProfileCourseTranscriptQuery({ username, courseId });

  const { blocks } = transcript || {};

  return (
    <Box>
      {isFetching ? <Loader /> : null}

      {isError ? (
        <Typography textAlign='center' color='error'>
          Something went wrong. Please try again later
        </Typography>
      ) : null}

      {isSuccess ? (
        <Box>
          <Transcript
            blocks={blocks}
            expanded={expanded}
            handleAccordianChange={handleAccordianChange}
          />

          <Stack mt={10} alignItems='flex-end'>
            <Button
              variant='contained'
              size='small'
              onClick={props.handleClose}
            >
              Close
            </Button>
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
};

export default CourseTranscript;
