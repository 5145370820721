import baseApiSlice from 'api/baseApiSlice';
import { TOASTMASTER_API_URL } from 'config';

enum ITags {
  ROLES = 'Roles',
}

const rolesApiWithTags = baseApiSlice.enhanceEndpoints({
  addTagTypes: [ITags.ROLES],
});

const rolesApiSlice = rolesApiWithTags.injectEndpoints({
  endpoints: builder => ({
    getRoles: builder.query<any, void>({
      query: () => ({
        url: `${TOASTMASTER_API_URL}/members/roles`,
      }),
      providesTags: [ITags.ROLES],
    }),

    getCurrentEvent: builder.query<string, Record<string, number>>({
      query: () => ({
        url: `${TOASTMASTER_API_URL}/events/current/`,
      }),
    }),

    syncEvent: builder.query<string, Record<string, number>>({
      query: () => ({
        url: `${TOASTMASTER_API_URL}/events/sync/`,
      }),
    }),
  }),

  overrideExisting: false,
});

export const { useGetRolesQuery, useLazyGetCurrentEventQuery, useLazySyncEventQuery } =
  rolesApiSlice;

export default rolesApiSlice;
