import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Stack, Typography } from '@mui/material';

import Loader from 'elements/Loader';
import Transcript from 'components/Transcript';
import { useGetTranscriptQuery } from 'api/reviewRequestsApi';
import { selectReviewReqeust } from './ducks/approvalSlice';

import {
  handleOpenApprovalModal,
  handleOpenDenyModal,
} from './ducks/approvalSlice';
import { ApproveInstructionalText } from 'pages/Requests/style';

const ApprovalRequest = (props: any) => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const handleAccordianChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const dispatch = useDispatch();

  const handleApproveButton = () => {
    dispatch(handleOpenApprovalModal());
  };
  const handleDenyButton = () => {
    dispatch(handleOpenDenyModal());
  };

  const { request } = useSelector(selectReviewReqeust);
  const {
    data: transcript,
    isFetching,
    isSuccess,
    isError,
  } = useGetTranscriptQuery(request?.id, { skip: !request?.id });

  const { blocks } = transcript || {};

  useEffect(() => {
    // expand the section requested for approval
    blocks?.children.forEach(({ id }: any, i: number) => {
      if (id === request.usageKey) {
        setExpanded(`panel${i + 1}`);
      }
    });
  }, [blocks?.children, request.usageKey]);

  let levelNumber;

  const approvalLevel =
    blocks?.children?.filter((level: any, index: any) => {
      const hasLevelToApprove = level.id === request.usageKey;

      if (hasLevelToApprove) {
        levelNumber = index;
      }

      return hasLevelToApprove;
    }) || [];

  const isPathApproval = blocks?.id === request.usageKey;

  const approvalLevelBlocks = {
    children: approvalLevel,
  };

  return (
    <Box>
      {isFetching ? <Loader /> : null}

      {isError ? (
        <Typography textAlign='center' color='error'>
          Something went wrong. Please try again later
        </Typography>
      ) : null}

      {isSuccess ? (
        <Box>
          <ApproveInstructionalText>
            <Box pb={4}>
              Before approving this completion request, compare the member's
              work and speech history to the requirements for the level or path.
              Confirm whether all requirements were met, then approve or deny
              the request.
            </Box>
          </ApproveInstructionalText>
          <Transcript
            blocks={isPathApproval ? blocks : approvalLevelBlocks}
            expanded={expanded}
            handleAccordianChange={handleAccordianChange}
            levelNumber={isPathApproval ? undefined : levelNumber}
          />
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='flex-end'
            mt={10}
            gap={1}
          >
            {props.handleClose ? (
              <Button size='small' variant='text' onClick={props.handleClose}>
                Cancel
              </Button>
            ) : null}
            {request?.status === 'Pending' ? (
              <>
                <Button variant='outlined' onClick={handleDenyButton}>
                  Deny
                </Button>
                <Button variant='contained' onClick={handleApproveButton}>
                  Approve
                </Button>
              </>
            ) : (
              <Button disabled variant='contained'>
                {request?.status === 'Approved' ? 'Approved' : 'Denied'}
              </Button>
            )}
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
};

export default ApprovalRequest;
