import {
  Box,
  styled,
  AppBar as MuiAppBar,
  Toolbar as MuiToolbar,
  Menu,
  alpha,
} from '@mui/material';
import { DeviceDown, DeviceUp } from 'styles/breakpoints';

export const AppBar = styled(MuiAppBar)`
  box-shadow: none;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.palette.ternary.main};
  background-color: white;

  .hijacking-alert-holder {
    width: 100%;
    order: 4;
  }
`;

export const Toolbar = styled(MuiToolbar)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${DeviceDown.sm} {
    flex-wrap: wrap;
  }
`;

export const LogoWrapper = styled('div')`
  width: 100px;
  margin: 5px 0;

  @media ${DeviceUp.xs} {
    width: 190px;
  }

  & img {
    max-width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
`;
export const HeaderRight = styled('div')`
  display: flex;
  align-items: center;

  .d-inline-flex {
    display: inline-flex;
  }

  .align-items-center {
    align-items: center;
  }

  .d-md-inline-flex {
    @media ${DeviceDown.md} {
      display: inline-flex !important;

      & a {
        margin: 12px;
      }
    }
  }

  .menu-btn {
    border-radius: 4px;
  }

  & a {
    margin: 12px 15px;
    color: ${({ theme }) => theme.palette.ternary.greyShade};

    &:hover {
      color: ${({ theme }) => theme.palette.primary.main};
    }

    span {
      margin: 0;
      font-weight: 600;
      font-family: ${({ theme }) => theme.typography.fontFamily};

      @media ${DeviceUp.md} {
        font-size: 14px;
      }
    }
  }

  & .mediumIcon {
    width: 40px;
    height: 40px;
    padding: 3px;
    margin: 0;
  }

  & .iconGrey {
    svg {
      fill: ${({ theme }) => theme.palette.ternary.greyShade};
    }
  }

  & .MuiIconButton-root.active {
    background: ${({ theme }) => theme.palette.ternary.light};
  }

  & .userAccount {
    border-radius: 4px;
    padding-left: 15px;

    &:hover {
      background: ${({ theme }) => theme.palette.ternary.light};
    }

    label {
      cursor: pointer;
      display: inline-block;
      margin-right: 10px;
      font-weight: 600;
      font-size: 15px;
      font-family: ${({ theme }) => theme.typography.fontFamily};
    }
  }
`;

export const MenuDropdownHolder = styled(Menu)`
  & .MuiList-root {
    width: 190px;
    padding: 8px 0;

    .MuiMenuItem-root,
    a {
      padding: 7px 15px;
      margin: 0;
      display: block;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      color: ${({ theme }) => theme.palette.text.primary};

      &:hover,
      &:active,
      &:focus {
        color: ${({ theme }) => theme.palette.secondary.dark};
        background: ${({ theme }) => theme.palette.ternary.light};
      }

      &:focus-visible {
        outline-offset: -3px;
      }

      &.error {
        color: ${({ theme }) => theme.palette.error.main};
      }
    }
  }
  .error {
    color: ${({ theme }) => theme.palette.error.dark};
  }
`;

export const StyledSearchIcon = styled('button')`
  background: none;
  border: none;
  position: absolute;
  right: 5px;
  top: 5px;
  width: 36px;
  height: 30px;
  cursor: pointer;

  &::before {
    color: ${({ theme }) => theme.palette.ternary.greyShade};
    font-size: 22px;
    font-family: 'Material Icons';
    content: '\\E8B6';
    display: block;
    padding-top: 2px;
  }

  &.MuiSvgIcon-root {
    cursor: pointer;
  }

  &.disable {
    pointer-events: none;
  }
`;

export const HeaderLeft = styled('div')`
  display: flex;
  align-items: center;
`;

export const SearchHolder = styled('div')`
  margin: 0 auto 0 30px;
  width: 260px;
  position: relative;

  @media ${DeviceDown.sm} {
    order: 3;
    margin: 0 auto 5px;
    width: 100%;
  }

  & .MuiFormControl-root {
    & .MuiInputBase-root {
      margin: 0;

      input {
        background: ${({ theme }) => theme.palette.primary.white};
        border-radius: 3px;
      }
    }
  }
`;

export const SkipNav = styled('a')`
  display: inline-block;
  position: absolute;
  z-index: 999;
  left: 0;
  right: 0;
  margin: auto;
  top: -600px;
  overflow: hidden;
  background: ${({ theme }) => theme.palette.ternary.white};
  border-bottom: 1px solid ${({ theme }) => theme.palette.ternary.white};
  padding: 15px 0;

  &:focus,
  &:active {
    top: 7px;
    width: 170px;
    background-color: ${({ theme }) => theme.palette.ternary.greyShade};
    opacity: 0.9;
    color: ${({ theme }) => theme.palette.ternary.white};
    text-decoration: none;
    text-align: center;
  }
`;
