import * as Yup from 'yup';
import dayjs from 'dayjs';

export const parseDateString = (value: any, originalValue: any) => {
  if (value === null || value === '') {
    return new Date();
  }
  const parsedDate = dayjs(originalValue).isValid()
    ? value
    : dayjs(originalValue).format('MMMM DD, YYYY');

  return parsedDate;
};

export const generalInformationScheema = {
  speech: Yup.object()
    .shape({
      id: Yup.string().required('Field is required'),
      name: Yup.string().required('Field is required'),
    })
    .nullable()
    .required('Field is required'),
  speechDate: Yup.date()
    .required('Field is required')
    .transform(parseDateString)
    .typeError('Invalid date format. (MMMM DD, YYYY)'),
};

export const speechCraftScheema = {
  // groupComment: Yup.string().notRequired(),
  attendees: Yup.array()
    .of(
      Yup.object()
        .shape({
          id: Yup.string().required('Field is required'),
        })
        .required('Field is required'),
    )
    .min(1, 'At least one selection is required')
    .required('Field is required'),
};
