import { FunctionComponent, useState, MouseEvent, useEffect } from 'react';
import { useClickAway, useKey, useEvent } from 'react-use';
import classNames from 'classnames';

import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';

import Loader from 'elements/Loader';
import { NOTIFICATION_INTERVAL } from 'config';
import { useGetUnreadNotificationQuery } from 'api/notificationApi';

import NotificationHeader from './NotificationHeader';
import NotificationList from './NotificationList';
import { BellIconHolder, IconBubble, MenuDropdownHolder } from './style';
import { useLocation } from 'react-router-dom';

const NotificationsMenu: FunctionComponent<any> = () => {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const location = useLocation();

  const { data, isLoading, refetch } = useGetUnreadNotificationQuery(
    {},
    {
      pollingInterval: NOTIFICATION_INTERVAL,
    },
  );

  useEffect(() => {
    refetch();
  }, [location, refetch]);

  const { count = 0, ...notifications } = data || {};

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async () => {
    setAnchorEl(null);
  };

  useClickAway(anchorEl, handleClose);
  useKey('Escape', handleClose);
  useEvent('blur', handleClose);

  return (
    <>
      <BellIconHolder>
        <IconButton
          aria-label='Notifications for user'
          className={classNames('mediumIcon iconGrey', {
            active: Boolean(anchorEl),
          })}
          onClick={handleOpen}
        >
          {!!count && <IconBubble className='notify-bubble' />}
          <NotificationsIcon />
        </IconButton>
      </BellIconHolder>
      <MenuDropdownHolder
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 1,
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <>
          <NotificationHeader count={count} handleClose={handleClose} />
          {isLoading ? (
            <Loader position='relative' />
          ) : (
            <NotificationList
              notifications={notifications}
              handleClose={handleClose}
            />
          )}
        </>
      </MenuDropdownHolder>
    </>
  );
};

export default NotificationsMenu;
