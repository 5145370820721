import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  // Button,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';
// @ts-ignore
import { getConfig } from '@edx/frontend-platform/config';

import { Button } from 'elements/Button/style';
import { EDX_LOGIN_COOKIE } from 'config';

const CookiePollLogout = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const prevLoggedIn = useRef(false);

  useEffect(() => {
    const timer = setInterval(() => {
      const cookies = new Cookies();
      const isLoggedIn = cookies.get(EDX_LOGIN_COOKIE);

      const isSessionExpired =
        prevLoggedIn.current && !isLoggedIn && !isDialogOpen;
      const isSessionRenewed =
        !prevLoggedIn.current && isLoggedIn && isDialogOpen;
      const updateSession =
        !prevLoggedIn.current && isLoggedIn && !isDialogOpen;

      if (isSessionExpired) {
        setIsDialogOpen(true);
        prevLoggedIn.current = false;
        toast.warning('Session has expired');
      } else if (isSessionRenewed) {
        setIsDialogOpen(false);
        prevLoggedIn.current = true;
        toast.success('Session has updated');
        window.setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else if (updateSession) {
        prevLoggedIn.current = true;
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [isDialogOpen]);

  return (
    <Dialog
      open={isDialogOpen}
      fullWidth
      maxWidth='xs'
      aria-labelledby='alert-dialog-session-expired'
      aria-describedby='alert-dialog-session-expired-login-again'
    >
      <DialogContent sx={{ pb: 4 }}>
        <DialogContentText id='alert-dialog-session-expired-login-again'>
          Your session has expired.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          href={getConfig().LOGIN_URL}
          variant='contained'
          fullwidth
          autofocus
        >
          Login
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CookiePollLogout;
