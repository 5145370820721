import { Box, styled } from '@mui/material';

export const SearchForm = styled(Box)`
  width: 260px;
  position: relative;

  .MuiButtonBase-root {
    position: absolute;
    right: 0;
    border-radius: 4px;
    color: ${({ theme }) => theme.palette.ternary.greyShade};

    &:hover {
      background: none;
    }
  }
  .MuiInputBase-root {
    &::before {
      border-bottom-color: ${({ theme }) => theme.palette.ternary.main};
    }
    .MuiInputBase-input {
      padding-right: 40px !important;
    }
  }
`;
