import React, { FunctionComponent, memo, useEffect, useRef, useState } from 'react';
import Button from 'elements/Button';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { MenuWrapper, MenuDropdownHolder } from 'elements/CustomDropdown/style';

const CustomDropdown: FunctionComponent<any> = ({classNames, popOverClasses, isOpen, btnStartIcon, btnEndIcon, menuTitle, menuAlignment, toggleDropdown, isFilterDropdown, disableBtn,  ...props}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isActiveDropdown, setIsActiveDropdown] = useState(false);
  const ref = useRef(null)
  const open = Boolean(anchorEl);
  
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsActiveDropdown(!isActiveDropdown);
    if(toggleDropdown)
      toggleDropdown(true)
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsActiveDropdown(!isActiveDropdown);
    if(toggleDropdown)
      toggleDropdown(false)
  };

  useEffect(() => {
    if(isActiveDropdown)
      setAnchorEl(ref.current);
    if(!isActiveDropdown && isOpen)
      setIsActiveDropdown(isOpen)
    if(isActiveDropdown && !isOpen)
      setIsActiveDropdown(false)
  }, [isActiveDropdown, isOpen])


  return (
    <MenuWrapper className={classNames} ref={ref}>
      <Button
        disabled={disableBtn}
        variant='outlined'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={btnStartIcon}
        endIcon={btnEndIcon}
        className={`${isActiveDropdown? ' active' : ''}`}
        fullWidth
        isFilterDropdown={isFilterDropdown}
      >
        {menuTitle || <MoreHorizIcon />}
      </Button>
      <MenuDropdownHolder
        transformOrigin={{ vertical: menuAlignment.vertical, horizontal: menuAlignment.horizontal }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={isActiveDropdown}
        onClose={handleClose}
        className={popOverClasses}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: { width: anchorEl && anchorEl.offsetWidth }
        }}
      >
       {props.children}
      </MenuDropdownHolder>
    </MenuWrapper>
  );
}

CustomDropdown.defaultProps = {
    isOpen: false,
    classNames: "",
    menuTitle: "",
    menuAlignment: {vertical: 'top', horizontal: 'right'}
}

export default memo(CustomDropdown);
