import { Box, styled } from '@mui/material';

export const EmptyDataTextContainer = styled(Box)`
  position: absolute;
  left: 60%;
  top: 45%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.palette.ternary.light};
  border: 1px solid ${({ theme }) => theme.palette.ternary.main};
  z-index: 2;
  padding: 0.625rem 1rem;
`;
