import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosClient from 'utils/client';
import querystring from 'querystring';
import { get, uniqBy, isEmpty, map } from 'lodash';

import { TOASTMASTER_API_URL, LMS_BASE_URL } from 'config';

const resourcesAPI = {
  async fetchResources({
    categories,
    subCategories,
    languages,
    page = 1,
    favorite,
    recently_viewed,
    shared,
    signal,
  }: any) {
    const params = {
      page,
      ...(categories && {
        categories,
      }),
      ...(subCategories && {
        sub_categories: subCategories,
      }),
      ...(languages && {
        languages,
      }),
      ...(favorite && {
        favorite,
      }),
      ...(recently_viewed && {
        recently_viewed,
      }),
      ...(shared && {
        shared: shared,
      }),
    };

    let qs = querystring.stringify(params);
    if (qs) {
      qs = `?${qs}`;
    }

    const result = await axiosClient({
      url: `${LMS_BASE_URL}${TOASTMASTER_API_URL}/asset_management/resources/${qs}`,
      method: 'GET',
      signal: signal,
    });
    return result;
  },
  async fetchResourceById({
    pageNumber,
    categoryId,
    categories,
    subCategories,
    languages,
    favorite,
    recently_viewed,
    shared,
    signal,
  }: any) {
    const params = {
      ...(pageNumber && {
        page_no: pageNumber,
      }),
      ...(categories && {
        categories,
      }),
      ...(subCategories && {
        sub_categories: subCategories,
      }),
      ...(languages && {
        languages,
      }),
      ...(favorite && {
        favorite,
      }),
      ...(recently_viewed && {
        recently_viewed,
      }),
      ...(shared && {
        shared: shared,
      }),
    };

    let qs = querystring.stringify(params);
    if (qs) {
      qs = `?${qs}`;
    }

    const result = await axiosClient({
      url: `${LMS_BASE_URL}${TOASTMASTER_API_URL}/asset_management/resources/${categoryId}/${qs}`,
      method: 'GET',
      signal: signal,
    });
    return result;
  },

  async updateResource({ payload, assetId }: any) {
    const result = await axiosClient({
      url: `${LMS_BASE_URL}${TOASTMASTER_API_URL}/asset_management/resources/${assetId}/`,
      method: 'PATCH',
      data: payload,
    });

    return result;
  },
};

export const getResources = createAsyncThunk(
  'resources/getResources',
  async (props: any, thunkAPI) => {
    const response = await resourcesAPI.fetchResources(props);
    return response.data;
  },
);

export const getResourceById = createAsyncThunk(
  'resources/getResourceById',
  async (props: any, thunkAPI) => {
    const response = await resourcesAPI.fetchResourceById(props);
    return response.data;
  },
);

export const updateResource = createAsyncThunk(
  'resources/updateResource',
  async (props: any, thunkAPI) => {
    const response = await resourcesAPI.updateResource(props);
    props.callback?.(response.data);
    return response.data;
  },
);

const initialState: any = {
  data: [],
  isLoading: false,
  isError: false,
};
const slice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    resetResources: () => {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getResources.pending, state => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getResources.rejected, state => {
        // state.isLoading = false;
        state.isError = true;
      })
      .addCase(getResources.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(getResourceById.pending, state => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getResourceById.rejected, state => {
        // state.isLoading = false;
        state.isError = true;
      })
      .addCase(getResourceById.fulfilled, (state, action) => {
        if (isEmpty(state.data) || !action.meta.arg.pageNumber) {
          state.data = [action.payload];
        } else {
          state.data = state.data.map((resource: any) => {
            if (resource.id === action.payload.id) {
              return {
                ...resource,
                resources: {
                  next: get(action, 'payload.resources.next', null),
                  previous: get(action, 'payload.resources.previous', null),
                  count: get(action, 'payload.resources.count', 0),
                  results: uniqBy(
                    [
                      ...get(resource, `resources.results`, []),
                      ...get(action, 'payload.resources.results', []),
                    ],
                    'id',
                  ),
                },
              };
            }

            return resource;
          });
        }
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(updateResource.fulfilled, (state, action) => {
        const { categoryId } = action?.meta?.arg || {};

        if (categoryId) {
          state.data = state.data.map((caregory: any) => {
            if (caregory.id !== categoryId) {
              return caregory;
            }

            return {
              ...caregory,
              resources: {
                next: get(caregory, 'resources.next', null),
                previous: get(caregory, 'resources.previous', null),
                count: get(caregory, 'resources.count', 0),
                results: map(
                  get(caregory, `resources.results`, []),
                  resource => {
                    if (resource.id === action.payload.id) {
                      return action.payload;
                    }

                    return resource;
                  },
                ),
              },
            };
          });
        }
      });
  },
});

export const { resetResources } = slice.actions;

export const { reducer } = slice;
