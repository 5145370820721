import { Box, styled } from '@mui/material';
import { theme } from 'styles/theme';

export const Container = styled(Box)`
  max-width: 1110px;
  width: 100%;
  padding: 0 15px;
  margin: 30px auto 0;

  & h2 {
    margin-bottom: 10px;
  }

  & .infinite-scroll-component {
    padding: 10px 5px
  }

  & .back-btn {
    display: flex;
    align-items: center;

    svg {
      width: 14px;
      margin-right: 8px;
    }
  }
`;

export const NavBreadCrumbs = styled('ul')`
  padding: 0;
  margin: 10px 0 20px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  & li {
    padding: 2px 10px 2px 0;
    font-weight: 700;
    font-size: 14px;

    &:last-child {
      padding-right: 0;
    }

    & a {
      color: ${({ theme }) => theme.palette.secondary.main};
    }
  }
`;
