import * as Yup from 'yup';

import dayjs from 'dayjs';

const parseDateString = (value: any, originalValue: any) => {
  if (value === null || value === '') {
    return new Date();
  }
  const parsedDate = dayjs(originalValue).isValid()
    ? value
    : dayjs(originalValue).format('MMMM DD, YYYY');

  return parsedDate;
};

export const validation = Yup.object({
  speechDate: Yup.date()
    .transform(parseDateString)
    .typeError('Invalid date format. (MMMM DD, YYYY)')
    .required('Field is required'),
  club: Yup.number().required('Field is required'),
  evaluator: Yup.number().required('Field is required'),
  speechTitle: Yup.string().required('Field is required'),
  evaluationForm: Yup.number().required('Field is required'),
  request: Yup.string(),
});
