import { FunctionComponent } from 'react';
import { Box, Stack, ToggleButtonGroup } from '@mui/material';
import { StyleToggleButtonGroup, StyledToggleButton } from './style';
import { useParams } from 'react-router-dom';
import { get, map } from 'lodash';
import { getPlatformName } from 'utils/utility';
import ToggleButton from 'elements/ToggleButton';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import AssessmentDetails from './AssessmentDetails';
import AssessmentResults from './AssessmentResults';
import { useRoles } from 'hooks';
import useProfilePage from 'pages/Profile/useProfilePage';

const ASSESSMENT_VIEW: string = 'assessment-details';
const ASSESSMENT_RESULTS_VIEW: string = 'assessment-results';

const tabs = [
  { ariaLabel: 'Assessments view', label: 'Assessments', id: ASSESSMENT_VIEW },
  {
    ariaLabel: 'Responses view',
    label: 'Responses',
    id: ASSESSMENT_RESULTS_VIEW,
  },
];

const SelfAssessments: FunctionComponent<any> = () => {
  const routerParams = useParams<any>();
  const activeSubSection = get(
    routerParams,
    'activeSubSection',
    ASSESSMENT_VIEW,
  );

  const { isCoordinator } = useRoles();
  const { username } = useProfilePage();
  const { pathname } = useLocation();
  const isProfilePage = pathname.includes('profile');

  return (
    <>
      <Stack alignItems='center'>
        <StyleToggleButtonGroup aria-label='resources view'>
          {map(tabs, (tab, index) => (
            <StyledToggleButton
              key={index}
              className={classNames({
                active: activeSubSection === tab.id,
              })}
              aria-controls={tab.ariaLabel}
              to={
                isCoordinator
                  ? `${getPlatformName()}/my-cohort/profile/${username}/self-Assessments/${
                      tab.id
                    }`
                  : isProfilePage
                  ? `${getPlatformName()}/profile/self-Assessments/${tab.id}`
                  : `${getPlatformName()}/self-assessments/${tab.id}`
              }
            >
              {tab.label}
            </StyledToggleButton>
          ))}
        </StyleToggleButtonGroup>
      </Stack>

      <Box>
        {activeSubSection === ASSESSMENT_VIEW ? (
          <AssessmentDetails />
        ) : (
          <AssessmentResults />
        )}
      </Box>
    </>
  );
};

export default SelfAssessments;
