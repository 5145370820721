import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, MenuItem, Stack, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import FormControlLabel from 'elements/FormControlLabel/FormControlLabel';
import { TextField } from 'elements/Form/style';
import { FieldWrapper } from 'elements/Form/style';
import { SelectField as Select } from 'elements/Form/style';

import {
  selectReviewReqeust,
  setComment,
  setDenyReason,
  handleDenyGoBack,
  handleOpenConfirmationModal,
} from './ducks/approvalSlice';

const Deny = (props: any) => {
  const [touched, setTouched] = useState({comment: false});
  const dispatch = useDispatch();
  const { comment, denyReason } = useSelector(selectReviewReqeust);

  return (
    <Box>
      <Box sx={{ backgroundColor: 'ternary.light' }} p={5}>
        <Typography variant='h3' sx={{ fontWeight: "700" }}>Reasons</Typography>
        <Typography>
          Completion requests must only be denied if a member did not complete one or more requirements for the level or path. 
          Before denying this completion, compare the requirements for the level or path to your records of the member's work and speech history. 
          Select the reason for the denial below and add specific details to ensure the member understands what to correct or complete before re-submitting. 
        </Typography>
        <Typography mt={3}>
          <Typography variant='h4' component='span'>
          Missing speech:
          </Typography>{' '}
          The member did not present one or more of the speeches required in the level or path. 
          Note the missing speech(es) and which project(s) need to be completed in your comments. 
        </Typography>
        <Typography mt={3}>
          <Typography variant='h4' component='span'>
          Incomplete assignment:
          </Typography>{' '}
          The member did not complete an assignment, or a component of an assignment, 
          associated with this level or path. Note details related to the incomplete assignment(s) and project(s) in your comments. 
        </Typography>
        <Typography mt={3}>
          <Typography variant='h4' component='span'>
          Additional details requested:
          </Typography>{' '}
          The member did not provide all documentation needed to confirm project and/or speech completion in this level or path. 
          Provide specific details and documentation as needed in your comments. 
        </Typography>
      </Box>

      <FieldWrapper mt={4}>
        <FormControlLabel
          label='Reason'
          labelPlacement='top'
          control={
            <Select
              fullWidth
              className={`${denyReason ? 'has-data' : ''}`}
              id='deny-reason'
              value={denyReason}
              IconComponent={KeyboardArrowDownIcon}
              onChange={(e: any) => dispatch(setDenyReason(e.target.value))}
              placeholder='Select a reason'
            >
              <MenuItem value='1'>Missing speech</MenuItem>
              <MenuItem value='2'>Incomplete assignment</MenuItem>
              <MenuItem value='3'>Additional details requested</MenuItem>
            </Select>
          }
        />
      </FieldWrapper>

      <FieldWrapper mt={4}>
        <FormControlLabel
          label='Comments*'
          labelPlacement='top'
          control={
            <TextField
              multiline
              fullWidth
              required
              className={`${comment ? 'has-data' : ''}`}
              id='deny-comment'
              placeholder='Please enter the description here.'
              value={comment}
              onChange={(e: any) => {
                setTouched((prev: any) => ({ ...prev, comment: true }));
                dispatch(setComment(e.target.value));
              }}
              error={touched.comment && !comment}
              helperText={touched.comment && !comment && "Field is required"}
            />
          }
        />
      </FieldWrapper>

      <Stack direction='row' mt={10} justifyContent='space-between'>
        <Button
          size='small'
          variant='text'
          color='secondary'
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={() => dispatch(handleDenyGoBack())}
        >
          Back
        </Button>

        <Stack direction='row' gap={1}>
          <Button size='small' variant='outlined' onClick={props.handleClose}>
            Cancel
          </Button>
          <Button
            size='small'
            variant='contained'
            disabled={!denyReason || !comment}
            onClick={() => dispatch(handleOpenConfirmationModal())}
          >
            Deny
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Deny;
