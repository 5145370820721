import { FunctionComponent, Fragment, useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'elements/Loader';
import { useGetRolesQuery } from 'api/initializeAppApi';
import { Container } from './style';
import AssessmentCard from './CardItem';
import {
  fetchSelfAssessmentForms,
  resetState as resetSelfAssessmentState,
  selectAllSelfAssessmentForms,
  selectSelfAssessmentForms as selectSelfAssessmentState,
} from '../selfAssessmentSlice';
import useProfilePage from 'pages/Profile/useProfilePage';

const AssessmentDetails: FunctionComponent<any> = () => {
  const dispatch = useDispatch();
  const { username: profileUsername, isSelfProfilePage } = useProfilePage();
  const username = useMemo(
    () => (isSelfProfilePage ? '' : profileUsername),
    [isSelfProfilePage, profileUsername],
  );

  const { data: events = [] } = useGetRolesQuery();
  const eventId = events[0]?.id;

  const { isFetching } = useSelector(selectSelfAssessmentState);
  const data = useSelector(selectAllSelfAssessmentForms);

  useEffect(() => {
    dispatch(fetchSelfAssessmentForms({ eventId, username }));

    return () => {
      dispatch(resetSelfAssessmentState());
    };
  }, [dispatch, eventId, username]);

  return (
    <Container mt={10}>
      {!isFetching && isEmpty(data) ? (
        <Box mt={10} textAlign='center'>
          Records not found
        </Box>
      ) : null}

      <Grid container spacing={3}>
        {data?.map((cardData: any, index: any) => (
          <Fragment key={cardData.id}>
            <Grid item xs={12} sm={4}>
              <AssessmentCard
                cardData={cardData}
                progress='before'
                isSubmitted={cardData?.isSubmitted?.before}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <AssessmentCard
                cardData={cardData}
                progress='mid'
                isSubmitted={cardData?.isSubmitted?.mid}
                isDisabled={!cardData?.isSubmitted?.before}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <AssessmentCard
                cardData={cardData}
                progress='after'
                isSubmitted={cardData?.isSubmitted?.after}
                isDisabled={!cardData?.isSubmitted?.mid}
              />
            </Grid>
          </Fragment>
        ))}
      </Grid>

      {isFetching ? (
        <Box mt={10} textAlign='center'>
          <Loader />
        </Box>
      ) : null}
    </Container>
  );
};

export default AssessmentDetails;
