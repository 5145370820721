import { memo, FunctionComponent } from 'react';
// import Dropzone from 'react-dropzone'
import { useDropzone } from 'react-dropzone';
import { Box } from 'elements/Dropzone/style';
import { isEmpty } from 'lodash';

const DropZoneFile: FunctionComponent<any> = ({
  children,
  acceptedFile,
  maxFileSize,
  onDrop,
  hasData,
  maxFiles,
}) => {
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: acceptedFile,
      maxSize: maxFileSize,
      onDrop,
      maxFiles,
    });

  return (
    <Box>
      <div
        {...getRootProps({
          className: `${
            !isEmpty(acceptedFiles) || hasData ? 'files dropzone' : 'dropzone'
          }`,
        })}
      >
        <input {...getInputProps()} />
        {children}
      </div>
    </Box>
  );
};

DropZoneFile.defaultProps = {
  acceptedFile: {},
  maxFileSize: 314572800,
  maxFiles: 5,
};

export default memo(DropZoneFile);
