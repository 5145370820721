import {
  Breadcrumbs,
  Box,
  Container,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { Link as RouterLink, generatePath, useHistory } from 'react-router-dom';
// @ts-ignore
import { getAuthenticatedUser } from '@edx/frontend-platform/auth';

import CardSilder from 'components/CardSlider';
import ClubCard from './Components/ClubCard';
import Loader from 'elements/Loader';
import { useGetClubsWithMembersQuery } from 'api/clubApi';
import { DashboardRoutes as Routes } from 'routes';
import { useEffect } from 'react';
import { getPlatformName } from 'utils/utility';

const MyClubs = () => {
  const history = useHistory();
  const user: any = getAuthenticatedUser();

  const {
    data: clubs,
    isFetching,
    isSuccess,
  } = useGetClubsWithMembersQuery(undefined);

  const sliderSettings = {
    slidesToShow: 4.3,
    // Announce slide change for screen readers
    afterChange: (currentIndex: any) => {
      const slide = document.querySelector(`[data-slick-index="${currentIndex}"]`);
      if (slide) {
        slide.setAttribute("aria-live", "polite");
      }
    },
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4.3,
        },
      },
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 3.5,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2.5,
        },
      },
      {
        breakpoint: 675,
        settings: {
          slidesToShow: 1.5,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1.1,
        },
      },
    ],
  };

  useEffect(() => {
    if (clubs?.length === 1) {
      history.push({
        pathname: generatePath(Routes.CLUB_DETAILS.path, {
          clubId: clubs[0].id,
        }),
        state: { clubName: clubs[0].name },
      });
    }
  }, [clubs, history]);

  return (
    <Container>
      <Box role='presentation' mt={7.5}>
        <Breadcrumbs role="navigation" aria-label="breadcrumbs">
          <Link
            component={RouterLink}
            to={`${getPlatformName()}`}
            underline='none'
            variant='h5'
            color='secondary'
          >
            Home
          </Link>
          <Typography variant='h5' color='ternary.dark' component='p'>
            My Clubs
          </Typography>
        </Breadcrumbs>
      </Box>

      {isFetching ? <Loader position='fixed' background='none' /> : null}

      {isSuccess && clubs.length > 0 ? (
        <Stack gap={4} mt={7.5}>
          <Typography variant='h1' pb={1}>My Clubs</Typography>
          {clubs.map(({ id, name, members, uuid }: any) => {
            const filteredMembers = members.results.filter(({ user: member }: any) => member.id !== user.userId);
            return (
              <Box key={id}>
                <Stack
                  direction='row'
                  gap={4}
                  alignItems='center'
                  justifyContent='space-between'
                  mb={2}
                >
                  <Typography variant='h3' component='h2' color='ternary.dark' mb={0}>
                    {name}
                  </Typography>
                  <Link
                    component={RouterLink}
                    to={{
                      pathname: generatePath(Routes.CLUB_DETAILS.path, {
                        clubId: id,
                      }),
                      state: { clubName: name },
                    }}
                    underline='none'
                    variant='h5'
                    color='secondary'
                  >
                    View All
                    <span className='sr-only'>{name}</span>
                  </Link>
                </Stack>
                <div
                  role="region"
                  aria-label={`Carousel, ${filteredMembers?.length} items`}
                  aria-roledescription="carousel"
                >
                  <CardSilder sliderSettings={{ ...sliderSettings }}>
                    {members.results
                      .filter(({ user: member }: any) => member.id !== user.userId)
                      .map(({ user }: any, index: number) => (
                        <div
                          className="club-member-card-item"
                          key={index}
                          role="group"
                          aria-roledescription="slide"
                          aria-label={`Item ${index + 1} of ${filteredMembers?.length}`}
                          aria-posinset={index + 1}
                          aria-setsize={members.results.length}
                          data-slick-index={index}
                        >
                          <ClubCard
                            key={user.id}
                            member={user}
                            clubId={id}
                            clubName={name}
                          />
                        </div>
                      ))}
                  </CardSilder>
                </div>
              </Box>
            )}
          )}
        </Stack>
      ) : null}

      {isSuccess && clubs.length <= 0 ? (
        <Typography variant='h1' textAlign='center' mt={20}>
          You are not a part of any club
        </Typography>
      ) : null}
    </Container>
  );
};

export default MyClubs;
