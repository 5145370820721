import { Box, styled } from '@mui/material';

export const ButtonWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;

  button {
    margin-left: 10px
  }
`;
