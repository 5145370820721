import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
  updateHistorySpeech: {
    status: false,
    errors: null,
    payload: {},
  },
  confirmation: {
    status: false,
    payload: {
      data: {},
      name: '',
    },
  },
};

const slice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    toggleUpdateSpeechHistory: (state, payload) => {
      return {
        ...state,
        updateHistorySpeech: {
          ...state.updateHistorySpeech,
          status: !state.updateHistorySpeech.status,
          ...payload,
        },
      };
    },
    toggleConfirmation: (state: any, { payload }: any) => {
      return {
        ...state,
        confirmation: {
          status: !state.confirmation.status,
          payload: {
            data: payload.data,
            name: payload.name,
          },
        },
      };
    },
    closeModal: (state: any) => {
      return state;
    },
  },
});

export const {
  toggleUpdateSpeechHistory,
  toggleConfirmation,
  closeModal,
} = slice.actions;

export const { reducer } = slice;
