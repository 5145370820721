import { createTheme } from '@mui/material/styles';
import { breakpoints } from 'styles/breakpoints';

declare module '@mui/material/styles' {
  interface Palette {
    ternary: Palette['primary'];
  }
  interface PaletteOptions {
    ternary?: PaletteOptions['primary'];
  }

  interface PaletteColor {
    black?: any;
    white?: any;
    mediumGray?: any;
    greyShade?: any;
    focusBlue?: any;
    tertiary1?: any;
    tertiary2?: any;
    tertiary3?: any;
    tertiary4?: any;
    tertiary5?: any;
    tertiary6?: any;
    tertiary7?: any;
    tertiary8?: any;
    tertiary9?: any;
    tertiary10?: any;
    tertiary11?: any;
    tertiary12?: any;
  }

  interface TypographyVariants {
    title: React.CSSProperties;
    quote: React.CSSProperties;
  }
  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    title?: React.CSSProperties;
    quote?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title: true;
    quote: true;
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    ternary: true;
  }
}

export const themeFonts = {
  fontFamilyRoboto: [
    'Roboto',
    'Lato',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
  ].join(','),
  fontFamilyLato: [
    'Lato',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
  ].join(','),
};

export const themeConfig = {
  breakpoints: {
    values: {
      xs: breakpoints.xxs,
      sm: breakpoints.sm,
      md: breakpoints.md,
      lg: breakpoints.lg,
      xl: breakpoints.xl,
    },
  },
  palette: {
    primary: {
      main: '#772432',
      light: '#B84848',
      dark: '#5C1F29',
      contrastText: '#fff',
    },
    secondary: {
      light: '#004165',
      main: '#006094',
      dark: '#00293B',
      contrastText: '#fff',
    },
    ternary: {
      light: '#F5F5F5',
      main: '#E8E8E8',
      dark: '#4D4D4D',
      black: '#000',
      white: '#fff',
      mediumGray: '#CCCCCC',
      greyShade: '#333333',
      tertiary1: '#006094',
      tertiary2: '#022A3A',
      tertiary3: '#035FC2',
      tertiary4: '#39C2D7',
      tertiary5: '#81CAA0',
      tertiary6: '#F2DF74',
      tertiary7: '#D6B430',
      tertiary8: '#DD8676',
      tertiary9: '#781327',
      tertiary10: '#3B0104',
      tertiary11: '#A93439',
      tertiary12: '#A9B2B1',
      focusBlue: '#035FC2',
    },
    error: {
      dark: '#FF0000',
      main: '#AC1B1B',
    },
    success: {
      main: '#157F5B',
    },
    warning: {
      main: '#D6B430',
    },
    info: {
      main: '#006094',
    },
    action: {
      disabled: '#77243280',
      disabledBackground: '#77243240',
    },
    text: {
      primary: '#333333',
    },
  },
  shape: {
    borderRadius: 3,
  },
  typography: {
    fontFamily: themeFonts.fontFamilyLato,
    h1: {
      fontWeight: '500',
      fontSize: '1.5em',
      '@media (min-width:600px)': {
        fontSize: '1.75em',
      },
    },
    h2: {
      marginBottom: 5,
      fontWeight: '700',
      fontSize: '1.25em',
      '@media (min-width:600px)': {
        fontSize: '1.5em',
      },
    },
    h3: {
      fontWeight: '500',
      fontSize: '1em',
      marginBottom: 8,
      '@media (min-width:600px)': {
        fontSize: '1.25em',
      },
    },
    h4: {
      fontWeight: '700',
      fontSize: '0.875em',
      '@media (min-width:600px)': {
        fontSize: '1em',
      },
    },
    h5: {
      fontWeight: '700',
      fontSize: '0.75em',
      '@media (min-width:600px)': {
        fontSize: '0.875em',
      },
    },
    h6: {
      fontWeight: '700',
      fontSize: '0.75em',
    },
    body1: {
      fontSize: '0.875em',
      lineHeight: '22px',
      // marginBottom: 8,
      '@media (min-width:600px)': {
        fontSize: '1em',
      },
    },
    button: {
      fontWeight: '500',
      fontSize: '0.875em',
    },
    quote: {
      color: '#004165',
      fontWeight: '500',
      fontSize: '0.875em',
      fontFamily: ['Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(
        ',',
      ),
    },
  },
  spacing: 4,
};

export const theme = createTheme({
  ...themeConfig,
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          '&:focus-visible': {
            outline: '3px solid' + themeConfig.palette.ternary.focusBlue,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minHeight: '40px',
          textTransform: 'none',
          boxShadow: 'none',
          fontWeight: 700,
          '&:hover': {
            boxShadow: 'none',
          },
          '&:active': {
            backgroundColor: themeConfig.palette.primary.light,
            color: themeConfig.palette.primary.contrastText,
            boxShadow: 'none',
          },
          '&:focus-visible': {
            backgroundColor: themeConfig.palette.primary.dark,
            color: themeConfig.palette.primary.contrastText,
          },
        },
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            '&:disabled': {
              color: themeConfig.palette.primary.contrastText,
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            padding: '0 4px',
            minHeight: 'auto',
            '&:hover': {
              background: 'none',
              textDecoration: 'underline',
              color: themeConfig.palette.primary.dark,
            },
            '&:active': {
              background: 'none',
              color: themeConfig.palette.primary.light,
            },
            '&:focus-visible': {
              backgroundColor: 'transparent',
              color: themeConfig.palette.primary.dark,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            '&:hover': {
              background: themeConfig.palette.secondary.dark,
            },
            '&:active': {
              background: themeConfig.palette.secondary.light,
            },
            '&:focus-visible': {
              background: themeConfig.palette.secondary.dark,
            },
          },
        },
        {
          props: { variant: 'text', color: 'secondary' },
          style: {
            textDecoration: 'underline',
            '&:hover': {
              color: themeConfig.palette.secondary.dark,
            },
            '&:active': {
              color: themeConfig.palette.secondary.light,
            },
            '&:focus-visible': {
              color: themeConfig.palette.secondary.dark,
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            borderColor: themeConfig.palette.primary.main,
            '&:hover': {
              background: themeConfig.palette.primary.dark,
              borderColor: themeConfig.palette.primary.dark,
              color: themeConfig.palette.ternary.white,
            },
            '&:active': {
              backgroundColor: themeConfig.palette.primary.light,
              borderColor: themeConfig.palette.primary.light,
            },
            '&:disabled': {
              color: themeConfig.palette.primary.main,
            },
          },
        },
        {
          props: { color: 'ternary' },
          style: {
            '&:hover': {
              background: themeConfig.palette.ternary.light,
            },
            '&:active': {
              background: themeConfig.palette.text.primary,
              color: themeConfig.palette.primary.contrastText,
            },
            '&.active': {
              background: themeConfig.palette.text.primary,
              color: themeConfig.palette.primary.contrastText,
            },
            '&:focus-visible': {
              backgroundColor: themeConfig.palette.ternary.dark,
            },
            '&:disabled': {
              opacity: 0.5,
              background: themeConfig.palette.ternary.main,
              color: themeConfig.palette.text.primary,
            },
          },
        },
      ],
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'title' },
          style: {
            '&::before': {
              content: '""',
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: 50,
              height: 6,
              backgroundColor: themeConfig.palette.primary.main,
            },
          },
        },
      ],
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: themeConfig.palette.ternary.greyShade, 
          },
          '&:hover fieldset.MuiOutlinedInput-notchedOutline': {
            borderWidth: '2px',
          },
          '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
            borderWidth: '2px',
            borderColor: themeConfig.palette.secondary.light 
          },
          '&.Mui-focused.Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
              borderColor: themeConfig.palette.error.main
          },
          '&.Mui-disabled fieldset.MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
            borderColor: 'rgba(0, 0, 0, 0.23)'
          },
          '&.Mui-disabled:hover fieldset.MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px !important',
          },
          '& .MuiInputBase-root.MuiInputBase-colorSuccess': {
              '& fieldset.MuiOutlinedInput-notchedOutline': {
                  borderColor: themeConfig.palette.success.main
              }
          },
          '& .MuiInputBase-root.MuiInputBase-colorSuccess + .MuiFormHelperText-root': {
              color: themeConfig.palette.success.main
          }
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          fieldset: {
            borderColor: themeConfig.palette.ternary.greyShade,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            background: themeConfig.palette.primary.light,
            color: themeConfig.palette.primary.contrastText,

            '&:hover': {
              background: themeConfig.palette.primary.light,
              color: themeConfig.palette.primary.contrastText,
            },

            '&.Mui-focusVisible': {
              background: themeConfig.palette.primary.light,
            },
          },
        },
      },
    },
  },
});

theme.typography.title = {
  color: '#000',
  fontSize: '1.75em',
  fontWeight: '700',
  display: 'block',
  position: 'relative',
  paddingBottom: 18,
  marginBottom: 20,
  fontFamily: ['Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
  [theme.breakpoints.up('sm')]: {
    fontSize: '2.25em',
  },
};
