import dayjs from 'dayjs';
import * as Yup from 'yup';

const parseDateString = (value: any, originalValue: any) => {
  const parsedDate = dayjs(originalValue).isValid()
    ? value
    : dayjs(originalValue).format('MMMM DD, YYYY');

  return parsedDate;
};

export const getValidationSchema = (
  isDirectFeedback = false,
  isSpeechCraftMFE = false,
) =>
  Yup.object({
    clubId: isSpeechCraftMFE
      ? Yup.number()
      : Yup.number().required('Field is required'),
    clubMembers: isDirectFeedback
      ? Yup.number().required('Field is required')
      : Yup.array()
          .min(1, 'At least 1 member is required')
          .required('Field is required'),
    speechTitle: isDirectFeedback
      ? Yup.string()
      : Yup.string().required('Field is required'),
    speechDate: Yup.date()
      .transform(parseDateString)
      .typeError('Invalid date format. (MMMM DD, YYYY)')
      .nullable()
      .required('Field is required'),
    request: Yup.string(),
  });
