import { Accordion, styled } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import { DeviceUp } from 'styles/breakpoints';
import { theme } from 'styles/theme';

export const AccordionWrapper = styled(Accordion)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.ternary.main};
  box-shadow: none;
  padding: 15px 0;
  border-radius: 0 !important;

  & h3 {
    margin: 0;
  }

  & .MuiAccordionDetails-root {
    @media ${DeviceUp.md} {
      padding: 0 20px;
    }
  }

  .MuiAccordionSummary-content.Mui-expanded,
  .MuiAccordionSummary-content {
    margin: 15px 0;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: auto;
  }

  .MuiAccordionSummary-expandIconWrapper {
    transform: none;

    &.Mui-expanded {
      min-height: auto;
      transform: none;

      .icon-text {
        color: ${({ theme }) => theme.palette.secondary.light};
      }
    }
  }

  .hide-icon {
    svg {
      opacity: 0;
      visibility: hidden;
    }
  }

  .icon-text {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.secondary.main};
    font-weight: 700;
    font-size: 14px;

    svg {
      margin-right: 15px;
      width: 16px;
    }
  }

  &.theme-accordion {
    margin: 0;

    .MuiAccordionSummary-expandIconWrapper {
      color: ${({theme}) => theme.palette.ternary.greyShade};
    }

    &.Mui-expanded {
      border-color: ${({theme}) => theme.palette.primary.main};

      .MuiAccordionSummary-root {
        .MuiAccordionSummary-content {
          //color: ${({theme}) => theme.palette.primary.main};
        }
        .MuiAccordionSummary-expandIconWrapper {
          color: ${({theme}) => theme.palette.primary.main};
          transform: rotate(-180deg);
        }
      }
    }
  }
`;

export const AccordionHeader = styled(AccordionSummary)`
  @media ${DeviceUp.md} {
    padding: 0 20px;
  }
`;
