import React, { FunctionComponent, useState } from 'react';

const AppContext = React.createContext({});

export const AppProvider: FunctionComponent<any> = ({
  roles = [],
  event,
  children,
}) => {
  const [isReleasingHijackUser, setIsReleasingHijackUser] = useState(false);

  return (
    <AppContext.Provider
      value={{ roles, event, isReleasingHijackUser, setIsReleasingHijackUser }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = (): any => React.useContext(AppContext);
