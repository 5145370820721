import React, { FunctionComponent } from 'react';
import { isEmpty } from 'lodash';
import { Redirect, Route, useRouteMatch, generatePath } from 'react-router-dom';

import { useIsSpeechCraftMFE } from 'hooks';
import { useAppContext } from 'contexts/AppContext';
import { DashboardRoutes as Routes, AppRoutes } from 'routes';

const PrivateRoute: FunctionComponent<any> = ({ roles = [], ...props }) => {
  const isSpeechCraftMFE = useIsSpeechCraftMFE();
  const { roles: userRoles = [] } = useAppContext();

  const hasAcccess =
    isEmpty(roles) ||
    userRoles?.[0]?.roles?.some((userRole: any) =>
      roles.includes(userRole.positionCode),
    );

  const isCourseListingPage = useRouteMatch(Routes.COURSE_LIST.path);
  const isClubMemberProfilePage = useRouteMatch(Routes.PROFILE.path);
  const isClubDetailPage = useRouteMatch(Routes.CLUB_DETAILS.path);
  const isMyClubPage = useRouteMatch(Routes.CLUBS.path);
  const isErrorPage = useRouteMatch(AppRoutes.ERROR.path);

  const isInvalidRoute =
    isSpeechCraftMFE &&
    (isCourseListingPage ||
      isClubMemberProfilePage ||
      isClubDetailPage ||
      isMyClubPage);

  if (props.isPublic) return <Route {...props} />;

  if (!isErrorPage && (!hasAcccess || isInvalidRoute)) {
    return <Redirect to={generatePath(AppRoutes.ERROR.path)} />;
  }

  return <Route {...props} />;
};

export default PrivateRoute;
