import { FunctionComponent, useState } from 'react';
import { truncate } from 'lodash';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { Typography } from '@mui/material';

import Card from 'elements/Card';
import Button from 'elements/Button';
import { DescriptionHolder } from 'elements/Card/style';

import feedbackImage from 'assets/images/icon-eval-form-share.svg';
import sharefeedbackImage from 'assets/images/icon-eval-form-complete.svg';

import { CardActions } from 'pages/Dashboard/components/Resources/style';
import { CardActionsHolder } from 'pages/Dashboard/components/CourseCard/style';

import { StyledTypography } from './style';
import { getPlatformName, getUserName } from 'utils/utility';

const getCardData = (data: any = {}, isSubmittedForm: boolean) => {
  if (isSubmittedForm) {
    return {
      title: data.request.evaluationForm.title,
      description: data.request.evaluationForm.description,
      evaluator: getUserName(data.request.evaluator),
      speechTitle: data.request.speechLog.speechTitle,
      date: data.modified,
      thumbnail: data.request.evaluationForm.thumbnail,
    };
  }

  return data;
};

const CardItem: FunctionComponent<any> = ({
  data,
  isSubmittedForm = false,
  handleOpenShareModal = () => {},
}) => {
  const { title, description, evaluator, speechTitle, date, thumbnail } =
    getCardData(data, isSubmittedForm);

  const history = useHistory();

  const redirectToDigitalForm = () => {
    history.push({
      pathname: `${getPlatformName()}/feedback/evaluation-form/${data.id}`,
      search: `${isSubmittedForm ? `?type=submitted` : ''}`,
    });
  };

  return (
    <Card
      cardImage={thumbnail}
      altText={title}
      handleClick={() => redirectToDigitalForm()}
      actionButtons={ !isSubmittedForm && (
        <CardActionsHolder>
          <CardActions
            direction='row'
            className='card-cta-icons-wrapper'>
            <div className='card-cta-icons'>
              <button
                aria-label='Complete Evaluation Resource'
                onClick={() => handleOpenShareModal(data, true)}
              >
                <img src={sharefeedbackImage} alt='Share feedack' />
              </button>

              <button
                aria-label='Send to my evaluator'
                onClick={() => handleOpenShareModal(data)}
              >
                <img src={feedbackImage} alt='Share' />
              </button>
            </div>
          </CardActions>
        </CardActionsHolder>
        )}
      >
      <Typography variant='h3' className='fw-700 mt-1'>
        {title}
      </Typography>
      {evaluator && <Typography>{evaluator} completed your evaluation! View it here.</Typography> }
      {isSubmittedForm && (
        <Typography>{dayjs(date).format('MMMM DD, YYYY')}</Typography>
      )}
      {description && !isSubmittedForm &&
        <DescriptionHolder>
          <Typography variant='body1'>
            {truncate(description, {
              length: 70,
              separator: ' ',
            })}
          </Typography>
        </DescriptionHolder>
      }
    </Card>
  );
};

export default CardItem;
