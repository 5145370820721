import { FunctionComponent, useMemo } from 'react';
import {
  Box,
  Typography,
  Avatar,
  ListItemText,
  ListItemAvatar,
  Stack,
  List,
} from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { isEmpty } from 'lodash';

import { useIsSpeechCraftMFE } from 'hooks';
import { DashboardRoutes as Routes } from 'routes';
import { useAppContext } from 'contexts/AppContext';

import Chip from 'elements/Chip';
import Breadcrumb from 'components/Breadcrumb';
import { getPlatformName, getUserName } from 'utils/utility';

import { ReactComponent as ClubIcon } from 'assets/images/club-icon.svg';
import { ReactComponent as EmailIcon } from 'assets/images/email-icon.svg';
import { ReactComponent as CalendarMonthIcon } from 'assets/images/calendar-Icon.svg';

import useProfilePage from './useProfilePage';
import { Header, StyledListItem, UserInfo, Container } from './style';

dayjs.extend(utc);

const ProfileHeader: FunctionComponent<any> = ({ profileData = {} }) => {
  const { user, clubs = [], dateJoined } = profileData;
  const { isMemberProfilePage } = useProfilePage();
  const isSpeechCraftMFE = useIsSpeechCraftMFE();
  const { event } = useAppContext();

  const links = useMemo(() => {
    if (isMemberProfilePage) {
      return [
        {
          label: 'Home',
          url: `${getPlatformName()}`,
        },
        {
          label: isSpeechCraftMFE ? 'My Cohort' : 'My Clubs',
          url: Routes[isSpeechCraftMFE ? 'MY_COHORT' : 'CLUBS'].path,
        },
        {
          label: 'Profile',
        },
      ];
    }

    return [
      {
        label: 'Home',
        url: `${getPlatformName()}`,
      },

      {
        label: 'Profile',
      },
    ];
  }, [isMemberProfilePage, isSpeechCraftMFE]);

  return (
    <Header>
      <Container>
        <Box py={7.5}>
          <Breadcrumb links={links} />
        </Box>
        <List disablePadding>
          <StyledListItem disableGutters disablePadding>
            <ListItemAvatar>
              <Avatar alt='dummyImage' src={user?.memberPhotoUrl} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <>
                  <Box mb={4}>
                    <Typography variant='h2' component='h1'>
                      {getUserName(user)}
                    </Typography>
                  </Box>
                  <Box
                    display='flex'
                    className={clubs.length > 1 ? 'flex-wrap' : ''}
                  >
                    {(!isEmpty(clubs) || isSpeechCraftMFE) && (
                      <UserInfo className={clubs.length > 1 ? 'w-100' : ''}>
                        {/* <ClubIcon /> */}
                        <Stack direction='row' flexWrap='wrap'>
                          {isSpeechCraftMFE
                            ? event?.name
                            : clubs?.map(({ name }: any) => {
                                return (
                                  <Typography
                                    variant='body1'
                                    className='club-name'
                                    component='span'
                                  >
                                    {name}
                                  </Typography>
                                  // <Chip className='bg-white' label={name} />
                                );
                              })}
                        </Stack>
                      </UserInfo>
                    )}
                    <UserInfo>
                      {(dateJoined || isSpeechCraftMFE) && (
                        <>
                          {/* <CalendarMonthIcon /> */}
                          <Typography variant='body1' component='span'>
                            Member since: &nbsp;
                            {isSpeechCraftMFE
                              ? dayjs(event.created)?.format('MMMM DD, YYYY')
                              : dayjs.utc(dateJoined)?.format('MMMM DD, YYYY')}
                          </Typography>
                        </>
                      )}
                    </UserInfo>
                    <UserInfo className='email-box'>
                      {/* <EmailIcon /> */}
                      <Typography variant='body1' component='span'>
                        <a href={`mailto:${user?.email}`}>{user?.email}</a>
                      </Typography>
                    </UserInfo>
                  </Box>
                </>
              }
            />
          </StyledListItem>
        </List>
      </Container>
    </Header>
  );
};

export default ProfileHeader;
