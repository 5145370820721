import { Box, styled, ListItem, Paper } from '@mui/material';
import { theme } from 'styles/theme';
import { DeviceUp, DeviceDown } from 'styles/breakpoints';

export const Container = styled(Box)`
  max-width: 1110px;
  width: 100%;
  padding: 0 15px;
  margin: 30px auto;

  .search-results {
    background: ${({theme}) => theme.palette.ternary.white};
    border: 1px solid ${({theme}) => theme.palette.ternary.main};
    padding: 15px;
    margin-bottom: 40px;

    @media ${DeviceUp.sm} {
      padding: 20px 30px;
    }
  }

  .text-center {
    text-align: center;
  }

  .mt-30 {
    margin-top: 30px;
  }

  .flex-center {
    display: flex;
    align-items: center;
  }

  h3 {
    color: ${({theme}) => theme.palette.ternary.dark};
  }
`;

export const SearchItem = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 15px;
  background: ${({theme}) => theme.palette.ternary.light};
  margin-bottom: 5px;
  border-radius: 3px;

  @media ${DeviceUp.sm} {
    flex-wrap: nowrap;
    padding: 20px 15px;
  }

  h3 {
    margin: 5px 0;
    color: ${({theme}) => theme.palette.ternary.greyShade};
  }

  .MuiAvatar-root {
    width: 36px;
    height: 36px;
    margin-right: 8px;

    &.file-icon {
      border: 1px solid ${({theme}) => theme.palette.ternary.main};
      background: ${({theme}) => theme.palette.ternary.white};

      img,
      svg {
        width: 16px;
        height: 16px;
      }
    }

    @media ${DeviceUp.md} {
      margin-right: 15px;
    }
  }
`;

export const SearchItemButton = styled(Box)`
  margin-left: auto;
  text-align: right;
`;

export const ClubModal = styled(Box)`
  .MuiStack-root {
    justify-content: space-between;

    a {
      margin-top: 10px;
      text-transform: capitalize;
      width: 100%;
      color: ${({theme}) => theme.palette.ternary.greyShade};
      background: ${({theme}) => theme.palette.ternary.light};
      justify-content: space-between;
      padding: 10px !important;
      text-decoration: none;

      @media ${DeviceUp.sm} {
        width: calc(50% - 5px);
      }
    }
  }
`;