import { memo, FunctionComponent } from 'react';
import classNames from 'classnames';
import {
  Box,
  BoxContent,
  BoxHeader,
  CardActionButtons,
} from 'elements/Card/style';
import Avatar from 'elements/Avatar';
import { CardActionArea, Tooltip } from '@mui/material';

const Card: FunctionComponent<any> = ({
  children,
  cardImage,
  altText,
  actionButtons,
  handleClick = () => {},
  tabindex,
  role,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  onFocus = () => {},
  onBlur = () => {},
  isDisable = false,
  handleOnClick = () => {},
  hoverText = '',
}) => {
  return (
    <Box className={classNames('card', { disabled: isDisable })}>
      {hoverText ? (
        <Tooltip title={hoverText}>
          <CardActionArea onClick={() => !isDisable && handleClick()}>
            <BoxHeader>
              <Avatar img={cardImage} altText={altText} />
            </BoxHeader>
            <BoxContent>{children}</BoxContent>
          </CardActionArea>
        </Tooltip>
      ) : (
        <CardActionArea onClick={() => !isDisable && handleClick()}>
          <BoxHeader>
            <Avatar img={cardImage} altText={altText} />
          </BoxHeader>
          <BoxContent>{children}</BoxContent>
        </CardActionArea>
      )}

      <CardActionButtons>{actionButtons}</CardActionButtons>
    </Box>
  );
};

export default memo(Card);
