import { styled, Checkbox as MuiCheckbox, } from '@mui/material';
import { theme } from 'styles/theme';

export const Checkbox = styled(MuiCheckbox)`
  &.Mui-focusVisible,
  &:hover {
    color: ${({ theme }) => theme.palette.ternary.greyShade};
    background: rgba(119, 36, 50, 0.04);
  }
  &.Mui-checked {
    color: ${({ theme }) => theme.palette.secondary.light};

    &:focus,
    &:hover {
      color: ${({ theme }) => theme.palette.secondary.dark};
    }

    &:active {
      color: ${({ theme }) => theme.palette.secondary.light};
    }
  }

  &.Mui-disabled {
    opacity: 0.25;
    color: ${({ theme }) => theme.palette.ternary.main};

    &.Mui-checked {
      color: ${({ theme }) => theme.palette.secondary.main};
    }
  }
`;

