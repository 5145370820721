import baseApiSlice from 'api/baseApiSlice';

const BASE_URL = '/api/bcm';

enum ITags {
  MemberOverview = 'member_overview',
  AllInProgressPaths = 'all_in_progress_paths',
  pathProgress = 'path_progress',
  recentActivity = 'recent_activity',
}

const bcmDashboardApiWithTags = baseApiSlice.enhanceEndpoints({
  addTagTypes: [
    ITags.MemberOverview,
    ITags.AllInProgressPaths,
    ITags.pathProgress,
    ITags.recentActivity,
  ],
});

const bcmDashboardApiSlice = bcmDashboardApiWithTags.injectEndpoints({
  endpoints: builder => ({
    getMemberOverview: builder.query({
      query: ({ clubId, page = 1 }) => ({
        url: `${BASE_URL}/member/overview/`,
        params: {
          club: clubId,
          page_size: 500,
          page,
        },
      }),
      providesTags: (result, error, { clubId }) => [
        { type: ITags.MemberOverview, clubId },
      ],
    }),

    getAllInProgressPaths: builder.query({
      query: ({ clubId, pageSize = 10, page = 1, search }) => ({
        url: `${BASE_URL}/progress/`,
        params: {
          club: clubId,
          page_size: pageSize,
          page,
          ...(search && { search }),
        },
      }),
      providesTags: (result, error, { clubId }) => [
        { type: ITags.AllInProgressPaths, clubId },
      ],
    }),

    getPathProgress: builder.query({
      query: ({ courseId, user }) => ({
        url: `${BASE_URL}/progress/${courseId}/detail`,
        params: {
          user,
          pageSize: 5000,
        },
      }),
      providesTags: (result, error, { courseId, user }) => [
        { type: ITags.pathProgress, courseId, user },
      ],
    }),

    getRecentActivity: builder.query({
      query: ({ clubId }) => ({
        url: `${BASE_URL}/activity/`,
        params: {
          club: clubId,
          page_size: 10000,
        },
      }),
      providesTags: (result, error, { clubId }) => [
        { type: ITags.recentActivity, clubId },
      ],
    }),
  }),
});

export const {
  useGetMemberOverviewQuery,
  useGetAllInProgressPathsQuery,
  useGetPathProgressQuery,
  useGetRecentActivityQuery,
} = bcmDashboardApiSlice;

export default bcmDashboardApiSlice;
