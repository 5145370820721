import { styled, Chip as MuiChip, alpha } from '@mui/material';
import { theme } from 'styles/theme';

export const Chip = styled(MuiChip)`
  background: ${({ theme }) => theme.palette.ternary.main};
  height: 24px;
  border: none;

  & .MuiChip-label{
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
    text-transform: capitalize;
  }

  &.bg-dark{
    background: ${({ theme }) => theme.palette.secondary.main};
    color: ${({ theme }) => theme.palette.secondary.contrastText};
  }

  &.bg-light-yellow{
    background: ${({ theme }) => theme.palette.ternary.tertiary6};
  }

  &.bg-white{
    background: ${({ theme }) => theme.palette.ternary.white};
  }

  &.tag-theme-blue {
    background: ${({ theme }) => theme.palette.ternary.tertiary1};
    color: ${({ theme }) => theme.palette.ternary.white};
    margin-right: 4px;

    & .MuiChip-label {
      text-transform: none;
      letter-spacing: 0;
    }
  }

  &.bg-dark-grey {
    background: ${({ theme }) => theme.palette.ternary.main};
  }

  &.filter-chip{
    height: auto;
    border-radius: 50px;
    margin: 5px 0;

    &:not(:last-child) {
      margin-right: 10px
    }

    & .MuiChip-label{
      padding: 5px 12px;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      text-transform: none;
      letter-spacing: 0;
    }
  }

  &.filter-chip-secondary {
    border: 2px solid ${({ theme }) => theme.palette.ternary.light};
    padding: 4px 10px;
    min-width: 70px;
    text-align: center;

    .MuiChip-label {
      font-weight: 600;
    }

    &.active {
      color: ${({ theme }) => theme.palette.secondary.light};
      border-color: ${({ theme }) => theme.palette.secondary.light};
      background: ${alpha(theme.palette.secondary.light, 0.1)};

      &:hover {
        background: ${alpha(theme.palette.secondary.light, 0.15)};
      }

      .MuiChip-label {
        font-weight: 700;
      }
    }

    &:not(:last-child) {
      margin-right: 5px
    }

    &:active {
      box-shadow: none;
    }
  }
}
`;
