import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import querystring from 'querystring';

import { LMS_BASE_URL } from 'config';
import axiosClient from 'utils/client';

const feedbackAdapter = createEntityAdapter({
  sortComparer: (a: any, b: any) => b.modified.localeCompare(a.modified),
});

const initialState = feedbackAdapter.getInitialState({
  isFetching: false,
  isSuccess: false,
  isError: false,
  error: null as any,
  hasMore: true,
  page: 0,
});

export const fetchFeedbacks = createAsyncThunk(
  'feedback/list',
  async (props: any, thunkApi: any) => {
    const page = thunkApi.getState().feedbacks.page;

    const params = {
      ...(props?.username && {
        username: props?.username,
      }),
      page: page + 1
    };

    let qs = querystring.stringify(params);
    if (qs) {
      qs = `?${qs}`;
    }

    const res = await axiosClient.get(
      `${LMS_BASE_URL}/api/member/feedback/response/${qs}`,
    );
    return res.data;
  },
  {
    condition: (_, thunkApi: any) => {
      const { isFetching, hasMore } = thunkApi.getState().feedbacks;
      if (isFetching || !hasMore) return false;
    },
  },
);

const feedbackSlice = createSlice({
  name: 'feedbacks',
  initialState,

  reducers: {
    resetFeedbacks: () => {
      return initialState;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(fetchFeedbacks.pending, (state, action) => {
        state.isFetching = true;
        state.isError = false;
        state.error = null;
        state.isSuccess = false;
      })
      .addCase(fetchFeedbacks.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.page += 1;

        const { results, next } = action.payload;
        state.hasMore = !!next;

        feedbackAdapter.upsertMany(state, results);
      })
      .addCase(fetchFeedbacks.rejected, (state, action) => {
        state.isFetching = false;
        state.isSuccess = false;
        state.isError = true;
        state.error = action.payload;
      });
  },
});

export const { resetFeedbacks } = feedbackSlice.actions;

export default feedbackSlice.reducer;

export const selectFeedbacks = (state: any) => state.feedbacks;

export const { selectAll: selectAllFeedbacks } =
  feedbackAdapter.getSelectors(selectFeedbacks);
