import { FC } from 'react';
import logoWhite from 'assets/images/logoWhite.svg';
import {
  AppBar,
  Toolbar,
  LogoWrapper,
  HeaderLeft,
  SkipNav,
} from 'components/Header/style';
// @ts-ignore
import { getConfig } from '@edx/frontend-platform';

const PublicHeader: FC<any> = () => {
  return (
    <AppBar
      sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
      enableColorOnDark={true}
      color='transparent'
    >
      <SkipNav href='#main'>Skip to main content</SkipNav>
      <Toolbar>
        <HeaderLeft>
          <LogoWrapper>
            <a href={`${getConfig().BASE_URL}`}>
              <img src={logoWhite} alt='Toastmasters International' />
            </a>
          </LogoWrapper>
        </HeaderLeft>
      </Toolbar>
    </AppBar>
  );
};

export default PublicHeader;
