import Cookies from 'universal-cookie';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AppRouter } from 'routes';
import globalStyle from 'styles/style';
import { theme } from 'styles/theme';
import { useLazyGetThemeQuery } from 'api/themeApi';
import { useEffect, useState } from 'react';
import { cloneDeep, first, isEmpty, merge, size } from 'lodash';
import Loader from 'elements/Loader';
import { GlobalStyles } from '@mui/material';
import { muiThemeMapping } from 'styles/muiThemeMapping';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import { AppProvider } from 'contexts/AppContext';
import { useIsSpeechCraftMFE } from 'hooks/useIsSpeechCraftMFE';
import { AdminUsers, PANEL_BASE_URL, REACT_APP_FAVICON_URL } from 'config';
import {
  useGetRolesQuery,
  useLazyGetCurrentEventQuery,
  useLazySyncEventQuery,
} from 'api/initializeAppApi';
// @ts-ignore
import { getConfig } from '@edx/frontend-platform';
// @ts-ignore
import { getAuthenticatedUser } from '@edx/frontend-platform/auth';
import CookiePollLogout from 'components/CookiePollLogout';

const App = () => {
  const user = getAuthenticatedUser();
  const isSpeechCraftMFE = useIsSpeechCraftMFE();

  let { roles: systemRoles = [] } = user || {};
  const isSystemUser = systemRoles.includes(AdminUsers.isSystemUser);

  if (isSpeechCraftMFE && isSystemUser) window.location.replace(PANEL_BASE_URL);

  const cookies = new Cookies();
  cookies.remove('csrftoken');
  const [
    triggerTheme,
    { isLoading, isError, data: dynamicTheme = [], isFetching },
  ]: any = useLazyGetThemeQuery();
  const [
    triggerCurrentEvent,
    { data: eventData = {}, isLoading: isEventLoading, error },
  ]: any = useLazyGetCurrentEventQuery();
  const [triggerSyncEventQuery, { data: _syncData }]: any =
    useLazySyncEventQuery();
  const {
    data: roles,
    isLoading: isRolesLoading,
    refetch,
  } = useGetRolesQuery(undefined, { skip: !user });
  const [tiTheme, setTiTheme] = useState<any>(theme);

  useEffect(() => {
    if (user) {
      triggerTheme();
      refetch();

      if (isSpeechCraftMFE) {
        triggerCurrentEvent();
        triggerSyncEventQuery();
      }
    }
  }, [user]);

  useEffect(() => {
    if (user && size(dynamicTheme) > 0 && !isError) {
      const getUpdatedMuiTheme = muiThemeMapping({ ...first(dynamicTheme) });
      setTiTheme(createTheme(merge(cloneDeep(theme), getUpdatedMuiTheme)));
    }
  }, [dynamicTheme, isError, user]);

  useEffect(() => {
    if (error?.status === 401) {
      window.location.replace(getConfig().LOGOUT_URL);
    }
  }, [error]);

  if (
    (isLoading && isFetching) ||
    isRolesLoading ||
    isEventLoading ||
    error?.status === 401
  ) {
    return <Loader position='fixed' background='none' />;
  }

  return (
    <>
      <BrowserRouter>
        <ThemeProvider theme={tiTheme}>
          <AppProvider roles={roles} event={eventData}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {/* @ts-ignore */}
              <Helmet>
                <link
                  href={`https://fonts.googleapis.com/css2?family=${tiTheme.typography.fontFamily}:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap`}
                  rel='stylesheet'
                />
                <link rel="icon" href={`${REACT_APP_FAVICON_URL}?ver=${Math.floor(Math.random() * 99)}`} />
                <link rel="apple-touch-icon" href={`${REACT_APP_FAVICON_URL}?ver=${Math.floor(Math.random() * 99)}`} />
                {/* Shortcut tiles */}
                <link rel="apple-touch-icon" href={`${REACT_APP_FAVICON_URL}?ver=${Math.floor(Math.random() * 99)}`} sizes="57x57" />
                <link rel="apple-touch-icon" href={`${REACT_APP_FAVICON_URL}?ver=${Math.floor(Math.random() * 99)}`} sizes="72x72" />
                <link rel="apple-touch-icon" href={`${REACT_APP_FAVICON_URL}?ver=${Math.floor(Math.random() * 99)}`} sizes="76x76" />
                <link rel="apple-touch-icon" href={`${REACT_APP_FAVICON_URL}?ver=${Math.floor(Math.random() * 99)}`} sizes="114x114" />
                <link rel="apple-touch-icon" href={`${REACT_APP_FAVICON_URL}?ver=${Math.floor(Math.random() * 99)}`} sizes="120x120" />
                <link rel="apple-touch-icon" href={`${REACT_APP_FAVICON_URL}?ver=${Math.floor(Math.random() * 99)}`} sizes="144x144" />
                <link rel="apple-touch-icon" href={`${REACT_APP_FAVICON_URL}?ver=${Math.floor(Math.random() * 99)}`} sizes="152x152" />
                <title>{`Toastmasters International ${
                  isSpeechCraftMFE ? 'Speechcraft' : 'LMS'
                }`}</title>
              </Helmet>
              <GlobalStyles styles={globalStyle} />
              <CookiePollLogout />
              <AppRouter />
            </LocalizationProvider>
          </AppProvider>
        </ThemeProvider>
      </BrowserRouter>
      <ToastContainer role='alert' theme='colored' />
    </>
  );
};

export default App;
