import { FunctionComponent } from 'react';
import { Stack } from '@mui/material';

import Chip from 'elements/Chip';
import {
  ResetAllFilters,
  AppliedFilters,
} from 'pages/Dashboard/components/Resources/style';

const FilterChips: FunctionComponent<any> = ({
  selectedFilteres,
  handleRemoveFilters,
  resetFilters,
  isFilterApplied,
  disableCategory,
}) => {
  return (
    <AppliedFilters>
      {isFilterApplied && (
        <>
          <Stack className='filtersWrapper' flexWrap='wrap'>
            {Object.keys(selectedFilteres)?.map((filterType: any) => {
              return Object.keys(selectedFilteres[filterType])?.map(
                (filterId: any, index: any) => {
                  if (selectedFilteres[filterType][filterId]['value'])
                    return (
                      <Chip
                        disabled={disableCategory && filterType === 'category'}
                        clickable={true}
                        label={selectedFilteres[filterType][filterId]['name']}
                        handleDelete={() =>
                          handleRemoveFilters(filterType, filterId)
                        }
                        className='filter-chip'
                      />
                    );
                },
              );
            })}
            <ResetAllFilters onClick={() => resetFilters()} color="secondary" variant='text'>
              Clear All
            </ResetAllFilters>
          </Stack>
        </>
      )}
    </AppliedFilters>
  );
};

export default FilterChips;
