import { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { DashboardRoutes as Routes } from 'routes';
import Button from 'elements/Button';
import Loader from 'elements/Loader';
import { Container } from 'pages/Search/style';
import {
  fetchSearchMembers,
  selectGlobalSearchMembers,
  selectAllSearchMembers,
  resetGlobalSearchMembers,
} from '../ducks/memberSlice';
import Members from './Members';

const SearchMembers = () => {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const query = searchParams.get('query') as string;

  const dispatch = useDispatch();

  const { isFetching, hasMore, total } = useSelector(selectGlobalSearchMembers);

  const members = useSelector(selectAllSearchMembers);

  const fetchMoreMembers = () => {
    dispatch(fetchSearchMembers(query));
  };

  useEffect(() => {
    if (!query) {
      history.push(Routes.DASHBOARD.path);
    }

    dispatch(fetchSearchMembers(query));

    return () => {
      dispatch(resetGlobalSearchMembers());
    };
  }, [query, history, dispatch]);

  return (
    <Container>
      <Typography variant='h3' mb={4}>
        Search Results - Members ({total})
      </Typography>

      {members.length > 0 ? (
        <Box className='search-results'>
          <Members members={members} />

          {hasMore && !isFetching ? (
            <div className='mt-30 text-center'>
              <Button className='mw120' variant='outlined' onClick={fetchMoreMembers}>
                Load More
              </Button>
            </div>
          ) : null}

          {isFetching ? (
            <div className='mt-30 text-center'>
              <Loader />
            </div>
          ) : null}
        </Box>
      ) : null}

      {members.length <= 0 && isFetching ? (
        <div className='mt-30 text-center'>
          <Loader position='absolute' background='transparent' />
        </div>
      ) : null}
    </Container>
  );
};

export default SearchMembers;
