import { Box, styled } from '@mui/material';

export const Container = styled(Box)`
  .card {
    padding: 0px;
    cursor: pointer;
    position: relative;

    h2 {
      line-height: 30px;
    }

    .secondary-icon {
      color: ${({ theme }) => theme.palette.secondary.main};
    }
  }
`;
