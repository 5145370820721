import React, {
  FunctionComponent,
  useEffect,
  useCallback,
  useState,
  useMemo,
} from 'react';
import { get, isEmpty } from 'lodash';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

import { getQueryParam } from 'utils/utility';
import Loader from 'elements/Loader';
import Confirmation from 'components/Confirmation';
import { HOC } from 'elements/Modal';
import { useIsSpeechCraftMFE } from 'hooks';

import {
  useGetCategoriesQuery,
  useShareAssetMutation,
  useGetLanguagePreferencesMutation,
} from 'api/resourcesApi';
import {
  getResources,
  getResourceById,
} from 'pages/Dashboard/ducks/resourcesSlice';
import { getResourcesSelector } from 'pages/Dashboard/ducks/selectors';
import { getResourceByIdSelector } from 'pages/Dashboard/ducks/selectors';

import AssetPreview from './AssetPreview';
import ResourcesFilter from './ResourcesFilter';
import ResourceSection from './ResourceSection';
import AssetShareModal from './AssetShareModal';
import { useResourcesFilter, useResourceAssets } from './hooks';
import { useAppContext } from 'contexts/AppContext';

const getConfirmationMessage = (data = []) => {
  return data
    .map(({ name }) => name)
    .join(', ')
    .replace(/,(?!.*,)/gim, ' and');
};

const getParamas = (filters: any) => {
  return Object.keys(filters)
    .filter(key => filters[key]['value'])
    .join(',');
};

const ConfirmationModal = HOC(Confirmation);
const AssetPreviewModal = HOC(AssetPreview);

const ResourcesDetails: FunctionComponent<any> = () => {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [assetToShare, setAssetToShare] = useState<any>({});
  const [shareAssetModalData, setShareAssetModalData] = useState({
    clubId: '',
    clubMembers: [],
  });
  const [selectedCategory, setSelectedCategory] = useState('');
  const [resetCategory, setResetCategory] = useState(false);
  const [pageNumber, setPageNubmer] = useState('');
  const [filterApiController, setFilterApiController] = useState<any>();
  const [resourceApiController, setResourceApiController] = useState<any>();

  const { event } = useAppContext();
  const isSpeechCraftMFE = useIsSpeechCraftMFE();

  const dispatch = useDispatch<any>();
  const { data = {} } = useGetCategoriesQuery();
  const languages = get(data, 'language', []);
  const categories = get(data, 'category', []);

  const { search } = useLocation();
  const categoryParam = useMemo(() => {
    return new URLSearchParams(search).get('category');
  }, [search]);

  const [
    getLanguagePrefrences,
    { data: languagePreferencesData, isLoading: isLanguagePrefLoading },
  ] = useGetLanguagePreferencesMutation();

  const initialFilters = useMemo(() => {
    const { preferredLanguageFilter } = languagePreferencesData || {};
    if (isEmpty(languages) || isEmpty(preferredLanguageFilter)) {
      return {};
    }

    return {
      language: languages.reduce((acc: any, language: any) => {
        if (preferredLanguageFilter?.includes(language.id)) {
          return {
            ...acc,
            [language.id]: {
              value: true,
              name: language.name,
            },
          };
        }

        return acc;
      }, {}),
    };
  }, [languagePreferencesData, languages]);

  const [shareAsset] = useShareAssetMutation();
  const {
    handleUpdateFilters,
    handleRemoveFilters,
    handleUpdateSortType,
    resetFilters,
    sortType,
    isFilterApplied,
    selectedFilteres,
    handleSelectCategaory,
  } = useResourcesFilter(categories, initialFilters, setPageNubmer);

  const {
    isModalOpen,
    selectedAsset,
    disableFavorite,
    handleOpenAssetPreview,
    handleCloseAssetPreview,
    handleFavoriteAsset,
  } = useResourceAssets();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const controller = new AbortController();

  const { data: resources, isLoading } = useSelector(
    getResourcesSelector,
  ) as any;
  const { data: selectedCategoryData = [] } = useSelector(state =>
    getResourceByIdSelector(state, selectedCategory),
  );

  const handleClickViewAll = (categoryId: any, name: string) => {
    setSelectedCategory(categoryId);
    setResetCategory(!!categoryId);

    // if (categoryId) handleSelectCategaory(categoryId, name);
  };

  // set category from url if available
  useEffect(() => {
    if (isEmpty(categories) || !categoryParam) return;

    for (const category of categories) {
      if (category.name === categoryParam) {
        setSelectedCategory(category.id);
        setResetCategory(!!category.id);
        // handleClickViewAll(category.id, category.name);
        handleSelectCategaory(category.id, category.name);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, categoryParam]);

  const resetState = () => {
    setIsShareModalOpen(false);
    setIsConfirmModalOpen(false);
    setAssetToShare({});
    setShareAssetModalData((prev: any) => ({
      ...prev,
      clubId: '',
      clubMembers: [],
    }));
  };

  const getFilteredResources = useCallback(
    paramas => {
      // Cancel previous reequest on updating filters
      filterApiController?.abort();
      dispatch(getResources({ ...paramas, signal: controller.signal }));
      setFilterApiController(controller);
    },
    [controller, dispatch, filterApiController],
  );

  const getNextData = (resources: any) => {
    const next = get(resources, 'next', null) || '';

    if (!next) {
      return;
    }

    const { page_no }: any = getQueryParam(next);
    setPageNubmer(page_no);
  };

  const handleOpenShareAssetModal = (asset: any) => {
    setIsShareModalOpen(true);
    setAssetToShare(asset);
  };

  const handleCloseShareAssetModal = () => {
    resetState();
  };

  const handleClickShareAsset = () => {
    setIsShareModalOpen(false);
    setIsConfirmModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setIsShareModalOpen(true);
    setIsConfirmModalOpen(false);
  };

  const handleSubmitShareAsset = async (values: any) => {
    const data = {
      sharedTo: values.clubMembers.map(({ id }: any) => id),
      asset: assetToShare.id,
    };

    try {
      await shareAsset(data).unwrap();
      toast.success('The resource has been shared.');
      resetState();
    } catch (error) {
      toast.error('Something went wrong!');
    }
  };

  const getResourceByCategory = useCallback(
    paramas => {
      // Cancel previous reequest on updating filters for selected category
      resourceApiController?.abort();
      dispatch(getResourceById({ ...paramas, signal: controller.signal }));
      setResourceApiController(controller);
    },
    [controller, dispatch, resourceApiController],
  );

  useEffect(() => {
    if (isSpeechCraftMFE) {
      return;
    }

    getLanguagePrefrences({});
  }, [getLanguagePrefrences, isSpeechCraftMFE]);

  useEffect(() => {
    if (!isSpeechCraftMFE) {
      return;
    }

    setShareAssetModalData((prev: any) => ({ ...prev, event }));
  }, [event, isSpeechCraftMFE]);

  useEffect(() => {
    const paramas = {
      categories: getParamas(selectedFilteres.category),
      subCategories: getParamas(selectedFilteres.subCategory),
      languages: getParamas(selectedFilteres.language),
      favorite: sortType === 'favorites',
      recently_viewed: sortType === 'recently viewed',
      shared: sortType === 'shared',
    };

    if (selectedCategory) {
      return;
    }

    getFilteredResources(paramas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCategory,
    isFilterApplied,
    selectedFilteres.category,
    selectedFilteres.language,
    selectedFilteres.subCategory,
    sortType,
  ]);

  useEffect(() => {
    const params = {
      categories: getParamas(selectedFilteres.category),
      subCategories: getParamas(selectedFilteres.subCategory),
      languages: getParamas(selectedFilteres.language),
      favorite: sortType === 'favorites',
      recently_viewed: sortType === 'recently viewed',
      shared: sortType === 'shared',
      categoryId: selectedCategory,
      pageNumber,
    };

    if (selectedCategory) {
      getResourceByCategory(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageNumber,
    selectedCategory,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(selectedFilteres.category),
    selectedFilteres.language,
    selectedFilteres.subCategory,
    sortType,
    isFilterApplied,
  ]);

  const confirmationMessage = getConfirmationMessage(
    shareAssetModalData.clubMembers,
  );

  const resourcesData = selectedCategory ? selectedCategoryData : resources;

  const categorieesData = React.useMemo(() => {
    return categories.filter((category: any) =>
      selectedCategory ? category.id === selectedCategory : true,
    );
  }, [categories, selectedCategory]);

  const isFetching =
    (isLoading || isLanguagePrefLoading) &&
    (selectedCategory ? !pageNumber : true);

  return (
    <Box>
      <ResourcesFilter
        categories={categorieesData}
        languages={languages}
        handleUpdateFilters={handleUpdateFilters}
        handleRemoveFilters={handleRemoveFilters}
        resetFilters={resetFilters}
        selectedFilteres={selectedFilteres}
        isFilterApplied={isFilterApplied}
        sortType={sortType}
        handleUpdateSortType={handleUpdateSortType}
        disableCategory={!!selectedCategory}
        setResetCategory={setResetCategory}
        setSelectedCategory={setSelectedCategory}
        isLanguagePrefLoading={isLanguagePrefLoading}
      />
      {isFetching ? (
        <Loader position='relative' />
      ) : (
        resourcesData.map((category: any) => (
          <ResourceSection
            key={category.id}
            category={category}
            handleOpenAssetPreview={handleOpenAssetPreview}
            handleFavoriteAsset={handleFavoriteAsset}
            handleShareAsset={handleOpenShareAssetModal}
            disableFavorite={disableFavorite}
            handleClickViewAll={handleClickViewAll}
            resetCategory={resetCategory}
            getNextData={getNextData}
          />
        ))
      )}
      {isModalOpen && (
        <AssetPreviewModal
          asset={selectedAsset}
          handleClose={handleCloseAssetPreview}
          disableFavorite={disableFavorite}
          handleFavoriteAsset={handleFavoriteAsset}
          handleShareAsset={handleOpenShareAssetModal}
          className='modal-xxl'
        />
      )}
      {isShareModalOpen && (
        <AssetShareModal
          title={assetToShare?.fileName}
          handleClose={handleCloseShareAssetModal}
          shareAssetModalData={shareAssetModalData}
          setShareAssetModalData={setShareAssetModalData}
          handleClickShareAsset={handleSubmitShareAsset}
          assetId={assetToShare.uuid}
          isSpeechCrafter={isSpeechCraftMFE}
        />
      )}
      {isConfirmModalOpen && (
        <ConfirmationModal
          handleClose={handleCloseConfirmationModal}
          handleConfirmation={handleSubmitShareAsset}
          title='Confirmation'
        >
          {`Are you sure you want to share the ${assetToShare.fileName} file to ${confirmationMessage}?`}
        </ConfirmationModal>
      )}
    </Box>
  );
};

export default ResourcesDetails;
