import { FunctionComponent } from 'react';
import { Box, Grid } from '@mui/material';
import { get, slice, isEmpty } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';

import Loader from 'elements/Loader';

import SectionHeader from 'components/SectionHeader';
import CardSlider from 'components/CardSlider';
import CardItem from 'pages/Dashboard/components/Feedback/EvaluationFeedback/CardItem';

const EvaluationFeedbackSection: FunctionComponent<any> = ({
  title,
  data = [],
  isSubmittedForm,
  handleActionButton,
  selectedForm,
  handleGetNextData,
  handleOpenShareModal,
}) => {
  return (
    <>
      {!isEmpty(get(data, 'results', [])) && (
        <Box sx={{ mt: 2, mb: 8 }}>
          <SectionHeader
            title={title}
            buttonText={selectedForm ? 'View Less' : 'View All'}
            handleActionButton={handleActionButton}
          />
          {selectedForm ? (
            <InfiniteScroll
              dataLength={get(data, 'results', []).length}
              next={() => handleGetNextData(data)}
              hasMore={get(data, 'next', '') ? true : false}
              loader={
                <div className='loader' key={0}>
                  <Loader />
                </div>
              }
              scrollableTarget='scrollableDiv'
              style={{ overflow: 'hidden' }}
            >
              <Box sx={{ display: 'flex', gap: 5, flexWrap: 'wrap' }}>
              <Grid container spacing={3}>
                {get(data, 'results', []).map((formData: any) => (
                  <Grid item xs={12} sm={4} md={3} key={formData?.id}>
                    <CardItem
                      data={formData}
                      isSubmittedForm={isSubmittedForm}
                      handleOpenShareModal={handleOpenShareModal}
                    />
                  </Grid>
                ))}
              </Grid>
              </Box>
            </InfiniteScroll>
          ) : (
            <CardSlider>
              {slice(get(data, 'results', []), 0, 10).map((data: any) => (
                <CardItem
                  data={data}
                  key={data?.id}
                  isSubmittedForm={isSubmittedForm}
                  handleOpenShareModal={handleOpenShareModal}
                />
              ))}
            </CardSlider>
          )}
        </Box>
      )}
    </>
  );
};

export default EvaluationFeedbackSection;
