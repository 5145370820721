import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Stack } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import FormControlLabel from 'elements/FormControlLabel/FormControlLabel';
import { TextField } from 'elements/Form/style';
import { FieldWrapper } from 'elements/Form/style';

import {
  setComment,
  selectReviewReqeust,
  handleApprovalGoback,
  handleOpenConfirmationModal,
} from './ducks/approvalSlice';

const Approve = (props: any) => {
  const dispatch = useDispatch();

  const { comment } = useSelector(selectReviewReqeust);

  return (
    <Box>
      <FieldWrapper mb={4}>
        <FormControlLabel
          label='Comments'
          labelPlacement='top'
          control={
            <TextField
              multiline
              fullWidth
              className={`${comment ? 'has-data' : ''}`}
              id='approve-comment'
              placeholder='Please enter the description here.'
              value={comment}
              onChange={(e: any) => dispatch(setComment(e.target.value))}
            />
          }
        />
      </FieldWrapper>

      <Stack direction='row' mt={10} justifyContent='space-between'>
        <Button
          size='small'
          variant='text'
          color='secondary'
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={() => dispatch(handleApprovalGoback())}
        >
          Back
        </Button>

        <Stack direction='row' gap={1}>
          <Button size='small' variant='outlined' onClick={props.handleClose}>
            Cancel
          </Button>
          <Button
            size='small'
            variant='contained'
            onClick={() => dispatch(handleOpenConfirmationModal())}
          >
            Approve
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Approve;
