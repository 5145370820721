import { FC } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { SilderWrapper } from 'components/CardSlider/style';

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }: any) => (
  <button
    {...props}
    className={
      'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
    }
    aria-hidden={currentSlide === 0 ? true : false}
    role='button'
    aria-label='Previous Item'
    aria-disabled={currentSlide === 0 ? true : false}
    disabled={currentSlide === 0 ? true : false}
    type='button'
  >
    <KeyboardArrowLeft color='secondary' />
  </button>
);

const SlickArrowRight = ({ currentSlide, slideCount, ...props }: any) => (
  <button
    {...props}
    className={
      'slick-next slick-arrow' +
      (currentSlide === slideCount - 1 || props.onClick === null ? ' slick-disabled' : '')
    }
    aria-hidden={currentSlide === slideCount - 1 || props.onClick === null ? true : false}
    role='button'
    aria-label='Next Item'
    aria-disabled={currentSlide === slideCount - 1 || props.onClick === null ? true : false}
    disabled={currentSlide === slideCount - 1 || props.onClick === null ? true : false}
    type='button'
  >
    <KeyboardArrowRight color='secondary' />
  </button>
);

const CardSilder: FC<any> = ({ children, sliderSettings }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    accessibility: false,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4.5,
        },
      },
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 3.5,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2.5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
        },
      },
    ],
    ...sliderSettings,
  };

  return (
    <SilderWrapper>
      <Slider {...settings}>{children}</Slider>
    </SilderWrapper>
  );
};

export default CardSilder;
