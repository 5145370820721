import { FC, useState } from 'react';
import { Typography, Box, Collapse, Grid, MenuItem } from '@mui/material';
import { each, filter, toString, get, includes, isEmpty, some } from 'lodash';
import classNames from 'classnames';

import Button from 'elements/Button';
import Checkbox from 'elements/Checkbox';
import DropdownField from 'elements/DropdownField';
import CustomDropdown from 'elements/CustomDropdown';
import FormControlLabel from 'elements/FormControlLabel/FormControlLabel';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import { useUpdateLanguagePreferencesMutation } from 'api/resourcesApi';

import {
  SortFieldWrapper,
  FilterWrapper,
  FilterBox,
  DispalyMenuItems,
  StyledLabel,
  GroupHeader,
} from 'pages/Dashboard/components/Resources/style';
import FilterChips from 'pages/Dashboard/components/Resources/ResourcesDetails/FilterChips';

import { SORT_OPTIONS } from 'config';
import { useIsSpeechCraftMFE } from 'hooks/useIsSpeechCraftMFE';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const renderMenuTitle = (filtersDate: any, defaultTitle: any = '-Select-') => {
  const count: any = Object.values(filtersDate).reduce(
    (acc: any, { value }: any) => {
      if (value) {
        return acc + 1;
      }
      return acc;
    },
    0,
  );

  if (!count) {
    return `All ${defaultTitle}`;
  }

  return `Update ${defaultTitle}`;
};

const ResourcesFilter: FC<any> = ({
  categories,
  languages,
  handleUpdateFilters,
  handleRemoveFilters,
  resetFilters,
  selectedFilteres,
  isFilterApplied,
  sortType,
  handleUpdateSortType,
  disableCategory,
  setResetCategory,
  setSelectedCategory,
  isLanguagePrefLoading,
}) => {
  const [activeDropdowns, setActiveDropdowns] = useState<any>({
    isActiveCategoryDropdown: false,
    isActiveSubCategoryDropdown: false,
    isActiveLanguageDropdown: false,
  });
  const isSpeechCraftMFE = useIsSpeechCraftMFE();

  const [updatePreferences] = useUpdateLanguagePreferencesMutation();

  const getSelectedCategory = () => {
    const selectedCategories: any = [];
    each(get(selectedFilteres, 'category'), (item, key) => {
      if (item.value) selectedCategories.push(key);
    });
    return filter(categories, item =>
      includes(selectedCategories, toString(item.id)),
    );
  };

  const selectedCategories = get(selectedFilteres, 'category');
  const hasSelectAnyCategory = some(selectedCategories, ({ value }) => value);
  const hasSelectAnySubcategory = some(
    get(selectedFilteres, 'subCategory'),
    ({ value }) => value,
  );
  const hasSelectLanguage = some(
    get(selectedFilteres, 'language'),
    ({ value }) => value,
  );

  const isSubCategoryDisabled =
    isEmpty(selectedCategories) || !hasSelectAnyCategory;

  // For implementing web accessibility so user can select checkboxes via keyboard.
  const checkboxesToggle = (event: any) => {
    event.target.children.item(0).click();
  };

  const handleUpdateLaguage = (event: any) => {
    if (!isSpeechCraftMFE) {
      const [, , languageId] = event.target.name.split('.');
      const payload: any = Object.keys(selectedFilteres.language).filter(
        key => selectedFilteres.language[key]['value'] && key !== languageId,
      );

      if (event.target.checked) {
        payload.push(languageId);
      }

      updatePreferences({
        preferredLanguageFilter: payload,
      });
    }

    handleUpdateFilters(event);
  };

  const handleDeleteFilter = (filterType: any, filterId: any) => {
    if (filterType === 'language' && !isSpeechCraftMFE) {
      const payload: any = Object.keys(selectedFilteres.language).filter(
        key => selectedFilteres.language[key]['value'] && key !== filterId,
      );

      updatePreferences({
        preferredLanguageFilter: payload,
      });
    }

    handleRemoveFilters(filterType, filterId);
  };

  const handleRemoveAllFilters = () => {
    if (!isSpeechCraftMFE) {
      updatePreferences({
        preferredLanguageFilter: [],
      });
    }

    setSelectedCategory('');
    resetFilters();
    setResetCategory(false);
  };

  return (
    <Box>
      <FilterWrapper>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={6} md={3} sx={{ mb: 2 }}>
            <Typography variant='h4' sx={{ mb: 1 }}>
              Filter by
            </Typography>
            <SortFieldWrapper>
              <FormControlLabel
                className='h-40'
                size='small'
                control={
                  <DropdownField
                    value={sortType || SORT_OPTIONS[0].value}
                    options={SORT_OPTIONS}
                    handleChange={handleUpdateSortType}
                    name='sortBy'
                    className='secondary-dropdown'
                  />
                }
              />
            </SortFieldWrapper>
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={{ mb: 2 }}>
            <Typography variant='h4' sx={{ mb: 1 }}>
              Category
            </Typography>
            <CustomDropdown
              isOpen={activeDropdowns.isActiveCategoryDropdown}
              toggleDropdown={(isOpen: boolean) =>
                setActiveDropdowns({
                  ...activeDropdowns,
                  isActiveCategoryDropdown: isOpen,
                })
              }
              btnEndIcon={<KeyboardArrowDownIcon />}
              menuTitle={renderMenuTitle(
                selectedFilteres['category'],
                'Categories',
              )}
              menuAlignment={{ vertical: 'top', horizontal: 'left' }}
              classNames={classNames({
                active: hasSelectAnyCategory,
              })}
              popOverClasses='text-capitalize'
              isFilterDropdown
            >
              {categories.map((category: any, index: any) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={checkboxesToggle}
                    disabled={disableCategory}
                  >
                    <StyledLabel
                      control={
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          name={`category.${category.name}.${category.id}`}
                          handleChange={handleUpdateFilters}
                          checked={
                            !!selectedFilteres['category']?.[category.id]?.[
                              'value'
                            ]
                          }
                        />
                      }
                      label={category.name}
                    />
                  </MenuItem>
                );
              })}
            </CustomDropdown>
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={{ mb: 2 }}>
            <div
              className={isSubCategoryDisabled ? 'disabled-dropdown' : ''}
              aria-disabled={isSubCategoryDisabled ? 'true' : 'false'}
            >
              <Typography variant='h4' sx={{ mb: 1 }}>
                Subcategory
              </Typography>
              <CustomDropdown
                disableBtn={isSubCategoryDisabled ? 'disabled-dropdown' : ''}
                isOpen={activeDropdowns.isActiveSubCategoryDropdown}
                toggleDropdown={(isOpen: boolean) =>
                  setActiveDropdowns({
                    ...activeDropdowns,
                    isActiveSubCategoryDropdown: isOpen,
                  })
                }
                btnEndIcon={<KeyboardArrowDownIcon />}
                menuTitle={renderMenuTitle(
                  selectedFilteres['subCategory'],
                  'Subcategories',
                )}
                menuAlignment={{ vertical: 'top', horizontal: 'left' }}
                classNames={classNames({
                  active: hasSelectAnySubcategory,
                })}
                popOverClasses='text-capitalize'
                isFilterDropdown
              >
                {getSelectedCategory().map((category: any, index: any) => {
                  return (
                    <>
                      <GroupHeader>{get(category, 'name')}</GroupHeader>
                      {get(category, 'subCategory').map(
                        (subCategoryItem: any, index: any) => {
                          return (
                            <MenuItem key={index} onClick={checkboxesToggle}>
                              <StyledLabel
                                control={
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    name={`subCategory.${subCategoryItem.name}.${subCategoryItem.id}`}
                                    handleChange={handleUpdateFilters}
                                    checked={
                                      !!selectedFilteres['subCategory']?.[
                                        subCategoryItem.id
                                      ]?.['value']
                                    }
                                  />
                                }
                                label={subCategoryItem.name}
                              />
                            </MenuItem>
                          );
                        },
                      )}
                    </>
                  );
                })}
              </CustomDropdown>
            </div>
          </Grid>
          {!isSpeechCraftMFE && (
            <Grid item xs={12} sm={6} md={3} sx={{ mb: 2 }}>
              <Typography variant='h4' sx={{ mb: 1 }}>
                Language
              </Typography>
              <CustomDropdown
                isOpen={activeDropdowns.isActiveLanguageDropdown}
                toggleDropdown={(isOpen: boolean) =>
                  setActiveDropdowns({
                    ...activeDropdowns,
                    isActiveLanguageDropdown: isOpen,
                  })
                }
                btnEndIcon={<KeyboardArrowDownIcon />}
                menuTitle={renderMenuTitle(
                  selectedFilteres['language'],
                  'Languages',
                )}
                menuAlignment={{ vertical: 'top', horizontal: 'left' }}
                classNames={classNames({
                  active: hasSelectLanguage,
                })}
                popOverClasses='text-capitalize'
                disableBtn={isLanguagePrefLoading}
                isFilterDropdown
              >
                {languages.map((language: any, index: any) => {
                  return (
                    <MenuItem key={index} onClick={checkboxesToggle}>
                      <StyledLabel
                        control={
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            name={`language.${language.name}.${language.id}`}
                            handleChange={handleUpdateLaguage}
                            checked={
                              !!selectedFilteres['language']?.[language.id]?.[
                                'value'
                              ]
                            }
                          />
                        }
                        label={language.name}
                      />
                    </MenuItem>
                  );
                })}
              </CustomDropdown>
            </Grid>
          )}
        </Grid>
        <FilterChips
          selectedFilteres={selectedFilteres}
          handleRemoveFilters={handleDeleteFilter}
          resetFilters={handleRemoveAllFilters}
          isFilterApplied={isFilterApplied}
          disableCategory={disableCategory}
        />
      </FilterWrapper>
    </Box>
  );
};

export default ResourcesFilter;
