import { styled, Box } from '@mui/material';
import { DeviceUp } from 'styles/breakpoints';

export const ConfirmationBox = styled(Box)`
    text-align: center;
    max-width: 300px;
    margin: 0 auto;
    position: relative;

    & .fs-20 {
        font-size: 20px;
    }
`;

export const ImageHolder = styled('div')`
    margin: 20px auto 30px;
    max-width: 160px;

    & img {
        object-fit: contain;
        width: 100%;
    }
`;

export const CTAButtons = styled(Box)`
    margin-top: 40px;

    @media ${DeviceUp.lg} {
        margin-top: 60px;
    }

    & .MuiButton-root {
        padding: 7px;

        &:not(:first-of-type) {
            margin-left: 5px;
        }
    }
`;
