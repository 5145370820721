import { Box, Stack, Typography, useTheme } from '@mui/material';
import {
  CartesianGrid,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  XAxis,
  YAxis,
} from 'recharts';

import { EmptyDataTextContainer } from './style';

const AssessmentChart = ({ assessmentResult }: any) => {
  const {
    title,
    choice1,
    choice2,
    choice3,
    choice4,
    choice5,
    before,
    mid,
    after,
  } = assessmentResult;
  const theme = useTheme();

  const isResultAvailable = before || mid || after;
  const chartData = [
    ...(before ? [{ name: 1, value: before }] : []),
    ...(mid ? [{ name: 2, value: mid }] : []),
    ...(after ? [{ name: 3, value: after }] : []),
  ];

  const yAxisTickFormatter = (value: number) => {
    switch (value) {
      case 1:
        return choice1;
      case 2:
        return choice2;
      case 3:
        return choice3;
      case 4:
        return choice4;
      case 5:
        return choice5;
      default:
        return '';
    }
  };

  const xAxisTickFormatter = (value: number) => {
    switch (value) {
      case 1:
        return 'Before';
      case 2:
        return 'Mid';
      case 3:
        return 'After';
      default:
        return '';
    }
  };

  return (
    <Stack>
      <Typography fontWeight='bold' ml={28}>
        {title}
      </Typography>
      <Box height={280} position='relative'>
        {!isResultAvailable ? (
          <EmptyDataTextContainer>
            <Typography variant='h5'>There is no data to display</Typography>
          </EmptyDataTextContainer>
        ) : null}
        <ResponsiveContainer width='100%' height='100%'>
          <ScatterChart
            width={500}
            height={300}
            margin={{
              top: 20,
              right: 10,
              left: 0,
              bottom: 20,
            }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis
              dataKey='name'
              tick={{ dy: 15 }}
              style={{
                fontWeight: 'bold',
                fontSize: '0.8rem',
              }}
              type='number'
              domain={[1, 2, 3]}
              ticks={[1, 2, 3]}
              tickFormatter={xAxisTickFormatter}
            />
            <YAxis
              width={120}
              dataKey='value'
              type='number'
              domain={[1, 2, 3, 4, 5]}
              ticks={[1, 2, 3, 4, 5]}
              tickFormatter={yAxisTickFormatter}
              tick={{ dx: -7 }}
              style={{
                fontWeight: 'bold',
                fontSize: '0.8rem',
              }}
            />
            <Scatter
              data={isResultAvailable ? chartData : []}
              type='monotone'
              stroke={theme.palette.secondary.main}
              strokeWidth={4}
              line={{ strokeWidth: 2 }}
              fill={theme.palette.secondary.main}
            />
          </ScatterChart>
        </ResponsiveContainer>
      </Box>
    </Stack>
  );
};

export default AssessmentChart;
