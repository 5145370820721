import { FunctionComponent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { generatePath, useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';

import Container from 'elements/Container';
import { DashboardRoutes as Routes } from 'routes';

import {
  useValidateSpeechMutation,
  useGetInCompleteSpeechQuery,
  useDeleteIncompleteSpeechMutation,
} from 'api/speechValidationApi';
import { useAppContext } from 'contexts/AppContext';

import FormStepper from './Stepper';
import FormStep from './Stepper/FormStep';
import GeneralInformation from './GeneralInformation';
import SpeechCrafter from './SpeechCrafter';
import {
  generalInformationScheema,
  speechCraftScheema,
} from './validationScheema';
import Summary from './Summary';
import ResumeSpeechModal from './ResumeSpeechModal';
import { parseDate } from 'utils/utility';
import dayjs from 'dayjs';

const _initialValues: any = {
  event: null,
  speech: {},
  speechDate: null,
  id: '',
  attendees: [],
  groupComment: '',
};

const getPayload = (values: any, activeStep: any) => {
  const { speechDate, id, speech, event, attendees, groupComment } = values;
  const speechDateTime = parseDate(speechDate).toISOString();

  if (!id) {
    return {
      event,
      speechDate: speechDateTime,
      speech: speech.id,
      complete: false,
    };
  }

  return {
    event,
    speechDate: speechDateTime,
    speech: speech.id,
    complete: activeStep === 2,
    id,
    attendeesList: attendees,
    groupComment,
  };
};

const SpeechValidation: FunctionComponent<any> = () => {
  const history = useHistory();
  const { event } = useAppContext();
  const { id: eventId } = event || {};

  const [initialValues, setInitialValues] = useState(_initialValues);
  const [activeStep, setActiveStep] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReloadPage, setIsReloadPage] = useState(false);

  const [validateSpeech, { isLoading: isSubmitting }] =
    useValidateSpeechMutation() as any;
  const {
    data = {},
    isLoading,
    isFetching,
    isSuccess,
  } = useGetInCompleteSpeechQuery();
  const [deleteSpeechValidation] = useDeleteIncompleteSpeechMutation();

  useEffect(() => {
    const { id, speechDate, speech, event, complete, attendees = [] } = data;
    const date = dayjs(new Date(speechDate)).format('YYYY-MM-DD');

    if (!id || isEmpty(data)) {
      setInitialValues((prev: any) => ({
        ...prev,
        event: eventId,
      }));

      return;
    }

    if (isEmpty(attendees)) {
      setActiveStep(1);
    }

    if (!isEmpty(attendees) && !complete) {
      setActiveStep(2);
    }

    setInitialValues((prev: any) => ({
      ...prev,
      id,
      complete,
      speechDate: date,
      speech: { id: speech.id, name: `${speech.name}#${speech.description}` },
      event: eventId,
      attendees: attendees.map(({ attendee, individualComment }: any) => ({
        ...attendee,
        individualComment,
      })),
      groupComment: attendees?.[0]?.groupComment || '',
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data), eventId]);

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    if (data?.id && isReloadPage) {
      setIsModalOpen(true);
    } else {
      setIsReloadPage(false);
    }
  }, [data?.id, isReloadPage, isSuccess]);

  useEffect(() => {
    setIsReloadPage(true);
  }, []);

  const handleSubmit = async (
    data: any,
    activeStep: any,
    helpers: any,
    handleNext: any,
  ) => {
    const payload: any = getPayload(data, activeStep);

    try {
      await validateSpeech(payload).unwrap();

      if (activeStep === 2) {
        helpers.resetForm();
        toast.success(`Your speech validation has been done`);
        history.push(
          generatePath(Routes.DASHBOARD.path, {
            activeOption: 'coordinator-home',
          }),
        );
      } else {
        handleNext();
      }
    } catch (error: any) {
      toast.error(error?.data || error?.message || 'Something went wrong.');
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setIsReloadPage(false);
  };

  const handleCreateSpeech = async () => {
    await deleteSpeechValidation(data.id);
    setActiveStep(0);
    setInitialValues({ ..._initialValues, event: eventId });
    handleClose();
  };

  return (
    <Container>
      <FormStepper
        title='Speech Validation'
        initialValues={initialValues}
        onSubmit={handleSubmit}
        isNextDisabled={isSubmitting || isFetching}
        step={activeStep}
        handleClose={() => {
          history.push(
            generatePath(Routes.DASHBOARD.path, {
              activeOption: 'coordinator-home',
            }),
          );
        }}
        enableReinitialize
      >
        <FormStep
          label='General Information'
          validationSchema={generalInformationScheema}
          isLoading={isLoading}
        >
          <GeneralInformation />
        </FormStep>

        <FormStep label='Speechcrafters' validationSchema={speechCraftScheema}>
          <SpeechCrafter />
        </FormStep>

        <FormStep label='Summary'>
          <Summary />
        </FormStep>
      </FormStepper>
      {isModalOpen && (
        <ResumeSpeechModal
          handleClose={handleClose}
          handleCreateSpeech={handleCreateSpeech}
        />
      )}
    </Container>
  );
};

export default SpeechValidation;
