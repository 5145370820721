import { FunctionComponent, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { FieldWrapper, TextField } from 'elements/Form/style';
import { StyledLabel as FormControlLabel } from 'elements/FormControlLabel/style';
import {
  useGetAssessmentFormsQuery,
  useGetAssessmentResultsQuery,
} from 'api/selfAssessmentApi';
import { useGetRolesQuery } from 'api/initializeAppApi';
import AssessmentChart from './AssessmentChart';
import {
  StyledAutocomplete as Autocomplete,
  StyledLabel,
  StyledPopperOptions,
} from 'elements/AutoComplete/style';
import Loader from 'elements/Loader';
import useProfilePage from 'pages/Profile/useProfilePage';

const AssessmentResults: FunctionComponent<any> = () => {
  const [formValue, setFormValue] = useState<any>('');

  const { username = '', isSelfProfilePage } = useProfilePage();

  const { data: events = [] } = useGetRolesQuery();
  const eventId = events[0]?.id;

  const {
    data: assessmentForms,
    isLoading: isLoadingForms,
    isFetching: isFetchingForms,
    isSuccess: isSuccessForms,
  } = useGetAssessmentFormsQuery({ eventId }, { skip: !eventId });

  const {
    data: assessmentResults,
    isLoading: isLoadingResults,
    isFetching: isFetchingResults,
    isSuccess: isSuccessAssessmentResults,
  } = useGetAssessmentResultsQuery(
    { formId: formValue?.id, eventId, user: isSelfProfilePage ? '' : username },
    { skip: !formValue?.id || !eventId },
  );

  useEffect(() => {
    if (isSuccessForms && assessmentForms?.length > 0) {
      setFormValue(assessmentForms?.[0]);
    }
  }, [isSuccessForms, assessmentForms]);

  return (
    <Box mt={10}>
      {isLoadingForms || isLoadingResults ? (
        <Box mt={5}>
          <Loader />
        </Box>
      ) : null}

      {!isFetchingForms && isSuccessForms && assessmentForms?.length <= 0 ? (
        <Typography variant='h3' textAlign='center'>
          No assessment form found!
        </Typography>
      ) : null}

      <Box mt={5} position='relative'>
        {isSuccessAssessmentResults && assessmentResults?.length > 0 ? (
          <Grid container spacing={8} mt={5}>
            {assessmentResults.map((assessmentResult: any) => (
              <Grid item xs={12} md={6} key={assessmentResult.id}>
                <AssessmentChart assessmentResult={assessmentResult} />
              </Grid>
            ))}
          </Grid>
        ) : null}
      </Box>

      {!isFetchingResults &&
      isSuccessAssessmentResults &&
      assessmentResults.length <= 0 ? (
        <Typography variant='h3' textAlign='center'>
          No Records Found!
        </Typography>
      ) : null}
    </Box>
  );
};

export default AssessmentResults;
