import React, { FC, useEffect, useState, useMemo } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import Grid from '@mui/material/Grid';
// @ts-ignore
import { getAuthenticatedUser } from '@edx/frontend-platform/auth';
import * as Yup from 'yup';

import Button from 'elements/Button';
import { HOC } from 'elements/Modal';
import DropdownField from 'elements/DropdownField';
import FormControlLabel from 'elements/FormControlLabel/FormControlLabel';
import CheckboxesAutoComplete from 'elements/AutoComplete/CheckboxesAutoComplete';
import { ButtonWrapper } from 'pages/Dashboard/components/UpdateSpeechHistory/style';
import TextField from 'elements/Form/TextField';

import { useGetClubsQuery } from 'pages/Dashboard/components/Feedback/feedbackApi';
import { useGetAvailableMembersQuery } from 'api/resourcesApi';
import { Typography } from '@mui/material';
import { getUserName } from 'utils/utility';

export const getValidationSchema = (isSpeechCrafter = false) =>
  Yup.object({
    ...(isSpeechCrafter && {
      event: Yup.object().required('Field is required'),
    }),
    ...(!isSpeechCrafter && {
      clubId: Yup.number().required('Field is required'),
    }),
    clubMembers: Yup.array().min(1, 'at least 1').required('Field is required'),
  });

const AssetShareModal: FC<any> = ({
  shareAssetModalData,
  setShareAssetModalData,
  handleClickShareAsset,
  assetId,
  isSpeechCrafter = false,
}) => {
  const user = getAuthenticatedUser();

  const [clubId, setClubId] = useState('');

  const formik = useFormik({
    initialValues: shareAssetModalData,
    validationSchema: getValidationSchema(isSpeechCrafter),
    onSubmit: (values: any, { setSubmitting }: FormikHelpers<any>) => {
      setSubmitting(true);
      setShareAssetModalData(values);
      handleClickShareAsset(values).then(() => setSubmitting(false));
    },
  });

  const {
    setFieldValue,
    values: formikValues,
    dirty,
    setFieldTouched,
    touched,
    errors,
    isValid,
    isSubmitting,
  }: any = formik;

  const { data: clubData = [], isFetching: isFetchingClubs } = useGetClubsQuery(
    {},
    { skip: isSpeechCrafter },
  );

  const {
    data: clubMembers = [],
    isFetching: isFetchingMembers,
    isSuccess: isSuccessMembers,
  } = useGetAvailableMembersQuery(
    {
      ...(isSpeechCrafter ? { eventId: formikValues?.event?.id } : { clubId }),
      assetId,
    },
    {
      skip: isSpeechCrafter
        ? !formikValues?.event?.id
        : clubId === '' || !assetId,
    },
  );

  const clubOptions = clubData.reduce(
    (acc: any, { name, id }: any) => [
      ...acc,
      {
        label: name,
        value: id,
      },
    ],
    [],
  );

  const handleUpdateField = (event: any) => {
    const { name, value } = event.target;

    if (name === 'clubId') {
      setClubId(value);
      setFieldValue('clubMembers', []);
    }

    setFieldValue(name, value);
    setFieldTouched(name, true, false);
  };

  useEffect(() => {
    setClubId(shareAssetModalData.clubId);
  }, [shareAssetModalData.clubId]);

  return (
    <>
      <Typography mb={4}>
        Share this resource with the member(s) you select below.
      </Typography>
      <Grid container rowSpacing={4}>
        {!isSpeechCrafter && (
          <Grid item xs={12}>
            <FormControlLabel
              label='Club'
              labelPlacement='top'
              control={
                <DropdownField
                  value={formikValues.clubId}
                  options={clubOptions}
                  handleChange={handleUpdateField}
                  name='clubId'
                  className={`${formikValues.club ? 'has-data' : ''}`}
                  helperText={touched['club'] && errors['club']}
                  error={!!touched['club'] && !!errors['club']}
                  isLoading={isFetchingClubs}
                  placeholder='Select a club'
                />
              }
            />
          </Grid>
        )}
        {isSpeechCrafter && (
          <Grid item xs={12}>
            <FormControlLabel
              label='Event'
              labelPlacement='top'
              control={
                <TextField
                  name='event'
                  value={formikValues?.event?.name}
                  className={`${formikValues.event?.name ? 'has-data' : ''}`}
                  disabled={true}
                />
              }
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControlLabel
            label={isSpeechCrafter ? 'Speechcrafter' : 'Member(s)'}
            labelPlacement='top'
            control={
              <CheckboxesAutoComplete
                multiple
                limitTags={-1}
                id='select-club-members'
                options={
                  isSuccessMembers
                    ? clubMembers
                        ?.map((cm: any) => ({ ...cm, name: getUserName(cm) }))
                        .filter((cm: any) => cm.id !== user.userId)
                    : []
                }
                disableCloseOnSelect
                loading={isFetchingMembers}
                loadingText={`Loading ${
                  isSpeechCrafter ? 'Speechcrafters' : 'Members'
                }`}
                getOptionLabel={(option: any) => option.name}
                defaultValue={formikValues.clubMembers}
                selectedOptions={(members: any) =>
                  setFieldValue('clubMembers', members)
                }
                placeholder={`Select ${
                  isSpeechCrafter ? 'speechcrafter(s)' : 'member(s)'
                }
                `}
                name='clubMembers'
              />
            }
          />
        </Grid>
      </Grid>
      <ButtonWrapper>
        <Button
          variant='contained'
          onClick={() => formik.handleSubmit()}
          disabled={isSubmitting || !dirty || !isValid}
        >
          Share
        </Button>
      </ButtonWrapper>
    </>
  );
};

export default HOC(AssetShareModal);
