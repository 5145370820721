import { FunctionComponent } from 'react';
import { Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';

import { useGetSpeechCompletionQuery } from 'api/speechDashboardApi';
import { getPlatformName } from 'utils/utility';

import Loader from 'elements/Loader';
import { getStringWithSeperator } from 'utils/utility';
import Breadcrumb from 'components/Breadcrumb';

import CompletionProgress from './CompletionProgress';
import { Container } from './style';

const links = [
  {
    label: 'Home',
    url: `${getPlatformName()}`,
  },
  {
    label: 'Dashboard',
  },
];

const SpeechCompletion: FunctionComponent<any> = () => {
  const { data = {}, isLoading, isSuccess } = useGetSpeechCompletionQuery();

  const speechCompletionData = Object.keys(data)?.reduce(
    (acc: any, key: any) => {
      return acc.concat({
        speechName: data[key].name,
        validatedAttendies: data[key].complete,
        totalAttendies: data[key].total,
        progress: Math.ceil((data[key].complete / data[key].total) * 100) || 0,
        completedNames: data[key].completedNames,
        incompletedNames: data[key].incompletedNames,
      });
    },
    [],
  );

  return (
    <Box className='w-100'>
      <Breadcrumb links={links} />

      {isLoading || isEmpty(speechCompletionData) ? (
        <Box textAlign='center' mt={25} className='w-100'>
          <Typography variant='body1'>
            {isSuccess ? 'No record found' : <Loader />}
          </Typography>
        </Box>
      ) : (
        <Container gap={3} mt={7.5}>
          {speechCompletionData?.map((data: any, index: number) => (
            <CompletionProgress data={data} />
          ))}
        </Container>
      )}
    </Box>
  );
};

export default SpeechCompletion;
