import { styled, CardActions } from '@mui/material';

export const CardActionsHolder = styled(CardActions)`
  padding: 0;
  position: absolute;
  top: 138px;
  right: 20px;

  .MuiButtonBase-root {
    text-transform: none;
  }
`;
