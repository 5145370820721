import baseApiSlice from 'api/baseApiSlice';

const BASE_API_URL = 'api';

enum ITags {
  SPEECH_CERTIFICATES = 'speech-certificates',
}

const speechCertificatesApiWithTags = baseApiSlice.enhanceEndpoints({
  addTagTypes: [ITags.SPEECH_CERTIFICATES],
});

const speechCertificatesApiSlice =
  speechCertificatesApiWithTags.injectEndpoints({
    endpoints: builder => ({
      getEventAttendeesCertificates: builder.query({
        query: () => ({
          url: `${BASE_API_URL}/events/certificates/status/`,
        }),
        providesTags: (result, error) => [
          { type: ITags.SPEECH_CERTIFICATES, id: 'LIST' },
        ],
      }),

      awardCertificates: builder.mutation({
        query: data => ({
          url: `${BASE_API_URL}/ti/certificates/speechcraft/create/`,
          method: 'POST',
          data,
        }),
        invalidatesTags: (result, error) => [
          { type: ITags.SPEECH_CERTIFICATES, id: 'LIST' },
        ],
      }),
    }),
  });

export const {
  useGetEventAttendeesCertificatesQuery,
  useAwardCertificatesMutation,
} = speechCertificatesApiSlice;
