import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    if(document && document.getElementById('scrollableDiv'))
      // @ts-ignore: Object is possibly 'null'.
      document.getElementById('scrollableDiv').scrollTo(0, 0);
  }, [pathname, document]);

  return null;
}