import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import compose from 'compose-function';
import { useInView } from 'react-intersection-observer';
import { useIsSpeechCraftMFE } from 'hooks';

import { HOC } from 'elements/Modal';
import UploadFile from 'pages/Dashboard/components/Resources/Documents/UploadFile';
import DocumentCard from 'pages/Dashboard/components/Resources/Documents/DocumentCard';
import Loader from 'elements/Loader';
import emptyDocImg from 'assets/images/empty-docs.svg';
import {
  selectDocuments,
  selectAllDocuments,
  resetDocuments,
  fetchDocuments,
} from './documentSlice';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

const UploadFileModal = compose(HOC)(UploadFile);

const Documents = () => {
  const isSpeechCraftMFE = useIsSpeechCraftMFE();
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const openUploadModal = () => {
    setIsUploadModalOpen(true);
  };
  const closeUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  const dispatch = useDispatch();
  const { ref, inView } = useInView();

  const { isFetching } = useSelector(selectDocuments);
  const documents = useSelector(selectAllDocuments);

  useEffect(() => {
    dispatch(fetchDocuments());

    return () => {
      dispatch(resetDocuments());
    };
  }, [dispatch]);

  useEffect(() => {
    if (inView) {
      dispatch(fetchDocuments());
    }
  }, [dispatch, inView]);

  return (
    <Box mb={20}>
      {!isFetching && documents.length <= 0 ? (
        <Stack mt={10} mb={8} alignItems='center'>
          <Typography
            mt={3.75}
            mb={4}
            variant='body1'
            textAlign='center'
            color={'#4d4d4d'}
          >
            File types accepted: mp4, doc/docx, pdf, jpg, png, svg, ppt/pptx <br />
            Maximum total storage limit 1GB. <br />
            <i>Note: Once maximum storage capacity is reached, additional uploads may be deleted.</i> <br />
          </Typography>
          <Box width={"100%"} display={"flex"} justifyContent={"flex-end"}>
            <Button className='gap-10' variant='contained' size='large' onClick={openUploadModal}>
              Upload file
            </Button>
          </Box>
        </Stack>
      ) : (
        <Box>
          <Stack mt={10} mb={8} alignItems='center'>
            <Typography
              mt={3.75}
              mb={4}
              variant='body1'
              textAlign='center'
              color={'#4d4d4d'}
            >
              File types accepted: mp4, doc/docx, pdf, jpg, png, svg, ppt/pptx <br />
              Maximum total storage limit 1GB. <br />
              <i>Note: Once maximum storage capacity is reached, additional uploads may be deleted.</i> <br />
            </Typography>
            <Box width={"100%"} display={"flex"} justifyContent={"flex-end"}>
              <Button className='gap-10' variant='contained' size='large' onClick={openUploadModal}>
                Upload file
              </Button>
            </Box>
          </Stack>
          <Grid container columnSpacing={3} rowSpacing={7} mt={4.5}>
            {documents.map((document: any, i: number, docArray) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                key={document.id}
                ref={docArray.length === i + 1 ? ref : undefined}
              >
                <DocumentCard document={document} />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {isFetching ? (
        <Box mt={10}>
          <Loader />
        </Box>
      ) : null}

      {isUploadModalOpen ? (
        <UploadFileModal title='Upload File' handleClose={closeUploadModal} />
      ) : null}
    </Box>
  );
};

export default Documents;
