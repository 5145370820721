import { FunctionComponent } from 'react';
import { Box, Typography } from '@mui/material';
import { generatePath, useHistory } from 'react-router-dom';
import { truncate, capitalize } from 'lodash';
import { green } from '@mui/material/colors';

import Card from 'elements/Card';
import { DescriptionHolder } from 'elements/Card/style';

import { DashboardRoutes as Routes } from 'routes';
import useProfilePage from 'pages/Profile/useProfilePage';
import dummyImage from 'assets/images/dummyImage.png';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useRoles } from 'hooks';

const AssessmentCard: FunctionComponent<any> = ({
  cardData,
  progress,
  isSubmitted,
  isDisabled = false,
}) => {
  const { title, description, id, imageUrl } = cardData;
  const { isCoordinator, isSpeechcrafter } = useRoles();

  const { isSelfProfilePage, username } = useProfilePage();

  const history = useHistory();
  return (
    <Card
      cardImage={imageUrl ?? dummyImage}
      altText={title}
      handleClick={() => {
        let routeKey = 'ASSESSMENT_FORM';
        if (isCoordinator) {
          routeKey = 'COORDINATOR_PROFILE_ASSESSMENT_FORM';
        } else if (isSelfProfilePage) {
          routeKey = 'PROFILE_ASSESSMENT_FORM';
        }

        history.push(
          generatePath(
            // @ts-ignore
            Routes[routeKey].path,
            { formId: id, progress, formTitle: title, username: username },
          ),
        );
      }}
      isDisable={isDisabled && isSpeechcrafter}
      hoverText={
        isDisabled && isSpeechcrafter
          ? `Please fill the ${
              progress === 'mid' ? 'before' : 'mid'
            } form to access this`
          : ''
      }
    >
      {isSubmitted ? (
        <Box position='absolute' sx={{ top: 4, right: 6 }}>
          <CheckCircleIcon className="secondary-icon" />
        </Box>
      ) : null}
      <Typography variant='h2' className='fw-700' title={title}>
        {`${title}- ${capitalize(progress)}`}
      </Typography>
      <DescriptionHolder>
        <Typography>
          {truncate(description, {
            length: 90,
            separator: ' ',
          })}
        </Typography>
      </DescriptionHolder>
    </Card>
  );
};

export default AssessmentCard;
