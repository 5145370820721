import { styled, Box } from '@mui/material';

export const ErrorContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1em;
  flex-grow: 1;
  text-align: center;
`;

export const ErrorBox = styled(Box)`
  display: inline-flex;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  border-radius: 5px;
  margin: 25px auto;
`;

export const ErrorImage = styled(Box)`
  max-width: 160px;
  margin: 30px auto;

  img {
    display: block;
    width: 100%;
    border-radius: 5px 5px 0 0;
  }
`;

export const ErrorText = styled(Box)`
  padding: 25px 20px;

  h2 {
    margin: 0;
    padding: 0 0 30px;
  }

  .mw-160 {
    min-width: 160px;
  }
`;
