import { FunctionComponent, useState, useRef } from 'react';
import {
  Box,
  useTheme,
  Stack,
  Typography,
  ClickAwayListener,
} from '@mui/material';
import { PieChart, Pie, ResponsiveContainer, Label } from 'recharts';
import Button from 'elements/Button';

import LegendTooltip from '../Assessments/LegendTooltip';
import TooltipContent from '../Assessments/TooltipContent';
import { PieTooltipWrapper } from '../Assessments/style';
import { CompletionProgressCard } from './style';

const data01 = [{ value: 10 }];

const CustomLabel = ({ viewBox, totalAttendies, validatedAttendies }: any) => {
  const { cx, cy } = viewBox;

  return (
    <g>
      <text
        x={cx}
        y={cy}
        className='recharts-text recharts-label'
        textAnchor='middle'
        dominantBaseline='central'
        alignmentBaseline='middle'
        fontSize='48'
        fontWeight='800'
        style={{ lineHeight: 58 }}
      >
        {`${validatedAttendies}/${totalAttendies}`}
      </text>
      <text
        x={cx}
        y={cy + 30}
        className='recharts-text recharts-label'
        textAnchor='middle'
        dominantBaseline='central'
        alignmentBaseline='middle'
        fontSize='12'
        fontWeight='700'
      >
        COMPLETED
      </text>
    </g>
  );
};

const CompletionProgress: FunctionComponent<any> = ({ data = {} }) => {
  const theme = useTheme();

  const {
    speechName,
    validatedAttendies,
    completedNames = [],
    incompletedNames = [],
    progress,
    totalAttendies,
  } = data;

  const oldRange = 100;
  const newRange = 360;
  const endAngle = -(((progress - oldRange) * newRange) / oldRange + 270);

  const pieChartData = [
    {
      name: speechName,
      status: progress === 100,
      value: progress,
    },
  ];

  const [openCompletedTooltip, setOpenCompletedTooltip] = useState(false);
  const [openIncompletedTooltip, setOpenIncompletedTooltip] = useState(false);
  const [openCompletedPieTooltip, setOpenCompletedPieTooltip] = useState(false);
  const [openIncompletedPieTooltip, setOpenIncompletedPieTooltip] =
    useState(false);

  const handleCompletedTooltipOpen = () => setOpenCompletedTooltip(true);
  const handleCompletedTooltipClose = () => setOpenCompletedTooltip(false);
  const handleIncompletedTooltipOpen = () => setOpenIncompletedTooltip(true);
  const handleIncompletedTooltipClose = () => setOpenIncompletedTooltip(false);

  const handleCompletedPieTooltipOpen = () => {
    setOpenIncompletedPieTooltip(false);
    setOpenCompletedPieTooltip(true);
  };
  const handleCompletedPieTooltipClose = () =>
    setOpenCompletedPieTooltip(false);

  const handleIncompletedPieTooltipOpen = () => {
    setOpenCompletedPieTooltip(false);
    setOpenIncompletedPieTooltip(true);
  };
  const handleIncompletedPieTooltipClose = () =>
    setOpenIncompletedPieTooltip(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 50, y: 270 });

  const handleCompleted = (payload: any, value: any, event: any) => {
    const elRect = containerRef.current!.getBoundingClientRect();
    const tooltipX = event.clientX - elRect.x - 240;
    const tooltipY = event.clientY - elRect.y - 140;

    setTooltipPosition({ x: tooltipX, y: tooltipY });
    handleCompletedPieTooltipOpen();
  };

  const handleIncompleted = (payload: any, value: any, event: any) => {
    const elRect = containerRef.current!.getBoundingClientRect();
    const tooltipX = event.clientX - elRect.x - 240;
    const tooltipY = event.clientY - elRect.y - 140;

    setTooltipPosition({ x: tooltipX, y: tooltipY });
    handleIncompletedPieTooltipOpen();
  };

  return (
    <CompletionProgressCard ref={containerRef}>
      <Typography variant='h5' mb={5}>
        {`${speechName} Completed`}
      </Typography>

      <ClickAwayListener
        onClickAway={
          openCompletedPieTooltip
            ? handleCompletedPieTooltipClose
            : openIncompletedPieTooltip
            ? handleIncompletedPieTooltipClose
            : () => {}
        }
      >
        <div className='recharts-holder'>
          <ResponsiveContainer aspect={1}>
            <PieChart>
              <defs>
                <linearGradient id='colorUv' x1='1' y1='1' x2='0' y2='0'>
                  <stop
                    offset='30%'
                    stopColor={theme.palette.secondary.main}
                    stopOpacity={1}
                  />
                  <stop
                    offset='100%'
                    stopColor={theme.palette.secondary.light}
                    stopOpacity={1}
                  />
                </linearGradient>
              </defs>
              <Pie
                data={data01}
                dataKey='value'
                startAngle={90}
                endAngle={-270}
                innerRadius={100}
                outerRadius={150}
                fill={theme.palette.ternary.main}
                isAnimationActive={false}
                aria-label={speechName}
                style={{ cursor: 'pointer' }}
                onClick={handleIncompleted}
              >
                {!progress && (
                  <Label
                    content={
                      <CustomLabel
                        totalAttendies={totalAttendies}
                        validatedAttendies={validatedAttendies}
                      />
                    }
                    position='center'
                  />
                )}
              </Pie>
              <Pie
                data={pieChartData}
                dataKey='value'
                startAngle={90}
                endAngle={endAngle}
                innerRadius={95}
                outerRadius={150}
                fill={`url(#colorUv)`}
                aria-label={speechName}
                style={{ cursor: 'pointer' }}
                onClick={handleCompleted}
              >
                <Label
                  content={
                    <CustomLabel
                      totalAttendies={totalAttendies}
                      validatedAttendies={validatedAttendies}
                    />
                  }
                  position='center'
                />
              </Pie>
            </PieChart>
          </ResponsiveContainer>

          {openCompletedPieTooltip ? (
            <PieTooltipWrapper
              // @ts-ignore
              leftX={tooltipPosition.x}
              topY={tooltipPosition.y}
            >
              <Box component='span' className='tooltip-arrow'></Box>
              <TooltipContent
                completed={completedNames}
                modalTitle={speechName}
                type='completed'
                incompleted={incompletedNames}
              />
            </PieTooltipWrapper>
          ) : null}
          {openIncompletedPieTooltip ? (
            <PieTooltipWrapper
              // @ts-ignore
              leftX={tooltipPosition.x}
              topY={tooltipPosition.y}
            >
              <Box component='span' className='tooltip-arrow'></Box>
              <TooltipContent
                incompleted={incompletedNames}
                modalTitle={speechName}
                type='incompleted'
                completed={completedNames}
              />
            </PieTooltipWrapper>
          ) : null}
        </div>
      </ClickAwayListener>

      <Stack className='chart-legend' direction='row' gap={8} mt={3}>
        <Box>
          <ClickAwayListener onClickAway={handleIncompletedTooltipClose}>
            <div>
              <LegendTooltip
                title={
                  <TooltipContent
                    incompleted={incompletedNames}
                    modalTitle={speechName}
                    type='incompleted'
                    completed={completedNames}
                  />
                }
                open={openIncompletedTooltip}
                onClose={handleIncompletedTooltipClose}
                disableHoverListener
                disableFocusListener
                disableTouchListener
                PopperProps={{
                  disablePortal: true,
                  style: {
                    zIndex: 1199,
                  },
                }}
                TransitionProps={{ timeout: 0 }}
                placement='top-end'
                arrow
              >
                <Typography className='incomplete-legend' variant='h5'>
                  <Button variant='text' onClick={handleIncompletedTooltipOpen}>
                    Incomplete
                  </Button>
                </Typography>
              </LegendTooltip>
            </div>
          </ClickAwayListener>
        </Box>
        <Box>
          <ClickAwayListener onClickAway={handleCompletedTooltipClose}>
            <div>
              <LegendTooltip
                title={
                  <TooltipContent
                    completed={completedNames}
                    modalTitle={speechName}
                    type='completed'
                    incompleted={incompletedNames}
                  />
                }
                open={openCompletedTooltip}
                onClose={handleCompletedTooltipClose}
                disableHoverListener
                disableFocusListener
                disableTouchListener
                PopperProps={{
                  disablePortal: true,
                  style: {
                    zIndex: 1199,
                  },
                }}
                TransitionProps={{ timeout: 0 }}
                placement='top-end'
                arrow
              >
                <Typography className='complete-legend' variant='h5'>
                  <Button variant='text' onClick={handleCompletedTooltipOpen}>
                    Completed
                  </Button>
                </Typography>
              </LegendTooltip>
            </div>
          </ClickAwayListener>
        </Box>
      </Stack>
    </CompletionProgressCard>
  );
};

export default CompletionProgress;
