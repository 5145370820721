import baseApiSlice from 'api/baseApiSlice';

const GLOBAL_SEARCH_API_URL = '/api/search';

const globalSearchApiWithTags = baseApiSlice.enhanceEndpoints({
  addTagTypes: ['globalSeach'],
});

const globalSearchApiSlice = globalSearchApiWithTags.injectEndpoints({
  endpoints: builder => ({
    getGlobalSearchItems: builder.query({
      query: query => ({
        url: `${GLOBAL_SEARCH_API_URL}/?search=${query}&page_size=5`,
      }),
    }),
  }),
});

export const { useGetGlobalSearchItemsQuery } = globalSearchApiSlice;

export default globalSearchApiSlice;
