import ReactDOM from 'react-dom';
import App from 'App';
import reportWebVitals from './reportWebVitals';
import {
  APP_INIT_ERROR,
  APP_READY,
  subscribe,
  initialize,
  mergeConfig,
  // @ts-ignore
} from '@edx/frontend-platform';
// @ts-ignore
import { AppProvider, ErrorPage } from '@edx/frontend-platform/react';
import { store } from 'store';

subscribe(APP_READY, () => {
  ReactDOM.render(
    <AppProvider store={store}>
      <App />
    </AppProvider>,
    document.getElementById('root'),
  );
});

subscribe(APP_INIT_ERROR, (error: { message: any }) => {
  ReactDOM.render(
    <ErrorPage message={error.message} />,
    document.getElementById('root'),
  );
});

initialize({
  handlers: {
    config: () => {
      mergeConfig(
        {
          ACCESS_TOKEN_COOKIE_NAME:
            process.env.REACT_APP_ACCESS_TOKEN_COOKIE_NAME || null,
          BASE_URL: process.env.REACT_APP_DASHBOARD_BASE_URL || null,
          CSRF_TOKEN_API_PATH:
            process.env.REACT_APP_CSRF_TOKEN_API_PATH || null,
          LMS_BASE_URL: process.env.REACT_APP_LMS_BASE_URL || null,
          LOGIN_URL: process.env.REACT_APP_LOGIN_URL || null,
          LOGOUT_URL: process.env.REACT_APP_LOGOUT_URL || null,
          PORT: process.env.REACT_APP_PORT || null,
          REFRESH_ACCESS_TOKEN_ENDPOINT:
            process.env.REACT_APP_REFRESH_ACCESS_TOKEN_ENDPOINT || null,
          SITE_NAME: process.env.REACT_APP_SITE_NAME || null,
          STUDIO_BASE_URL: process.env.REACT_APP_STUDIO_BASE_URL || null,
          LIBRARY_BASE_URL: process.env.REACT_APP_LIBRARY_BASE_URL || null,
          USER_INFO_COOKIE_NAME:
            process.env.REACT_APP_USER_INFO_COOKIE_NAME || null,
          SESSION_COOKIE_DOMAIN:
            process.env.REACT_APP_SESSION_COOKIE_DOMAIN || null,
          PANEL_BASE_URL: process.env.REACT_APP_PANEL_URL || null,
          TOASTMASTER_PATHWAYS_LOGIN_URL:
            process.env.REACT_APP_TOASTMASTER_PATHWAYS_LOGIN_URL || null,
          TOASTMASTER_SPEECHCRAFT_LOGIN_URL:
            process.env.REACT_APP_TOASTMASTER_SPEECHCRAFT_LOGIN_URL || null,
          PATHWAYS_BASE_URL: process.env.REACT_APP_PATHWAYS_BASE_URL || null,
          SPEECHCRAFT_BASE_URL: process.env.REACT_APP_SPEECHCRAFT_BASE_URL || null,
        },
        'ToastmasterLMSAppConfig',
      );
    },
  },
  messages: [],
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
