import { FC, useState } from 'react';
import { TextField, MenuItem, Box, Typography } from '@mui/material';
import {
  StyledAutocomplete,
  StyledLabel,
  StyledPopperOptions,
  OptionTitle,
} from 'elements/AutoComplete/style';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import { isEmpty } from 'lodash';

const AutoComplete: FC<any> = ({
  options,
  placeholder,
  label,
  selectedOptions,
  defaultValue,
  error,
  helperText,
  props,
  disableCloseOnSelect = false,
  isSpeechValition = false,
  multiline = false,
  disabled= false,
  name='autocomplete',
  id='autocomplete'
}) => {
  const [hasData, setHasData] = useState([]);
  return (
    <StyledAutocomplete
      disabled={disabled}
      id={id}
      name={name}
      popupIcon={<KeyboardArrowDownIcon />}
      options={options}
      disableCloseOnSelect={disableCloseOnSelect}
      value={defaultValue}
      PopperComponent={StyledPopperOptions}
      className={!isEmpty(hasData) || defaultValue ? 'has-data' : ''}
      ChipProps={{ deleteIcon: <CloseIcon /> }}
      getOptionLabel={(option: any) =>
        isSpeechValition
          ? option.name?.split('#')?.[0] || placeholder
          : option.name || placeholder
      }
      isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
      onChange={(event, value: any) => {
        selectedOptions(value);
        setHasData(value);
      }}
      renderOption={(props, option: any) => {
        const [title, description] = option.name?.split('#') || [];

        return (
          <MenuItem {...props}>
            {isSpeechValition ? (
              <Box sx={{ padding: '15px 0' }}>
                <OptionTitle>{title}</OptionTitle>
                <Typography variant='body1'>{description}</Typography>
              </Box>
            ) : (
              option.name
            )}
          </MenuItem>
        );
      }}
      renderInput={params => {
        return (
          <StyledLabel
            label={label}
            value=''
            labelPlacement='top'
            control={
              <TextField
                {...params}
                placeholder={placeholder}
                name={label}
                error={error}
                helperText={helperText}
                multiline={multiline}
                fullWidth
              />
            }
          />
        );
      }}
      {...props}
    />
  );
};

export default AutoComplete;
