import { styled } from '@mui/material';

export const NavBreadCrumbs = styled('ul')`
  padding: 0;
  margin: 10px 0 20px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  & li {
    padding: 2px 10px 2px 0;
    font-weight: 700;
    font-size: 14px;

    &:last-child {
      padding-right: 0;
    }

    & a {
      color: ${({ theme }) => theme.palette.secondary.main};
    }
  }
`;
