import { Box, styled, AppBar as MuiAppBar, Toolbar as MuiToolbar, Menu, alpha } from '@mui/material';
import { DeviceDown, DeviceUp } from 'styles/breakpoints';

export const AppBar = styled(MuiAppBar)`
  box-shadow: none;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.palette.ternary.main};

  @media ${DeviceUp.sm} {
    position: fixed;
  }
`;

export const Toolbar = styled(MuiToolbar)`
  display: flex;
  align-items: center; 
  justify-content: space-between;

  @media ${DeviceDown.sm} {
    flex-wrap: wrap;
  }
`;

export const LogoWrapper = styled('div')`
  width: 100px;
  margin: 5px 0;

  @media ${DeviceUp.xs} {
    width: 190px;
  }

  & img {
    max-width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
`;

export const HeaderLeft = styled('div')`
  display: flex;
  align-items: center;
`;

export const SkipNav = styled('a')`
  display: inline-block;
  position: absolute;
  z-index: 999;
  left: 0;
  right: 0;
  margin: auto;
  top: -600px;
  overflow: hidden;
  background: ${({ theme }) => theme.palette.ternary.white};
  border-bottom: 1px solid ${({ theme }) => theme.palette.ternary.white};
  padding: 15px 0;

  &:focus,
  &:active {
    top: 7px;
    width: 170px;
    background-color: ${({ theme }) => theme.palette.ternary.greyShade};
    opacity: 0.9;
    color: ${({ theme }) => theme.palette.ternary.white};
    text-decoration: none;
    text-align: center;
  }
`;
