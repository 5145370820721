import { styled } from '@mui/material';

export const FooterWrapper = styled('footer')`
  padding: 15px 0;
  text-align: center;
  border-top: 1px solid ${({ theme }) => theme.palette.ternary.main};

  p {
    margin: 0;
  }
`;