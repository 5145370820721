import { FunctionComponent } from 'react';

import Loader from 'elements/Loader';

const FormStep: FunctionComponent<any> = ({
  children,
  isLoading = false,
  loadingMessage = '',
}) => {
  return isLoading ? <Loader message={loadingMessage} /> : <>{children}</>;
};

export default FormStep;
