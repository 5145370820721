import { createSelector } from 'reselect';

const stateSelector = (state: any) => state.modals;

export const getUpdateSpeechHistorySelector = createSelector(
  stateSelector,
  modals => modals.updateHistorySpeech,
);

export const getConfirmationSelector = createSelector(
  stateSelector,
  modals => ({
    status: modals.confirmation.status,
    data: modals.confirmation.payload.data,
  }),
);
