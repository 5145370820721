import { FunctionComponent } from 'react';
import { Typography } from '@mui/material';
import { isEmpty } from 'lodash';

const EmptyStateMessage: FunctionComponent<any> = ({
  selectedClub = true,
  isSuccess,
  data,
  isError,
}) => {
  const message = isError
    ? 'Oops! Something went wrong.'
    : !selectedClub && !isSuccess
    ? `Please select a club from the dropdown menu above.`
    : isSuccess && isEmpty(data) && selectedClub
    ? `No records found!`
    : '';

  const textAlign = !selectedClub && !isSuccess ? 'left' : 'center';
  const variant = !selectedClub && !isSuccess ? 'body2' : 'h3';

  return (
    <>
      {' '}
      {message && (
        <Typography variant={variant} textAlign={textAlign}>
          {message}
        </Typography>
      )}
    </>
  );
};

export default EmptyStateMessage;
