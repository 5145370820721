import { Box, Typography, Stack } from '@mui/material';
import { useFormik } from 'formik';
import dayjs, { Dayjs } from 'dayjs';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { FieldWrapper } from 'elements/Form/style';
import { StyledLabel as FormControlLabel } from 'elements/FormControlLabel/style';
import DatePicker from 'elements/DatePicker';
import { parseDateString } from 'pages/SpeechValidation/validationScheema';
import AutoComplete from 'elements/AutoComplete';
import Button from 'elements/Button';
import { useCreateMeetingLogMutation } from 'api/profileApi';
import { parseDate } from 'utils/utility';
import useScrollToError from 'hooks/useScrolltoError';

const validationSchema = Yup.object({
  role: Yup.object()
    .shape({
      id: Yup.number().required('Field is required'),
      name: Yup.string().required('Field is required'),
    })
    .nullable()
    .required('Field is required'),
  date: Yup.date()
    .required('Field is required')
    .transform(parseDateString)
    .typeError('Invalid date format. (MMMM DD, YYYY)'),
});

const CreateMeetingLog = ({
  meetingRoles = [],
  handleClose,
  isLoadingMeetingRoles,
}: any) => {
  const [createMeetingLog, { isLoading }] = useCreateMeetingLogMutation();

  const handleSubmit = async (values: any) => {
    try {
      await createMeetingLog({
        meetingRole: values.role.id,
        date: parseDate(values.date).toISOString(),
      }).unwrap();
      toast.success('Meeting role added');
      handleClose();
    } catch (err: any) {
      toast.error(
        err?.data === 'The current record already exists.'
          ? err?.data
          : 'Something went wrong, please try again later!',
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      role: null,
      date: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleChangeDate = (newValue: Dayjs | null) => {
    formik.setFieldValue(
      'date',
      newValue ? dayjs(newValue).format('YYYY-MM-DD') : null,
    );
    formik.setFieldTouched('date', true, false);
  };

  const handleRoleChange = (role: any) => {
    formik.setFieldValue('role', role);
    formik.setFieldTouched('role', true, false);
  };

  useScrollToError(formik);

  return (
    <Box>
      <Typography variant='body1' lineHeight={1.9}>
        Add dates for meeting roles in which you have served below. Your club
        officers may use the information provided here to confirm you have
        satisfied meeting role requirements.
      </Typography>

      <Box mt={5}>
        <form onSubmit={formik.handleSubmit}>
          <FieldWrapper mb={4}>
            <FormControlLabel
              label='Role*'
              labelPlacement='top'
              control={
                <AutoComplete
                  id='role'
                  options={meetingRoles}
                  loading={isLoadingMeetingRoles}
                  loadingText='Loading Meeting Roles'
                  getOptionLabel={(option: any) => option.name}
                  defaultValue={formik.values.role}
                  selectedOptions={handleRoleChange}
                  placeholder='Select Role'
                  name='role'
                  helperText={formik.touched['role'] && formik.errors['role']}
                  error={!!formik.touched['role'] && !!formik.errors['role']}
                />
              }
            />
          </FieldWrapper>

          <FieldWrapper>
            <FormControlLabel
              label='Date*'
              labelPlacement='top'
              control={
                <DatePicker
                  id="date"
                  label='Date'
                  value={formik.values.date}
                  handleChange={handleChangeDate}
                  name='date'
                  placeholder='Select Date'
                  className={`primary-color-icon ${
                    formik.values.date ? 'has-data' : ''
                  }`}
                  helperText={formik.touched['date'] && formik.errors['date']}
                  error={!!formik.touched['date'] && !!formik.errors['date']}
                />
              }
            />
          </FieldWrapper>

          <Stack direction='row' gap={5} mt={13} justifyContent='center'>
            <Button
              onClick={handleClose}
              variant='outlined'
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button onClick={() => {}} type='submit' disabled={isLoading}>
              Save 
            </Button>
          </Stack>
        </form>
      </Box>
    </Box>
  );
};

export default CreateMeetingLog;
