import baseApiSlice from 'api/baseApiSlice';

const BASE_URL = '/api/self_assessment';

const selfAssessmentApiWithTags = baseApiSlice.enhanceEndpoints({
  addTagTypes: ['self-assessments'],
});

const selfAssessmentApi = selfAssessmentApiWithTags.injectEndpoints({
  endpoints: builder => ({
    getAssessmentForms: builder.query({
      query: ({ eventId = '' }) => ({
        url: `${BASE_URL}/form/?${eventId ? `event=${eventId}` : ''}`,
      }),
      providesTags: [{ type: 'self-assessments', id: 'FORM_LIST' }],
    }),

    getAssessmentFormQuestions: builder.query({
      query: ({ formId, eventId, progress, username = '' }: any) => ({
        url: `${BASE_URL}/form/${formId}/?event=${eventId}&username=${username}&progress=${progress}`,
      }),
      providesTags: [{ type: 'self-assessments', id: 'QUESTION_LIST' }],
    }),

    submitAssessmentForm: builder.mutation({
      query: data => ({
        url: `${BASE_URL}/response/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        { type: 'self-assessments', id: 'QUESTION_LIST' },
        { type: 'self-assessments', id: 'RESULT_LIST' },
        { type: 'self-assessments', id: 'FORM_LIST' },
      ],
    }),

    getAssessmentResults: builder.query({
      query: ({ formId, eventId, user = '' }) => ({
        url: `${BASE_URL}/form/${formId}/${eventId}/`,
        params: {
          ...(user && {
            user,
          }),
        },
      }),
      providesTags: [{ type: 'self-assessments', id: 'RESULT_LIST' }],
    }),

    getAverageAssessmentResults: builder.query({
      query: ({ formId }) => ({
        url: `${BASE_URL}/form/${formId}/average/`,
      }),
    }),
  }),
});

export const {
  useGetAssessmentFormsQuery,
  useGetAssessmentFormQuestionsQuery,
  useSubmitAssessmentFormMutation,
  useGetAssessmentResultsQuery,
  useGetAverageAssessmentResultsQuery,
} = selfAssessmentApi;

export default selfAssessmentApi;
