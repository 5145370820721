import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosClient from 'utils/client';
import { get, uniqBy } from 'lodash';
import querystring from 'querystring';

import { TOASTMASTER_API_URL, LMS_BASE_URL } from 'config';

const evaluationFormsAPI = {
  async fetchEvaluationForms({ page_no = 1, page }: any) {
    const params = {
      ...(page && {
        page: page,
      }),
    };

    let qs = querystring.stringify(params);
    if (qs) {
      qs = `?${qs}`;
    }

    const result = await axiosClient({
      url: `${LMS_BASE_URL}${TOASTMASTER_API_URL}/evaluation_forms/form/${qs}`,
      method: 'GET',
    });
    return result;
  },
  async fetchReceivedEvaluationForms({ page_no = 1, page }: any) {
    const params = {
      ...(page && {
        page: page,
      }),
    };

    let qs = querystring.stringify(params);
    if (qs) {
      qs = `?${qs}`;
    }

    const result = await axiosClient({
      url: `${LMS_BASE_URL}${TOASTMASTER_API_URL}/evaluation_forms/response/responses_received/${qs}`,
      method: 'GET',
    });
    return result;
  },

  async postShareEvaluationForm(payload: any) {
    const result = await axiosClient({
      url: `${LMS_BASE_URL}${TOASTMASTER_API_URL}/evaluation_forms/evaluation_request/`,
      method: 'POST',
      data: payload,
    });
  
    return result;
  },
};

export const getEvaluationForms = createAsyncThunk(
  'evaluationForms/getEvaluationForms',
  async (props: any, thunkAPI) => {
    const response = await evaluationFormsAPI.fetchEvaluationForms(props);
    return response.data;
  },
);

export const getReceivedEvaluationForms = createAsyncThunk(
  'evaluationForms/getReceivedEvaluationForms',
  async (props: any, thunkAPI) => {
    const response = await evaluationFormsAPI.fetchReceivedEvaluationForms(
      props,
    );
    return response.data;
  },
);

export const shareEvaluationForm = createAsyncThunk(
  'evaluationForms/shareEvaluationForm',
  async (props: any, thunkAPI) => {
    const response = await evaluationFormsAPI.postShareEvaluationForm(
      props,
    );
    return response.data;
  },
);

const initialState: any = {
  evaluationForms: {
    data: [],
    isLoading: false,
    isError: false,
  },
  receivedEvaluationForms: {
    data: [],
    isLoading: false,
    isError: false,
  },
};
const slice = createSlice({
  name: 'evaluationForms',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getEvaluationForms.pending, state => {
        state.evaluationForms.isLoading = true;
        state.evaluationForms.isError = false;
      })
      .addCase(getEvaluationForms.rejected, state => {
        state.evaluationForms.isLoading = false;
        state.evaluationForms.isError = true;
      })
      .addCase(getEvaluationForms.fulfilled, (state, action) => {
        state.evaluationForms.data = {
          next: get(action, 'payload.next', null),
          previous: get(action, 'payload.previous', null),
          count: get(action, 'payload.count', 0),
          results: uniqBy(
            [
              ...get(action, 'payload.results', []),
              ...get(state, 'evaluationForms.data.results', []),
            ],
            'id',
          ),
        };

        state.evaluationForms.isLoading = false;
        state.evaluationForms.isError = false;

        return state;
      })
      .addCase(getReceivedEvaluationForms.pending, state => {
        state.receivedEvaluationForms.isLoading = true;
        state.receivedEvaluationForms.isError = false;
      })
      .addCase(getReceivedEvaluationForms.rejected, state => {
        state.receivedEvaluationForms.isLoading = false;
        state.receivedEvaluationForms.isError = true;
      })
      .addCase(getReceivedEvaluationForms.fulfilled, (state, action) => {
        state.receivedEvaluationForms.data = action.payload;
        state.receivedEvaluationForms.isLoading = false;
        state.receivedEvaluationForms.isError = false;

        return state;
      });
  },
});

export const {} = slice.actions;

export const { reducer } = slice;
