import { Box, Paper, styled, Stack } from '@mui/material';

export const HeadingWrapper = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  svg {
    margin-right: 13px;
  }
`;

export const InfoSection = styled(Paper)`
  padding: 24px 20px 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 6px rgba(0, 0, 41, 0.12);

  .MuiChip-root {
    background: ${({ theme }) => theme.palette.ternary.light};
    font-weight: 700;
    font-size: 14px;
    padding: 5px;
    min-height: 26px;

    .MuiChip-deleteIcon {
      margin-left: -2px;
      color: ${({ theme }) => theme.palette.ternary.greyShade};
    }
  }
`;

export const SocialMediaIcons = styled(Stack)`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  a {
    display: inline-flex;
    align-items: center;
    gap: 12px;

    .MuiTypography-root {
      font-weight: 500;
    }
  }

  svg {
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    width: 20px;
  }
`;
