import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get, uniqBy, map } from 'lodash';
import querystring from 'querystring';

import { LMS_BASE_URL } from 'config';
import axiosClient from 'utils/client';

const initialState = {
  data: {},
  isLoading: false,
  isError: false,
};

const notificationsAPI = {
  async fetchUnReadNotificationsCount() {
    const result = await axiosClient({
      url: `${LMS_BASE_URL}/api/ti/notifications/unread_count/`,
      method: 'GET',
    });
    return result;
  },

  async fetchAllNotifications({ page = 1, filter, signal }: any) {
    const params = {
      ...(page && {
        page: page,
      }),
      ...(filter && {
        filter,
      }),
    };

    let qs = querystring.stringify(params);
    if (qs) {
      qs = `?${qs}&&page_size=10`;
    }

    const result = await axiosClient({
      url: `${LMS_BASE_URL}/api/ti/notifications/all/${qs}`,
      method: 'GET',
      signal: signal,
    });
    return result;
  },

  async markAllAsRead() {
    try {
      const result = await axiosClient({
        url: `${LMS_BASE_URL}/api/ti/notifications/unread_list/?mark_all_read=true`,
        method: 'GET',
      });

      return result;
    } catch (error: any) {
      throw error;
    }
  },
};

export const getAllNotifications = createAsyncThunk(
  'notifications/getAllNotifications',
  async (props: any) => {
    const response = await notificationsAPI.fetchAllNotifications(props);
    return response.data;
  },
);

export const markAllAsRead = createAsyncThunk(
  'notifications/markAllAsRead',
  async (props: any, { rejectWithValue }) => {
    try {
      const response = await notificationsAPI.markAllAsRead();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response);
    }
  },
);

const slice = createSlice({
  name: 'notifications',
  initialState: initialState,
  reducers: {
    resetNotifications: () => {
      return initialState;
    },
    markNotificationRead: (state, { payload }) => {
      state.data = {
        ...state.data,
        results: map(
          get(state, 'data.results', []),
          (notification: any) => {
            if (notification.slug === payload) {
              return { ...notification, unread: false };
            }
            return notification;
          },
        ),
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAllNotifications.fulfilled, (state, action: any) => {
        const shouldResetState = get(action, 'meta.arg.resetData', false);

        state.data = {
          next: get(action, 'payload.next', null),
          previous: get(action, 'payload.previous', null),
          count: get(action, 'payload.count', 0),
          results: shouldResetState
            ? get(action, 'payload.results', [])
            : uniqBy(
                [
                  ...get(state, 'data.results', []),
                  ...get(action, 'payload.results', []),
                ],
                'slug',
              ),
        };
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(getAllNotifications.pending, state => {
        state.isLoading = true;
      })
      .addCase(getAllNotifications.rejected, state => {
        state.isError = true;
      })
      .addCase(markAllAsRead.fulfilled, state => {
        state.data = {
          ...state.data,
          results: map(get(state, 'data.results', []), (notification: any) => {
            return { ...notification, unread: false };
          }),
        };
      });
  },
});

export const { resetNotifications, markNotificationRead } = slice.actions;

export default slice.reducer;
