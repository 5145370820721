import { Box, styled } from '@mui/material';

export const Container = styled(Box)`
  max-width: 1110px;
  width: 100%;
  padding: 0 15px;
  margin: 30px auto 0;

  & h2 {
    margin-bottom: 10px;
  }

  & .infinite-scroll-component {
    padding: 10px 5px;
  }
`;
