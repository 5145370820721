import { useDispatch, useSelector } from 'react-redux';
import compose from 'compose-function';
import { toast } from 'react-toastify';
import { useHistory, generatePath } from 'react-router-dom';

import Confirmation from 'components/Confirmation';
import { HOC } from 'elements/Modal';
import { useReviewRequestMutation } from 'api/reviewRequestsApi';
import { APPROVAL_REQUEST_STATUS, REQUEST_STATUS } from 'config';

import { selectReviewReqeust, resetRequestReview } from './ducks/approvalSlice';
import { reserRequests, getAllRequests } from './ducks/slice';
import { DashboardRoutes as ROUTES } from 'routes';

const ConfirmationModal = compose(HOC)(Confirmation);

const ConfirmRequest = (props: any) => {
  const { request, comment, denyReason, isApproving, confirmModalOpen } =
    useSelector(selectReviewReqeust);
  const dispatch = useDispatch();
  const history = useHistory();

  const { type, levelNumber, courseDisplayName, requesterName } = request;

  const [reviewRequest] = useReviewRequestMutation();

  const handleClose = () => dispatch(resetRequestReview());

  const handleConfirmation = async () => {
    let data: any = {
      status: APPROVAL_REQUEST_STATUS.Approved,
      comment,
    };

    if (!isApproving) {
      data = {
        ...data,
        status: APPROVAL_REQUEST_STATUS.Denied,
        reason: denyReason,
      };
    }

    const isLevelApproval = type.toLowerCase() === 'level';
    const successMessage = `${
      isLevelApproval
        ? `Level ${levelNumber}`
        : `${courseDisplayName} path completion`
    } has been ${isApproving ? 'approved' : 'denied'} for ${requesterName}`;

    try {
      const resp = await reviewRequest({
        requestId: request.id,
        data,
      }).unwrap();
      if (resp?.status === REQUEST_STATUS.PENDING) {
        toast.error(resp.detail);
      } else {
        toast.success(successMessage);
      }

      dispatch(resetRequestReview());
      if (props.resetParams) {
        dispatch(reserRequests());
        dispatch(getAllRequests(props.resetParams));
      } else {
        history.push(
          generatePath(ROUTES.BCM_DASHBOARD.path, {
            activeOption: 'approvals',
          }),
        );
      }
    } catch (error) {
      toast.error('Oops, an error occurred. Please try again later.');
    }
  };

  return confirmModalOpen ? (
    <ConfirmationModal
      title='Confirmation'
      handleClose={handleClose}
      handleConfirmation={handleConfirmation}
    >
      {`Do you really want to ${isApproving ? 'approve' : 'deny'} the ${
        request.type
      } request?`}
    </ConfirmationModal>
  ) : null;
};

export default ConfirmRequest;
