import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import { LMS_BASE_URL } from 'config';
import axiosClient from 'utils/client';

const SEARCH_API_URL = `${LMS_BASE_URL}/api/search`;

const searchResourcesAdapter = createEntityAdapter({
  selectId: (a: any) => a.id,
});

const initialState = searchResourcesAdapter.getInitialState({
  isFetching: false,
  isSuccess: false,
  isError: false,
  error: null as any,
  hasMore: true,
  page: 0,
  total: 0,
});

export const fetchSearchResources = createAsyncThunk(
  'globalSearchResources/list',
  async (query: string, thunkApi: any) => {
    const page = thunkApi.getState().globalSearchResources.page;
    const nextPage = page + 1;
    const res = await axiosClient.get(
      `${SEARCH_API_URL}/?search=${query}&resource_page=${nextPage}&page_size=15`,
    );
    return res.data;
  },
  {
    condition: (_, thunkApi: any) => {
      const { isFetching, hasMore } = thunkApi.getState().globalSearchResources;
      if (isFetching || !hasMore) return false;
    },
  },
);

const globalSearchResourcesSlice = createSlice({
  name: 'globalSearchResources',
  initialState,

  reducers: {
    resetGlobalSearchResources: () => initialState,
    addSearchResources: (state, action) => {
      const { count = 0, results = [] } = action.payload || {};
      state.total = count;
      searchResourcesAdapter.upsertMany(state, results);
    },
  },

  extraReducers: builder => {
    builder
      .addCase(fetchSearchResources.pending, (state, action) => {
        state.isFetching = true;
        state.isSuccess = false;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchSearchResources.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;

        const resources = action.payload['resources'];
        const { count, next, results } = resources;
        state.hasMore = !!next;
        state.page += 1;
        state.total = count;

        searchResourcesAdapter.upsertMany(state, results);
      })
      .addCase(fetchSearchResources.rejected, (state, action) => {
        state.isFetching = false;
        state.isSuccess = false;
        state.isError = true;
        state.error = action.payload;
      });
  },
});

export const { resetGlobalSearchResources, addSearchResources } =
  globalSearchResourcesSlice.actions;

export default globalSearchResourcesSlice.reducer;

export const selectGlobalSearchResources = (state: any) =>
  state.globalSearchResources;

export const { selectAll: selectAllSearchResources } =
  searchResourcesAdapter.getSelectors(selectGlobalSearchResources);
