import { styled } from "@mui/material";
import { theme } from 'styles/theme';
import levelBg from '../../assets/images/level-bg.svg';
import { DeviceUp } from "styles/breakpoints";

export const SharedContentWrapper = styled('div')`
    max-width: 1080px;
    width: 100%;
    background: rgb(245,245,245);
    background: linear-gradient(180deg, rgba(245,245,245,1) 0%, rgba(255,255,255,1) 100%);
    margin: 20px auto;
    padding: 20px 15px;

    @media ${DeviceUp.sm} {
        margin: 40px auto;
        padding: 30px;
    }

    & .MuiAvatar-root {
        width: 88px;
        height: 88px;
        border: 4px solid ${({ theme }) => theme.palette.ternary.main};
        margin-bottom: 15px;
    }

    & .MuiGrid-container {
        align-items: center;
        position: relative;
        padding: 20px 0;

        &::before {
            content: "";
            position: absolute;
            top: 0px;
            bottom: 0px;
            left: 0;
            right: 0;
            margin: auto;
            width: 1px;
            background: ${({ theme }) => theme.palette.ternary.main};
            z-index: 1;
            display: none;
            
            @media ${DeviceUp.md} {
                display: block;
            }
        }
    }

    & .earned-content {
        max-width: 400px;
        width: 94%;
        margin: 0 auto;
        text-align: center;
    }

    & .color-light {
        color: ${({ theme }) => theme.palette.ternary.dark};
    }
`;

export const LevelImage = styled('div')`
    background: url(${levelBg}) no-repeat center top;
    width: 246px;
    height: 280px;
    padding: 35px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .img-holder {
        width: 170px;
        height: 170px;
        object-fit: contain;
        border-radius: 100%;
        overflow: hidden;
        margin: 0 auto;
    }

    & img {
        width: 100%;
        height: 100%;
    }
`;

export const CourseTitle = styled('div')`
    display: flex;
    align-items: center;

    & img {
        min-width: 50px;
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 100%;
        margin-right: 15px;
        display: inline-block;
    }
`;