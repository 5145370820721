import { createApi } from '@reduxjs/toolkit/query/react';
import { LMS_BASE_URL } from 'config';
import { AxiosError } from 'axios';
import axiosClient from 'utils/client';

interface IConf {
  baseUrl: string;
  baseOptions?: any;
}

const axiosBaseQuery =
  ({ baseUrl, baseOptions }: IConf = { baseUrl: '', baseOptions: {} }) =>
  async ({ url, method, data, params, queryOptions }: any) => {
    try {
      const result = await axiosClient({
        url: new URL(url, baseUrl).href,
        method,
        data,
        params,
        ...baseOptions,
        ...queryOptions,
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status || err?.status,
          data: err.response?.data || err?.message || err,
        },
      };
    }
  };

const baseApiSlice = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: `${LMS_BASE_URL}` }),
  keepUnusedDataFor: 30,
  endpoints: builder => ({}),
});

export default baseApiSlice;
