import { styled, Box as CardWrapper, CardContent } from '@mui/material';
import {DeviceUp} from 'styles/breakpoints';
import {theme} from 'styles/theme';

export const Box = styled(CardWrapper)`
  text-align: center;

  & h3 {
    margin-top: 30px;
    margin-bottom: 12px;
    font-weight: 700;

    @media ${DeviceUp.md} {
      font-size: 1.5em;
    }
  }

  & .MuiTypography-body1 {
    max-width: 390px;
    margin: auto;

    @media ${DeviceUp.md} {
      font-size: 1.25em;
      line-height: 28px;
    }

    & span {
      font-weight: 700;
      overflow-wrap: break-word;
    }
  }

  & .flexwrap {
    flex-wrap: wrap;
  }

  & .MuiChip-root {
    margin: 5px 5px 0 0;
  }
`;

export const BoxContent = styled(CardContent)`
  padding:0;
`;

export const CTAButtons = styled(Box)`
    margin-top: 60px;

    & .MuiButton-root {
        padding: 6px;

        &:not(:first-of-type) {
            margin-left: 5px;
        }
    }
`;

export const ImageHolder = styled('div')`
    margin: 20px auto 30px;
    max-width: 160px;

    & img {
        object-fit: contain;
        width: 100%;
    }
`;
