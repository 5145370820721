import { Box, styled, List, ListItem, Paper } from '@mui/material';
import { theme } from 'styles/theme';
import { DeviceUp, DeviceDown } from 'styles/breakpoints';

export const Container = styled(Box)`
  max-width: 1110px;
  width: 100%;
  padding: 0 15px;
  margin: 30px auto 0;

  & h2 {
    margin-bottom: 10px;
  }

  & .infinite-scroll-component {
    padding: 10px 5px;
  }
`;

export const NavBreadCrumbs = styled('ul')`
  padding: 0;
  margin: 10px 0 20px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  & li {
    padding: 2px 10px 2px 0;
    font-weight: 700;
    font-size: 14px;

    &:last-child {
      padding-right: 0;
    }

    & a {
      color: ${({ theme }) => theme.palette.secondary.main};
    }
  }
`;

export const RequestList = styled(List)`
  position: relative;
  padding: 0px 10px;

  @media ${DeviceUp.md} {
    padding: 0px 40px;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    border-bottom: 1px solid ${({ theme }) => theme.palette.ternary.main};
    left: 10px;
    right: 10px;

    @media ${DeviceUp.md} {
      left: 40px;
      right: 40px;
    }
  }

  &:hover {
    background: ${({ theme }) => theme.palette.ternary.light};

    &::after {
      left: 0;
      right: 0;
    }
  }

  .MuiAvatar-root {
    height: 60px;
    width: 60px;
    margin-right: 10px;
    background: ${({ theme }) => theme.palette.ternary.greyShade};
  }

  button {
    min-width: 90px;
  }
`;

export const RequestListItem = styled(ListItem)`
  justify-content: center;
  align-items: center;
  padding: 30px 0px;

  @media ${DeviceDown.xs} {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .MuiListItemText-root {
    @media ${DeviceDown.xs} {
      order: 3;
      padding: 5px 0 0;
    }
  }
`;

export const FilterWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  @media ${DeviceUp.sm} {
    flex-wrap: nowrap;
  }
`;

export const SortFieldWrapper = styled(Box)`
  width: 285px;
  padding-left: 15px;
  margin-left: auto;
  margin-bottom: 15px;

  & .MuiTypography-root {
    white-space: nowrap;
    margin-right: 5px;
  }

  & .MuiFormControl-root {
    height: 40px;
  }
`;

export const CustomPaper = styled(Paper)`
  box-shadow: 0px 2px 10px rgba(0, 0, 41, 0.1);

  .infinite-scroll-component {
    // padding: 5px 0;
  }
`;

export const ApproveInstructionalText = styled(Box)`
  margin: 0px 34px;
  text-align: center;
`;
