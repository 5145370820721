import * as Yup from 'yup';

import { parseDateString } from 'pages/SpeechValidation/validationScheema';

export const generalInformationScheema = {
  issueDate: Yup.date()
    .max(new Date(), 'Date cannot be in the future')
    .required('Field is required')
    .transform(parseDateString)
    .typeError('Invalid date format. (MMMM DD, YYYY)'),
};

export const recipientsScheema = {
  attendees: Yup.array()
    .of(Yup.object())
    .min(1, 'Select at least one recipient')
    .required('Field is required'),
};

export const confirmationScheema = {
  coordinatorCheck1: Yup.boolean().oneOf([true], 'Field is required'),
  coordinatorCheck2: Yup.boolean().oneOf([true], 'Field is required'),
  coordinatorCheck3: Yup.boolean().oneOf([true], 'Field is required'),
};
