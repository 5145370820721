import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Stack, Typography } from '@mui/material';
import { toast } from 'react-toastify';

import DropZoneFile from 'elements/Dropzone';
import Loader from 'elements/Loader';
import { useCreateDocumentsMutation } from 'api/documentsApi';
import { resetDocuments, fetchDocuments } from './documentSlice';

const UploadFile = (props: any) => {
  const [uploadedFiles, setUploadedFiles] = useState<File[] | []>([]);
  const [apiController, setApiController] = useState<any>();

  const [createDocs, { isLoading }] = useCreateDocumentsMutation();

  const dispatch = useDispatch();

  const handleCreateDocs = async () => {
    const data = new FormData();
    uploadedFiles.forEach(file => data.append('file', file));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const controller = new AbortController();
    setApiController(controller);
    try {
      await createDocs({ data, signal: controller.signal }).unwrap();
      toast.success(
        `Your file${
          uploadedFiles.length > 1 ? 's have' : ' has'
        } been uploaded`,
      );
      dispatch(resetDocuments());
      dispatch(fetchDocuments());
      props.handleClose();
    } catch (error: any) {
      if (error?.data === 'canceled') {
        return;
      }

      toast.error(`Oops, an error occurred. Please try again later.`);
    }
  };

  return (
    <Box>
      <DropZoneFile
        acceptedFile={{
          'image/jpeg': [],
          'image/png': [],
          'image/svg+xml': [],
          'application/pdf': [],
          'application/msword': [],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            [],
          'video/mpeg': [],
          'video/wmv': [],
          'video/mov': [],
          'video/x-msvideo': [],
          'video/mp4': [],
          'video/quicktime': [],
          'video/3gpp': [],
          'video/x-flv': [],
          'video/x-ms-wmv': [],
          'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            [],
          'application/vnd.ms-powerpoint': [],
        }}
        hasData={!!uploadedFiles}
        onDrop={(acceptedFiles: any, e: any) => {
          const max = 5;
          if (
            uploadedFiles.length === max ||
            uploadedFiles.length + acceptedFiles.length > max
          ) {
            return;
          }
          setUploadedFiles(prev => [...acceptedFiles, ...prev]);
        }}
      >
        <Box className='file-upload file-upload-box'>
          {uploadedFiles.length > 0 ? (
            uploadedFiles.map((doc, i) => (
              <Typography variant='body1' key={`${doc.name}-${i}`}>{`${
                doc.name
              } - ${(doc.size / 1024 / 1024).toFixed(1)} MB`}</Typography>
            ))
          ) : (
            <Typography variant='body1'>{`Drag and Drop file(s) here or`}</Typography>
          )}
          <Button variant='contained' onClick={() => {}}>
            Browse
          </Button>
          <Typography variant='body2'>
            Maximum file size - 300 MB
          </Typography>
          <Typography variant='body2'>
            Maximum number of files - 5
          </Typography>

          {isLoading ? (
            <Box mt={2}>
              <Loader />
            </Box>
          ) : null}
        </Box>
      </DropZoneFile>

      <Stack direction='row' gap={1} mt={10} justifyContent='flex-end'>
        <Button
          variant='outlined'
          onClick={() => {
            apiController?.abort();
            props.handleClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          onClick={handleCreateDocs}
          disabled={uploadedFiles.length <= 0 || isLoading}
        >
          Upload
        </Button>
      </Stack>
    </Box>
  );
};

export default UploadFile;
