import React, { FC, useEffect } from 'react';
import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { slice } from 'lodash';

import { DEFAULT_PAGE_SIZE, COURSE_CATEGORY } from 'config';
import CourseSection from 'pages/Dashboard/components/CourseSection';
import Loader from 'elements/Loader';
import { useIsSpeechCraftMFE } from 'hooks';

import {
  getActiveCoursesSelector,
  getSuggestedCoursesSelector,
  getCompletedCoursesSelector,
} from 'pages/Dashboard/ducks/selectors';
import { getPlatformName } from 'utils/utility';
import {
  getActiveEnrolledCourses,
  getSuggestedCourses,
  getCompletedEnrolledCourses,
  setCourseRegistration,
  resetCourses,
} from 'pages/Dashboard/ducks/slice';

const params = { page: 1 };

const LearningPaths: FC<any> = ({
  transcriptTitle = '',
  showSpeechTranscript = false,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isSpeechCraftMFE = useIsSpeechCraftMFE();

  const { isLoading: isActiveCourseLoading, data: activeEnrolledCourses } =
    useSelector(getActiveCoursesSelector);

  const {
    isLoading: isSuggestedCoursesLoading,
    data: { results: suggestedCourses = [] } = {},
  } = useSelector(getSuggestedCoursesSelector);

  const {
    isLoading: isCompletedCoursesLoading,
    data: { results: completedEnrolledCourses = [] } = {},
  } = useSelector(getCompletedCoursesSelector);

  const handleActionButton = (courseCategory: string) => {
    history.push(`${getPlatformName()}/courseList/${courseCategory}`);
  };

  const handleCardRegistration = async (id: any, enrollmentAction: any) => {
    const params = { page: 1, resetInitialState: true };

    await dispatch(setCourseRegistration({ id, enrollmentAction }));

    dispatch(getActiveEnrolledCourses({}));
    dispatch(getSuggestedCourses(params));
  };

  useEffect(() => {
    dispatch(getActiveEnrolledCourses({}));
    if (!isSpeechCraftMFE) {
      dispatch(getSuggestedCourses(params));
      dispatch(getCompletedEnrolledCourses(params));
    }

    return () => {
      dispatch(resetCourses());
    };
  }, [dispatch, isSpeechCraftMFE]);

  return (
    <Box>
      {isActiveCourseLoading ? (
        <Loader position='relative' />
      ) : (
        <>
          <CourseSection
            sectionHeading='Active'
            showSlider={false}
            data={activeEnrolledCourses}
            handleCardRegistration={handleCardRegistration}
            showSpeechTranscript={showSpeechTranscript}
            transcriptTitle={transcriptTitle}
          />
          <CourseSection
            sectionHeading='Suggested Learning'
            actionButtonText='View All'
            handleActionButton={() =>
              handleActionButton(COURSE_CATEGORY.SUGGESTED)
            }
            data={slice(suggestedCourses, 0, DEFAULT_PAGE_SIZE)}
            handleCardRegistration={handleCardRegistration}
            showSpeechTranscript={showSpeechTranscript}
          />
          <CourseSection
            sectionHeading='Completed'
            actionButtonText='View All'
            handleActionButton={() =>
              handleActionButton(COURSE_CATEGORY.COMPLETED)
            }
            data={slice(completedEnrolledCourses, 0, DEFAULT_PAGE_SIZE)}
            handleCardRegistration={handleCardRegistration}
            showSpeechTranscript={showSpeechTranscript}
          />
        </>
      )}
    </Box>
  );
};

export default LearningPaths;
