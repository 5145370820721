import React, { FC, useEffect, useState } from 'react';
import { useClickAway, useKey, useEvent } from 'react-use';
import AccountCircle from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import logoWhite from 'assets/images/logoWhite.svg';
import {
  AppBar,
  Toolbar,
  LogoWrapper,
  HeaderLeft,
  HeaderRight,
  MenuDropdownHolder,
  SearchHolder,
  StyledSearchIcon,
  SkipNav,
} from 'components/Header/style';
import ListItemText from '@mui/material/ListItemText';
import { Link, useHistory, generatePath } from 'react-router-dom';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import MenuIcon from '@mui/icons-material/Menu';
// @ts-ignore
import { getConfig } from '@edx/frontend-platform';
import { isEmpty } from 'lodash';
import Cookies from 'universal-cookie';
import TextField from 'elements/Form/TextField';
import { DashboardRoutes as Routes } from 'routes';
import {
  REACT_APP_LOGO_URL,
  REACT_APP_PATHWAYS_LOGO_URL,
  REACT_APP_SPEECHCRAFT_LOGO_URL,
  TOASTMASTERS_WEBSITE_URL,
} from 'config';
import { useIsSpeechCraftMFE, useRoles } from 'hooks';

import NotificationsMenu from 'components/NotificationsMenu';
import { toast } from 'react-toastify';
import { getPlatformName, getUserName, parseJwt } from 'utils/utility';
import classNames from 'classnames';
import { ListItemButton } from '@mui/material';
import { useGetProfileDetailQuery } from 'api/profileApi';

import axiosClient from 'utils/client';
import { LMS_BASE_URL, TOASTMASTER_AUTH_URL, Levels } from 'config';
import Alert from 'elements/Alert';
import { useAppContext } from 'contexts/AppContext';
import { HijackingAlert } from 'pages/Dashboard/style';
import Button from 'elements/Button';

const handleReleaseHijackUser = async (setIsReleasingHijackUser: Function) => {
  setIsReleasingHijackUser(true);
  try {
    const { data } = await axiosClient({
      url: `${TOASTMASTER_AUTH_URL}`,
      method: 'GET',
    });

    await axiosClient({
      url: `${LMS_BASE_URL}/hijack/release/`,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      csrftoken: data?.csrfToken,
    } as any);

    toast.success(`You have successfully stopped Impersonating this user.`);
    window.location.replace(
      `${getConfig().PANEL_BASE_URL}/support/impersonate`,
    );
  } catch (error: any) {
    toast.error(
      `Oops! Something went wrong. Please try refreshing the page to see if that resolves the issue.`,
    );
  } finally {
    setIsReleasingHijackUser(false);
  }
};

const Header: FC<any> = ({ user = {} }) => {
  const { roles = [] } = user;

  const { isCoordinator, isBcm } = useRoles();
  const isSpeechCraftMFE = useIsSpeechCraftMFE();
  const { setIsReleasingHijackUser } = useAppContext();

  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [menuBarEl, setMenuBarEl] = useState(null);
  const [appMenuBarEl, setAppMenuBarEl] = useState(null);
  const [searchText, setSearchText] = useState('');

  const history = useHistory();

  const cookies = new Cookies();
  const jwtToken = cookies.get('edx-jwt-cookie-header-payload');
  const parsedJwtToken = parseJwt(jwtToken);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuBar = (event: any) => {
    setMenuBarEl(event.currentTarget);
  };

  const handleAppMenuBar = (event: any) => {
    setAppMenuBarEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuBarEl(null);
    setAppMenuBarEl(null);
  };

  const handleLogout = () => {
    const cookies = new Cookies();
    cookies.remove('csrftoken', { path: getConfig().LOGOUT_URL });
    window.location.replace(getConfig().LOGOUT_URL);
  };

  const handleTiRedirect = () => {
    window.location.href = `${TOASTMASTERS_WEBSITE_URL}myhome`;
  };

  const hadleUpdateTextSearch = (event: any) => {
    const { value } = event.target;
    setSearchText(value);
  };

  const handleSearch = () => {
    if (searchText) {
      history.push(`${Routes.SEARCH.path}?query=${searchText}`);
    } else {
      toast.info('Please enter a search query!');
    }
  };

  useClickAway(anchorEl, handleClose);
  useKey('Escape', handleClose);
  useEvent('blur', handleClose);

  const { data: userData, refetch } = useGetProfileDetailQuery(user);

  useEffect(() => {
    if (!userData) refetch();
  }, [refetch, userData]);

  const isLevelOneUser = isEmpty(roles) && !isEmpty(user);
  const isLevelFourUser = roles.includes(Levels.level4);
  const isLevelFiveUser = roles.includes(Levels.level5);
  const isLevelTwoUser = roles.includes(Levels.level2);
  const isLevelThreeUser = roles.includes(Levels.level3);

  const assetsManagementUrl = `${getConfig().PANEL_BASE_URL}${
    isLevelThreeUser
      ? '/support/impersonate'
      : isLevelTwoUser
      ? '/support/member-transcript'
      : ''
  }`;

  const hasLibraryOrStudioAccess =
    isLevelFourUser || isLevelFiveUser || user.administrator;
  return (
    <AppBar
      sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
      enableColorOnDark={true}
      color='transparent'
    >
      <SkipNav href='#main'>Skip to main content</SkipNav>
      <Toolbar>
        <HeaderLeft>
          <LogoWrapper>
            <a href={`${getConfig().BASE_URL}`}>
              <img
                src={
                  isSpeechCraftMFE
                    ? `${REACT_APP_SPEECHCRAFT_LOGO_URL}?ver=${Math.floor(Math.random() * 99)}`
                    : `${REACT_APP_PATHWAYS_LOGO_URL}?ver=${Math.floor(Math.random() * 99)}`
                }
                onError={({ currentTarget }: any) => {
                  currentTarget.onerror = null;
                  currentTarget.src = REACT_APP_LOGO_URL;
                }}
                alt={`Toastmasters ${isSpeechCraftMFE ? "Speechcraft" : "Pathways"} learning experience`}
              />
            </a>
          </LogoWrapper>
        </HeaderLeft>
        <SearchHolder role="search">
          <TextField
            placeholder='Search'
            fullWidth
            value={searchText}
            onChange={hadleUpdateTextSearch}
            onKeyPress={(event: any) => {
              if (event.key === 'Enter') handleSearch();
            }}
            className={searchText ? 'has-data' : ''}
            inputProps={{
              "aria-label": "Find in the app",
            }}
          />
          <StyledSearchIcon
            aria-label='Search'
            onClick={(): void => {
              handleSearch();
            }}
          />
        </SearchHolder>

        <HeaderRight>
          <div role='navigation' className='d-inline-flex d-md-none align-items-center'>
            {!isSpeechCraftMFE && (
              <a href={`${getConfig().LMS_BASE_URL}`}>
                <ListItemText primary='Learning Dashboard' />
              </a>
            )}
            {!isSpeechCraftMFE && isBcm && (
              <Link
                to={generatePath(Routes.BCM_DASHBOARD.path, {
                  activeOption: 'approvals',
                })}
              >
                <ListItemText primary='Base Camp Manager' />
              </Link>
            )}
            {isSpeechCraftMFE && isCoordinator && (
              <Link
                to={generatePath(Routes.SPEECH_DASHBOARD.path, {
                  activeOption: 'speech-completion',
                })}
              >
                <ListItemText primary='Dashboard' />
              </Link>
            )}
            {isSpeechCraftMFE && isCoordinator && (
              <Link to={generatePath(Routes.SPEECH_CERTIFICATES.path)}>
                <ListItemText primary='Certificates' />
              </Link>
            )}
            {isSpeechCraftMFE && isCoordinator && (
              <Link to={generatePath(Routes.SPEECH_VALIDATION.path)}>
                <ListItemText primary='Speech Validation' />
              </Link>
            )}
          </div>
          <IconButton
            aria-label='Navigation menu'
            aria-controls='nav-menubar'
            aria-haspopup='true'
            onClick={handleMenuBar}
            color='inherit'
            className={classNames('d-md-inline-flex d-none menu-btn', {
              active: Boolean(menuBarEl),
            })}
          >
            <MenuIcon />
          </IconButton>
          <MenuDropdownHolder
            keepMounted
            disablePortal
            id='nav-menubar'
            anchorEl={menuBarEl}
            open={Boolean(menuBarEl)}
            onClose={handleClose}
          >
            {!isSpeechCraftMFE && (
              <MenuItem component={'a'} href={`${getConfig().LMS_BASE_URL}`} onClick={handleClose}>
                Learning Dashboard
              </MenuItem>
            )}
            {!isSpeechCraftMFE && isBcm && (
              <MenuItem
                component={Link}
                to={generatePath(Routes.BCM_DASHBOARD.path, {
                  activeOption: 'approvals',
                })}
                onClick={handleClose}
              >
                Base Camp Manager
              </MenuItem>
            )}
            {isSpeechCraftMFE && isCoordinator && (
              <MenuItem
                component={Link}
                to={generatePath(Routes.SPEECH_DASHBOARD.path, {
                  activeOption: 'speech-completion',
                })}
                onClick={handleClose}
              >
                Dashboard
              </MenuItem>
            )}
            {isSpeechCraftMFE && isCoordinator && (
              <Link to={generatePath(Routes.SPEECH_CERTIFICATES.path)}>
                <ListItemText primary='Certificates' />
              </Link>
            )}
            {isSpeechCraftMFE && isCoordinator && (
              <MenuItem
                component={Link}
                to={generatePath(Routes.SPEECH_VALIDATION.path)}
                onClick={handleClose}
              >
                Speech Validation
              </MenuItem>
            )}
          </MenuDropdownHolder>

          {!isLevelOneUser && (
            <>
              <IconButton
                aria-label='App menu'
                aria-controls='nav-appmenubar'
                aria-haspopup='true'
                onClick={handleAppMenuBar}
                color='inherit'
                className={classNames('d-inline-flex menu-btn', {
                  active: Boolean(appMenuBarEl),
                })}
              >
                <span className='material-icons'>apps</span>
              </IconButton>
              <MenuDropdownHolder
                keepMounted
                disablePortal
                id='nav-appmenubar'
                anchorEl={appMenuBarEl}
                open={Boolean(appMenuBarEl)}
                onClose={handleClose}
              >
                {hasLibraryOrStudioAccess && (
                  <>
                    <ListItemButton href={`${getConfig().STUDIO_BASE_URL}`}>
                      Studio
                    </ListItemButton>

                    <ListItemButton href={`${getConfig().LIBRARY_BASE_URL}`}>
                      Library
                    </ListItemButton>
                  </>
                )}
                <ListItemButton href={assetsManagementUrl}>
                  Assets Management
                </ListItemButton>
              </MenuDropdownHolder>
            </>
          )}
          <NotificationsMenu />
          <IconButton
            className={classNames('userAccount', {
              active: Boolean(anchorEl),
            })}
            aria-label='account of current user'
            aria-controls='menu-appbar'
            aria-haspopup='true'
            onClick={handleMenu}
            color='inherit'
          >
            <label>{getUserName(userData?.user, true)}</label>
            <AccountCircle />
            <ArrowDropDownRoundedIcon />
          </IconButton>
          <MenuDropdownHolder
            keepMounted
            disablePortal
            id='menu-appbar'
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {/* <MenuItem component={Link} to={`${getPlatformName()}`} onClick={handleClose}>
              {isSpeechCraftMFE ? 'Home' : 'Home Page'}
            </MenuItem> */}
            <MenuItem
              component={Link}
              to={`${getPlatformName()}/profile/about`}
              onClick={handleClose}
            >
              Profile
            </MenuItem>
            {isSpeechCraftMFE && (
              <MenuItem component={Link} to={Routes.MY_COHORT.path} onClick={handleClose}>
                My Cohort
              </MenuItem>
            )}
            <MenuItem component={Link} to={generatePath(Routes.REQUESTS.path)} onClick={handleClose}>
              Requests
            </MenuItem>
            {!isSpeechCraftMFE && (
              <MenuItem component={Link} to={Routes.CLUBS.path} onClick={handleClose}>
                My Clubs
              </MenuItem>
            )}
            {!isSpeechCraftMFE && (
              <MenuItem component={"a"} href={`${TOASTMASTERS_WEBSITE_URL}myhome`} onClick={handleTiRedirect}>
                  Toastmasters Home
              </MenuItem>
            )}
            <MenuItem component={"a"} href={getConfig().LOGOUT_URL} onClick={handleLogout}>
                Sign Out
            </MenuItem>
          </MenuDropdownHolder>
        </HeaderRight>
      </Toolbar>
      {parsedJwtToken?.is_hijacked && (
        <div className='hijacking-alert-holder'>
          <HijackingAlert>
            <Alert
              type='info'
              action={
                <Button
                  color='secondary'
                  onClick={() => {
                    handleReleaseHijackUser(setIsReleasingHijackUser);
                  }}
                >
                  Exit
                </Button>
              }
            >
              <strong>
                You are now Impersonating another user. Please note that you
                will not be able to make any updates on behalf of this user.
              </strong>
            </Alert>
          </HijackingAlert>
        </div>
      )}
    </AppBar>
  );
};

export default Header;
