import { each, find, get, isObject, toLower } from 'lodash';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import { MFE_CONFIG } from 'config';
import Cookies from 'universal-cookie';

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'few seconds ago',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mnth',
    MM: '%dmnth',
    y: '1y',
    yy: '%dy',
  },
});

export const stringToColor = (string: string) => {
  let hash = 0;

  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash); // eslint-disable-line no-bitwise
  }

  const hue = hash % 360;
  return `hsl(${hue}, 30%, 60%)`;
};

export const getCookie = (name: any) => {
  // https://stackoverflow.com/questions/10730362/get-cookie-by-name?page=1&tab=scoredesc#tab-top
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts && parts.length === 2) {
    const data = parts.pop();
    if (data) return data.split(';').shift();
  }
  return null;
};

export const checkCookie = () => {
  let { cookie: cachedCookie } = document;

  return (callback: (arg0: any) => void, ...callbackParams: any) => {
    const { cookie } = document;

    if (cookie !== cachedCookie) {
      cachedCookie = cookie;

      if (callbackParams && typeof callbackParams === 'object') {
        callback({ ...callbackParams });
      }
    }
  };
};

export const getGreetings = () => {
  const greetings = [
    { hour: 17, greet: 'Good Evening' },
    { hour: 12, greet: 'Good Afternoon' },
    { hour: 5, greet: 'Good Morning' },
    { hour: 0, greet: 'Good Night' },
  ];

  const hour = new Date().getHours();

  for (let i = 0; i < greetings.length; i++) {
    const greeting = greetings[i];

    if (hour >= greeting.hour) {
      return greeting.greet;
    }
  }

  return '';
};

export const isValidInput = (str: string) =>
  /^[A-Za-z0-9-)(}{\[\]_.\s]*$/.test(str);

export const isExisted = (exitingItems: any, str: string) =>
  find(exitingItems, obj => toLower(str) === toLower(obj.name));
export const isExistedSubCategory = (
  exitingItems: any,
  str: string,
  parentCategory: any,
) =>
  find(
    exitingItems,
    obj =>
      toLower(str) === toLower(obj.name) && obj.category === parentCategory.id,
  );

export const getQueryParam = (rawUrl: string) =>
  rawUrl
    .substring(rawUrl.indexOf('?') + 1)
    .split('&')
    .reduce(
      (memo, param) => ({
        ...memo,
        [param.split('=')[0]]: param.split('=')[1],
      }),
      {},
    );

export const getNameFromUrl = (url: string) => url.split('/').pop();

export const getCategoryNameById = (categories: any, id: any) => {
  let name = '';
  each(categories, item => {
    if (item.id === id) return (name = item.name);
  });
  return name;
};

export const getFileExtension = (url: string) => url.split('.').pop();

export enum FileType {
  Unknown,
  Image,
  Video,
  Document,
}
export const getFileType = (fileName: string) => {
  const imageExtensions = ['jpeg', 'jpg', 'png', 'svg'];
  const documentExtensions = ['pdf', 'doc', 'docx', 'ppt', 'pptx'];
  const videoExtensions = ['avi', 'mp4', 'mpeg', '3gp', 'flv', 'mov', 'wmv'];

  const fileExtension = getFileExtension(fileName)?.toLocaleLowerCase();

  const fileType = {
    type: FileType.Unknown,
    extension: '',
  };

  if (!fileExtension) return fileType;

  fileType.extension = fileExtension;

  if (imageExtensions.includes(fileExtension)) {
    fileType.type = FileType.Image;
  } else if (documentExtensions.includes(fileExtension)) {
    fileType.type = FileType.Document;
  } else if (videoExtensions.includes(fileExtension)) {
    fileType.type = FileType.Video;
  }

  return fileType;
};

export const getStringWithSeperator = (
  arr: any = [],
  s1 = ', ',
  s2 = ' and ',
) => {
  return arr
    .slice(0, -1)
    .join(s1)
    .concat(arr.length > 1 ? s2 : '', arr.slice(-1));
};

export const timeAgo = (date: any) => {
  const _date = dayjs(new Date(date));
  const currentDate = dayjs(new Date());
  const days = Math.round(currentDate.diff(_date, 'day', true));
  const weeks = Math.round(days / 7);

  if (days < 7) {
    return dayjs(new Date(date)).fromNow(true);
  }

  return `${weeks}w`;
};

export const getUserName = (userDetail: any = {}, isFirstName = false) => {
  const { firstName = '', lastName = '', name } = userDetail;

  if (!firstName && !lastName) {
    return name;
  }

  if (isFirstName) {
    return firstName;
  }

  return `${firstName} ${lastName}`;
};

export type Iintstance = 'speechcraft' | 'pathways';

export const getActiveConfigs = () => {
  const instance =
    (process.env.REACT_APP_PLATFORM_NAME as Iintstance) || 'pathways';
  if (MFE_CONFIG[instance]) return MFE_CONFIG[instance];
};

export const getPlatformConfig = (attrName: string) => {
  return get(getActiveConfigs(), attrName);
};

export const getPlatformName = () =>
  process.env.REACT_APP_PLATFORM_NAME === 'speechcraft' ? '' : '/dashboard';

export const parseJwt = (token: string) => {
  if (!token) {
    return {};
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(val => '%' + ('00' + val.charCodeAt(0).toString(16)).slice(-2))
      .join(''),
  );
  return JSON.parse(jsonPayload);
};

export const getIsUserHijacked = () => {
  const cookies = new Cookies();
  const jwtToken = cookies.get('edx-jwt-cookie-header-payload');
  const parsedJwtToken = parseJwt(jwtToken);

  return !!parsedJwtToken?.is_hijacked;
};

export const getFirstErrorKey: any = (object: any, keys: any = []) => {
  const firstErrorKey = Object.keys(object)[0];
  if (isObject(object[firstErrorKey])) {
    return getFirstErrorKey(object[firstErrorKey], [...keys, firstErrorKey]);
  }
  return [...keys, firstErrorKey].join('.');
};

export const parseDate = (date: string) => {
  // date format YYYY-MM-DD (ISO 8601)
  // directly parsing string causes date to be 1 day before in minus GMT timezones
  const [year, month, day] = date.split('-');
  return new Date(+year, +month - 1, +day);
};

export const scrollCellIntoView = (cell: HTMLElement) => {
  // Datagrid keyboard navigation scroll fix
  const scrollableDiv = document.getElementById('scrollableDiv');
  const rect = cell.getBoundingClientRect();
  const topOffset = 200;

  const isInViewPort =
    rect.top >= topOffset &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || cell.clientHeight) &&
    rect.right <= (window.innerWidth || cell.clientWidth);

  if (!isInViewPort) {
    cell.scrollIntoView({ block: 'nearest' });

    // account for fixed headers if scrolling top
    // because the element will be behind the headers from using scrollIntoView api
    const cellRectAfter = cell.getBoundingClientRect();
    if (cellRectAfter.top < topOffset) {
      scrollableDiv!.scrollTo({
        top: scrollableDiv!.scrollTop - topOffset + cellRectAfter.top + 19,
      });
    }

    // leave some space at the bottom to show loader or page end
    if (
      Math.abs(
        cellRectAfter.bottom - (window.innerHeight || cell.clientHeight),
      ) <= 5
    ) {
      scrollableDiv!.scrollTo({
        top: scrollableDiv!.scrollTop + 40,
      });
    }
  }
};
