import { FC, SyntheticEvent, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { StyleToggleButtonGroup, StyledToggleButton } from './style';

import MemberFeedback from './MemberFeedback';
import { useParams } from 'react-router-dom';
import { get, map } from 'lodash';
import EvaluationFeedback from './EvaluationFeedback';
import useProfilePage from 'pages/Profile/useProfilePage';
import { getPlatformName } from 'utils/utility';
import { useIsSpeechCraftMFE } from 'hooks/useIsSpeechCraftMFE';

const EVALUATION_FEEBACK_VIEW: string = 'evaluation-feedback';

const Feedback: FC<any> = ({ memberName }) => {
  const routerParams = useParams<any>();
  const isSpeechCraftMFE = useIsSpeechCraftMFE();
  const activeSubSection = get(
    routerParams,
    'activeSubSection',
    isSpeechCraftMFE ? 'messages-feedback' : 'member-feedback',
  );

  const MEMBER_FEEDBACK_VIEW: string = isSpeechCraftMFE
    ? 'messages-feedback'
    : 'member-feedback';

  const tabs = [
    {
      ariaLabel: `${
        isSpeechCraftMFE ? 'messages feeback view' : 'member feeback view'
      }`,
      label: `${isSpeechCraftMFE ? 'Messages' : 'Member Feedback'}`,
      id: `${isSpeechCraftMFE ? 'messages-feedback' : 'member-feedback'}`,
    },
    {
      ariaLabel: `evaluation feedback view`,
      label: `Evaluation Feedback`,
      id: `evaluation-feedback`,
    },
  ];

  const { isSelfProfilePage, isMemberProfilePage } = useProfilePage();
  const isProfilePage = isSelfProfilePage || isMemberProfilePage;

  return (
    <>
      {!isProfilePage && (
        <Stack alignItems='center'>
          <StyleToggleButtonGroup aria-label='feedback view'>
            {map(tabs, (tab, index) => (
              <StyledToggleButton
                key={index}
                className={activeSubSection === tab.id ? 'active' : ''}
                to={`${getPlatformName()}/feedback/${tab.id}`}
              >
                {tab.label}
              </StyledToggleButton>
            ))}
          </StyleToggleButtonGroup>
        </Stack>
      )}
      <Box>
        {MEMBER_FEEDBACK_VIEW === activeSubSection || isProfilePage ? (
          <MemberFeedback memberName={memberName} />
        ) : (
          <EvaluationFeedback />
        )}
      </Box>
    </>
  );
};

export default Feedback;
