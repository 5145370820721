import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { LMS_BASE_URL } from 'config';
import axiosClient from 'utils/client';

const DOCUMENT_API_URL = `${LMS_BASE_URL}/api/documents`;

const documentAdapter = createEntityAdapter({
  sortComparer: (a: any, b: any) => b.created.localeCompare(a.created),
});

const initialState = documentAdapter.getInitialState({
  isFetching: false,
  isSuccess: false,
  isError: false,
  error: null as any,
  hasMore: true,
  page: 0,
});

export const fetchDocuments = createAsyncThunk(
  'documents/list',
  async (_, thunkApi: any) => {
    const page = thunkApi.getState().documents.page;
    const nextPage = page + 1;
    const res = await axiosClient.get(
      `${DOCUMENT_API_URL}/?page=${nextPage}&page_size=30`,
    );
    return res.data;
  },
  {
    condition: (_, thunkApi: any) => {
      const { isFetching, hasMore } = thunkApi.getState().documents;
      if (isFetching || !hasMore) return false;
    },
  },
);

const documentSlice = createSlice({
  name: 'documents',
  initialState,

  reducers: {
    resetDocuments: () => {
      return initialState;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(fetchDocuments.pending, (state, action) => {
        state.isFetching = true;
        state.isError = false;
        state.error = null;
        state.isSuccess = false;
      })
      .addCase(fetchDocuments.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;

        const { results, next, currentPage } = action.payload;
        state.hasMore = !!next;
        state.page = currentPage;

        documentAdapter.upsertMany(state, results);
      })
      .addCase(fetchDocuments.rejected, (state, action) => {
        state.isFetching = false;
        state.isSuccess = false;
        state.isError = true;
        state.error = action.payload;
      });
  },
});

export const { resetDocuments } = documentSlice.actions;

export default documentSlice.reducer;

export const selectDocuments = (state: any) => state.documents;

export const { selectAll: selectAllDocuments } =
  documentAdapter.getSelectors(selectDocuments);
