import {
  Avatar,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import Chip from 'elements/Chip';
import { FunctionComponent } from 'react';

const SpeechcraftersTable: FunctionComponent<any> = ({ rows = [] }) => {
  const theme = useTheme();

  return (
    <TableContainer>
      <Table sx={{ minWidth: 600 }} aria-label='Speechcrafters Table'>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant='h5'>Name</Typography>
            </TableCell>
            <TableCell align='center'>
              <Typography variant='h5'>Status</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any) => (
            <TableRow
              key={row.name}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                '&:hover': { backgroundColor: theme.palette.ternary.light },
              }}
            >
              <TableCell component='th' scope='row'>
                <Stack direction='row' alignItems='center' gap={4}>
                  <Avatar src={row.profileImage || row.name.split('')[0]} alt='Member Profile Image' sx={{ width: 36, height: 36 }} />
                  <Typography variant='h4'>{row.name}</Typography>
                </Stack>
              </TableCell>
              <TableCell style={{ width: '120px' }} align='center'>
                <Chip label={row.status} className='bg-dark-grey w-100' />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SpeechcraftersTable;
