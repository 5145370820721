import React, { memo, FunctionComponent } from 'react';

const ConfirmIcon: FunctionComponent<any> = () => {
  return (
    <svg width="130" height="129" viewBox="0 0 130 129" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.5 64.5C0.5 28.8976 29.3976 0 65 0C76.3709 0 87.0261 2.95776 96.2849 8.14696L94.0774 10.3545C85.4241 5.6741 75.5565 3 65 3C31.0224 3 3.5 30.5224 3.5 64.5C3.5 98.4776 31.0224 126 65 126C98.9776 126 126.5 98.4776 126.5 64.5C126.5 60.8432 126.131 57.2648 125.511 53.8377L128.088 51.2602C129.004 55.5448 129.5 59.9709 129.5 64.5C129.5 100.102 100.602 129 65 129C29.3976 129 0.5 100.102 0.5 64.5Z" fill="#333333"/>
      <path d="M54.9018 84.7382L124.916 14.7244L126.981 16.7756L54.9001 88.9201L33 67.02L35.0918 64.9282L54.9018 84.7382Z" fill="#772432"/>
    </svg>
  );
};


export default memo(ConfirmIcon);
