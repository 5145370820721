import { Avatar, Button, Stack, Typography } from '@mui/material';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { useState } from 'react';

import { Box as Card, BoxContent as CardContent } from 'elements/Card/style';
import GiveFeedback from 'pages/Dashboard/components/Feedback/MemberFeedback/SpeechcraftGiveFeedback';
import { getUserName } from 'utils/utility';
import { useAppContext } from 'contexts/AppContext';
import { DashboardRoutes as Routes } from 'routes';
import { Role } from 'config';

const MemberCard = ({ member = {} }: any) => {
  const { user, role } = member;
  const { username, memberPhotoUrl, profileImage } = user;

  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const { event } = useAppContext();

  const handleOpenFeedbackModal = () => {
    setFeedbackModalOpen(true);
  };

  const handleCloseFeedbackModal = () => {
    setFeedbackModalOpen(false);
  };

  const displayName = `${getUserName(user)}${
    Role.coordinator === role.positionCode ? ' (Coordinator)' : ''
  }`;

  return (
    <Card className='card club-card' sx={{ pb: 0 }}>
      <CardContent className='flex-column'>
        <Stack alignItems='center' textAlign='center' mb={7.5} gap={4}>
          <Avatar
            className='club-member-profile-img'
            src={memberPhotoUrl}
            alt={`${username}'s profile image`}
            sx={{ width: 100, height: 100 }}
          />
          <Typography variant='h4'>{displayName}</Typography>
        </Stack>

        <Stack
          className='club-card-cta-holder'
          flexDirection='row'
          justifyContent='space-around'
          alignItems='center'
          mt='auto'
        >
          <Button
            variant='contained'
            component={RouterLink}
            to={generatePath(Routes.SPEECHCRAFTER_PROFILE.path, {
              username: username,
              activeSection: 'about',
            })}
          >
            View profile
          </Button>
          <Button variant='outlined' onClick={handleOpenFeedbackModal}>
            Send message
          </Button>
        </Stack>
      </CardContent>

      <GiveFeedback
        modalOpen={feedbackModalOpen}
        handleOpen={handleOpenFeedbackModal}
        handleClose={handleCloseFeedbackModal}
        slectedEvent={event?.id}
        selectedMembers={[
          {
            id: user.id,
            name: getUserName(user),
          },
        ]}
        memberUsername={user.username}
      />
    </Card>
  );
};

export default MemberCard;
