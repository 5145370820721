import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { isEmpty, get } from 'lodash';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { Typography, Box } from '@mui/material';

import { HOC } from 'elements/Modal';
import Button from 'elements/Button';
import Loader from 'elements/Loader';
import Breadcrumb from 'components/Breadcrumb';
import { ReactComponent as ArrowBackIcon } from 'assets/images/arrow-back.svg';

import { useShareAssetMutation, useGetAssetQuery } from 'api/resourcesApi';
import { useGetNotificationDetailsQuery } from 'api/notificationApi';
import { useResourceAssets } from 'pages/Dashboard/components/Resources/ResourcesDetails/hooks';
import { useAppContext } from 'contexts/AppContext';
import { useIsSpeechCraftMFE } from 'hooks';

import Confirmation from 'components/Confirmation';
import AssetPreview from 'pages/Dashboard/components/Resources/ResourcesDetails/AssetPreview';
import AssetShareModal from 'pages/Dashboard/components/Resources/ResourcesDetails/AssetShareModal';

import { Container, BackArrow } from '../../style';
import { getPlatformName } from 'utils/utility';

const ConfirmationModal = HOC(Confirmation);

const getConfirmationMessage = (data = []) => {
  return data
    .map(({ name }) => name)
    .join(', ')
    .replace(/,(?!.*,)/gim, ' and');
};

const Resources: FunctionComponent<any> = () => {
  const routerParams = useParams<any>();
  const [shareAsset] = useShareAssetMutation();
  const slug = get(routerParams, 'slug');
  const assetUuid = get(routerParams, 'assetUuid');

  const { event } = useAppContext();
  const isSpeechCraftMFE = useIsSpeechCraftMFE();

  const {
    isFetching: isFetchingNotification,
    data = {},
    refetch,
  }: any = useGetNotificationDetailsQuery(slug, { skip: !slug });

  const { isFetching: isFetchingAsset, data: assetData = {} } =
    useGetAssetQuery(assetUuid, { skip: !assetUuid });

  const resourceData = slug ? data : assetData;
  const isFetching = slug ? isFetchingNotification : isFetchingAsset;

  const links = [
    {
      label: 'Home',
      url: `${getPlatformName()}`,
    },
    {
      label: 'Resources',
      url: `${getPlatformName()}/resources`,
    },
    {
      label: isFetching
        ? ''
        : resourceData?.isDeleted
        ? 'unavailable'
        : `${resourceData?.fileName}`,
    },
  ];

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [assetToShare, setAssetToShare] = useState<any>({});
  const [shareAssetModalData, setShareAssetModalData] = useState({
    clubId: '',
    clubMembers: [],
  });

  const {
    selectedAsset,
    disableFavorite,
    handleOpenAssetPreview,
    handleCloseAssetPreview,
    handleFavoriteAsset,
  } = useResourceAssets();

  const handleOpenShareAssetModal = (asset: any) => {
    setIsShareModalOpen(true);
    setAssetToShare(asset);
  };

  const confirmationMessage = getConfirmationMessage(
    shareAssetModalData.clubMembers,
  );

  const resetState = () => {
    setIsShareModalOpen(false);
    setIsConfirmModalOpen(false);
    setAssetToShare({});
    setShareAssetModalData((prev: any) => ({
      ...prev,
      clubId: '',
      clubMembers: [],
    }));
  };

  const handleCloseShareAssetModal = () => {
    resetState();
  };

  const handleClickShareAsset = () => {
    setIsShareModalOpen(false);
    setIsConfirmModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setIsShareModalOpen(true);
    setIsConfirmModalOpen(false);
  };

  const handleSubmitShareAsset = async (values: any) => {
    const data = {
      sharedTo: values.clubMembers.map(({ id }: any) => id),
      asset: assetToShare.id,
    };

    try {
      await shareAsset(data).unwrap();
      toast.success('The resource has been shared.');
      resetState();
    } catch (error) {
      toast.error('Something went wrong!');
    }
  };

  useEffect(() => {
    if (!isSpeechCraftMFE) {
      return;
    }

    setShareAssetModalData((prev: any) => ({ ...prev, event }));
  }, [event, isSpeechCraftMFE]);

  useEffect(() => {
    if (!isFetching && !isEmpty(resourceData)) {
      const { id, favorite, isDeleted = false } = resourceData;

      if (isDeleted) return;

      handleOpenAssetPreview({
        ...resourceData,
        resourceId: id,
        favorite,
        categoryId: uuidv4(),
      });
    }
  }, [isFetching, resourceData]);

  return (
    <Container>
      <Breadcrumb links={links} />
      <Box mt={8} pb={8}>
        <Box mb={4.5}>
          <Button onClick={() => {}} variant='text'>
            <BackArrow>
              <ArrowBackIcon />
              <Typography variant='h5' className='fw-700'>
                <Link to={`${getPlatformName()}/resources`}>
                  Back to Resources
                </Link>
              </Typography>
            </BackArrow>
          </Button>
        </Box>
        <Box>
          {slug && !isFetching && resourceData?.isDeleted ? (
            <Typography variant='h2' textAlign='center' pt={14}>
              The resource is no longer available!
            </Typography>
          ) : null}
          {isFetching ? <Loader /> : null}
          {!isFetching && !isEmpty(selectedAsset) ? (
            <AssetPreview
              asset={selectedAsset}
              handleClose={handleCloseAssetPreview}
              disableFavorite={disableFavorite}
              handleFavoriteAsset={handleFavoriteAsset}
              handleShareAsset={handleOpenShareAssetModal}
            />
          ) : null}
        </Box>
      </Box>

      {isShareModalOpen && (
        <AssetShareModal
          title={assetToShare?.fileName}
          handleClose={handleCloseShareAssetModal}
          shareAssetModalData={shareAssetModalData}
          setShareAssetModalData={setShareAssetModalData}
          handleClickShareAsset={handleSubmitShareAsset}
          assetId={assetToShare.uuid}
          isSpeechCrafter={isSpeechCraftMFE}
        />
      )}
      {isConfirmModalOpen && (
        <ConfirmationModal
          handleClose={handleCloseConfirmationModal}
          handleConfirmation={handleSubmitShareAsset}
          title='Confirmation'
        >
          {`Are you sure you want to share the ${assetToShare.fileName} file to ${confirmationMessage}?`}
        </ConfirmationModal>
      )}
    </Container>
  );
};

export default Resources;
