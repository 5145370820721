import { useState, FunctionComponent, useCallback } from 'react';
import { Button, Typography } from '@mui/material';
import compose from 'compose-function';
import classNames from 'classnames';

import Card from 'elements/Card';
import feedbackImage from 'assets/images/shareIcon.svg';
import { CardActions } from 'pages/Dashboard/components/Resources/style';
import { CardActionsHolder } from 'pages/Dashboard/components/CourseCard/style';
import { HOC } from 'elements/Modal';
import { LMS_BASE_URL } from 'config';
import { getIsUserHijacked } from 'utils/utility';

import CertificateSharing from './CertificateSharing';
import { useIsSpeechCraftMFE, useRoles } from 'hooks';

const CertificateSharingModal = compose(HOC)(CertificateSharing);

type certificateType = {
  name: string;
  logo: string;
  socialMediaLink: string;
  isEarned: string;
  description: string;
  thumbnail: string;
};

type levelCardType = {
  certificate: certificateType;
  isMemberProfilePage: any;
};

const LevelCard: FunctionComponent<levelCardType> = ({
  certificate,
  isMemberProfilePage,
}) => {
  const { name, logo, thumbnail, socialMediaLink, isEarned, description } =
    certificate;
  const socialMediaUrl = `${LMS_BASE_URL}${socialMediaLink}`;
  const isUserHijacked = getIsUserHijacked();
  const { isBcm, isCoordinator } = useRoles();
  const isSpeechcraftMfe = useIsSpeechCraftMFE();

  const [isSharingModalOpen, setIsSharingModalOpen] = useState(false);
  const handleOpenSharingModal = () =>
    !isMemberProfilePage && isEarned && setIsSharingModalOpen(true);
  const handleCloseSharingModal = () => setIsSharingModalOpen(false);

  const handleClickCard = useCallback(() => {
    const isNotDownloadble =
      !socialMediaUrl ||
      (isMemberProfilePage && !isBcm && !isSpeechcraftMfe) ||
      !socialMediaUrl ||
      (isMemberProfilePage && !isCoordinator && isSpeechcraftMfe);

    if (isNotDownloadble) {
      return;
    }

    window.open(socialMediaUrl, '_blank');
  }, [isMemberProfilePage, socialMediaUrl]);

  return (
    <>
      <Card
        handleClick={handleClickCard}
        actionButtons={
          <CardActionsHolder>
            <CardActions direction='row' className='card-cta-icons-wrapper'>
              <div
                className={classNames('card-cta-icons', {
                  disabled: !isEarned,
                })}
              >
                <Button
                  aria-label='Share'
                  onClick={() => !isUserHijacked && handleOpenSharingModal()}
                >
                  <img src={feedbackImage} alt='Share' />
                </Button>
              </div>
            </CardActions>
          </CardActionsHolder>
        }
        cardImage={thumbnail}
        isDisable={!isEarned}
        altText={description}
      >
        <Typography mb={0} variant='h2' component='h4'>
          {name}
        </Typography>
      </Card>

      {isSharingModalOpen ? (
        <CertificateSharingModal
          title='Share Certificate'
          handleClose={handleCloseSharingModal}
          sharingUrl={socialMediaUrl}
        />
      ) : null}
    </>
  );
};

export default LevelCard;
