import { FunctionComponent } from 'react';
import {
  Avatar,
  Button,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';

const SpeechcraftersTable: FunctionComponent<any> = ({ rows = [] }) => {
  const theme = useTheme();

  return (
    <TableContainer>
      <Table sx={{ minWidth: 800 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant='h5'>Name</Typography>
            </TableCell>

            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any) => (
            <TableRow
              key={row.name}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                '&:hover': { backgroundColor: theme.palette.ternary.light },
              }}
            >
              <TableCell component='th' scope='row'>
                <Stack direction='row' alignItems='center' gap={4}>
                  <Avatar src={row.profileImage || row.name.split('')[0]} alt='Member Profile Image' sx={{ width: 36, height: 36 }} />
                  <Typography variant='h4'>{row.profileName}</Typography>
                </Stack>
              </TableCell>

              <TableCell align='right'>
                <Button
                  color='secondary'
                  sx={{ textDecoration: 'underline' }}
                  component={Link}
                  to={row.profileUrl}
                >
                  View profile
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SpeechcraftersTable;
