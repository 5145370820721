import { useRouteMatch } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
// @ts-ignore
import { getAuthenticatedUser } from '@edx/frontend-platform/auth';

import { DashboardRoutes } from 'routes';

const useProfilePage = () => {
  const routerParams = useParams<any>();

  const user = getAuthenticatedUser();
  const isClubMemberProfilePage = useRouteMatch(DashboardRoutes.PROFILE.path);
  const isSpeechcrafterProfilePage = useRouteMatch(
    DashboardRoutes.SPEECHCRAFTER_PROFILE.path,
  );

  const isSelfProfilePage = useRouteMatch(DashboardRoutes.MY_PROFILE.path);
  const username = get(routerParams, 'username', '');
  const clubId = get(routerParams, 'clubId', '');
  const isMemberProfilePage =
    isClubMemberProfilePage || isSpeechcrafterProfilePage;

  return {
    isMemberProfilePage,
    isSelfProfilePage,
    username: isSelfProfilePage ? user.username : username,
    clubId,
    isSpeechcrafterProfilePage
  };
};

export default useProfilePage;
