import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { reducer as modalsReducer } from 'pages/Modals/ducks/slice';
import { reducer as coursesReducer } from 'pages/Dashboard/ducks/slice';
import { default as feedbackReducer } from 'pages/Dashboard/components/Feedback/feedbackSlice';
import { reducer as resourcesReducer } from 'pages/Dashboard/ducks/resourcesSlice';
import { default as documentReducer } from 'pages/Dashboard/components/Resources/Documents/documentSlice';
import { reducer as evaluationForms } from 'pages/Dashboard/ducks/evaluationFormsSlice';
import { reducer as requestsReducer } from 'pages/Requests/ducks/slice';
import { default as reqeustReview } from 'pages/Requests/ducks/approvalSlice';
import { default as clubMembers } from 'pages/MyClubs/ducks/slice';
import { default as notificationsReducer } from 'pages/Notifications/ducks/slice';
import { default as globalSearchMemberReducer } from 'pages/Search/ducks/memberSlice';
import { default as globalSearchResourceReducer } from 'pages/Search/ducks/resourceSlice';
import { default as selfAssessmentFormsReducer } from 'pages/Dashboard/components/SelfAssessments/selfAssessmentSlice';
import { default as pathInProgressReducer } from 'pages/BcmDashboard/components/PathInProgress/pathInProgressSlice';
import { default as recentActivityReducer } from 'pages/BcmDashboard/components/RecentActivity/recentActivitySlice';
import { default as eventMembers } from 'pages/MyCohort/slice';

import baseApiSlice from 'api/baseApiSlice';

export const store = configureStore({
  reducer: {
    modals: modalsReducer,
    courses: coursesReducer,
    feedbacks: feedbackReducer,
    resources: resourcesReducer,
    documents: documentReducer,
    evaluationFeedback: evaluationForms,
    requests: requestsReducer,
    requestReview: reqeustReview,
    clubMembers: clubMembers,
    notifications: notificationsReducer,
    globalSearchMembers: globalSearchMemberReducer,
    globalSearchResources: globalSearchResourceReducer,
    selfAssessmentForms: selfAssessmentFormsReducer,
    inProgressCourses: pathInProgressReducer,
    userRecentActivity: recentActivityReducer,
    eventMembers: eventMembers,
    [baseApiSlice.reducerPath]: baseApiSlice.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([baseApiSlice.middleware]),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
