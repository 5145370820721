import React, { memo, FunctionComponent } from 'react';
import {Chip as StyledChip} from 'elements/Chip/style';
import CloseIcon from '@mui/icons-material/Close';

const Chip: FunctionComponent<any> = ({label, variant, handleClick, handleDelete, clickable, className, disabled}) => {
  return (
    <StyledChip
      label={label}
      variant={variant}
      onClick={handleClick}
      onDelete={handleDelete}
      deleteIcon={<CloseIcon />}
      clickable={clickable}
      className={className}
      disabled={disabled}
    />
  );
};


export default memo(Chip);
