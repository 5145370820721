import { Box, Button, Stack, Typography, Divider } from '@mui/material';
import { isEmpty, capitalize } from 'lodash';

import Loader from 'elements/Loader';
import { useGetSpeechTranscriptQuery } from 'api/profileApi';

import Chip from 'elements/Chip';
import useProfilePage from '../useProfilePage';

import { ListItem } from './style';

const SpeechTranscript = ({ handleClose }: any) => {
  const { isMemberProfilePage, username } = useProfilePage();

  const {
    data: transcript,
    isFetching,
    isSuccess,
    isError,
  } = useGetSpeechTranscriptQuery(isMemberProfilePage ? username : '');

  return (
    <Box>
      {isFetching ? <Loader /> : null}

      {isError ? (
        <Typography textAlign='center' color='error'>
          Something went wrong. Please try again later
        </Typography>
      ) : null}

      {isSuccess ? (
        <Box mx={5}>
          <Typography variant='h3'>Overview</Typography>
          <Divider />
          <Box>
            {isEmpty(transcript?.results) ? (
              <Stack alignItems='center' justifyContent='center' pt={15}>
                No record found
              </Stack>
            ) : (
              transcript?.results?.map((data: any) => {
                const { status, name, comments, description } = data;
                return (
                  <Box>
                    <Stack direction='row' py={7.5}>
                      <Box flex={1}>
                        <Typography variant='h4' color='primary'>
                          {name}
                        </Typography>
                      </Box>
                      <ListItem flex={2.3}>
                        <Chip
                          label={capitalize(status)}
                          className='bg-dark-grey'
                        />
                        <Typography mt={2.5}>{description}</Typography>
                        {(comments.groupComment ||
                          comments.individualComment) &&
                          status === 'Completed' && (
                            <>
                              <Typography variant='h4' mt={5} mb={2.5}>
                                Comments
                              </Typography>
                              <Stack gap={4}>
                                <Stack gap={1}>
                                  <Typography variant='h5'>
                                    {comments.individualComment
                                      ? 'Individual Comment'
                                      : 'Group Comment'}
                                  </Typography>
                                  <Typography variant='h6'>
                                    {comments.individualComment ||
                                      comments.groupComment}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </>
                          )}
                      </ListItem>
                    </Stack>
                    <Divider />
                  </Box>
                );
              })
            )}
          </Box>
          <Stack mt={10} alignItems='flex-end'>
            <Button variant='contained' size='small' onClick={handleClose}>
              Close
            </Button>
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
};

export default SpeechTranscript;
