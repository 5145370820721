import { FunctionComponent, useEffect } from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';

import { getActiveEnrolledCourses } from 'pages/Dashboard/ducks/slice';
import LearningPaths from 'pages/Dashboard/components/LearningPaths';

const Transcript: FunctionComponent<any> = ({ transcriptTitle }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getActiveEnrolledCourses({}));
  }, [dispatch]);

  return (
    <Box px={4}>
      <LearningPaths
        showSpeechTranscript={true}
        transcriptTitle={transcriptTitle}
      />
    </Box>
  );
};

export default Transcript;
