import { styled, Avatar, Stack, Paper, Box } from '@mui/material';

export const SpeechCraftContainer = styled(Paper)`
  width: 100%;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.palette.ternary.main};
  border-radius: 4px;
  box-shadow: none;

  fieldset.error-holder {
    margin: 0;
    border: 0;
  }

  .error-msg {
    margin-top: 12px;
    color: ${({ theme }) => theme.palette.error.main};
  }

  .disable-selected-row {
    opacity: 0.5;
    pointer-events: none;
  }

  .MuiDataGrid-root {
    max-height: 600px;
    border: none;

    .MuiDataGrid-withBorderColor {
      border-color: ${({ theme }) => theme.palette.ternary.main};
    }
    .MuiDataGrid-columnHeaders {
      &:hover {
        .MuiDataGrid-columnSeparator {
          visibility: hidden;
        }
      }
    }
    .MuiDataGrid-columnHeader {
      color: ${({ theme }) => theme.palette.ternary.dark};
    }
    .MuiDataGrid-row {
      &.Mui-selected,
      &.Mui-selected:hover,
      &:hover {
        background: ${({ theme }) => theme.palette.ternary.light};
      }
    }
    .MuiButtonBase-root.MuiCheckbox-root {
      &.Mui-checked {
        color: ${({ theme }) => theme.palette.secondary.light};
      }
    }
    .MuiDataGrid-columnHeader,
    .MuiDataGrid-cell {
      &:focus-within,
      &:focus-visible,
      &:focus {
        outline-color: ${({ theme }) => theme.palette.ternary.focusBlue};
        outline-offset: -1px;
      }
    }
  }
`;

export const StyledAvatar = styled(Avatar)`
  width: '36px';
  height: '36px';
`;

export const StyledCell = styled(Stack)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .secondary-color {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const InfoSection = styled(Paper)`
  padding: 20px;
  margin: 15px 0;
  border: 1px solid ${({ theme }) => theme.palette.ternary.main};
  border-radius: 4px;
  box-shadow: none;

  .MuiFormControlLabel-root {
    & + .error-msg {
      padding: 0 15px;
    }
  }
`;

export const SpeechCraftAvatar = styled(Avatar)`
  width: '26px';
  height: '26px';
`;

export const SearchForm = styled(Box)`
  width: 260px;
  position: relative;

  .MuiButtonBase-root {
    position: absolute;
    right: 0;
    border-radius: 4px;
    color: ${({ theme }) => theme.palette.ternary.greyShade};
    padding: 0;
    top: 11px;

    &:hover {
      background: none;
    }
  }
  .MuiInputBase-root {
    &::before {
      border-bottom-color: ${({ theme }) => theme.palette.ternary.main};
    }
    .MuiInputBase-input {
      padding-right: 40px !important;
      padding-left : 0;
    }
  }
`;

export const CloseIconWrapper = styled(Box)`
  position: absolute;
  right: 25px;
  top: 11px;

  svg {
    font-size: 0.875em;
    cursor: pointer;
  }
`;
