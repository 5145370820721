import baseApiSlice from 'api/baseApiSlice';

const BASE_API_URL = 'api/events';

enum ITags {}

const speechCraftApiApiWithTags = baseApiSlice.enhanceEndpoints({
  addTagTypes: [],
});

const ApiSlice = speechCraftApiApiWithTags.injectEndpoints({
  endpoints: builder => ({
    getEventAttendees: builder.query<any, any>({
      query: ({ attendeesType = '' }) => ({
        url: `${BASE_API_URL}/attendees/`,
        params: {
          ...(attendeesType && {
            attendees: attendeesType,
          }),
        },
      }),
    }),
  }),

  overrideExisting: false,
});

export const { useGetEventAttendeesQuery } = ApiSlice;

export default ApiSlice;
