import baseApiSlice from 'api/baseApiSlice';

const CLUB_API_URL = '/api/members';

const clubApiWithTags = baseApiSlice.enhanceEndpoints({
  addTagTypes: ['club'],
});

const clubApiSlice = clubApiWithTags.injectEndpoints({
  endpoints: builder => ({
    getClubsWithMembers: builder.query({
      query: () => ({
        url: `${CLUB_API_URL}/club_detail?page_size=10`,
      }),
    }),
  }),
});

export const { useGetClubsWithMembersQuery } = clubApiSlice;

export default clubApiSlice;
