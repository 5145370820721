import React, { FC, useEffect, useMemo } from 'react';
// @ts-ignore
import { getConfig } from '@edx/frontend-platform';
import { get } from 'lodash';
import { useLocation, useParams } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import { ErrorContainer, ErrorBox, ErrorImage, ErrorText } from './style';
import error404 from 'assets/images/error-404.svg';
import error403 from 'assets/images/error-403.svg';
import Button from 'elements/Button';
import { useIsSpeechCraftMFE } from 'hooks';
import { ErrorMessages, ErrorTypes, TOASTMASTERS_WEBSITE_URL, SPEECHCRAFT_BASE_URL, PATHWAYS_BASE_URL } from 'config';
// @ts-ignore
import { getAuthenticatedUser } from '@edx/frontend-platform/auth';

const Error: FC<any> = () => {
  const user = getAuthenticatedUser();
  const { search } = useLocation();
  const routerParams = useParams<any>();
  const status = get(routerParams, 'status');
  const isAccessDenied = status === 'restricted-access';
  const isSpeechCraftMFE = useIsSpeechCraftMFE();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const errorType = searchParams.get('errorType') as string;
  const isPathwaysUser = searchParams.get('is_pathways_user') as string;
  const isSpeechcraftUser = searchParams.get('is_speechcraft_user') as string;

  const handleClick = () => {
    let url = `${TOASTMASTERS_WEBSITE_URL}start-pathways/select-your-preference`;
    if(isSpeechcraftUser == "true" && SPEECHCRAFT_BASE_URL) {
      url = SPEECHCRAFT_BASE_URL;
    } else if (isPathwaysUser == "true" && PATHWAYS_BASE_URL) {
      url = `${PATHWAYS_BASE_URL}/dashboard`;
    }
    window.location.replace(url);
  };

  return (
    <ErrorContainer>
      <ErrorBox>
        <ErrorText>
          <Typography variant='h2'>
            {isAccessDenied ? '403 - Forbidden: Access is denied.' : '404 - Page Not Found'}
          </Typography>
          <ErrorImage>
            {isAccessDenied ? <img alt='403 - Forbidden: Access is denied.' src={error403} /> : <img alt='404 - Page Not Found' src={error404} /> }
          </ErrorImage>
          <Typography>
            {get(ErrorMessages, get(ErrorTypes, errorType), `You don't have permission to access this page.`)}{' '}
          </Typography>
          <Box mt={15}>
            <Button className='mw-160' onClick={handleClick}>
              Go to Homepage
            </Button>
          </Box>
        </ErrorText>
      </ErrorBox>
    </ErrorContainer>
  );
};

export default Error;
