import { FC } from 'react';
import { Box, Grid } from '@mui/material';
import { isEmpty } from 'lodash';

import SectionHeader from 'components/SectionHeader';
import CourseCard from 'pages/Dashboard/components/CourseCard';
import CardSlider from 'components/CardSlider';
import { useIsSpeechCraftMFE } from 'hooks/useIsSpeechCraftMFE';

const CourseSection: FC<any> = ({
  sectionHeading,
  headingComponent,
  data = [],
  showSlider = true,
  actionButtonText,
  handleActionButton,
  handleCardRegistration,
  canViewCourseProgress,
  showSpeechTranscript = false,
  showCourseProgressAlert = false,
  transcriptTitle
}) => {
  const hasData = !isEmpty(data);
  const buttonText = hasData ? actionButtonText : '';
  const isSpeechCraftMFE = useIsSpeechCraftMFE();

  return (
    <Box sx={{ mb: 10 }}>
      {hasData && (
        <>
          {!isSpeechCraftMFE && (
            <SectionHeader
              title={sectionHeading}
              headingComponent={headingComponent}
              buttonText={buttonText}
              handleActionButton={handleActionButton}
            />
          )}
          {showSlider ? (
            <CardSlider>
              {data.map((cardData: any, index: number) => (
                <CourseCard
                  key={index}
                  data={cardData}
                  handleCardRegistration={handleCardRegistration}
                  showSpeechTranscript={showSpeechTranscript}
                />
              ))}
            </CardSlider>
          ) : (
            <Grid container spacing={3}>
              {data.map((cardData: any, index: number) => (
                <Grid item xs={12} sm={4} md={3} key={index}>
                  <CourseCard
                    data={cardData}
                    handleCardRegistration={handleCardRegistration}
                    canViewCourseProgress={canViewCourseProgress}
                    showCourseProgressAlert={showCourseProgressAlert}
                    showSpeechTranscript={showSpeechTranscript}
                    transcriptTitle={transcriptTitle}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}
    </Box>
  );
};

export default CourseSection;
