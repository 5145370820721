import { Box, Link, Typography } from '@mui/material';
import { HOC } from 'elements/Modal';
import { useEffect, useRef, useState } from 'react';

import { LegendTypography } from './style';
import TooltipFullList from './TooltipFullList';

const TooltipFullListModal = HOC(TooltipFullList);

const TooltipContent = ({ completed, incompleted, modalTitle, type }: any) => {
  const isCompleted = type === 'completed';
  const isIncompleted = type === 'incompleted';
  const [showModalButton, setShowModalButton] = useState(false);

  const completedText =
    isCompleted && completed.length ? completed.join(', ') : 'None';
  const incompletedText =
    isIncompleted && incompleted.length ? incompleted.join(', ') : 'None';

  const [modalOpen, setModalOpen] = useState(false);

  const textRef = useRef<HTMLParagraphElement | null>(null);

  useEffect(() => {
    const el = textRef.current;
    if (el) {
      const isOverflown =
        el.scrollHeight > el.clientHeight || el.scrollWidth > el.clientWidth;
      if (isOverflown) {
        setShowModalButton(true);
      }
    }
  }, []);

  return (
    <Box>
      <LegendTypography
        className={isCompleted ? 'complete-legend' : 'incomplete-legend'}
        variant='h5'
      >
        {isCompleted ? 'Completed' : 'Incomplete'}
      </LegendTypography>
      <Typography
        variant='body2'
        mt={2}
        fontWeight='bold'
        color='ternary.dark'
        sx={{
          overflow: 'hidden',
          display: '-webkit-box',
          '-webkitLineClamp': '2',
          lineClamp: '2',
          '-webkitBoxOrient': 'vertical',
          boxOrient: 'vertical',
        }}
        ref={textRef}
      >
        {isCompleted ? completedText : incompletedText}
      </Typography>

      {showModalButton ? (
        <Typography variant='body2' mt={4}>
          <Link
            component='button'
            color='secondary'
            onClick={() => setModalOpen(true)}
            fontSize={14}
            fontWeight='bold'
          >
            See full list
          </Link>
        </Typography>
      ) : (
        <Typography variant='body2' mt={4} pt={4}></Typography>
      )}

      {modalOpen ? (
        <TooltipFullListModal
          title={`${modalTitle} Details`}
          handleClose={() => setModalOpen(false)}
          completed={completed}
          incompleted={incompleted}
        />
      ) : null}
    </Box>
  );
};

export default TooltipContent;
