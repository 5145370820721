import { FC, memo, useState } from 'react';
import {
  ConfirmationBox,
  CTAButtons,
} from 'components/ExpiredRequest/style';
import { Backdrop, CircularProgress, Typography } from '@mui/material';
import Button from 'elements/Button';
import ConfirmIcon from 'elements/Svgs/Confirm';
import Loader from 'elements/Loader';

const ExpiredRequest: FC<any> = ({
  data,
  handleConfirmation,
  children,
  buttonText,
  showLoader = true,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <ConfirmationBox>
      {loading && showLoader && (
        <Loader position='absolute' background='white' />
      )}
      <Typography className='fs-20' variant='body1'>
        {children}
      </Typography>
      <CTAButtons>
        <Button
          onClick={() => {
            setLoading(!loading);
            handleConfirmation(data);
          }}
          variant='contained'
        >
          {buttonText ? buttonText : 'Confirm'}
        </Button>
      </CTAButtons>
    </ConfirmationBox>
  );
};

export default memo(ExpiredRequest);
