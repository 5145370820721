import {
  FunctionComponent,
  useEffect,
  useState,
  useCallback,
  createElement,
} from 'react';
import { Link, useParams, generatePath } from 'react-router-dom';
import { Typography, Stack } from '@mui/material';
import Button from 'elements/Button';
import { ReactComponent as ArrowBackIcon } from 'assets/images/arrow-back.svg';
import { isEmpty, get } from 'lodash';
import { toast } from 'react-toastify';

// @ts-ignore
import { getAuthenticatedUser } from '@edx/frontend-platform/auth';

import Loader from 'elements/Loader';
import Breadcrumb from 'components/Breadcrumb';
import { useGetNotificationDetailsQuery } from 'api/notificationApi';

import { HOC } from 'elements/Modal';
import Confirmation from 'components/Confirmation';
import { useDeleteFeedbackMutation } from 'pages/Dashboard/components/Feedback/feedbackApi';
import GiveFeedback from 'pages/Dashboard/components/Feedback/MemberFeedback/GiveFeedback';
import FeedbackItem from 'pages/Dashboard/components/Feedback/MemberFeedback/FeedbackItem';
import RequestFeedback from 'pages/Dashboard/components/Feedback/MemberFeedback/RequestFeedback';
import SpeechcraftGiveFeedback from 'pages/Dashboard/components/Feedback/MemberFeedback/SpeechcraftGiveFeedback';

import {
  Container,
  FeedbackWrapper,
  FeedbackButtonsBox,
  BackArrow,
  NoFeedback,
} from '../../style';
import { getPlatformName, getUserName } from 'utils/utility';
import { useIsSpeechCraftMFE } from 'hooks';
import { DashboardRoutes as Routes } from 'routes';

const ConfirmationModal = HOC(Confirmation);

const Feedback: FunctionComponent<any> = () => {
  const user = getAuthenticatedUser();
  const routerParams = useParams<any>();
  const slug = get(routerParams, 'slug');

  const isSpeechCraftMFE = useIsSpeechCraftMFE();

  const [feedbackToUpdate, setFeedbackToUpdate] = useState<any>({});
  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const handleOpenRequestModal = () => setRequestModalOpen(true);
  const handleCloseRequestModal = () => setRequestModalOpen(false);

  const [giveModalOpen, setGiveModalOpen] = useState(false);
  const handleOpenGiveModal = () => setGiveModalOpen(true);
  const handleCloseGiveModal = () => setGiveModalOpen(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleOpenDeleteModal = (feedback: any) => {
    setDeleteModalOpen(true);
    setFeedbackToUpdate(feedback);
  };
  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
    setFeedbackToUpdate({});
  };

  const [isRequestedFeedback, setIsRequestedFeedback] = useState(false);
  const handleRequestedFeedback = () => setIsRequestedFeedback(false);

  const [isEditModlaOpen, setIsEditModlaOpen] = useState(false);

  const handleEditFeedback = (feedback: any) => {
    setFeedbackToUpdate(feedback);
    setIsEditModlaOpen(true);
    handleOpenGiveModal();
  };

  const resetEditModal = () => {
    setFeedbackToUpdate({});
    setIsEditModlaOpen(false);
  };

  const {
    isFetching,
    data: feedback,
    refetch,
    isSuccess,
  }: any = useGetNotificationDetailsQuery(slug, { skip: !slug });

  const { feedbackRequest, feedback: feedbackText, isDeleted } = feedback || {};

  const [deleteFeedback] = useDeleteFeedbackMutation();

  const handleDeleteConfirmation = useCallback(async () => {
    try {
      await deleteFeedback(feedbackToUpdate?.id).unwrap();
      refetch();
      handleCloseDeleteModal();
      toast.success(`Your feedback has been successfully deleted!`);
    } catch {
      toast.error('Something went wrong');
    }
  }, [deleteFeedback, feedbackToUpdate?.id, refetch]);

  useEffect(() => {
    if (!isFetching && isRequestedFeedback) {
      handleOpenGiveModal();
    }
  }, [isFetching, isRequestedFeedback]);

  useEffect(() => {
    if (isEmpty(feedbackText) && isSuccess && !isDeleted) {
      setIsRequestedFeedback(true);
    }
  }, [feedbackText, isDeleted, isSuccess]);

  const links = [
    {
      label: 'Home',
      url: `${getPlatformName()}`,
    },
    {
      label: 'Feedback',
      url: generatePath(Routes.DASHBOARD.path, {
        activeSection: 'feedback',
        activeSubSection: `${
          isSpeechCraftMFE ? 'messages-feedback' : 'member-feedback'
        }`,
      }),
    },
    {
      label: `${getUserName(user)} ${
        isSpeechCraftMFE ? 'Messages & Feedback' : 'Feedback'
      }`,
    },
  ];

  return (
    <Container>
      <Breadcrumb links={links} />
      <FeedbackWrapper>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          flexWrap='wrap'
          gap={5}
        >
          <BackArrow>
            <Typography variant='h5' className='fw-700'>
              <Link
                to={generatePath(Routes.DASHBOARD.path, {
                  activeSection: 'feedback',
                  activeSubSection: `${
                    isSpeechCraftMFE ? 'messages-feedback' : 'member-feedback'
                  }`,
                })}
              >
                <ArrowBackIcon />
                Back to {isSpeechCraftMFE ? 'Messages' : 'Member Feedback'}
              </Link>
            </Typography>
          </BackArrow>
          <FeedbackButtonsBox direction='row' gap={1} flexWrap='wrap'>
            <Button
              variant='outlined'
              disabled={isFetching}
              onClick={handleOpenGiveModal}
            >
              Give feedback
            </Button>
            <Button
              variant='contained'
              disabled={isFetching}
              onClick={handleOpenRequestModal}
            >
              Request feedback
            </Button>
          </FeedbackButtonsBox>
        </Stack>
        <Stack mt={5} gap={3}>
          {isFetching ? (
            <Loader />
          ) : isEmpty(feedback) || !isSuccess || isDeleted ? (
            <NoFeedback>
              <Typography>
                {isEmpty(feedback)
                  ? 'Feedback not found'
                  : 'Feedback has been removed'}
              </Typography>
            </NoFeedback>
          ) : (
            <FeedbackItem
              key={feedback?.id}
              feedback={feedback}
              handleDeleteFeedback={handleOpenDeleteModal}
              handleEditFeedback={handleEditFeedback}
            />
          )}
        </Stack>
      </FeedbackWrapper>
      {createElement(
        isSpeechCraftMFE ? SpeechcraftGiveFeedback : GiveFeedback,
        {
          modalOpen: giveModalOpen,
          handleClose: handleCloseGiveModal,
          handleOpen: handleOpenGiveModal,
          isRequestedFeedback: isRequestedFeedback,
          feedbackData: isEditModlaOpen ? feedbackToUpdate : feedbackRequest,
          handleRequestedFeedback: handleRequestedFeedback,
          refetchRequestFeedback: refetch,
          isEditModal: isEditModlaOpen,
          resetEditModal: resetEditModal,
          selectedMembers: feedbackToUpdate?.receiver
            ? [feedbackToUpdate?.receiver]
            : [],
          ...(!isSpeechCraftMFE && {
            selectedClub: feedbackToUpdate?.club?.id || '',
            selectedClubName: feedbackToUpdate?.club?.name,
          }),
          ...(isSpeechCraftMFE && {
            slectedEvent: feedbackToUpdate?.event,
          }),
        },
      )}

      <RequestFeedback
        modalOpen={requestModalOpen}
        handleClose={handleCloseRequestModal}
        handleOpen={handleOpenRequestModal}
      />

      {deleteModalOpen && (
        <ConfirmationModal
          title='Confirmation'
          buttonText='Yes'
          handleClose={handleCloseDeleteModal}
          handleConfirmation={handleDeleteConfirmation}
        >
          {`Are you sure you want to delete the feedback for ${getUserName(
            feedback?.receiver,
            isSpeechCraftMFE,
          )}?`}
        </ConfirmationModal>
      )}
    </Container>
  );
};

export default Feedback;
