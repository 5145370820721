import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@mui/material';
import Button from 'elements/Button';
import { useHistory } from 'react-router-dom';

import { HeaderList, NotificationCount } from './style';
import { getPlatformName } from 'utils/utility';

const NotificationHeader: FunctionComponent<any> = ({
  count = 0,
  handleClose,
}) => {
  const history = useHistory();

  const handleNavigate = () => {
    handleClose();
    history.push(`${getPlatformName()}/notifications`);
  };

  return (
    <HeaderList>
      <Box display='flex' alignItems='center'>
        <Typography variant='h4' component='span'>
          Unread Notifications
        </Typography>
        <NotificationCount variant='body2' component='span'>
          {count && count < 10 ? `0${count}` : count}
        </NotificationCount>
      </Box>
      <Button onClick={handleNavigate} variant='text' color='secondary'>
        View All
      </Button>
    </HeaderList>
  );
};

export default NotificationHeader;
