import { Box, Stack, Typography } from '@mui/material';

import Button from 'elements/Button';

interface Props {
  completed: string[];
  incompleted: string[];
  handleClose: React.MouseEventHandler<HTMLButtonElement>;
}

const TooltipFullList = ({
  completed = [],
  incompleted = [],
  handleClose,
}: Props) => {
  return (
    <Box>
      {completed.length ? (
        <>
          <Typography variant='h3'>Completed</Typography>
          <Typography variant='body1'>{completed.join(', ')}</Typography>
        </>
      ) : null}

      {incompleted.length ? (
        <>
          <Typography variant='h3' mt={8}>
            Incomplete
          </Typography>
          <Typography variant='body1'>{incompleted.join(', ')}</Typography>
        </>
      ) : null}

      <Stack alignItems='center' mt={13}>
        <Button onClick={handleClose} variant='outlined'>
          Close
        </Button>
      </Stack>
    </Box>
  );
};

export default TooltipFullList;
