import { Box, styled } from '@mui/material';
import { DeviceUp, DeviceDown } from 'styles/breakpoints';

export const Container = styled(Box)`
  max-width: 1110px;
  width: 100%;
  padding: 0 15px;
  margin: 30px auto 0;

  & h2 {
    margin-bottom: 10px;
  }

  & .infinite-scroll-component {
    padding: 10px 5px;
  }

  & .back-btn {
    display: flex;
    align-items: center;

    svg {
      width: 14px;
      margin-right: 8px;
    }
  }
`;

export const BrandLogos = styled('div')`
  .MuiList-root {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 30px;

    @media ${DeviceUp.sm} {
      padding-bottom: 60px;
    }

    & .MuiListItem-root {
      display: inline-flex;
      width: auto;
      border-left: 1px solid #9ea9a8;
      padding: 5px 25px;

      & img {
        max-width: 100px;
        object-fit: contain;

        @media ${DeviceUp.sm} {
          max-width: 165px;
        }
      }

      &:first-child {
        padding-left: 0;
        border: none;
      }
    }
  }
`;
export const FormWrapperOuter = styled('div')`
  max-width: 1080px;
  width: 100%;
  margin: 10px auto 30px;
  position: relative;
`;

export const FormWrapper = styled('div')`
  width: 100%;
  padding: 20px;
  background: ${({ theme }) => theme.palette.ternary.white};
  border-radius: 4px;
  position: relative;

  @media ${DeviceUp.sm} {
    padding: 40px;
  }

  & .MuiTypography-h1,
  & .MuiTypography-h2,
  & .MuiTypography-h3,
  & .MuiTypography-h4 {
    color: ${({ theme }) => theme.palette.secondary.main};
  }

  & .MuiTypography-h4 {
    font-size: 18px;
    margin-bottom: 5px;
  }

  & p strong {
    color: ${({ theme }) => theme.palette.secondary.main};
    font-weight: 700;

    &.grey-text {
      color: ${({ theme }) => theme.palette.ternary.greyShade};
    }
  }

  & .MuiFormControlLabel-root {
    & .MuiButtonBase-root {
      &.Mui-disabled {
        opacity: 1;
      }
    }
    & .MuiFormControlLabel-label.Mui-disabled {
      color: inherit;
    }

    & .MuiFormControl-root {
      .MuiInputBase-root {
        &.Mui-disabled fieldset {
          border-color: ${({ theme }) => theme.palette.ternary.greyShade};
          border-width: 1px;
        }

        &:hover {
          fieldset {
            border-width: 2px;
          }
        }

        & .MuiInputBase-input {
          &.Mui-disabled {
            color: ${({ theme }) => theme.palette.ternary.greyShade};
            -webkit-text-fill-color: ${({ theme }) =>
              theme.palette.ternary.greyShade};
          }
        }
      }
    }
  }

  & .inline-label-control {
    .MuiFormControlLabel-label {
      min-width: 120px;
    }
  }
  & .MuiFormControlLabel-labelPlacementStart {
    @media ${DeviceDown.sm} {
      flex-wrap: wrap-reverse;
    }

    .MuiFormControlLabel-label {
      margin-right: 10px;
      font-weight: 700;

      @media ${DeviceDown.md} {
        min-width: 120px;
      }

      @media ${DeviceDown.sm} {
        width: 100%;
        margin-right: 0;
        margin-bottom: 5px;
      }
    }

    .MuiInputBase-root {
      height: 40px;
    }
  }

  & .radio-options {
    padding: 6px 0;

    label {
      margin-right: 15px;
    }
  }

  & .choices-box {
    padding: 0 0 30px;
  }

  & .criteria-options {
    margin: 30px auto;
    border: 1px solid ${({ theme }) => theme.palette.ternary.greyShade};
    border-radius: 4px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding: 5px 15px;

      li {
        padding: 15px;
      }
    }
  }

  .flex-start li {
    align-items: flex-start;
    padding: 4px 15px;

    svg {
      color: ${({ theme }) => theme.palette.secondary.main};
      margin-right: 5px;
    }
  }

  .list-item {
    display: flex;
  }
  .serial-number {
    min-width: 26px;
  }

  & .column-text {
    column-count: 1;
    column-gap: 60px;

    @media ${DeviceUp.sm} {
      column-count: 2;
    }

    .text-box {
      padding-bottom: 30px;
      page-break-inside: avoid;
      break-inside: avoid-column;
      display: table;
    }
  }
`;

export const BreadCrumbsHolder = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  ul {
    margin: 0px;
  }

  .back-btn {
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
`;
