import { FC, SyntheticEvent, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { StyleToggleButtonGroup, StyledToggleButton } from './style';
import ResourcesDetails from './ResourcesDetails';
import Documents from './Documents';
import { useParams } from 'react-router-dom';
import { get, map } from 'lodash';
import { getPlatformName } from 'utils/utility';

const RESOURCES_VIEW: string = 'resources-details';
const MY_DOCUMENTS_VIEW: string = 'my-documents';

const tabs = [
  { ariaLabel: 'Resources view', label: 'Resources', id: 'resources-details' },
  { ariaLabel: 'My Documents view', label: 'My Documents', id: 'my-documents' },
];

const Resources: FC<any> = () => {
  const routerParams = useParams<any>();
  const activeSubSection = get(
    routerParams,
    'activeSubSection',
    'resources-details',
  );

  return (
    <>
      <Stack alignItems='center'>
        <StyleToggleButtonGroup aria-label='resources view'>
          {map(tabs, (tab, index) => (
            <StyledToggleButton
              key={index}
              className={activeSubSection === tab.id ? 'active' : ''}
              aria-label={tab.ariaLabel}
              to={`${getPlatformName()}/resources/${tab.id}`}
            >
              {tab.label}
            </StyledToggleButton>
          ))}
        </StyleToggleButtonGroup>
      </Stack>

      <Box>
        {activeSubSection === RESOURCES_VIEW ? (
          <ResourcesDetails />
        ) : (
          <Documents />
        )}
      </Box>
    </>
  );
};

export default Resources;
