import { FormControl, FormLabel, Popper as MuiPopper, styled } from '@mui/material';
import { theme } from 'styles/theme';

export const StyledFormControl = styled(FormControl)`
  & .MuiInputBase-root.MuiOutlinedInput-root {
    font-weight: 700;

    &.Mui-focused {
      color: ${({ theme }) => theme.palette.secondary.light};

      & svg {
        color: ${({ theme }) => theme.palette.secondary.light};
      }

      & fieldset {
        border-color: ${({ theme }) => theme.palette.secondary.light};
      }
    }

    &:hover {
      color: ${({ theme }) => theme.palette.secondary.main};

      & svg {
        color: ${({ theme }) => theme.palette.secondary.main};
      }

      & fieldset {
        border-color: ${({ theme }) => theme.palette.secondary.main};
      }
    }

    & fieldset {
      border-radius: 0;
      border: none;
      border-bottom: 1px solid ${({ theme }) => theme.palette.ternary.main};
    }
  }
`;

export const StyledFormLabel = styled(FormLabel)`
  display: flex;
  align-items: start;
  color: ${({ theme }) => theme.palette.text.primary};
  margin-top: 8px;
  font-weight: 700;
`;
