import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import { LMS_BASE_URL } from 'config';
import axiosClient from 'utils/client';

const SEARCH_API_URL = `${LMS_BASE_URL}/api/search`;

const searchMembersAdapter = createEntityAdapter();

const initialState = searchMembersAdapter.getInitialState({
  isFetching: false,
  isSuccess: false,
  isError: false,
  error: null as any,
  hasMore: true,
  page: 0,
  total: 0,
});

export const fetchSearchMembers = createAsyncThunk(
  'globalSearchMembers/list',
  async (query: string, thunkApi: any) => {
    const page = thunkApi.getState().globalSearchMembers.page;
    const nextPage = page + 1;
    const res = await axiosClient.get(
      `${SEARCH_API_URL}/?search=${query}&member_page=${nextPage}&page_size=15`,
    );
    return res.data;
  },
  {
    condition: (_, thunkApi: any) => {
      const { isFetching, hasMore } = thunkApi.getState().globalSearchMembers;
      if (isFetching || !hasMore) return false;
    },
  },
);

const globalSearchMembersSlice = createSlice({
  name: 'globalSearchMembers',
  initialState,

  reducers: {
    resetGlobalSearchMembers: () => initialState,
    addSearchMembers: (state, action) => {
      const { count = 0, results = [] } = action.payload || {};
      state.total = count;
      searchMembersAdapter.upsertMany(state, results);
    },
  },

  extraReducers: builder => {
    builder
      .addCase(fetchSearchMembers.pending, (state, action) => {
        state.isFetching = true;
        state.isSuccess = false;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchSearchMembers.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;

        const members = action.payload['members'];
        const { count, next, results } = members;
        state.hasMore = !!next;
        state.page += 1;
        state.total = count;

        searchMembersAdapter.upsertMany(state, results);
      })
      .addCase(fetchSearchMembers.rejected, (state, action) => {
        state.isFetching = false;
        state.isSuccess = false;
        state.isError = true;
        state.error = action.payload;
      });
  },
});

export const { resetGlobalSearchMembers, addSearchMembers } = globalSearchMembersSlice.actions;

export default globalSearchMembersSlice.reducer;

export const selectGlobalSearchMembers = (state: any) =>
  state.globalSearchMembers;

export const { selectAll: selectAllSearchMembers } =
  searchMembersAdapter.getSelectors(selectGlobalSearchMembers);
