import { Box, styled, Stepper } from '@mui/material';

export const StyledStepper = styled(Stepper)`
  .MuiStepButton-root {
    &.Mui-disabled {
      .MuiStepLabel-labelContainer,
      .MuiStepIcon-text {
        opacity: 0.4;
      }
    }
    .MuiStepLabel-label {
      font-size: 16px;
      color: ${({ theme }) => theme.palette.primary.main};

      &.Mui-disabled {
        color: ${({theme}) => theme.palette.ternary.greyShade};
      }
      &.Mui-completed {
        color: ${({ theme }) => theme.palette.success.main};
      }
    }
    .MuiStepLabel-iconContainer {
      &.Mui-disabled {
        svg {
          color: ${({theme}) => theme.palette.ternary.light};
          opacity: 1;
        }
        .MuiStepIcon-text {
          fill: ${({theme}) => theme.palette.ternary.greyShade};
        }
      }
      svg {
        width: 40px;
        height: 40px;
      }
      .MuiStepIcon-text {
        font-weight: 700;
      }
    }

    & .Mui-completed {
      color: ${({ theme }) => theme.palette.success.main};
    }
  }
  .MuiDivider-root {
    bottom: unset;
    top: 20px;
    border-style: dashed;
  }
`;

export const Title = styled(Box)`
  justify-content: center;
  display: flex;
`;

export const ActionHolder = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  margin-bottom: 30px;
`;

export const ContentWrapper = styled(Box)<any>((props: any) => {
  return `
  margin: 90px auto 0px;
  max-width: ${props.isSecondStep ? 'auto' : '534px'};
`;
});
