import { memo, FunctionComponent } from 'react';
import {Checkbox as StyledCheckbox} from 'elements/Checkbox/style';

const Checkbox: FunctionComponent<any> = ({ size, disabled, defaultChecked, handleChange, checked, value, name=''}) => {
  return (
    <StyledCheckbox
      size={size}
      disabled={disabled}
      defaultChecked={defaultChecked}
      onChange={handleChange}
      checked={checked}
      value={value}
      name={name}
    />
  );
};


export default memo(Checkbox);
