import { FC, useState, useEffect } from 'react';
import { Box, Button, RadioGroup, Stack, Typography } from '@mui/material';
import Slider from 'react-slick';
import { toast } from 'react-toastify';
import { isEmpty, size, trim } from 'lodash';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
// @ts-ignore
import { getAuthenticatedUser } from '@edx/frontend-platform/auth';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

import Modal from 'elements/Modal';
import { Radio } from 'elements/Radio/style';
import TextField from 'elements/Form/TextField';
import ConfirmIcon from 'elements/Svgs/Confirm';
import DropdownField from 'elements/DropdownField';
import { FieldWrapper } from 'elements/Form/style';
import CheckboxesAutoComplete from 'elements/AutoComplete/CheckboxesAutoComplete';
import { StyledLabel as FormControlLabel } from 'elements/FormControlLabel/style';

import { useValidations } from 'hooks';
import { getUserName } from 'utils/utility';
import useProfilePage from 'pages/Profile/useProfilePage';

import {
  useGetClubsQuery,
  useGetClubMembersQuery,
  useGiveFeedbackMutation,
  useGetFeedbackBadgesQuery,
  useUpdateFeedbackMutation,
} from '../feedbackApi';
import { resetFeedbacks, fetchFeedbacks } from '../feedbackSlice';

import BadgeSelectItem from './BadgeSelectItem';
import { getValidationSchema } from './validationSchema';

import { SliderWrapper } from './style';
import { ImageWrapper } from './style';

const INITIAL_VALUES = {
  clubId: '',
  clubMembers: [],
  feedback: '',
  badgeId: '',
  visibility: 'public',
};

const GiveFeedback: FC<any> = props => {
  const user = getAuthenticatedUser();
  const { username, isMemberProfilePage } = useProfilePage();
  const dispatch = useDispatch();

  const [club, setClub] = useState('');
  const [initialValues, setInitialValues] = useState<any>(INITIAL_VALUES);

  const {
    modalOpen,
    handleClose,
    handleOpen,
    selectedClub,
    selectedClubName,
    selectedMembers = [],
    isRequestedFeedback = false,
    feedbackData = {},
    handleRequestedFeedback = () => {},
    refetchRequestFeedback = () => {},
    isEditModal = false,
    resetEditModal = () => {},
  } = props;

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const handleCloseConfirmation = () => {
    setConfirmationModalOpen(false);
    resetEditModal();
    handleClose();
    resetState();
  };

  const handleCloseFeedbackModal = () => {
    handleClose();
    resetState();
    resetEditModal();
  };

  const handleOpenConfirmation = () => {
    handleClose();
    setConfirmationModalOpen(true);
  };

  const handleFormSubmitted = () => {
    handleClose();
    setConfirmationModalOpen(false);
  };

  const handleGoBack = () => {
    setConfirmationModalOpen(false);
    handleOpen();
  };

  const resetState = () => {
    setInitialValues(INITIAL_VALUES);
    setClub(selectedClub ?? '');
    handleFormSubmitted();
    resetForm();
  };

  const handleBadgeChange = (e: any) => {
    if (e.target.checked) setFieldValue('badgeId', e.target.value);
    else setFieldValue('badgeId', e.target.value);
  };

  const [giveFeedback, { isLoading }] = useGiveFeedbackMutation();
  const [updateFeedback, { isLoading: isFeedbackUpdating }] =
    useUpdateFeedbackMutation();

  const {
    data: clubs,
    isFetching: isFetchingClubs,
    isSuccess: isSuccessClubs,
  } = useGetClubsQuery({}, { skip: isRequestedFeedback || selectedClub || !modalOpen });

  useEffect(() => {
    if (clubs?.length === 1) {
      setInitialValues((prev: any) => ({
        ...prev,
        clubId: clubs[0].id,
        clubMembers: [],
      }));
      setClub(clubs[0].id);
    }
  }, [clubs, club]);

  const {
    data: clubMembers,
    isFetching: isFetchingMembers,
    isSuccess: isSuccessMembers,
  } = useGetClubMembersQuery(
    { clubId: club },
    { skip: club === '' || isRequestedFeedback || selectedClub || !modalOpen},
  );

  const { data: feedbackBadges, isSuccess: isSuccessBadges } =
    useGetFeedbackBadgesQuery({});

  const handleGiveFeedback = async () => {
    const { badgeId, clubId, clubMembers, feedback, visibility } = formikValues;
    const membersIds = clubMembers.map(({ id }: Record<string, string>) => id);

    const data = {
      club: clubId,
      receiver: membersIds,
      isPrivate: visibility === 'private',
      sender: user.userId,
      feedback,
      badge: badgeId ? badgeId : null,
      ...(isRequestedFeedback && {
        feedbackRequest: feedbackData.id,
      }),
      ...(isEditModal && {
        id: feedbackData.id,
      }),
    };

    try {
      if (isEditModal) {
        await updateFeedback(data).unwrap();
      } else {
        await giveFeedback(data).unwrap();
      }

      (isRequestedFeedback || isEditModal) && refetchRequestFeedback();
      toast.success(
        `Your feedback has been successfully ${
          isEditModal ? 'updated' : 'posted'
        }`,
      );
      resetState();
      handleRequestedFeedback();
      resetEditModal();
      if (isMemberProfilePage) {
        dispatch(resetFeedbacks());
        dispatch(fetchFeedbacks({ username }));
      }
    } catch (error: any) {
      toast.error(error?.data || 'Something went wrong!');
    }
  };

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }: any) => (
    <button
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden='true'
      role='tab'
      aria-disabled={currentSlide === 0 ? true : false}
      type='button'
    >
      <KeyboardArrowLeft />
    </button>
  );

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }: any) => (
    <button
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden='true'
      role='tab'
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type='button'
    >
      <KeyboardArrowRight />
    </button>
  );

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: getValidationSchema(false, false),
    onSubmit: () => {
      handleOpenConfirmation();
    },
  });

  const {
    setFieldValue,
    values: formikValues,
    setFieldTouched,
    touched,
    errors,
    resetForm,
  }: any = formik;

  const handleUpdateField = (event: any) => {
    const { name, value } = event.target;
    const isFeedbackField = name === 'feedback';
    const updatedValue = size(trim(value)) > 0 ? value : '';

    setFieldValue(name, isFeedbackField ? updatedValue : value);
    setFieldTouched(name, true, false);

    if (name === 'clubId') {
      setClub(value);
      setFieldValue('clubMembers', [], false);
      setFieldTouched(name, false, false);
    }
  };

  const clubOptions = clubs?.reduce(
    (acc: any, { name, id }: any) => [
      ...acc,
      {
        label: name,
        value: id,
      },
    ],
    [],
  );

  useEffect(() => {
    if (!isEmpty(selectedMembers) && selectedClub) {
      setClub(selectedClub);
      setInitialValues((prev: any) => ({
        ...prev,
        clubId: selectedClub,
        clubMembers: selectedMembers.map((user: any) => ({
          id: user?.id,
          name: getUserName(user),
        })),
      }));
    }
  }, [selectedClub, selectedMembers]);

  useEffect(() => {
    const { feedback, badgeId } = formikValues;
    if (isEditModal) {
      setClub(selectedClub);

      setInitialValues((prev: any) => ({
        ...prev,
        ...(!feedback && { feedback: feedbackData?.feedback || '' }),
        ...(!badgeId && { badgeId: feedbackData?.badge?.id || '' }),
        clubId: feedbackData?.club?.id,
        clubMembers: selectedMembers.map((user: any) => ({
          id: user?.id,
          name: getUserName(user),
        })),
      }));

      return;
    }

    if (isRequestedFeedback) {
      setInitialValues((prev: any) => ({
        ...prev,
        clubId: feedbackData?.club,
        clubMembers: [
          {
            id: feedbackData.sender.id,
            name: getUserName(feedbackData?.sender),
          },
        ],
        visibility: feedbackData?.isPrivate ? 'private' : 'public',
      }));
    }
  }, [
    isRequestedFeedback,
    JSON.stringify(feedbackData),
    isEditModal,
    selectedMembers,
  ]);

  useValidations(formik);

  return (
    <>
      {modalOpen ? (
        <Modal
          title={`${isEditModal ? 'Edit' : 'Give'} Feedback`}
          handleClose={() => {
            resetForm();
            handleRequestedFeedback();
            handleCloseFeedbackModal();
          }}
        >
          <Box mt={1.5}>
            {selectedClub && selectedClubName ? (
              <FieldWrapper mb={4}>
                <FormControlLabel
                  label='Club'
                  labelPlacement='top'
                  id='choose_club'
                  control={
                    <TextField
                      fullWidth
                      className='has-data'
                      disabled={true}
                      id='selectClub'
                      name='clubId'
                      value={selectedClubName}
                      onChange={handleUpdateField}
                      helperText={touched['clubId'] && errors['clubId']}
                      error={!!touched['clubId'] && !!errors['clubId']}
                      inputProps={{'aria-labelledby': 'choose_club'}}
                    />
                  }
                />
              </FieldWrapper>
            ) : null}
            {selectedClub ? (
              <FieldWrapper mb={4}>
                <FormControlLabel
                  label='Member'
                  labelPlacement='top'
                  id='choose_member'
                  control={
                    <TextField
                      fullWidth
                      className='has-data'
                      disabled={true}
                      id='selectMember'
                      name='clubMembers'
                      value={formikValues.clubMembers[0]?.name}
                      onChange={handleUpdateField}
                      helperText={
                        touched['clubMembers'] && errors['clubMembers']
                      }
                      error={
                        !!touched['clubMembers'] && !!errors['clubMembers']
                      }
                      inputProps={{'aria-labelledby': 'choose_member'}}
                    />
                  }
                />
              </FieldWrapper>
            ) : null}
            {!isRequestedFeedback && !selectedClub && (
              <FieldWrapper mb={4}>
                <FormControlLabel
                  label='Club'
                  labelPlacement='top'
                  id='feedback_club'
                  control={
                    <DropdownField
                      labelId='feedback_club'
                      options={clubOptions}
                      className={`h-50 ${
                        formikValues.clubId ? 'has-data' : ''
                      }`}
                      value={formikValues.clubId}
                      name='clubId'
                      helperText={touched['clubId'] && errors['clubId']}
                      error={!!touched['clubId'] && !!errors['clubId']}
                      handleChange={handleUpdateField}
                      isLoading={isFetchingClubs}
                      placeholder='Select a club'
                    />
                  }
                />
              </FieldWrapper>
            )}

            {!isRequestedFeedback && !selectedClub && (
              <FieldWrapper mb={4}>
                <FormControlLabel
                  label='Member(s)'
                  labelPlacement='top'
                  control={
                    <CheckboxesAutoComplete
                      multiple
                      id='select-club-members'
                      options={
                        isSuccessMembers
                          ? clubMembers
                              .filter((cm: any) => cm.id !== user.userId)
                              .map((cm: any) => ({
                                ...cm,
                                name: getUserName(cm),
                              }))
                          : []
                      }
                      disableCloseOnSelect
                      loading={isFetchingMembers}
                      loadingText='Loading Members'
                      getOptionLabel={(option: any) => option.name}
                      defaultValue={formikValues.clubMembers}
                      selectedOptions={(members: any) => {
                        setFieldValue('clubMembers', members);
                        setFieldTouched('clubMembers', true, false);
                      }}
                      placeholder='Select member(s)'
                      name='clubMembers'
                      helperText={
                        touched['clubMembers'] && errors['clubMembers']
                      }
                      error={
                        !!touched['clubMembers'] && !!errors['clubMembers']
                      }
                    />
                  }
                />
              </FieldWrapper>
            )}

            {isRequestedFeedback && (
              <FieldWrapper mb={1}>
                <Typography variant='h4'>{feedbackData.request}</Typography>
              </FieldWrapper>
            )}

            <FieldWrapper mb={4}>
              <FormControlLabel
                label={isRequestedFeedback ? '' : 'Feedback'}
                labelPlacement='top'
                control={
                  <TextField
                    multiline
                    fullWidth
                    className={`${
                      formikValues.feedback
                        ? 'has-data textarea-italic'
                        : ' textarea-italic'
                    }`}
                    id='feedback'
                    placeholder='For example: You inspired the audience with your personal story, passion, and energy.'
                    name='feedback'
                    value={formikValues.feedback}
                    onChange={handleUpdateField}
                    helperText={touched['feedback'] && errors['feedback']}
                    error={!!touched['feedback'] && !!errors['feedback']}
                    inputProps={{'aria-label': 'Feedback'}}
                  />
                }
              />
            </FieldWrapper>

            {isSuccessBadges ? (
              <SliderWrapper mt={7.5} px={3}>
                <Slider {...settings}>
                  {feedbackBadges.map((badge: any) => (
                    <BadgeSelectItem
                      badge={badge}
                      selectedBadgeId={formikValues.badgeId}
                      handleBadgeChange={handleBadgeChange}
                    />
                  ))}
                </Slider>
              </SliderWrapper>
            ) : null}
          </Box>

          <Box mt={10}>
            <Stack direction='column' ml={4}>
              {!isRequestedFeedback && !isEditModal && (
                <>
                  <Stack direction='column'>
                    <Typography id='feedback-visibility' variant='h4' component='label'>Visibility</Typography>
                    <RadioGroup
                      aria-labelledby='feedback-visibility'
                      name='visibility'
                      value={formikValues.visibility}
                      onChange={e => handleUpdateField(e)}
                    >
                      <FormControlLabel
                        value='public'
                        control={<Radio size='small' />}
                        label='Visible to all club members'
                      />
                      <FormControlLabel
                        value='private'
                        control={<Radio size='small' />}
                        label='Visible to selected member(s) only'
                      />
                    </RadioGroup>
                  </Stack>
                  <br />
                </>
              )}
              <Button
                sx={{ alignSelf: 'flex-end' }}
                variant='contained'
                size='small'
                onClick={() => {
                  formik.handleSubmit();
                }}
              >
                {isEditModal ? 'Update' : 'Post feedback'}
              </Button>
            </Stack>
          </Box>
        </Modal>
      ) : null}

      {confirmationModalOpen ? (
        <Modal title='Confirmation' handleClose={handleCloseConfirmation}>
          <ImageWrapper>
            <ConfirmIcon />
          </ImageWrapper>
          <Typography textAlign='center' variant='h3' fontWeight={400}>
            Are you sure you want to
            {`${
              isEditModal
                ? ' update this feedback for '
                : ' post this feedback to '
            }`}
            {[
              formikValues.clubMembers
                .map((m: any) => getUserName(m))
                .slice(0, -1)
                .join(', '),
              formikValues.clubMembers
                .map((m: any) => getUserName(m))
                .slice(-1)[0],
            ].join(
              formikValues.clubMembers.length === 1
                ? ''
                : formikValues.clubMembers.length < 3
                ? ' and '
                : ', and ',
            )}
            ?
          </Typography>

          <Stack
            direction='row'
            alignItems='center'
            justifyContent='center'
            gap={2.5}
            mt={15}
          >
            <Button
              variant='outlined'
              onClick={handleGoBack}
              disabled={isLoading || isFeedbackUpdating}
            >
              Back
            </Button>
            <Button
              variant='contained'
              onClick={handleGiveFeedback}
              disabled={isLoading || isFeedbackUpdating}
            >
              Post
            </Button>
          </Stack>
        </Modal>
      ) : null}
    </>
  );
};

export default GiveFeedback;
