import {styled, Box as ModalBox, Dialog as ModalHolder, DialogContent} from "@mui/material";
import {theme} from "styles/theme";
import {DeviceUp} from 'styles/breakpoints';

export const Dialog = styled(ModalHolder)`
  .MuiDialog-paper {
    width: 100%;
    max-width: 615px;
  }

  &.modal-xs {
    .MuiDialog-paper {
      max-width: 535px;

      & .MuiDialogContent-root {
        padding: 0 30px 30px;
      }
    }
  }

  &.modal-sm {
    .MuiDialog-paper {
      max-width: 570px;
    }
  }

  &.modal-lg {
    .MuiDialog-paper {
      max-width: 795px;
    }
  }

  &.modal-xl {
    .MuiDialog-paper {
      max-width: 1024px;
    }
  }

  &.modal-xxl {
    .MuiDialog-paper {
      max-width: 1440px;
    }
  }
`;

export const Box = styled(DialogContent)`
  background: ${({ theme }) => theme.palette.ternary.white};
  padding: 0 15px 20px;
  position: relative;

  @media ${DeviceUp.sm} {
    padding: 0 40px 40px;
  }

  & .MuiButton-outlined,
  & .MuiButton-contained {
    min-width: 120px;
  }
`;

export const CloseIconHolder = styled('button')`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 3;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;

  &.withOutTitleCloseIcon {
    top: 12px;
    right: 12px;
  }

  svg {
    font-size: 24px;
    display: block;
  }
`;

export const ModalHeading = styled('div')`
  position: relative;
  text-align: center;

  & h2 {
    @media ${DeviceUp.md} {
      font-size: 1.5em;
    }
  }

  & .MuiTypography-root {
    margin: 0;
    padding: 40px;
  }

  &.withOutTitle {
    .MuiTypography-root {
      padding-bottom: 0px;
    }

    & .p-0 {
      padding: 0;
    }
  }
`;
