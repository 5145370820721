import { Card, styled } from '@mui/material';

export const StyledDocCard = styled(Card)`
  box-shadow: 0px 2px 4px rgba(0, 0, 41, 0.16);
  position: relative;

  & .cardContent-bottom {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  & .CardContent-doc {
    background-color: ${props => props.theme.palette.ternary.light};
    height: 50px;
    padding: 0.85rem 0.75rem;
    flex-grow: 1;
    max-width: calc(100% - 100px);

    .MuiStack-root {
      justify-content: flex-end;
    }
  }

  & .CardActions-doc {
    background-color: ${props => props.theme.palette.ternary.main};
    height: 50px;
    width: 100px;
  }

  & .MuiCardMedia-media {
    &.MuiCardMedia-img {
      height: 100%;
      border-bottom: 1px solid ${props => props.theme.palette.primary.main};
    }
  }

  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 41, 0.16);
  }
`;
