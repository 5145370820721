const getFont = (dynamicTheme: any) => {
  let font = dynamicTheme?.bodyFontFamily?.split(',')[0];
  font = font?.split('+').join(' ');
  return font;
};

const themePayload = (dynamicTheme: any) => {
  return {
    palette: {
      primary: {
        main: dynamicTheme.primaryColor,
        light: dynamicTheme.primaryActiveColor,
        dark: dynamicTheme.primaryHoverColor,
        contrastText: '#fff',
      },
      secondary: {
        main: dynamicTheme.secondaryColor,
        light: dynamicTheme.secondaryActiveColor,
        dark: dynamicTheme.secondaryHoverColor,
        contrastText: '#fff',
      },
      ternary: {
        light: dynamicTheme.grey1,
        main: dynamicTheme.grey2,
        dark: dynamicTheme.grey3,
        black: '#000',
        white: '#fff',
        mediumGray: '#CCCCCC',
        greyShade: dynamicTheme.grey4,
        tertiary1: dynamicTheme.tertiary1,
        tertiary2: dynamicTheme.tertiary2,
        tertiary3: dynamicTheme.tertiary3,
        tertiary4: dynamicTheme.tertiary4,
        tertiary5: dynamicTheme.tertiary5,
        tertiary6: dynamicTheme.tertiary6,
        tertiary7: dynamicTheme.tertiary7,
        tertiary8: dynamicTheme.tertiary8,
        tertiary9: dynamicTheme.tertiary9,
        tertiary10: dynamicTheme.tertiary10,
        tertiary11: dynamicTheme.tertiary11,
        tertiary12: dynamicTheme.tertiary12,
        focusBlue: dynamicTheme.tertiary3,
      },
      error: {
        dark: '#FF0000',
        main: dynamicTheme.semantic2,
      },
      success: {
        main: dynamicTheme.semantic1,
      },
      warning: {
        main: dynamicTheme.tertiary7,
      },
      info: {
        main: dynamicTheme.tertiary1,
      },
      action: {
        disabled: `${dynamicTheme.primaryColor}80`,
        disabledBackground: `${dynamicTheme.primaryColor}40`,
      },
      text: {
        primary: dynamicTheme.grey4,
      },
    },
    typography: {
      fontFamily: getFont(dynamicTheme),
    },
  };
};

const muiThemeMapping = (dynamicTheme: any) => {
  const themeConfig = themePayload(dynamicTheme);
  return {
    ...themeConfig,
    components: {
      MuiButtonBase: {
        styleOverrides: {
          root: {
            '&:focus-visible': {
              outline: '3px solid' + themeConfig.palette.ternary.focusBlue,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            '&:active': {
              backgroundColor: themeConfig.palette.primary.light,
              color: themeConfig.palette.primary.contrastText,
            },
            '&:focus-visible': {
              backgroundColor: themeConfig.palette.primary.dark,
              color: themeConfig.palette.primary.contrastText,
            },
          },
        },
        variants: [
          {
            props: { variant: 'contained' },
            style: {
              '&:disabled': {
                color: themeConfig.palette.primary.contrastText,
              },
            },
          },
          {
            props: { variant: 'text' },
            style: {
              '&:hover': {
                color: themeConfig.palette.primary.dark,
              },
              '&:active': {
                color: themeConfig.palette.primary.light,
              },
              '&:focus-visible': {
                color: themeConfig.palette.primary.dark,
              },
            },
          },
          {
            props: { variant: 'contained', color: 'secondary' },
            style: {
              '&:hover': {
                background: themeConfig.palette.secondary.dark,
              },
              '&:active': {
                background: themeConfig.palette.secondary.light,
              },
              '&:focus-visible': {
                background: themeConfig.palette.secondary.dark,
              },
            },
          },
          {
            props: { variant: 'text', color: 'secondary' },
            style: {
              '&:hover': {
                color: themeConfig.palette.secondary.dark,
              },
              '&:active': {
                color: themeConfig.palette.secondary.light,
              },
              '&:focus-visible': {
                color: themeConfig.palette.secondary.dark,
              },
            },
          },
          {
            props: { variant: 'outlined' },
            style: {
              borderColor: themeConfig.palette.primary.main,
              '&:hover': {
                background: themeConfig.palette.primary.dark,
                borderColor: themeConfig.palette.primary.dark,
                color: themeConfig.palette.ternary.white,
              },
              '&:active': {
                backgroundColor: themeConfig.palette.primary.light,
                borderColor: themeConfig.palette.primary.light,
              },
              '&:disabled': {
                color: themeConfig.palette.primary.main,
              },
            },
          },
          {
            props: { color: 'ternary' },
            style: {
              '&:hover': {
                background: themeConfig.palette.ternary.light,
              },
              '&:active': {
                background: themeConfig.palette.text.primary,
                color: themeConfig.palette.primary.contrastText,
              },
              '&.active': {
                background: themeConfig.palette.text.primary,
                color: themeConfig.palette.primary.contrastText,
              },
              '&:focus-visible': {
                backgroundColor: themeConfig.palette.ternary.dark,
              },
              '&:disabled': {
                background: themeConfig.palette.ternary.main,
                color: themeConfig.palette.text.primary,
              },
            },
          },
        ],
      },
      MuiTypography: {
        variants: [
          {
            props: { variant: 'title' },
            style: {
              '&::before': {
                backgroundColor: themeConfig.palette.primary.main,
              },
            },
          },
        ],
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            '& fieldset': {
              borderColor: themeConfig.palette.ternary.greyShade, 
            },
            '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
              borderColor: themeConfig.palette.secondary.light 
            },
            '&.Mui-focused.Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
                borderColor: themeConfig.palette.error.main
            },
            '&.Mui-disabled fieldset.MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.23)'
            },
            '& .MuiInputBase-root.MuiInputBase-colorSuccess': {
                '& fieldset.MuiOutlinedInput-notchedOutline': {
                    borderColor: themeConfig.palette.success.main
                }
            },
            '& .MuiInputBase-root.MuiInputBase-colorSuccess + .MuiFormHelperText-root': {
                color: themeConfig.palette.success.main
            }
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            fieldset: {
              borderColor: themeConfig.palette.ternary.greyShade,
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              background: themeConfig.palette.primary.light,
              color: themeConfig.palette.primary.contrastText,

              '&:hover': {
                background: themeConfig.palette.primary.light,
                color: themeConfig.palette.primary.contrastText,
              },

              '&.Mui-focusVisible': {
                background: themeConfig.palette.primary.light,
              },
            },
          },
        },
      },
    },
  };
};

export { muiThemeMapping };
