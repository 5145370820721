import React, { memo, FunctionComponent } from 'react';
import { Button as StyledButton } from 'elements/Button/style';
import { IButton } from 'elements/Button/types';

const Button: FunctionComponent<IButton> = ({
  className,
  children,
  onClick,
  disabled,
  variant,
  type,
  fullWidth,
  startIcon,
  endIcon,
  color,
  isFilterDropdown,
  ariaLive
}) => {
  className = `btn ${className}`;
  return (
    <StyledButton
      fullWidth={fullWidth}
      disabled={disabled}
      variant={variant}
      className={className}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      color={color}
      type={type || 'button'}
      isFilterDropdown={isFilterDropdown}
      aria-live={ariaLive}
    >
      {children}
    </StyledButton>
  );
};

Button.defaultProps = {
  disabled: false,
  className: '',
  color: 'primary',
  variant: "contained",
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => {},
};

export default memo(Button);
