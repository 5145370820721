import baseApiSlice from 'api/baseApiSlice';

const BASE_API_URL = 'api/evaluation_forms';

const evaluationFeedbackApiWithTags = baseApiSlice.enhanceEndpoints({
  addTagTypes: ['evaluation form'],
});

const evaluationFeedbackApiSlice =
  evaluationFeedbackApiWithTags.injectEndpoints({
    endpoints: builder => ({
      getEvaluationForm: builder.query({
        query: (formId: any) => ({
          url: `${BASE_API_URL}/form/${formId}/`,
        }),
      }),

      getSubmittedEvaluationForm: builder.query({
        query: (formId: any) => ({
          url: `${BASE_API_URL}/response/${formId}/`,
        }),
        transformResponse: data => {
          return {
            ...data,
            ...data.request,
          };
        },
      }),

      getEvaluationFormRequest: builder.query({
        query: (requestId: any) => ({
          url: `${BASE_API_URL}/evaluation_request/${requestId}/`,
        }),
      }),

      postEvaluationForm: builder.mutation({
        query: (data: any) => ({
          url: `${BASE_API_URL}/response/`,
          method: 'POST',
          data,
        }),
      }),

      postEvaluationFeedbackForm: builder.mutation({
        query: (data: any) => ({
          url: `${BASE_API_URL}/direct/response/`,
          method: 'POST',
          data,
        }),
      }),
    }),
  });

export const {
  useGetEvaluationFormQuery,
  useGetSubmittedEvaluationFormQuery,
  useGetEvaluationFormRequestQuery,
  usePostEvaluationFormMutation,
  usePostEvaluationFeedbackFormMutation,
} = evaluationFeedbackApiSlice;

export default evaluationFeedbackApiSlice;
