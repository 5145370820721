import { styled, ToggleButtonGroup } from '@mui/material';
import { Link } from 'react-router-dom';
import { DeviceUp } from 'styles/breakpoints';

export const StyleToggleButtonGroup = styled(ToggleButtonGroup)`
  padding: 10px;
  background: ${({ theme }) => theme.palette.ternary.white};
  border: 1px solid ${({ theme }) => theme.palette.ternary.main};

  .MuiToggleButtonGroup-grouped {
    &:not(:first-of-type) {
      border: 1px solid transparent;
      margin-left: 5px;

      &:hover {
        border-color: ${({ theme }) => theme.palette.ternary.main};
      }
    }

    &.active {
      border-color: ${({ theme }) => theme.palette.ternary.dark};
      background: ${({ theme }) => theme.palette.ternary.dark};
      color: ${({ theme }) => theme.palette.ternary.white} !important;

      &:hover {
        border-color: ${({ theme }) => theme.palette.ternary.dark};
        background: ${({ theme }) => theme.palette.ternary.dark};
      }
    }
  }
`;

export const StyledToggleButton = styled(Link)`
  text-transform: capitalize;
  font-weight: 700;
  font-size: 15px;
  padding: 10px 15px;
  color: ${({ theme }) => theme.palette.ternary.greyShade};
  border: 1px solid transparent;
  border-radius: 3px !important;

  &:hover {
    border-color: ${({ theme }) => theme.palette.ternary.main};
  }

  @media ${DeviceUp.sm} {
    font-size: 16px;
    padding: 9px 20px;
    min-width: 170px;
    text-align: center;
  }

  &.disabled {
    pointer-events: none;
    color: #aaa;
  }
`;
